import { useEffect, useState, useContext } from 'react';
import { useParams, NavLink } from 'react-router-dom';

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import Sidebar from '../../../components/Sidebar/Sidebar';
import AdminNavbar from '../../../components/AdminNavbar/AdminNavbar';
import { TeamContext, TeamContextProvider } from '../../../contexts/TeamContext';
import { getYourFormDetailSubmitted } from '../../../libs/dataFunctions';
import Spinner from '../../../components/Spinner/Spinner';
import tinyCalendarIcon from "../../../assets/images/icon-tiny-calendar.svg";
import placeholderImg from '../../../assets/images/placeholder.png';
import iconTimeTiny from '../../../assets/images/icon-time-tiny.svg';
import iconDownloadPdf from '../../../assets/images/icon-download-formpdf.svg';
import "./yourformresponse.scss";

dayjs.extend(utc);
dayjs.extend(tz);

const AdminFormResponseView=({formId})=>{
    const { team, teamUpdating } = useContext(TeamContext);
    const [loading, setLoading] = useState(false);
    const [formItem, setFormItem] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const fetchFormdata = async()=> {
        setLoading(true);
        const [formData] = await Promise.all([
            getYourFormDetailSubmitted(team._id, token, formId)
        ]);
        setFormItem(formData.data);
        setLoading(false);
    };

    const handleViewSheet=async()=>{
        setButtonLoading(true);
        var formdata=new FormData();        
        formdata.append("type", "download_form_data_in_sheet");
        formdata.append("team_id", team._id);
        formdata.append("form_id",formItem.form_id);
        formdata.append("user_sub", formItem.sub);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_forms' , requestOptions)
        .then(response => response.text())
        .then(result => {
            var res=JSON.parse(result)
            window.open(res.data.pdf, '_blank');
            setButtonLoading(false);
        })
        .catch(error => console.log('error', error));
    }

    const handleDownload=async()=>{
        setButtonLoading(true);
        var formdata=new FormData();        
        formdata.append("type", "download_form_data_in_pdf");
        formdata.append("team_id", team._id);
        formdata.append("form_id",formItem.form_id);
        formdata.append("user_sub", formItem.sub);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_forms' , requestOptions)
        .then(response => response.text())
        .then(result => {
            var res=JSON.parse(result)
            window.open(res.data.pdf, '_blank');
            setButtonLoading(false);
        })
        .catch(error => console.log('error', error));
    }
    
    useEffect(() => {
        setLoading(true);
        if (!teamUpdating) {
            if(team.role!=='admin' && (team.role!=='member' || team.admin_groups_new === undefined || team.admin_groups_new.length<=0)){
                window.location="/";
            }
            else{                
                fetchFormdata();
            }
        }
    }, [teamUpdating]);

    return (
        <>
        {!loading ? 
        <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}/your-forms`}>
                    Forms
                </NavLink>
                <span className='separator'>&lt;</span>
                {formItem.title}
            </div>
            <div className='body px-5 lg:px-8 py-10'>
                <div className='p-7.5'>
                    <div className="form-detail-card bg-white py-6 px-5 lg:px-9.5 relative">
                        <div>
                            <div className={`flex gap-2.5 items-center mb-6`}>
                                <div className='w-12.5 lg:w-22 mr-2.5'>
                                {formItem.profilePicture !== '' ?  
                                    <img src={formItem.profilePicture} alt="" width={88} height={88} className="rounded-full w-12.5 lg:w-22 h-12.5 lg:h-22" />
                                :
                                    <img src={placeholderImg} alt="" width={88} height={88} className="rounded-full w-12.5 lg:w-22 h-12.5 lg:h-22" />
                                }
                                </div>
                                <div className='flex-1'>
                                    <div className='pr-2.5 text-base font-semibold uppercase'>{formItem.name}</div>
                                    <div className="flex gap-3.5">                                                                                                        
                                        <div className='flex gap-2 items-center'>
                                            <img src={tinyCalendarIcon} width={12} height={12} alt="time" />
                                            <div className='text-xs'>{dayjs.utc(formItem.submited_at).local().format('DD MMM YYYY')}</div>
                                        </div>
                                        <div className='flex gap-2 items-center'>
                                            <img src={iconTimeTiny} width={12} height={12} alt="time" />
                                            <div className='text-xs'>{dayjs.utc(formItem.submited_at).local().format('hh:mm A')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="form-card-title font-bold text-lg/6 mb-4.5">{formItem.title}</h2>
                            <div className="tracking-[0.01em] font-light text-xs mb-5 md:mb-7">{formItem.description}</div>
                        </div>                                                
                        <hr className='my-8'/>
                        <div className='questions'>
                            <div className='form-wrapper'>
                            {formItem.questions!==undefined &&
                                <>
                                {formItem.questions.map((question, index)=>{
                                    return(
                                        <div key={index} className='question-box mb-7.5'>
                                            <div className='question-index text-xs text-white inline-block px-4 py-1 mb-4'>Question {index+1}</div>
                                            <div className='px-4 pb-5 font-bold text-base tracking-[0.5px]'>
                                                {question.question}{question.is_required.toLowerCase()==='yes' && <sup>*</sup>}
                                            </div>
                                            <div className='relative px-4 pb-5'>
                                                {question.question_type==='paragraph' &&
                                                <>
                                                    <textarea className='w-full text-base !bg-white px-5 py-2.5 rounded-[8px] outline-none' readOnly defaultValue={question.answer_single}/>
                                                </>
                                                }
                                                {question.question_type==='short_answer' &&
                                                    <>
                                                    <input className='w-full text-base !bg-white px-5 py-2.5 rounded-[8px] outline-none' readOnly defaultValue={question.answer_single}/>
                                                    </>
                                                }
                                                {question.question_type==='checkbox' &&
                                                    <>
                                                    <div className='w-full bg-white px-5 py-2.5 rounded-[8px] flex flex-wrap gap-5 items-start'>
                                                    {question.options.map((checkOption, oindex)=>{
                                                        return(
                                                            <label htmlFor={question._id+'-'+oindex} key={checkOption+'-'+oindex} className='flex gap-2.5 items-center'>
                                                                <input 
                                                                    id={question._id+'-'+oindex}
                                                                    type="checkbox"
                                                                    value={checkOption}
                                                                    readOnly
                                                                    className='appearance-none h-3 w-3 rounded-sm bg-white align-top float-left mr-1 cursor-pointer' 
                                                                    checked={question.answer_multiple.includes(checkOption) ? true : false}
                                                                />
                                                                <span className='text-xs/3 font-light'>{checkOption}</span>
                                                            </label>
                                                        )
                                                    })}                                                        
                                                    </div>
                                                    </>
                                                }
                                                {question.question_type==='single_selection' &&
                                                    <>
                                                    <div className='w-full bg-white px-5 py-2.5 rounded-[8px] flex flex-wrap gap-5 items-start'>
                                                        {question.options.map((checkOption, oindex)=>{
                                                            return(
                                                                <label htmlFor={question._id+'-'+oindex} key={checkOption+'-'+oindex} className='flex gap-2.5 items-center'>
                                                                    <input 
                                                                        id={question._id+'-'+oindex}
                                                                        type="radio"
                                                                        value={checkOption}
                                                                        readOnly
                                                                        className='h-3 w-3 border border-[#363F6C] bg-white align-top float-left mr-1 cursor-pointer' 
                                                                        checked={question.answer_single===checkOption ? true : false}
                                                                    />
                                                                    <span className='text-xs/3 font-light'>{checkOption}</span>
                                                                </label>
                                                            )
                                                        })}                                                        
                                                    </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="flex justify-center gap-2.5 mt-10">
                                    {!buttonLoading ?
                                    <>
                                    <button type="button" className="button border border-black !p-2 !bg-white hover:!bg-[var(--color-yellow)] hover:!text-[var(--color-darkblue)] flex gap-2 items-center" onClick={()=>{handleDownload()}}>
                                        <img src={iconDownloadPdf} width={30} height={30} alt="Download" />
                                        <span>Download</span>
                                    </button>
                                    <button type="button" className="button" onClick={()=>{handleViewSheet()}}>View In Sheets</button>
                                    </>
                                    :
                                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
                                    }
                                </div>                                
                                </>                            
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
        }
        </>
    )
}

const AdminFormResponse=()=>{
    const { id, formid } = useParams();
    
    return (
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="">
                    <AdminNavbar heading="Forms" />
                    <AdminFormResponseView formId={formid} />
                </div>
            </TeamContextProvider>
        </>
    );
}
export default AdminFormResponse;