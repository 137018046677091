import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import placeholderImg from '../../../assets/images/placeholder.png';
//import { useNavigate } from 'react-router-dom';
import copyIcon from "../../../assets/images/copy.svg";
import Spinner from '../../../components/Spinner/Spinner';
import { getGroupMemberDetail } from '../../../libs/dataFunctions';

const GroupMemberRow = ({group, isMobile, team, member, setIsCheckMembers, isCheckMembers, showExportOptions, refreshGroupMembers}) =>{
    const [openDetail, setOpenDetail] = useState(false);
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    const GroupMemberDetail = ({setShowForm}) =>{
        const [errorMessage,setErrorMessage] = useState('');
        const [successMessage,setSuccessMessage] = useState('');
        const [loading,setLoading] = useState(false);
        const [memberDetail, setMemberDetail] = useState([]);
        const { register, handleSubmit, formState: { errors } } = useForm();
        //const navigate = useNavigate();
        async function refreshMemberDetail(){ 
            setLoading(true);
            let memberData = await getGroupMemberDetail(team._id, member._id, token, group._id);
            setMemberDetail(memberData.data);
            setLoading(false);
        }
    
        useEffect(()=>{
            refreshMemberDetail();
        },[]);

        const onSubmit = async(data) => {
            setErrorMessage('');
            setSuccessMessage('');
            setLoading(true);
            var formdata = new FormData();
            formdata.append("type", "update_group_member");
            formdata.append("team_id", team._id);
            formdata.append("group_id", group._id);
            formdata.append("is_admin", data.is_admin ? 'Yes' : 'No');
            formdata.append("member_sub_id",memberDetail.sub);
            
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status!==1){
                    setErrorMessage(rdata.message);
                }
                else{
                    if(data.is_admin){
                        memberDetail.user_role='group_admin';
                    }
                    else{
                        memberDetail.user_role='member';
                    }
                    setSuccessMessage(rdata.message);
                    refreshGroupMembers();
                    setShowForm(false);                    
                }
            })
            .catch(error => console.log('error', error));
        }
        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap popup-form-memberdetails relative !px-2.5 sm:!px-8">                    
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                    {!loading ?
                    <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">
                        <div className='member-card'>
                            <div className='flex flex-col sm:flex-row items-center sm:items-start w-full member-card-row pb-5 border-b border-[#53545C]/30'>
                                <div className='pr-5'>
                                    {memberDetail.profilePicture !== '' ?  
                                        <img src={memberDetail.profilePicture} alt="" width={100} height={100} className="rounded-full cursor-pointer"/>
                                    :
                                        <img src={placeholderImg} alt="" width={100} height={100} className="rounded-full cursor-pointer"/>
                                    }
                                </div>
                                <div className='member-info grow flex flex-col sm:flex-row px-2.5 sm:px-0 py-2.5 sm:py-0 text-center sm:text-left sm:items-start'>
                                    <div className='flex flex-col self-center'>
                                        <h3 className='member-name'>{memberDetail.name}</h3>
                                        <div className='member-email'>{memberDetail.email}</div>
                                        {memberDetail.user_role!=='admin' && memberDetail.user_role !== 'child' ?
                                            <div className='form-field-wrap mb-5 check-field-wrap relative'>                                
                                                <input 
                                                    type="checkbox" id="isgroupadmin" className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                                                    defaultChecked={memberDetail.user_role === 'group_admin' ? true : false}
                                                    {...register("is_admin")}
                                                />
                                                <label htmlFor="isgroupadmin" className='!text-[12px]'>Group Administrator Privileges</label>
                                            </div>
                                        :
                                            <div className={`member-role uppercase is-${memberDetail.user_role}`}>{memberDetail.user_role==='group_admin' ? 'Group admin' : memberDetail.user_role}</div>
                                        }
                                    </div>                        
                                </div>                      
                            </div>
                            <div className='flex flex-col sm:flex-row w-full justify-between member-card-row py-5'>
                                {memberDetail.phoneNumber!=="" &&
                                    <div className='member-phone flex items-center mb-2.5 sm:mb-0'><div className='button-tiny phone rounded-full mr-1.5'></div>{memberDetail.phoneCode!=="" ? memberDetail.phoneCode+' '+ memberDetail.phoneNumber : memberDetail.phoneNumber}</div>
                                }
                                <div className='member-birthdate flex items-center'><div className='button-tiny date rounded-full mr-1.5'></div>{(memberDetail.dateOfBirth!=='' && memberDetail.dateOfBirth!==null) ? dayjs(memberDetail.dateOfBirth).format('DD / MMM / YYYY'): (memberDetail.is_above_18 ? 'Over 18 years' : 'Under 18 years')}</div>
                            </div>
                            {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                            {successMessage!=='' && <p className="text-green-500 mb-2.5 text-center" role="alert">{successMessage}</p>}
                            {memberDetail.user_role!=='admin' && memberDetail.user_role !== 'child' ?
                                <div className='flex justify-center'>
                                    <input className='button button--large' type="submit" value="Update" disabled={loading}/>
                                </div>
                            :
                                null
                            }
                            </div>
                    </form>
                    :
                    <>
                        <div className="py-5 text-center"><Spinner /></div>
                    </>
                    }
                </div>
            </div>
        )
    }
    const deleteMember = async (memberid)=>{
        if(window.confirm("Are you sure you want to remove this member from the group?")){
            var formdata = new FormData();
            formdata.append("type", "delete_group_member");
            formdata.append("group_id",group._id);
            formdata.append("member_id", memberid);
            formdata.append("team_id", team._id);
            if(member.same_child_subs.length>0){
                formdata.append("same_child_subs", member.same_child_subs.join('@#'));
            }
            formdata.append('separate_by','@#');            
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
  
            await fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
            .then(response => response.text())
            .then(result => {
                refreshGroupMembers();
            })
            .catch(error => console.log('error', error));
        }
    }
    const handleClickMembers = e => {
        const { id, checked, value } = e.target;
        let tmpObj = {};
        Object.assign(tmpObj, JSON.parse(value));        
        setIsCheckMembers([...isCheckMembers, tmpObj]);
        if (!checked) {
          setIsCheckMembers(isCheckMembers.filter((item) => {return !item._id.includes(id)}));
        }
    }
    const copyToClipboard=(data) => {
        let textField = document.createElement('textarea');
        textField.innerText = data;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        alert('Copied!');
    }
    
    return(
        <>        
        {!isMobile ?
            <tr className='border-b border-black-100/10 px-7.5'>
                <td className='py-2 pl-2.5 sm:pl-5 md:pl-[30px]'>                
                    {showExportOptions ?
                        <div className='flex items-center'>
                        <label key={member._id} className='text-[12px] mr-3 text-black font-light cursor-pointer'>
                            <input                    
                                type="checkbox"
                                name={member._id}
                                id={member._id}
                                value={JSON.stringify(member)}
                                className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                                onChange={handleClickMembers}
                                checked={isCheckMembers.some((item)=>{ return item._id===member._id })}
                            />
                        </label>
                        {member.profilePicture !== '' ?  
                            <img src={member.profilePicture} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(team.role === 'admin' || group.user_role==='group_admin') setOpenDetail(true)}}/>
                            :
                            <img src={placeholderImg} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(team.role === 'admin' || group.user_role==='group_admin') setOpenDetail(true)}}/>
                        }
                        </div>
                        :
                        <>
                        {member.profilePicture !== '' ?  
                            <img src={member.profilePicture} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(team.role === 'admin' || group.user_role==='group_admin') setOpenDetail(true)}}/>
                            :
                            <img src={placeholderImg} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(team.role === 'admin' || group.user_role==='group_admin') setOpenDetail(true)}}/>
                        }
                        </>
                    }
                    {openDetail &&
                        <GroupMemberDetail setShowForm={setOpenDetail} />
                    }
                </td>
                <td>
                    <div className='member-name uppercase cursor-pointer' onClick={(e)=>{if(team.role === 'admin' || group.user_role==='group_admin')setOpenDetail(true)}}>{member.name}</div>
                    <div className={`member-role uppercase is-${member.user_role}`}>{member.user_role==='group_admin' ? 'Group admin' : member.user_role}</div>
                    {(team.role === 'admin' || group.user_role==='group_admin') &&
                        <>
                            <div className='md:hidden'>{(member.dateOfBirth!=='' && member.dateOfBirth!==null) ? dayjs(member.dateOfBirth).format('DD / MMM / YYYY'): (member.is_above_18 ? 'Over 18 years' : 'Under 18 years')}</div>
                            {member.email.trim()!=='' &&
                                <div className='flex items-center lg:hidden'><img src={copyIcon} alt="" className='mr-1.5' onClick={()=>copyToClipboard(member.email)}/><a href={`mailto:${member.email}`} className="truncate  text-ellipsis overflow-hidden">{member.email}</a></div>
                            }
                            {member.phoneNumber!=='' &&
                                <div className='flex items-center lg:hidden'><img src={copyIcon} alt="" className='mr-1.5' onClick={()=>copyToClipboard(member.phoneCode!=="" ? member.phoneCode+' '+member.phoneNumber : member.phoneNumber)}/>{member.phoneCode!=="" ? member.phoneCode+' '+member.phoneNumber : member.phoneNumber}</div>
                            }
                        </>
                    }
                </td>
                {(team.role === 'admin' || group.user_role==='group_admin') &&
                    <>
                        <td className='hidden lg:table-cell'>
                            {member.email.trim()!=='' &&
                                <div className='flex items-center'><img src={copyIcon} alt="" className='mr-1.5' onClick={()=>copyToClipboard(member.email)}/><a href={`mailto:${member.email}`}>{member.email}</a></div>
                            }
                        </td>
                        <td className='hidden lg:table-cell'>
                            {member.phoneNumber!=='' &&
                                <div className='flex items-center'><img src={copyIcon} alt="" className='mr-1.5' onClick={()=>copyToClipboard(member.phoneCode!=="" ? member.phoneCode+' '+ member.phoneNumber : member.phoneNumber)}/>{member.phoneCode!=="" ? member.phoneCode+' '+ member.phoneNumber : member.phoneNumber}</div>
                            }
                        </td>
                        <td className='hidden md:table-cell'>{(member.dateOfBirth!=='' && member.dateOfBirth!==null) ? dayjs(member.dateOfBirth).format('DD / MMM / YYYY'): (member.is_above_18 ? 'Over 18 years' : 'Under 18 years')}</td>
                    </>
                }            
                {(team.role === 'admin' || group.user_role==='group_admin') && (group.name.toLowerCase()!=='all members') &&
                    <td className='text-right pr-2.5 sm:pr-[30px]'>
                        <button type="button" className='button button-red w-[89px]' onClick={()=>deleteMember(member._id)}>Remove</button>
                    </td>
                }            
            </tr>
            :
            <>
            <div className='flex relative px-3 mb-5 pb-5 border-b-[1px] border-slate-500 border-solid'>
                <div className='min-w-[50px] max-w-[90px]'>
                {showExportOptions ?
                    <div className='flex items-center'>
                    <label key={member._id} className='text-[12px] mr-3 text-black font-light cursor-pointer'>
                        <input                    
                            type="checkbox"
                            name={member._id}
                            id={member._id}
                            value={JSON.stringify(member)}
                            className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                            onChange={handleClickMembers}
                            checked={isCheckMembers.some((item)=>{ return item._id===member._id })}
                        />
                    </label>
                    {member.profilePicture !== '' ?  
                        <img src={member.profilePicture} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(team.role === 'admin') setOpenDetail(true)}}/>
                        :
                        <img src={placeholderImg} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(team.role === 'admin') setOpenDetail(true)}}/>
                    }
                    </div>
                    :
                    <>
                    {member.profilePicture !== '' ?  
                        <img src={member.profilePicture} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(team.role === 'admin') setOpenDetail(true)}}/>
                        :
                        <img src={placeholderImg} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(team.role === 'admin') setOpenDetail(true)}}/>
                    }
                    </>
                }
                </div>
                <div className='flex-1 pl-3'>
                    <div className='member-name uppercase cursor-pointer' onClick={(e)=>{if(team.role === 'admin') setOpenDetail(true)}}>{member.name}</div>
                    <div className={`member-role uppercase is-${member.user_role}`}>
                        {member.user_role==='group_admin' ? 'Group admin' : member.user_role}
                    </div>
                    {(team.role === 'admin' || group.user_role==='group_admin') &&
                        <>
                            <div className='text-[12px] leading-[16px] mb-1.5'>
                                {member.email !=='' &&
                                    <a href={`mailto:${member.email}`} className="truncate  text-ellipsis overflow-hidden text-[#363F6C]">{member.email}</a>
                                }
                            </div>
                            <div className='text-[12px] leading-[16px] mb-1.5 flex items-center'><div className='button-tiny date rounded-full mr-1.5'></div>{(member.dateOfBirth!=='' && member.dateOfBirth!==null) ? dayjs(member.dateOfBirth).format('DD / MMM / YYYY'): (member.is_above_18 ? 'Over 18 years' : 'Under 18 years')}</div>              
                            <div className='text-[12px] leading-[16px] flex items-center'>
                            <div className='button-tiny phone rounded-full mr-1.5'></div>{member.phoneCode!=="" ? member.phoneCode+' '+member.phoneNumber : member.phoneNumber}</div>
                        </>
                    }
                </div>
                {(team.role === 'admin' || group.user_role==='group_admin') && (group.name.toLowerCase()!=='all members') &&
                    <div className="flex items-center justify-end w-[60px] absolute top-1 right-1">
                        <button type="button" className="button-tiny delete rounded-full w-[89px]" onClick={()=>{deleteMember()}}></button>
                    </div>
                }
            </div>
            {openDetail &&
                <GroupMemberDetail setShowForm={setOpenDetail} />
            }
            </>
        }
        </>       
    )
}
export default GroupMemberRow;