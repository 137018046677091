import { useEffect, useState, useContext } from 'react';
import { useLocation, NavLink, useParams, useNavigate } from 'react-router-dom';

import * as DOMPurify from 'dompurify';
import dayjs from "dayjs";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Spinner from '../../../components/Spinner/Spinner';
import { TeamContext, TeamContextProvider } from "../../../contexts/TeamContext";
import placeholderImg from '../../../assets/images/placeholder-white.png';
import DocumentView from '../../../components/DocumentView/DocumentView';
import { generateRandomName } from '../../../components/ImageCropper/cropUtils';

const NewsArticleView = () => {
    const [previewImage, setPreviewImage] = useState(null);
    const location  = useLocation();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { team } = useContext(TeamContext);
    const navigate = useNavigate();
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    let isPreview = true;
    let previewData = [];
    previewData = location.state.preViewData;
    
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
            resolve(base64String);
        }
        reader.onerror = error => reject(error);
    });
    
    const submitArticle = async() =>{
        setErrorMessage('');
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "create_news");
        formdata.append("title", previewData.title);
        formdata.append("hour", previewData.hour);
        formdata.append("date", previewData.newsdate);
        formdata.append("published", previewData.publish);
        formdata.append("group_ids", previewData.group_ids.join('@#'));
        formdata.append("details", previewData.details);
        formdata.append("commentsEnabled", previewData.commentsEnabled);
        formdata.append("notify_email", previewData.notify_email);
        formdata.append("notify_notification",previewData.notify_notification);
        formdata.append("notify_sms",previewData.notify_sms);
        formdata.append("team_id", team._id);
        
        if(previewData.notify_sms==='Yes' && previewData.card_id !==undefined){
            formdata.append("card_id",previewData.card_id);
            formdata.append("sms_details",previewData.sms_details);
            formdata.append("sms_charges_amount",previewData.sms_charges_amount);
        }
        
        if(previewData.image !== undefined){
            setLoading(true);
            var formdatas3 = new FormData();
            let tmpFilename = generateRandomName();
            const imgFileObj = new File([previewData.image], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: previewData.image.type })
            formdatas3.append("files[]", imgFileObj);
            var requestOptionss3 = {
                method: 'PUT',
                body: formdatas3,
                redirect: 'follow'
            };
            const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
            const resultsApp = await responsesApp.text();
            const rdatasApp = JSON.parse(resultsApp);
            formdata.append('content',rdatasApp.data[0]);
        }
        else if(previewData.imageUrl !== undefined){
            formdata.append("content", previewData.imageUrl);
            if(previewData.extension!==undefined){
                formdata.append("extension", previewData.extension);
            }
        }

        if((previewData.pdfFiles !== undefined && previewData.pdfFiles.length > 0) || (previewData.pdfOldFiles!==undefined && previewData.pdfFiles.pdfOldFiles > 0)){
            let pdfcodes = [];
            let exts = [];
            if(previewData.pdfOldFiles!==undefined && previewData.pdfOldFiles.length > 0){
                for(let i=0; i<previewData.pdfOldFiles.length; i++){
                    pdfcodes.push(previewData.pdfOldFiles[i]);
                    exts.push(previewData.pdfOldFiles[i].split('.').pop());
                };
            }
            if(previewData.pdfFiles !== undefined && previewData.pdfFiles.length > 0){
                for(let i=0; i<previewData.pdfFiles.length; i++){
                    let bs64 = await toBase64(previewData.pdfFiles[i]);
                    pdfcodes.push(bs64);
                    exts.push(previewData.pdfexts[i]);
                };
            }            
            formdata.append("document_content", pdfcodes.join('@#'));
            formdata.append("document_extension", exts.join('@#'));
        }
        formdata.append('separate_by','@#');
        setLoading(true);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_news' , requestOptions)
        .then(response => response.text())
        .then(result => {
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setLoading(false);
                setErrorMessage(rdata.message);
            }
            else{
                navigate('/team/'+team._id);
            }
        })
        .catch(error => console.log('error', error));
        setLoading(false);
    }
    useEffect(()=>{
        setLoading(true);
        setTimeout(()=>{
            if(isPreview && previewData.image !== undefined){
                setPreviewImage(previewData.image);
            }
            setLoading(false);
        },2000);
    },[isPreview, previewData.image]);

    return(
        <>
            {!loading ?
            <>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}/news`}>
                        News
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Article Preview
                </div>
                <div className="body news-article px-5 lg:px-2.5 lg:px-8 py-10">
                    <div className='article-content bg-white px-8 pt-5 pb-8 mb-6'>
                        <div className='flex flex-wrap justify-between'>
                            <div>
                                <div className='article-meta italic text-black mb-8'>
                                    {dayjs(previewData.newsdate).format("DD MMM, YYYY")}
                                </div>
                            </div>
                            <div>
                                <button type="button" className='button' onClick={()=>{
                                    navigate('/team/'+team._id+'/news/create-news',{
                                        state:{
                                            news:previewData,
                                            previewback: true
                                        }          
                                    });
                                }}>Back</button>
                            </div>
                        </div>
                        <div className='flex flex-col lg:flex-row lg:items-start'>
                            <div className='article-header w-full mb-5 mr-0.5'>                    
                                {previewImage!==null && previewImage!==undefined ?
                                    <div className='image-wrapper responsive'>
                                        <img className='absolute object-cover w-full h-full' src={`${URL.createObjectURL(previewImage)}`} alt="" height={430}/>                            
                                    </div>
                                    :
                                    <>
                                    {previewData.imageUrl!==undefined ?
                                        <div className='image-wrapper responsive'>
                                            <img className='absolute object-cover w-full h-full' src={`${previewData.imageUrl}`} alt="" height={430}/>                            
                                        </div>
                                    :
                                        <div className='image-wrapper bg-[#363F6C] responsive'>
                                            <img className={`absolute object-contain w-full h-full max-w-[90%] max-h-[90%] m-auto left-0 right-0 top-0 bottom-0`} src={placeholderImg} alt="" height={430}/>
                                        </div>                                    
                                    }
                                    </>
                                }
                                <div className='article-heading w-full flex flex-col md:flex-row items-center justify-between px-2.5 md:px-8 py-6'>
                                    <h1 className='text-white text-center md:text-left'>{previewData.title}</h1>                            
                                </div>                        
                            </div>
                            <div className='article-text no-border pb-10 w-full pl-7' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(previewData.details)}} />
                        </div>
                        {/*(previewData.groups!==undefined && previewData.groups.length > 0) &&
                        <div className='article-groups flex flex-wrap items-center pt-10 mb-5'>
                            <span className='mr-3 mb-4'>Group You selected : </span>
                            {previewData.groups.map((group,index)=>{
                                    return(
                                        <div className="article-group ml-2 mb-4" key={index}>{group}</div>
                                    )
                                })
                            }
                        </div>
                        */}
                        {((previewData.pdfFiles !== undefined && previewData.pdfFiles.length > 0) || (previewData.pdfOldFiles!==undefined && previewData.pdfFiles.length>0)) &&
                            <div className='documents'>
                                <h3>Documents</h3>
                                <ul className='flex flex-wrap'>
                                    {previewData.pdfOldFiles!==undefined && previewData.pdfFiles.length>0 &&
                                        <>
                                        {previewData.pdfOldFiles.map((doc,index) => { 
                                            return (
                                                <li key={index}>
                                                    <div className='mr-5 mb-5 w-[150px] h-[180px] relative'>
                                                        <DocumentView doc={doc} hasLink={false} />
                                                    </div>
                                                </li>
                                            )
                                        })}
                                        </>
                                    }
                                    {previewData.pdfFiles !== undefined && previewData.pdfFiles.length > 0 &&
                                        <>
                                        {previewData.pdfFiles.map((doc,index) => { 
                                            return (
                                                <li key={index}>
                                                    <div className='mr-5 mb-5 w-[150px] h-[180px] relative'>
                                                        <DocumentView doc={doc} hasLink={false} />
                                                    </div>
                                                </li>
                                            )
                                        })}
                                        </>
                                    }
                                </ul>
                            </div>
                        }
                    </div>                    
                    <div className="w-full px-2.5 my-[60px]">
                        <div className='flex justify-center'>
                            {errorMessage==="" ?
                            <input className='button button--large' type="button" value='Publish' onClick={()=>{submitArticle()}}/>
                            :
                            <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>
                            }
                        </div>
                    </div>
                </div>
            </>
            :
            <>
                <div className="py-5 text-center"><Spinner /></div>
            </>
            }
        </>
    )
}

const NewsArticlePreview = () =>{
    const { id, newsid } = useParams();
    return(
        <>
            <Sidebar type="thin"/>
            <TeamContextProvider id={id}>
                <div id="page" className="page-newsform">
                    <AdminNavbar heading="News"/>
                    <NewsArticleView />
                </div>                
            </TeamContextProvider>
        </>
    )
}
export default NewsArticlePreview;