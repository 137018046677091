import { useState, useEffect, useRef, useContext } from 'react';
import { useParams, NavLink } from "react-router-dom";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import Calendar from 'react-calendar';
import { getAllBookings } from '../../../libs/dataFunctions';
import Sidebar from "../../../components/Sidebar/Sidebar";
import { TeamContextProvider, TeamContext } from "../../../contexts/TeamContext";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import Spinner from '../../../components/Spinner/Spinner';
import './bookings.scss';
import ExistingBookingCard from './ExistingBookingCard';
dayjs.extend(utc);
dayjs.extend(tz);

const AdminFacilities = () =>{
    const { team, teamUpdating } = useContext(TeamContext);
    //const [value, onChange] = useState(new Date());
    const [activeDate, setActiveDate] = useState(new Date());
    const [loading,setLoading] = useState(false);
    const [calloading,setCalLoading] = useState(false);
    const [bookingsAll,setbookingsAll] = useState([]);
    const [bookingsList,setbookingsList] = useState([]);
    const [searchParam] = useState(["date"]);
    const [filterType, setFilterType] = useState('All');
    const [showFilterOpt,setFilterOpt] = useState(false);
    const [filterParam] = useState(["facility_name"]);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const changeDate = (e) => {
        setActiveDate(e);
        var tempData = [], i=0;
        setFilterType('All');
        bookingsAll.map((fitems,j)=>{
            if(searchbyDate(fitems.booking,dayjs(e).format("YYYY-MM-DD")).length>0){
                tempData[i] ={
                    _id: fitems._id, 
                    name: fitems.name,
                    booking:searchbyDate(fitems.booking,dayjs(e).format("YYYY-MM-DD")).map(function(object, i){
                        return object;
                    })
                }
                i++;
                return tempData;
            }
            return null;
        });
        
        setbookingsList(tempData);       
    }

    const changeMonth = (e) => {
        var month, date;
        date = new Date(e.activeStartDate);
        month = ("0" + (date.getMonth() + 1)).slice(-2);
        setFilterType('All');
        fetchAllBookints(date.getFullYear()+'-'+month, false);
    };

    const searchbyDate=(items,q)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem] ?
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(q.toLowerCase()) > -1
                    :
                    item
                );
            });
        });
    }
    const filterBy = (items) => {
        if(filterType!=='All'){
            return items.filter((item) => {
                return filterParam.some((newItem) => {
                    return (
                        item[newItem] ?
                            item[newItem]
                                .toString()
                                .toLowerCase()
                                .indexOf(filterType.toLowerCase()) > -1
                        :
                        item
                    );
                });
            });        
        }
        else{
            return items;
        }        
    }
    const ref = useRef();

    const fetchAllBookints=async (calDate, main=true)=>{
        if(main){
            setLoading(true);
        }
        else{
            setCalLoading(true);
        }
        const [bookingsData] = await Promise.all([
            getAllBookings(team._id, token, calDate)
        ]);
        
        var tempData = [], tempData2 = [], i=0;

        bookingsData.data.map((fitems,j)=>{
            if(j!==0){
                if(searchbyDate(fitems.booking,dayjs().format("YYYY-MM-DD")).length>0){
                    tempData[i] ={
                        _id: fitems._id, 
                        name: fitems.name,
                        booking:searchbyDate(fitems.booking,dayjs().format("YYYY-MM-DD")).map(function(object, i){
                            return object;
                        })
                    }
                    i++;
                    return tempData;
                }
                return null;
            }
            return null;
        });

        bookingsData.data.map((fitems,j)=>{
            if(j!==0){
                tempData2[i] ={
                    _id: fitems._id, 
                    name: fitems.name,
                    booking:fitems.booking.map(function(object, i){
                        return object;
                    })
                }
                i++;
                return tempData2;
            }
            return null;
        });
        
        setbookingsAll(tempData2);
        setbookingsList(tempData);
        setFilterType('All');
        if(main){
            setLoading(false);
        }
        else{
            setCalLoading(false);
        }
    }

    useEffect(()=>{
        if (!teamUpdating) {
            const cmonth = new Date().toLocaleString("en-GB", { month: "2-digit" });
            const cyear = new Date().getUTCFullYear();
            fetchAllBookints(cyear+'-'+cmonth);
        }
    },[teamUpdating]);

    return(
        <>
        {!loading ?
        <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}/events`}>
                    Events
                </NavLink>
                <span className='separator'>&lt;</span>
                Existing Bookings
            </div>
            <div className='body px-5 lg:px-8 py-10'>
                <div className='rounded-xl bg-white pb-10'>
                    <div className="tab-actionbar px-2.5 sm:px-7.5 py-4.5 mb-7.5 flex flex-col md:flex-row justify-between items-center">
                        <div className="title-col max-w-[320px] md:max-w-full mx-auto md:mx-0 mb-2.5 md:mb-0">Existing Bookings</div>
                        <div className="cta-col max-w-[320px] md:max-w-full mx-auto md:mx-0 flex justify-center md:justify-end flex-wrap items-center relative">
                            <label htmlFor="search-form" className="search-field relative mr-auto ml-auto md:mr-3.5 mb-2.5 md:mb-0">
                                <input
                                    type="search"
                                    name="search-field"
                                    id="search-form"
                                    className="search-input"
                                    placeholder="Search Booking"
                                    //value={q}
                                    onChange={(e) => {}}
                                />                        
                            </label>                                        
                        </div>                    
                    </div>
                    <div className='xl:flex px-2.5 sm:px-7.5 xl:gap-7.5'>
                        <div className='calander-bookings-view relative mb-5'>
                            <Calendar
                                onClickDay={changeDate}
                                value={activeDate}
                                onActiveStartDateChange={changeMonth}                
                                tileContent={({activeStartDate, date, view})=>{
                                    let count=0;
                                    bookingsAll.map((fitems)=>{
                                        if(fitems.booking.find(x=>x.date===dayjs(date).format("YYYY-MM-DD"))){
                                            count++;
                                        }
                                        return count;
                                    });                            
                                    
                                    return (
                                        count>0 ? 
                                            <>
                                            <div className="flex justify-center pt-2.5 absolute w-full bottom-0 left-0">                                    
                                                {bookingsAll.map((fitems)=>{
                                                    return searchbyDate(fitems.booking,dayjs(date).format("YYYY-MM-DD")).map(function(object, i){
                                                        return <span className="w-[4px] h-[4px] mx-[2px] bg-white rounded-full" key={object._id+i}></span>
                                                    })
                                                })}
                                            </div>
                                            </>
                                        : null
                                        
                                    );
                                }}
                            />
                            {calloading &&
                                <div className='loading-overlay'><div className='loading'></div></div>
                            }
                        </div>
                        <div className='calendar-booking-list lg:flex-1 rounded-xl bg-[#EFF1F9]'>
                            {bookingsList.length>0 ?
                                <>
                                <div className="px-2.5 sm:px-7.5 py-4.5 flex flex-row justify-between items-center border-b border-[rgba(54,63,108,0.4)]">
                                    <div className="text-xl text-[#363F6C] font-bold">Existing Bookings</div>
                                    <div className="cta-col flex justify-end flex-wrap items-center relative" ref={ref}>
                                        <select className='rounded-[20px] bg-white border-b border-[rgba(54,63,108,0.4)] py-2.5 pl-5 pr-10' onChange={(e)=>{setFilterType(e.target.value)}}>
                                            <option value={'All'}>All</option>
                                            {bookingsList.map((facilityOpt)=>{
                                                return(
                                                    <option key={facilityOpt._id} value={facilityOpt.name}>{facilityOpt.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className='px-2.5 sm:px-7.5 py-4.5'>
                                    <div className="bookings-list pt-5 xl:h-[50vh] mb-5 custom-scroll">
                                        {bookingsList.map((facilityItem)=>{
                                            return(
                                                <div className='mb-5' key={facilityItem._id}>
                                                    <h2 className="text-lg/4.5 font-medium border-s-2 border-[#FBDB03] pl-2 mb-5.5">
                                                        {facilityItem.name}                                                            
                                                    </h2>
                                                    {facilityItem.booking.map((bookingItem)=>{
                                                        return(
                                                            <ExistingBookingCard key={bookingItem._id} BookingItem={bookingItem} teamID={team._id} />
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                </>
                                :
                                <>
                                <div className="px-2.5 sm:px-7.5 py-4.5 flex flex-row justify-between items-center border-b border-[rgba(54,63,108,0.4)]">
                                    <div className="text-xl text-[#363F6C] font-bold">Existing Bookings</div>                                    
                                </div>
                                <div className='px-2.5 sm:px-7.5 py-4.5 text-center'>No Bookings Avaialble.</div>
                                </>
                            }
                            <div className='flex items-center justify-center mb-5'>
                                <a href={`/team/${team._id}/manage-facilities`} type='button' className='button button--large'>Manage Facilities</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
        }
        </>
    );
}
const AdminBookingsOverview=()=>{
    const { id } = useParams();
    return(
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="">
                    <AdminNavbar heading="Existing Bookings" />  
                    <AdminFacilities />              
                </div>
            </TeamContextProvider>
        </>
    )
}
export default AdminBookingsOverview;