import { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { TeamContext } from "../../../contexts/TeamContext";
import EventRow from "./EventRow";
import revents from '../../../assets/images/noevents.png';
import Spinner from "../../../components/Spinner/Spinner";
import { getEventsLists, getEventsUpcoming, getEventsPast } from "../../../libs/dataFunctions";
import IsMobile from "../../../components/isMobile";
import GroupFilterPopup from "./GroupFilterPopup";

const TabEvents=()=>{
    const {team, token } = useContext(TeamContext);
    const [eventsList, setEventsList] = useState([]);
    const [eventType, setEventsType] = useState('upcoming');
    const [eventsLoading, setEventLoading] = useState(false);    
    const [q, setSearchQuery] = useState("");    
    const [searchParam] = useState(["name"]);
    const [showSortingOpt, setShowSortingOpt] = useState(false); 
    const [showFilterOpt,setFilterOpt] = useState(false);
    const [showGroupFilterOpt,setGroupFilterOpt] = useState(false);
    const [showFacilityOpt, setShowFacilityOpt] = useState(false);
    const [filterType, setFilterType] = useState('All');
    const [selectedGroup,setSelectedGroup] = useState([]);
    const navigate = useNavigate();
    const isMobile = IsMobile(window);
    
    const sortBy = async(orderby) =>{
        if(orderby==='past'){
            setEventLoading(true);
            let eventDataData = await getEventsPast(team._id, token);
            setEventsList(eventDataData);
            setEventLoading(false);
            setEventsType('past');
            setShowSortingOpt(false);
        }        
        else if(orderby==='upcoming'){
            setEventLoading(true);
            let eventDataData = await getEventsUpcoming(team._id, token);
            setEventsList(eventDataData);
            setEventLoading(false);
            setEventsType('upcoming');
            setShowSortingOpt(false);
            setFilterOpt(false);
        }        
    }
    const filterBy = async(f) => {
        setEventLoading(true);
        let eventDataData = await getEventsLists(team._id, token, eventType, f, selectedGroup);
        setEventsList(eventDataData);
        setEventLoading(false);
        setShowSortingOpt(false);
        setFilterOpt(false);
        setFilterType(f);
    }

    async function refreshEvents(teamid){
        setEventLoading(true);
        let eventDataData = await getEventsLists(teamid, token, eventType);
        setEventsList(eventDataData);
        setEventLoading(false);
    }

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                if(q!==''){
                    return (
                        item[newItem] &&  !item.is_ads?
                            item[newItem]
                                .toString()
                                .toLowerCase()
                                .indexOf(q.toLowerCase()) > -1
                        :
                        !item.is_ads ? item : null
                    );
                }
                else{
                    return item;
                }
            });
        });
    }

    const handleOnGroupChange=async()=>{
        setEventLoading(true);
        let eventDataData = await getEventsLists(team._id, token, eventType, null, selectedGroup);
        setEventsList(eventDataData);
        setEventLoading(false);
        setShowSortingOpt(false);
        setFilterOpt(false);
        setFilterType('All');
    }

    const ref = useRef();

    useEffect(()=>{})

    useEffect(()=>{
        if(selectedGroup.length>0){
            handleOnGroupChange();
        }
        else{
            refreshEvents(team._id);
        }
    },[team._id, selectedGroup]);

    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if ((showSortingOpt || showFilterOpt || showFacilityOpt) && ref.current && !ref.current.contains(e.target)) {
                setShowSortingOpt(false);
                setFilterOpt(false);
                setShowFacilityOpt(false);
            }
        }      
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[showSortingOpt, showFilterOpt, showFacilityOpt]);

    return(
        !eventsLoading ?
        <>
            <div className="tab-actionbar px:2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center mb-[30px]">
                <div className="title-col mb-2.5 md:mb-0">{eventType==='upcoming' ? 'Upcoming' : 'Past'} Events List</div>
                <div className="cta-col max-w-[320px] md:max-w-full mx-auto md:mx-0 flex justify-center md:justify-end flex-wrap items-center relative" ref={ref}>
                    <label htmlFor="search-form" className="search-field relative mr-3.5 mb-2.5 md:mb-0">
                        <input
                            type="search"
                            name="search-field"
                            id="search-form"
                            className="search-input"
                            placeholder="Search Events"
                            value={q}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />                        
                    </label>
                    {(team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0)) &&
                        <>
                            <a href={`/team/${team._id}/events/create-event`} className="button button-action button-new mr-1.5">Add event</a>                            
                        </>
                    }
                    {team.role==='admin' &&
                    <>
                    <button type="button" className="button button-action button-import mr-1.5" onClick={()=>{navigate('/team/'+team._id+'/import-events');}}>Import</button>
                    {team.site_id!==undefined && team.site_id!=="" &&
                        <a href={`/team/${team._id}/fixture-migration`} className="button button-action mr-1.5">Fixture Migration</a>
                    }
                    <a href={`/team/${team._id}/events/selection`} className="button button-action mr-1.5">TEAM SELECTION BOARD</a>
                    </>
                    }
                    <button type="button" className={`button-icon button-icon-facilities mr-2.5`} onClick={(e)=>{setFilterOpt(false); setShowSortingOpt(false); setShowFacilityOpt(!showFacilityOpt)}}><span>Manage Facilities</span></button>
                    <button type="button" className={`button-icon button-icon-calendar mr-2.5`} onClick={(e)=>{setFilterOpt(false); setShowFacilityOpt(false); setShowSortingOpt(!showSortingOpt)}}><span>Sort</span></button>
                    <button type="button" className={`button-icon button-icon-sort mr-2.5`} onClick={(e)=>{setShowSortingOpt(false); setShowFacilityOpt(false); setFilterOpt(!showFilterOpt)}}><span>Short</span></button>
                    <button type="button" className={`button-icon button-icon-filter`} onClick={(e)=>{setShowSortingOpt(false); setShowFacilityOpt(false); setGroupFilterOpt(!showGroupFilterOpt)}}><span>Filter</span></button>
                    <ul className={`sort-actions absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showFacilityOpt ? 'hidden' : ''}`}>
                        {team.role==='admin' &&
                            <li><a href={`/team/${team._id}/existing-bookings`}>Manage Facilities</a></li>
                        }
                        <li><a href={`/team/${team._id}/your-bookings`}>Book a Facility</a></li>
                    </ul>
                    <ul className={`sort-actions absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showSortingOpt ? 'hidden' : ''}`}>
                        <li className={eventType==='upcoming' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('upcoming');}}>Upcoming</div></li>
                        <li className={eventType==='past' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('past');}}>Past</div></li>                        
                    </ul>
                    <ul className={`sort-actions absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showFilterOpt ? 'hidden' : ''}`}>
                        <li className={filterType==='All' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{filterBy('All');}}>All</div></li>
                        <li className={filterType==='Competition' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{filterBy('Competition');}}>Competition</div></li>
                        <li className={filterType==='Game' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{filterBy('Game');}}>Game</div></li>
                        <li className={filterType==='Race' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{filterBy('Race');}}>Race</div></li>
                        <li className={filterType==='Social Event' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{filterBy('Social Event');}}>Social Event</div></li>
                        <li className={filterType==='Practice' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{filterBy('Practice');}}>Practice</div></li>
                        <li className={filterType==='Other' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{filterBy('Other');}}>Other</div></li>                        
                    </ul>
                </div>
            </div>
            {showGroupFilterOpt &&
                <GroupFilterPopup setShowForm={setGroupFilterOpt} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} team={team} token={token} />
            }
            <div className="events-list lg:px-5">
                {eventsList.length > 0 ?
                <>
                        <div className="flex flex-wrap">                                
                            {search(eventsList).map((event,index) => {                        
                                    return (
                                        <EventRow event={event} team={team} key={index} isMobile={isMobile} eventType={eventType} />                          
                                    )
                                })
                            }
                        </div>
                    </>
                :
                    <div className="empty text-center">
                        <div className="image"><img src={revents} alt="" width={228} height={202} /></div>
                        <div className="title mb-5">No Event Yet?</div>
                        <div className="description mb-8">
                        {team.role==='admin'?
                            'Share your Team Events schedule with your members here'
                            :
                            ' Your Admin will share Team Events with you here soon'
                        }
                        </div>
                        {(team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0))?
                        <a href={`/team/${team._id}/events/create-event`} className='button button-yellow button-yellow-add'>Add Event</a>
                        :null
                        }
                    </div>
                }
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default TabEvents;