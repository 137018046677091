import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
const counterSlice = createSlice({
    // The name of the slice
    name : 'newmsg_counter',
    // The initial state of the slice
    initialState,
    // The reducers of the slice
    reducers : {
        fetchNewCounterAction : (state,action) => {
            return action.payload;
        },        
    }
});

// Export the actions of the slice
export const {fetchNewCounterAction } = counterSlice.actions;
// Export the reducer of the slice
export default counterSlice.reducer;