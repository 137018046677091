import React, { useEffect, useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { getYourForms } from "../../../libs/dataFunctions";
import NoForms from "../../../assets/images/noemptyother.png";
import AdminFormCard from "./AdminFormCard";
import YourFormCard from "./YourFormCard";
import './yourform.scss';

const AdminForms=({team, token})=>{
    const [loading, setLoading]=useState(false);
    const [formsList, setFormsList] = useState([]);
    const [q, setSearchQuery] = useState("");    
    const [searchParam] = useState(["title"]);
    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    const fetchForms = async() =>{
        setLoading(true);
        const [yourFormsdata] = await Promise.all([
            getYourForms(team._id,token),
        ]);
        setFormsList(yourFormsdata.data);        
        setLoading(false);
    }
    useEffect(()=>{
        fetchForms();
    },[]);
    
    return(
        <>
        {!loading 
        ?
        <>
            <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center">
                <div className="title-col max-w-[320px] md:max-w-full mx-auto md:mx-0 mb-2.5 md:mb-0">Your Forms</div>
                <div className="cta-col max-w-[320px] md:max-w-full mx-auto md:mx-0 flex justify-center md:justify-end flex-wrap items-center relative">
                    <label htmlFor="search-form" className="search-field relative mr-auto ml-auto md:mr-3.5 mb-2.5 md:mb-0">
                        <input
                            type="search"
                            name="search-field"
                            id="search-form"
                            className="search-input"
                            placeholder="Search Form"
                            value={q}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />                        
                    </label>
                    {(team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0)) &&
                        <a href={`/team/${team._id}/create-form`} type="button" className='button button-action button-new' onClick={(e)=>{}}>Create a Form</a>
                    }
                </div>
            </div>
            <div className={`py-3 px-2.5 sm:px-7.5`}>
                {search(formsList).length > 0 ? 
                <>
                    <div className="your-forms-grid grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-5 lg:gap-9 mb-10">
                    {search(formsList).map((formItem,index) => {                        
                        return (
                            <React.Fragment key={formItem._id}>
                            {(formItem.user_role==='admin' || formItem.user_role==='group_admin') ?
                                <AdminFormCard key={formItem._id} formItem={formItem} token={token} teamID={team._id} fetchForms={fetchForms} />
                            :                            
                                <YourFormCard key={formItem._id} formItem={formItem} token={token} teamID={team._id} />
                            }
                            </React.Fragment>
                        )
                    })}
                    </div>                    
                </>
                :
                <div className="empty text-center">
                    <div className="image"><img src={NoForms} alt="Plaisport" width={228} /></div>                                        
                    <div className="title mb-5">No Forms Yet?</div>
                    <div className="description mb-8">Forms are not available yet.</div>
                    {(team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0)) &&
                        <a href={`/team/${team._id}/create-form`} type="button" className='button button-yellow button-yellow-add' onClick={(e)=>{}}>Create a Form</a>
                    }
                </div>
                }
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
        }
        </>
    )
}
export default AdminForms;