import { useState, useEffect } from "react";
import rduties from '../../../assets/images/duties.svg';
import Spinner from "../../../components/Spinner/Spinner";
import { getUnApprovedMedia } from "../../../libs/dataFunctions";
import MediaApprovalCard from "./MediaApprovalCard";

const MediaApproval = ({team, token}) =>{
    const [loading, setLoading] = useState(false);
    const [unapprovalData, setUnApprovalData] = useState([]);
    const [q, setSearchQuery] = useState(""); 
    const [searchParam] = useState(["folder_name"]);
    
    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    const fetchUnapprovedMedia=async()=>{
        setLoading(true);
        const [mediaData] = await Promise.all([
            getUnApprovedMedia(token,team._id),
        ]);
        setUnApprovalData(mediaData.data);
        setLoading(false);
    }
    useEffect(()=>{
        fetchUnapprovedMedia();
    },[]);
    return(
        <>
        {!loading ?
        <>
        <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center mb-[30px] relative">
            <div className="title-col mb-2.5 md:mb-0">
                Images for Approval
            </div>
            <div className="cta-col flex flex-col md:flex-row items-center relative">
                <label htmlFor="search-form" className="search-field relative mr-3.5 !mb-0">
                    <input
                        type="search"
                        name="search-field"
                        id="search-form"
                        className="search-input"
                        placeholder="Search folder name"
                        value={q}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />                        
                </label>                
            </div>
        </div>
        <div className="px-[26px]">
            {unapprovalData.length>0 ?
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5"> 
                    {search(unapprovalData).map((carditem,index) => {                        
                        return (
                            <MediaApprovalCard carditem={carditem} key={index} token={token} teamId={team._id} fetchUnapprovedMedia={fetchUnapprovedMedia} />
                        )
                    })}
                </div>
            :
            <div className="empty text-center">
                <div className="image"><img src={rduties} alt="Plaisport" width={60} height={60} /></div>
                <div className="title mb-5">No Media Yet?</div>
            </div>
            }
        </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
        }
        </>
    )
}

export default MediaApproval;