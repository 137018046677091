import { useState, useCallback, useEffect } from "react";
import PlaiImageCropper from "../../../components/ImageCropper/ImageCropper";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Spinner from "../../../components/Spinner/Spinner";
import { withTimeout } from "../../../libs/utils";
import { generateRandomName } from "../../../components/ImageCropper/cropUtils";

const schema = yup.object({
    group_name: yup.string().required('Group name is required.'),
}).required();

const EditGroupform = ({setShowForm, groupData, setGroupData, socket}) =>{
    const [loading, setLoading]=useState(false);
    const [content, setContent] = useState('');
    const limitTextarea = 100;
    const [groupImage, setGroupImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    
    const setFormattedContent = useCallback(
        (text) => {
          setContent(text.slice(0, limitTextarea));
        },
        [limitTextarea, setContent]
    );
    
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const onSubmit=async(data)=>{ 
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "update_group");
        formdata.append("conversation_id", groupData.conversation_id);
        formdata.append("name", data.group_name);
        formdata.append("description", data.group_desc);
        if (groupImage !== '' && groupImage!==null) {
            var formdatas3 = new FormData();
            let tmpFilename = generateRandomName();
            const imgFileObj = new File([groupImage], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: groupImage.type })
            formdatas3.append("files[]", imgFileObj);
            var requestOptionss3 = {
                method: 'PUT',
                body: formdatas3,
                redirect: 'follow'
            };
            const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
            const resultsApp = await responsesApp.text();
            const rdatasApp = JSON.parse(resultsApp);
            formdata.append('content',rdatasApp.data[0]);
        }

        var secureHeader = new Headers();
        secureHeader.append('Authorization', 'Bearer ' + token);
        secureHeader.append('device_type', 'W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/chat', requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if (rdata.status !== 1) {
                    setErrorMessage(rdata.message);
                }
                else{
                    socket.emit('update_group', rdata.conversation_details, withTimeout((response) => {
                        console.log("success!" + response);
                      }, (response) => {
                        console.log(response);
                      }, 1500));

                    setGroupData(prevState=>{
                        return {...prevState, name: rdata.data.name, description:rdata.data.description, profilePicture:rdata.data.profilePicture};                        
                    });
                    setShowForm(false);
                }
            })
            .catch(error => console.log('error', error));
    }

    useEffect(()=>{
        setValue('group_name',groupData.name);
        setValue('group_desc',groupData.description);
        setFormattedContent(groupData.description);
    },[]);

    return(  
              
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails !max-w-[600px] relative !px-2.5 sm:!px-8">                    
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                <div className="form-heading text-center mb-[30px]">{'Edit Group'}</div>
                {!loading ?
                <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                    <div className="form-field-container mb-5">
                        <div className='form-field-wrap no-field-wrap relative max-w-[300px] mx-auto'>
                            <PlaiImageCropper 
                                default_label={'Upload Photo'} 
                                default_message={'Optional*<br/>Maximum upload file size: 2MB<br/>(1000 X 1000)'}
                                onFinalChange={(newImage)=>{setGroupImage(newImage)}}
                                editImage={groupData.profilePicture ? groupData.profilePicture : ''}
                                aspect={1 / 1}/>
                        </div>
                    </div>
                    <div className="form-field-container mb-5">
                        <div className="label !text-[#6E7079] !px-0">Group Name*</div>
                        <div className='form-field-wrap no-field-wrap relative'>
                            <input 
                                type="text" 
                                id="group-name" 
                                disabled={loading} 
                                placeholder="Group Name *" 
                                className='text-field'
                                {...register("group_name")}
                                aria-invalid={errors.group_name ? "true" : "false"}
                            />
                        </div>
                        {errors.group_name && <p className="error mb-2.5" role="alert">{errors.group_name?.message}</p>}
                    </div>
                    <div className="form-field-container mb-5">
                        <div className="label !text-[#6E7079] !px-0">Group Description</div>
                        <div className='form-field-wrap relative'>
                            <textarea
                                id="group-desc" 
                                disabled={loading} 
                                className='textarea-field'
                                {...register("group_desc")}
                                onChange={e => setFormattedContent(e.target.value)}
                                value={content}
                            />
                            <p className="text-right text-[12px] leading-[12px]">
                                {content.length}/{limitTextarea}
                            </p>
                        </div>
                    </div>
                    <div className="w-full px-2.5">
                        <div className='flex justify-center'>
                            <input className='button' type="submit" value='Submit' disabled={loading}/>
                        </div>
                    </div>
                    {errorMessage!=="" && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p> }
                </form>
                :
                <>
                    <div className="text-center py-5"><Spinner /></div>
                </>
                }
            </div>
        </div>
    )
}

export default EditGroupform;