import React from "react";
import { useFieldArray } from "react-hook-form";

const FieldOption = ({ nestIndex, control, register, questionType }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `questions.${nestIndex}.options`,    
  });

  return (
    <>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-7.5 gap-y-5">
      {fields.map((item, k) => {
        return (
          <div key={item.id} className={`relative option-wrapper type-${questionType}`}>
            <input
                className="input-option"
              {...register(`questions.${nestIndex}.options.${k}`, {
                required: true,
              })}
              style={{ marginRight: "25px" }}
            />
            {fields.length > 1 &&
                <button type="button" onClick={() => remove(k)} className="button-del-form-option"></button>
            }
          </div>
        );
      })}
    </div>
    <div className="mt-7">
        <button type="button" className="button-addmore-option flex items-center justify-center gap-2.5 text-base font-semibold text-black hover:bg-black hover:text-white" onClick={() => append(["Option 1"])}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 2.69971V15.2997" stroke="currentColor" strokeWidth="2"/>
                <path d="M15.3003 8.99951L2.70029 8.99951" stroke="currentColor" strokeWidth="2"/>
            </svg>
            <span>
                Add More
            </span>
        </button>
    </div>
    </>
  );
}

export default FieldOption;