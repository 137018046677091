import dayjs from "dayjs";
import IsMobile from "../../../components/isMobile";
import placeholderImg from '../../../assets/images/placeholder.png';

const ProductOrderRow = ({orders, team}) =>{
    const isMobile = IsMobile(window);    
    return(
      !isMobile?
        <>
          <div className="table-row">
            <div className='table-cell py-[20px] pl-[30px] pr-1.5 text-[#363F6C] align-middle'>
              {orders.profilePicture !== '' ?  
                <img src={orders.profilePicture} alt="" width={60} height={60} className="rounded-full cursor-pointer"/>
                :
                <img src={placeholderImg} alt="" width={60} height={60} className="rounded-full cursor-pointer"/>
              }
            </div>
            <div className='table-cell py-[20px] pr-1.5 align-middle font-medium min-w-[200px]'><strong className="text-[#363F6C]">{orders.firstName} {orders.lastName}</strong> <br/>{orders.email}</div>            
            <div className='table-cell py-[20px] px-5 align-middle'>{orders.address}</div>
            <div className='table-cell py-[20px] pr-1.5 min-w-[140px] align-middle'>{dayjs(orders.date).format("MMM DD YYYY hh:mm A")}</div>
            <div className='table-cell py-[20px] pr-1.5 w-[120px] !font-medium align-middle'>{orders.amount}</div>            
        </div>
      </>
      :
      <>
        <div className="px-2.5 py-2.5 border-b border-black-100/10">
          <div className="flex">
            <div className="w-[80px] px-1.5">
              {orders.profilePicture !== '' ?  
                <img src={orders.profilePicture} alt="" width={60} height={60} className="rounded-full cursor-pointer"/>
                :
                <img src={placeholderImg} alt="" width={60} height={60} className="rounded-full cursor-pointer"/>
              }
            </div>
            <div>
              <div className="flex justify-between">
                <div className="font-medium text-[14px] leading-[18px] px-1.5">{orders.firstName} {orders.lastName}</div>
                <div className="text-[#363F6C] font-bold text-[14px] leading-[18px] px-1.5">{orders.amount}</div>
              </div>
              <div className="text-[#363F6C] text-[12px] leading-[18px] px-1.5">{orders.email}</div>
              <div className="text-[12px] leading-[18px] px-1.5">{orders.address}</div>
              <div className="text-[12px] leading-[18px] text-slate-500 px-1.5">{dayjs(orders.date).format("MMM DD YYYY hh:mm A")}</div>
            </div>
          </div>
        </div>
      </>
    );
}


export default ProductOrderRow;