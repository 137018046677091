import { useState, useEffect } from "react";
import { getFantacyMatrix } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";

const FantacyMatrix=({setShowForm, token, team, selectedMatrix, setSelectedMatrix})=>{
    const [loading, setLoading]=useState(false);
    const [matrixList, setMatrixList] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    const fetchMarixList = async() =>{
        setLoading(true);
        let [matrixListdata] = await Promise.all([
            getFantacyMatrix(token, team._id)
        ]);

        setMatrixList(matrixListdata.data);        
        setLoading(false);
    }
    const handleSelectMatrix=(matrixID)=>{
        setErrorMessage(null);
        setSelectedMatrix(matrixID);
    }
    useEffect(()=>{ 
        fetchMarixList();
    },[]);

    return(                
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails !max-w-[670px] relative !px-2.5 sm:!px-8">
                <div className="relative">
                {!loading ?
                <>
                    {matrixList.length > 0 ?
                        <>
                        <div className="mb-7">
                            <h2 className="text-[22px] leading-[33px] text-center font-semibold">Fanyacy Matrix</h2>
                        </div>
                        <div className="custom-scroll xl:max-h-[80vh]">                            
                            <div>
                            {matrixList.length>0 ?
                            <>
                                {matrixList.map((matrix)=>{
                                    return(
                                        <div className="rounded-xl px-5 py-5 bg-[var(--color-darkblue)] mb-4" key={matrix._id}>
                                            <div className="flex justify-between gap-4 mb-5">
                                                <div className="rounded-xl px-4 py-2.5 bg-white text-black text-sm">{matrix.name}</div>
                                                <div>
                                                    {selectedMatrix===matrix._id ?
                                                    <>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.8889 1H4.11111C2.39289 1 1 2.39289 1 4.11111V11.8889C1 13.6071 2.39289 15 4.11111 15H11.8889C13.6071 15 15 13.6071 15 11.8889V4.11111C15 2.39289 13.6071 1 11.8889 1Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M5.66663 8L7.41663 9.55555L10.3333 6.44444" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </>
                                                    :
                                                    <>
                                                        <button type="button" onClick={()=>{handleSelectMatrix(matrix._id)}}>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M11.8889 1.00012H4.11111C2.39289 1.00012 1 2.39301 1 4.11123V11.889C1 13.6072 2.39289 15.0001 4.11111 15.0001H11.8889C13.6071 15.0001 15 13.6072 15 11.889V4.11123C15 2.39301 13.6071 1.00012 11.8889 1.00012Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        </button>
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex gap-5 text-white">
                                                <div>
                                                    <div className="text-xs">Total Players</div>
                                                    <div className="text-sm font-semibold text-[var(--color-yellow)]">{matrix.max_player}</div>
                                                </div>
                                                <div>
                                                    <div className="text-xs">Total Budget</div>
                                                    <div className="text-sm font-semibold text-[var(--color-yellow)]">{team.currency_symbol}{matrix.total_budget}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {errorMessage!==null && 
                                    <p className="text-red-600 text-center mb-5">{errorMessage}</p>
                                }
                                <div className="flex justify-center"><button className="button" type="button" onClick={()=>{
                                    if(selectedMatrix!==null && selectedMatrix!==""){
                                        setShowForm(false);
                                    }
                                    else{
                                        setErrorMessage("Please select Fantacy Matrix")
                                    }
                                }}>Next</button></div>
                            </>
                            :
                            <div>Fanyacy Matrix not found.</div>
                            }
                            </div>
                        </div>                        
                        </>
                    :
                        <div>Fanyacy Matrix not available.</div>
                    }
                </>
                :
                <>
                    <div className="text-center py-5"><Spinner /></div>
                </>
                }
                </div>
            </div>
        </div>
    )
}
export default FantacyMatrix;