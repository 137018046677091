import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Select, { components } from "react-select";
import * as yup from 'yup';
import Spinner from "../../../components/Spinner/Spinner";
import { getGroups } from "../../../libs/dataFunctions";
import DownArrowSvg from "../../../assets/images/icon-down.svg";

const schema = yup.object({
    user_sub_ids: yup
    .array()
    .min(1, "Select at least one child")
    .nullable()
    .required("Select at least one child"),
}).required();

const JoinMembersForm = ({team, setShowForm, refreshMembers, remainingMembers}) =>{
    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(false);
    const [successMessage,setSuccessMessage] = useState(false);
    const [childoptions, setChildOptions] = useState([]);
    const [selectedChilds, setSelectedChilds] = useState([]);
    const [selectedChildsOut, setSelectedChildsOut] = useState(selectedChilds);
    
    const { handleSubmit, control, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const newMemberSubmit = async(data)=>{
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "add_child");
        formdata.append("team_id", team._id);
        formdata.append("user_sub_ids", data.user_sub_ids.join('@#'));
        formdata.append('separate_by','@#');
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                setSuccessMessage(rdata.message);
                reset();
                setShowForm(false);
                refreshMembers();                
            }
        })
        .catch(error => console.log('error', error));
    }

    const onSubmit = async(data) => {
        newMemberSubmit(data);
    }

    const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <img src={DownArrowSvg} width={24} height={24} alt="" />
          </components.DropdownIndicator>
        );
    };
    function handleSelectChange(event) {
        const current = [...event];
        var current_childs = current.map((ch) => {
            return ch.value
        });
        setSelectedChilds(current_childs);
        setSelectedChildsOut(event);        
    }    
    useEffect(() => {
        let atndee=[];
        if(remainingMembers.length > 0){
            remainingMembers.forEach(element => {
                atndee.push({'value':element.sub, 'label':element.name});
            });
        }        
        setChildOptions(atndee);
    },[]);

    return (
        !loading ?
        <>
            <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
            <div className="form-heading text-center mb-[30px]">Join Team</div>
            <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">                
                <div className="flex flex-wrap">
                    <div className="w-full">
                        <div className="mb-1.5">Who is joining this team?</div>
                        <div className='form-field-wrap mb-5 select-field-wrap groups-field-wrap relative'>
                            <Controller
                                control={control}
                                name='user_sub_ids'
                                render={({ field: { onChange, value, ref }}) => (
                                    <Select
                                        inputRef={ref}
                                        value={selectedChildsOut}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        components={{ DropdownIndicator }}
                                        placeholder={<div>Select Child*</div>}
                                        onChange={val => {
                                            onChange(val.map(c => c.value));
                                            handleSelectChange(val);
                                        }}
                                        options={childoptions}
                                        isMulti
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderWidth: 0,
                                                backgroundColor: 'rgba(239, 241, 249, 0.6)',
                                                padding: '6px 7px 6px 46px'
                                            }),
                                            menu: (baseStyles) => ({
                                                ...baseStyles,
                                                zIndex:1000,
                                                backgroundColor: '#ffffff'
                                            })
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,                                                
                                        })}
                                    />
                                )}
                            />                           
                        </div>
                        {errors.user_sub_ids?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.user_sub_ids?.message}</p> }                        
                    </div>
                </div>
                {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                {successMessage!=='' && <p className="text-green-600 mb-2.5 text-center" role="alert">{successMessage}</p>}
                <div className="w-full px-2.5">
                    <div className='flex justify-center'>
                        <input className='button button--large' type="submit" value="Add" disabled={loading}/>
                    </div>
                </div>                
            </form>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default JoinMembersForm;