import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    activeChatSource : {},
    activeChatMessages : []
};
const activeChatSlice = createSlice({
    // The name of the slice
    name : 'activeChat',
    // The initial state of the slice
    initialState,
    // The reducers of the slice
    reducers : { 
        fetchActiveChatAction : (state,action) => {
            return(
                {
                    activeChatSource:action.payload.chatSource,
                    activeChatMessages:action.payload.messages
                }
            )            
        },
        newMessageAction:(state,action) => {
            state.activeChatMessages.push(action.payload)
        },
        readMessageAction:(state,action) => {
            state.activeChatMessages = state.activeChatMessages.map(obj => {
                if (action.payload.includes(obj.conversation_details_id)) {
                    return {...obj, is_read: true};
                }          
                return obj;
            });
        },
        updateActiveSourceAction:(state,action) => {
            state.activeChatSource=action.payload;
        },
        newMemberChatAction:(state,action) => {
            return(
                {
                    activeChatSource:action.payload,
                    activeChatMessages: initialState.activeChatMessages
                }
            )
        },
        deleteActiveChatAction:(state,action) => {
            return(initialState)
        },
        deleteActiveMessageAction : (state,action) => {
            const newState = state.activeChatMessages.filter(obj => {
                return obj.conversation_details_id !== action.payload;
            });
            state.activeChatMessages = newState;
        },        
    }
});

// Export the actions of the slice
export const {fetchActiveChatAction, newMessageAction, updateActiveSourceAction, newMemberChatAction, readMessageAction, deleteActiveChatAction, deleteActiveMessageAction } = activeChatSlice.actions;
// Export the reducer of the slice
export default activeChatSlice.reducer;