import { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Spinner from "../../../components/Spinner/Spinner";
import { formatCreditCardNumber, formatExpirationDate, formatCVC, getIsUser } from '../../../libs/utils';
import CountriesList from '../../../libs/countries';
import Amex from '../../../assets/images/amex.svg';
import Discover from '../../../assets/images/discover.svg';
import DinnersClub from '../../../assets/images/dinersclub.svg';
import Jcb from '../../../assets/images/jcb.svg';
import Mastercard from '../../../assets/images/mastercard.svg';
import UnionPay from '../../../assets/images/unionpay.svg';
import Visa from '../../../assets/images/visa.svg';

const schema = yup.object().shape({
    name: yup.string().required('Card Holder name is required.'), 
    number: yup.string().required('Card number is required'),
    cvv: yup.string().required('CVV is required'),
    expiry: yup.string().required('Expiry date is required'),
    country: yup.string().required('Country is required'),
});

const CardIcon=({value})=>{
    const icons = {
        'amex':Amex,
        'mastercard':Mastercard,
        'visa':Visa,
        'discover':Discover,
        'jcb':Jcb,
        'dinersclub': DinnersClub,
        'uninpay': UnionPay,
        'elo':null,
        'hipercard':null,
        'troy':null,
        'maestro':null,
        'laser':null
    }
    if (!value) {
        return null;
    }
    
    return(
        icons[value]!==null ? <img src={icons[value]} alt="" width={24} /> : null
    )
}

const AddCardForm = ({setShowForm,refreshMyCards}) =>{
    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [cardNum, setCardNum] = useState('');
    const [cardHolderName, setCardName] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardCVV, setCardCVV] = useState('');
    const [country, setCountry] = useState('');
    const [cardicon, setCardIcon] = useState(null);
    const [isDefault, setDefault] = useState('No');

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    
    const onSubmit = async() => {
        setErrorMessage('');
        let expiry = cardExpiry.split("/");
        let today, cardday;
        today = new Date();
        cardday = new Date();
        cardday.setFullYear(parseInt(expiry[1])+2000,expiry[0]-1,0);
        if(cardicon==='amex'){
            if(cardNum.replace(/\D+/g, '').length<15){
                setErrorMessage("Invalid card number");
                if(cardCVV.length<4){
                    setErrorMessage("Invalid card number, cvc number");
                }
                return;
            }
            if(cardCVV.length<4){
                setErrorMessage("Invalid CVC number");
                return;
            }
        }
        else if(cardicon==='dinersclub'){
            if(cardNum.replace(/\D+/g, '').length<14){
                setErrorMessage("Invalid card number");
                return;
            }
        }
        else{
            if(cardNum.replace(/\D+/g, '').length<16){
                setErrorMessage("Invalid card number");
                return;
            }
        }
        if (cardday < today) {
            setErrorMessage("Invalid expiry date");
            return;
        }
        setLoading(true); 
        let formdata = new FormData();
        formdata.append("type", "add_card");
        formdata.append('card_number',cardNum);
        formdata.append('name',cardHolderName);
        formdata.append('exp_year',expiry[1]);
        formdata.append('exp_month',expiry[0]);
        formdata.append('country',country);
        formdata.append('cvc',cardCVV);
        formdata.append('is_default_card',isDefault);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_stripe_card' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                setShowForm(false);
                refreshMyCards();
            }
        })
        .catch(error => console.log('error', error));      
    }

    useEffect(() => {
        setValue('is_default_card','No');
    },[]);

    return(
        !loading ?
        <>
        <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
        <div className="form-heading text-center mb-[30px]">Add Card</div>
        <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
            <div className="flex flex-wrap">
                <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                    <input 
                        placeholder="0000 0000 0000 0000" className='text-field' 
                        type='tel'
                        {...register("number")}
                        maxLength='25'
                        autoComplete="off"
                        onChange={(e)=>{
                            e.target.value = formatCreditCardNumber(e.target.value);
                            setCardNum(e.target.value);
                            let icon = getIsUser(e.target.value);
                            setCardIcon(icon);
                            setErrorMessage('');
                        }}
                    />
                    <div className='absolute top-[18px] right-[20px]'>
                        <CardIcon value={cardicon}/>
                    </div>                    
                </div>
                {errors.number && <p className="error w-full mb-2.5 -mt-2.5" role="alert">{errors.number.message}</p>}            
                <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                    <input 
                        type='text'
                        {...register("name")}
                        autoComplete="off"
                        placeholder="Cardholder Name *"
                        onChange={(e)=>{
                            setCardName(e.target.value);
                            setErrorMessage('');
                        }}
                    />
                </div>  
                {errors.name && <p className="error w-full mb-2.5 -mt-2.5" role="alert">{errors.name.message}</p>} 
                <div className="w-full sm:w-1/2 sm:pr-1 mb-5">    
                    <div className='form-field-wrap w-full  no-field-wrap relative'>
                        <input 
                            placeholder="MM/YY" className='text-field' 
                            type='tel'
                            {...register("expiry")}
                            pattern='\d\d/\d\d'
                            autoComplete="off"
                            onChange={(e)=>{
                                e.target.value = formatExpirationDate(e.target.value);
                                setCardExpiry(e.target.value);
                                setErrorMessage('');
                            }}
                        />
                    </div>
                    {errors.expiry && <p className="error w-full mb-2.5" role="alert">{errors.expiry.message}</p>}
                </div>
                <div className="w-full sm:w-1/2 sm:pl-1 mb-5">
                    <div className='form-field-wrap w-full no-field-wrap relative'>
                        <input 
                            type='tel'
                            {...register("cvv")}
                            className='text-field'
                            placeholder='CVC'
                            autoComplete="off"
                            onChange={(e)=>{
                                e.target.value = formatCVC(e.target.value,{number:cardNum});
                                setCardCVV(e.target.value);
                                setErrorMessage('');
                            }}
                        />
                    </div>                     
                    {errors.cvv && <p className="error w-full mb-2.5" role="alert">{errors.cvv.message}</p>}
                </div>              
                <div className='form-field-wrap w-full mb-5 select-field-wrap country-field-wrap relative'>
                    <select {...register("country")} onChange={(e)=>{setCountry(e.target.value)}}>
                        <option value="">Select Country *</option>
                        {CountriesList.map((c,index)=>{
                            return(
                                <option key={index} value={c.code}>{c.name}</option>
                            );
                        })}
                    </select>
                </div>
                {errors.country && <p className="error w-full mb-2.5 -mt-2.5" role="alert">{errors.country.message}</p>}         
                <div className="form-field-wrap w-full button-group-wrap mb-5 flex justify-between items-center">
                    <div className="flex-1 button-grop-label pr-2.5 uppercase">Use as default</div>
                    <div className="r-button-group w-[148px] flex">
                        <div className="mr-4">
                        <input type="radio" {...register("is_default_card")} value="Yes" id="default-yes" className="peer hidden" onChange={(e)=>{setDefault(e.target.value)}} />
                        <label
                            htmlFor="default-yes"
                            className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                            >Yes</label>
                        </div>
                        <div>
                        <input type="radio" {...register("is_default_card")} value="No" id="default-no" className="peer hidden" onChange={(e)=>{setDefault(e.target.value)}} />
                        <label
                            htmlFor="default-no"
                            className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                            >No</label>
                        </div>
                    </div>
                </div>
                {errorMessage!=="" && <p className='text-red-700 w-full error mb-5'>{errorMessage}</p>}
                <div className="w-full px-2.5 pt-5">
                    <div className='flex justify-center mb-10'>
                        <input className='button' type="submit" value='Save' disabled={loading}/>
                    </div>
                </div>
            </div>
        </form>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}
export default AddCardForm;