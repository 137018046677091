import { useState, useEffect, useRef } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { getNewsSeenBy } from "../../../libs/dataFunctions";
import placeholderImg from '../../../assets/images/placeholder.png';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);

const NewsSeenBy = ({setShowForm, newsid, team})=>{
    const [searchParam] = useState(["name"]);
    const [q, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [seenMembers, setSeenMembers] = useState([]);
    const ref = useRef();

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    const fetchSeenList=async()=>{
        setLoading(true);
        let seenData = await getNewsSeenBy(token, team._id, newsid);
        setSeenMembers(seenData.data);
        setLoading(false);
    }

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]?
                        item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                    :
                    null
                );
            });
        });
    }

    useEffect(()=>{
        fetchSeenList();
    },[]);
    return (
        <>
        <div className="popup-form px-5">
            <div className="popup-form-wrap relative px-5 !md:px-7.5 !w-[90%] lg:!w-full !max-w-[850px]">
            {!loading ?
                <>
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <div>
                        <div className="form-heading text-center mb-8 uppercase">Seen By...</div>
                        <div className="search-form-wrap mb-5 relative" ref={ref}>
                            <label htmlFor="search-form" className="search-field relative mr-3.5 flex-1">
                                <input
                                    type="search"
                                    name="search-field"
                                    id="search-form"
                                    className="search-input lg:!py-[11px]"
                                    placeholder="Search Now"
                                    value={q}
                                    onChange={(e) => {
                                        setSearchQuery(e.target.value);                                                            
                                    }}
                                />
                            </label>
                        </div>
                        <div>
                            {search(seenMembers).length>0 ?
                            <>
                            {search(seenMembers).map((member)=>{
                                return(
                                    <div key={member._id} className={`rounded lg:rounded-xl shadow-[0_6px_30px_0_rgba(54,63,108,0.10)] mb-2.5 lg:mb-5 px-2 lg:pl-3.5 lg:pr-5 py-2 lg:py-3.5 bg-white flex items-center`}>
                                        <div className="flex w-2/3 items-center">
                                            <div className='w-[24px] lg:w-[50px] mr-2.5'>
                                            {member.profilePicture !== '' ?  
                                                <img src={member.profilePicture} alt="" width={50} height={50} className="rounded-full w-[24px] lg:w-[50px] h-[24px] lg:h-[50px] cursor-pointer" />
                                            :
                                                <img src={placeholderImg} alt="" width={50} height={50} className="rounded-full w-[24px] lg:w-[50px] h-[24px] lg:h-[50px] cursor-pointer" />
                                            }
                                            </div>
                                            <div className='member-info flex-1'>
                                                <div className='font-medium text-[9px] lg:text-[16px] leading-[14px] leading-[24px] text-[#363F6C]'>{member.name}</div>
                                                <div className='text-[7px] lg:text-[12px] leading-[7px] lg:leading-[12px] text-black'>Seen at: {dayjs(member.seen_at).format('DD MMM YYYY || hh:mm A')}</div>
                                            </div>
                                        </div>
                                        <div className="w-1/3 flex justify-end items-center">
                                            <div className="text-[9px] lg:text-[12px] leading-[9px] lg:leading-[12px] py-2.5 px-2.5 rounded-full text-black bg-[#e1fde9] font-medium">seen on APP</div>
                                        </div>
                                    </div>
                                )
                            })}
                            </>
                            :
                            <>
                            <div className="text-center font-medium">No data found.</div>
                            </>
                            }
                        </div>
                    </div>
                </>
                :
                <div className="py-5 text-center"><Spinner /></div>
            }
            </div>
        </div>
        </>
    )
}

export default NewsSeenBy;