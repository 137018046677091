import { useContext, useState, useEffect, useRef } from "react";
import { TeamContext } from "../../../contexts/TeamContext";
import Spinner from "../../../components/Spinner/Spinner";
import { getRequets } from "../../../libs/dataFunctions";
import RequestRow from "./RequestRow";

const TabRequests=()=>{
    const {team, token } = useContext(TeamContext);
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(false);
    
    async function refreshRequests(){
        setLoading(true);
        let requestData = await getRequets(team._id, token);
        setRequests(requestData);
        setLoading(false);
    }
    useEffect(()=>{      
        refreshRequests();         
    },[]);
    return(
        !loading ?
        <>
            <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center">
                <div className="title-col mb-2.5 md:mb-0">Requests List</div>                
            </div>
            {team.role==='admin' &&
            <div className="requests-list">                
                    <table className="table-auto w-full">
                        <thead>
                            <tr>
                                <th className="w-[120px] pl-[30px]"></th>
                                <th className="text-left px-2.5 py-[18px]">Name</th>
                                <th className="text-left px-2.5 py-[18px] w-[230px]">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requests && requests.length > 0 ? (
                                requests.map((request,index) => {                        
                                    return (
                                        <RequestRow request={request} team={team} key={index} />
                                    )
                                })
                            )
                            :
                            null
                            }
                        </tbody>
                    </table>                
            </div>
            }
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default TabRequests;