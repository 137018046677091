import { useContext, useState, useEffect, useRef } from "react";
import { TeamContext } from "../../../contexts/TeamContext";
import GroupsRow from "./GroupsRow";
import rgroups from '../../../assets/images/noemptyother.png';
import Spinner from "../../../components/Spinner/Spinner";
import GroupForm from "./GroupForm";
import { getGroupsV2Old } from "../../../libs/dataFunctions";

const TabGroups=()=>{
    const { team, token } = useContext(TeamContext);
    const [groups, setGroups] = useState([]);
    const [groupsLoading, setGroupsLoading] = useState(false);
    const [showNewForm, setShowNewForm] = useState(false);
    const [q, setSearchQuery] = useState("");    
    const [searchParam] = useState(["name"]);
    const [totalGroups, setTotalGroups] = useState(0);

    async function refreshGroups(){
        setGroupsLoading(true);
        let groupsData = await getGroupsV2Old(team._id, token);
        setGroups(groupsData);
        setTotalGroups(groupsData.length);
        setGroupsLoading(false);
    }

    const search=(items)=>{
        return items.filter((item) => {
            if(item.nest_groups.length>0){
                let res = search(item.nest_groups);
                if(res.length>0){
                    return item;
                }
            }
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    let scrl = useRef(null);
    const [scrollX, setscrollX] = useState(0);
    const [scrolEnd, setscrolEnd] = useState(false);
    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };
    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift);

        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    useEffect(()=>{
        refreshGroups();
    },[]);

    const GroupNewForm = ({setShowNewForm}) =>{        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap popup-form-group relative">                    
                    <GroupForm team={team} setShowForm={setShowNewForm} refreshGroups={refreshGroups} isListview={false} isAddForm={true} />
                </div>
            </div>
        )
    }

    return(
        !groupsLoading ?
        <>
            <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center">
                <div className="title-col mb-2.5 md:mb-0">Groups List</div>
                <div className="cta-col flex flex-col md:flex-row items-center">
                    <label htmlFor="search-form" className="search-field relative mr-3.5 mb-2.5 md:mb-0">
                        <input
                            type="search"
                            name="search-field"
                            id="search-form"
                            className="search-input"
                            placeholder="Search Groups"
                            value={q}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />                        
                    </label>
                    {team.role==='admin' &&
                        <button type="button" className="button button-action button-new" onClick={(e)=>{setShowNewForm(true)}}>Add Group</button>
                    }
                </div>
                {showNewForm &&
                    <GroupNewForm setShowNewForm={setShowNewForm} />
                }
            </div>
            {groups && groups.length > 0 ?
                <>
                    <div className="groups-list px-2.5 sm:px-7.5 pb-[77px]"> 
                        {search(groups).sort((a, b) => {
                                return new Date(b.dateStart).getTime() - new Date(a.dateStart).getTime()
                            }).map((group,index) => {                        
                                return (
                                    <GroupsRow group={group} team={team} refreshGroups={refreshGroups} neastedGroups={group.nest_groups} totalGroups={totalGroups} index={index} key={index} />
                                )
                            })
                        }
                    </div>
                </>
                :
                <div className="empty text-center">
                    <div className="image"><img src={rgroups} alt="Plaisport" width={228} /></div>
                    <div className="title mb-5">No Groups Yet?</div>
                    <div className="description mb-8">Don’t forget, you can manage what content every member sees - Simply create some groups and allocate your members!</div>
                    {team.role==='admin' &&
                        <button type="button" className='button button-yellow button-yellow-add' onClick={(e)=>{setShowNewForm(true)}}>Add Groups</button>
                    }
                </div>
                }
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default TabGroups;