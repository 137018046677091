import { useContext, useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useAuthState } from "../../../contexts/AuthContext";
import { TeamContext } from "../../../contexts/TeamContext";
import { getChatGroupDetails, getMembersForChat, getGroupsV2 } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";
import placeholderImg from '../../../assets/images/placeholder.png';
import EditGroupform from "./EditChatGroupForm";
import { withTimeout } from "../../../libs/utils";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);

const ChatGroupMemberRow = ({member, isAdmin, convId, setGroupMembers, currentUserData, socket})=>{    
    const [showDetail, setShowDetail] = useState(false);
    
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const ChatMemberDetail = ({setShowForm}) =>{
        const changeRole = async() =>{
            var formdata = new FormData();
            formdata.append("type", "change_role");
            formdata.append("conversation_id", convId);
            formdata.append("user_sub",member.sub);
            
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
                .then(response => response.text())
                .then(result => {
                    let rdata = JSON.parse(result);
                    if(rdata.status===1){
                        socket.emit('change_user_role', rdata.conversation_details, withTimeout((response) => {
                            console.log("success!" + response);
                          }, (response) => {
                            console.log(response);
                          }, 1500));
                          
                        setGroupMembers(prevState=>{
                            const newState = prevState.map(obj => {
                                if (obj.sub === member.sub) {
                                    if(member.user_role === 'Admin'){
                                        return {...obj, user_role: 'Member'};
                                    }
                                    else{
                                        return {...obj, user_role: 'Admin'};
                                    }                              
                                }          
                                return obj;
                            });
                            return newState;
                        });
                    }
                    else{
                        alert(rdata.message);
                    }
                })
                .catch(error => console.log('error', error));
        }

        const removeMemberFromGroup = async() =>{
            if(window.confirm("Are you sure to remove this member from group?")){
                var formdata = new FormData();
                formdata.append("type", "delete_member");
                formdata.append("conversation_id", convId);
                formdata.append("user_sub_ids",member.sub);
                
                var secureHeader = new Headers();
                secureHeader.append('Authorization','Bearer ' + token);
                secureHeader.append('device_type','W');
                const requestOptions = {
                    method: 'POST',
                    redirect: 'follow',
                    body: formdata,
                    headers: secureHeader
                };

                await fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        let rdata = JSON.parse(result);
                        if(rdata.status===1){
                            socket.emit('delete_member', rdata.conversation_details, withTimeout((response) => {
                                console.log("success!" + response);
                              }, (response) => {
                                console.log(response);
                              }, 1500));
                            setShowForm(false);
                            setGroupMembers(current =>
                            current.filter(obj => {
                                return obj.sub !== member.sub;
                            }));
                        }
                        else{
                            alert(rdata.message);
                        }
                    })
                    .catch(error => console.log('error', error));
            }
        }

        return(
            <div className="popup-form">
                <div className="popup-form-wrap popup-form-memberdetails relative !px-2.5 sm:!px-8">                    
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                    <div className='flex flex-col sm:flex-row items-center w-full member-card-row pb-5 border-b border-[#53545C]/30'>
                        <div className='pr-5'>
                            {member.profilePicture !== '' ?  
                            <img src={member.profilePicture} alt="" width={100} height={100} className="rounded-full cursor-pointer"/>
                            :
                            <img src={placeholderImg} alt="" width={100} height={100} className="rounded-full cursor-pointer"/>
                            }
                        </div>
                        <div className='px-2.5 sm:px-0 py-2.5 sm:py-0 text-center sm:text-left sm:items-start'>
                            <div className='flex flex-col self-center grow'>
                                <h3 className='text-[18px] leading-[27px] font-medium text-[#363F6C]'>{member.name}</h3>
                                <div className='text-[14px] leading-[21px] font-regular text-[#53545C]'>{member.email}</div>
                                <div className='text-[14px] leading-[21px] font-regular text-[#53545C]'>{member.user_role}</div>                                    
                            </div>
                        </div>                            
                    </div>
                    <div className='flex flex-col sm:flex-row w-full justify-between member-card-row py-5'>
                        {member.phoneNumber!=="" &&
                        <div className='member-phone flex items-center mb-2.5 sm:mb-0'><div className='button-tiny phone rounded-full mr-1.5'></div>{member.phoneCode!=="" ? member.phoneCode+' '+member.phoneNumber : member.phoneNumber}</div>
                        }
                        <div className='member-birthdate flex items-center'><div className='button-tiny date rounded-full mr-1.5'></div>{(member.dateOfBirth!=='' && member.dateOfBirth!==null) ? dayjs(member.dateOfBirth).format('DD / MMM / YYYY'): (member.is_above_18 ? 'Over 18 years' : 'Under 18 years')}</div>
                    </div>
                    {isAdmin && currentUserData.sub!==member.sub &&
                    <>
                        {member.user_role.toLowerCase()!=='admin' ?
                            <div className="flex justify-between bg-[#F5F5F8] rounded-[100px] py-3 px-5 cursor-pointer text-[#363F6C] hover:bg-[#363F6C] hover:text-white mb-5" onClick={()=>changeRole()}>
                                <div className="text-[12px] leading-[18px] font-medium">Make Group Admin</div>
                                <div className="w-[20px] h-[20px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_3984_3837)">
                                            <path d="M8.07716 0.119995C5.75639 0.119995 3.89408 1.71384 3.89408 4.75999C3.89408 6.74923 4.68639 8.77538 5.88947 10.0238C6.3587 11.2723 5.51255 11.7369 5.33716 11.8031C2.9087 12.6938 0.0717773 14.3092 0.0717773 15.9138V16.5138C0.0717773 18.7008 4.23639 19.2061 8.10024 19.2061C8.94239 19.2047 9.78423 19.1729 10.6241 19.1108C9.57243 18.0007 8.98751 16.5291 8.99024 15C8.99024 13.6223 9.45947 12.36 10.2395 11.3461C10.1241 11.0754 10.0779 10.6485 10.2879 9.99999C11.4841 8.74999 12.2595 6.74153 12.2595 4.75923C12.2595 1.71384 10.3949 0.120764 8.07639 0.120764L8.07716 0.119995ZM15.0002 10.1438C13.7124 10.1438 12.4773 10.6554 11.5667 11.5661C10.6561 12.4767 10.1445 13.7118 10.1445 14.9996C10.1445 16.2874 10.6561 17.5225 11.5667 18.4332C12.4773 19.3438 13.7124 19.8554 15.0002 19.8554C16.2881 19.8554 17.5232 19.3438 18.4338 18.4332C19.3444 17.5225 19.856 16.2874 19.856 14.9996C19.856 13.7118 19.3444 12.4767 18.4338 11.5661C17.5232 10.6554 16.2881 10.1438 15.0002 10.1438ZM14.3272 12.3077H15.6733V14.3031H17.6687V15.6969H15.6733V17.6923H14.3272V15.6969H12.3079V14.3031H14.3272V12.3077Z" fill="currentColor"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3984_3837">
                                                <rect width="20" height="20" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                            :
                            <div className="flex justify-between bg-[#F5F5F8] rounded-[100px] py-3 px-5 cursor-pointer text-[#363F6C] hover:bg-[#363F6C] hover:text-white mb-5" onClick={()=>changeRole()}>
                                <div className="text-[12px] leading-[18px] font-medium">Remove As Group Admin</div>
                                <div className="w-[20px] h-[20px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_3984_3837)">
                                            <path d="M8.07716 0.119995C5.75639 0.119995 3.89408 1.71384 3.89408 4.75999C3.89408 6.74923 4.68639 8.77538 5.88947 10.0238C6.3587 11.2723 5.51255 11.7369 5.33716 11.8031C2.9087 12.6938 0.0717773 14.3092 0.0717773 15.9138V16.5138C0.0717773 18.7008 4.23639 19.2061 8.10024 19.2061C8.94239 19.2047 9.78423 19.1729 10.6241 19.1108C9.57243 18.0007 8.98751 16.5291 8.99024 15C8.99024 13.6223 9.45947 12.36 10.2395 11.3461C10.1241 11.0754 10.0779 10.6485 10.2879 9.99999C11.4841 8.74999 12.2595 6.74153 12.2595 4.75923C12.2595 1.71384 10.3949 0.120764 8.07639 0.120764L8.07716 0.119995ZM15.0002 10.1438C13.7124 10.1438 12.4773 10.6554 11.5667 11.5661C10.6561 12.4767 10.1445 13.7118 10.1445 14.9996C10.1445 16.2874 10.6561 17.5225 11.5667 18.4332C12.4773 19.3438 13.7124 19.8554 15.0002 19.8554C16.2881 19.8554 17.5232 19.3438 18.4338 18.4332C19.3444 17.5225 19.856 16.2874 19.856 14.9996C19.856 13.7118 19.3444 12.4767 18.4338 11.5661C17.5232 10.6554 16.2881 10.1438 15.0002 10.1438ZM14.3272 12.3077H15.6733V14.3031H17.6687V15.6969H15.6733V17.6923H14.3272V15.6969H12.3079V14.3031H14.3272V12.3077Z" fill="currentColor"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3984_3837">
                                                <rect width="20" height="20" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        }
                        <div className="flex justify-between bg-[#F5F5F8] rounded-[100px] py-3 px-5 cursor-pointer text-[#FF0000] hover:bg-[#FF0000] hover:text-white" onClick={()=>{removeMemberFromGroup()}}>
                            <div className="text-[12px] leading-[18px] font-medium">Remove from Group</div>
                            <div className="w-[20px] h-[20px]">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_3984_3843)">
                                        <path d="M6.66683 9.16675C6.44582 9.16675 6.23385 9.25455 6.07757 9.41083C5.92129 9.56711 5.8335 9.77907 5.8335 10.0001C5.8335 10.2211 5.92129 10.4331 6.07757 10.5893C6.23385 10.7456 6.44582 10.8334 6.66683 10.8334H13.3335C13.5545 10.8334 13.7665 10.7456 13.9228 10.5893C14.079 10.4331 14.1668 10.2211 14.1668 10.0001C14.1668 9.77907 14.079 9.56711 13.9228 9.41083C13.7665 9.25455 13.5545 9.16675 13.3335 9.16675H6.66683Z" fill="currentColor"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M19.1668 10C19.1668 15.0625 15.0627 19.1667 10.0002 19.1667C4.93766 19.1667 0.833496 15.0625 0.833496 10C0.833496 4.93754 4.93766 0.833374 10.0002 0.833374C15.0627 0.833374 19.1668 4.93754 19.1668 10ZM17.5002 10C17.5002 10.985 17.3062 11.9602 16.9293 12.8702C16.5524 13.7801 15.9999 14.6069 15.3035 15.3033C14.607 15.9998 13.7802 16.5522 12.8703 16.9291C11.9603 17.306 10.9851 17.5 10.0002 17.5C9.01525 17.5 8.03998 17.306 7.13004 16.9291C6.2201 16.5522 5.3933 15.9998 4.69686 15.3033C4.00042 14.6069 3.44798 13.7801 3.07107 12.8702C2.69416 11.9602 2.50016 10.985 2.50016 10C2.50016 8.01092 3.29034 6.10326 4.69686 4.69674C6.10338 3.29022 8.01104 2.50004 10.0002 2.50004C11.9893 2.50004 13.8969 3.29022 15.3035 4.69674C16.71 6.10326 17.5002 8.01092 17.5002 10Z" fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3984_3843">
                                            <rect width="20" height="20" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </>
                    }
                </div>
            </div>
        )
    }

    return(
        <>
        <div className={`rounded lg:rounded-xl shadow-[0_6px_30px_0_rgba(54,63,108,0.10)] mb-2.5 lg:mb-5 px-2 lg:pl-3.5 lg:pr-5 py-2 lg:py-3.5 bg-white flex items-center`}>
            <div className="flex w-2/3 items-center">
                <div className='w-[24px] lg:w-[50px] mr-2.5'>
                {member.profilePicture !== '' ?  
                    <img src={member.profilePicture} alt="" width={50} height={50} className="rounded-full w-[24px] lg:w-[50px] h-[24px] lg:h-[50px] cursor-pointer" />
                :
                    <img src={placeholderImg} alt="" width={50} height={50} className="rounded-full w-[24px] lg:w-[50px] h-[24px] lg:h-[50px] cursor-pointer" />
                }
                </div>
                <div className='member-info flex-1'>
                    <div className='font-medium text-[9px] lg:text-[16px] leading-[14px] leading-[24px] text-[#363F6C]'>{member.name}</div>
                    <div className='text-[7px] lg:text-[12px] leading-[7px] lg:leading-[12px] text-black'>{dayjs(member.created_at).format('DD MMMM YYYY')}</div>
                </div>
            </div>
            <div className="w-1/3 flex justify-end items-center">
                <div className={`text-[7px] lg:text-[12px] leading-[12px] lg:leading-[24px] mr-3 ${member.user_role.toLowerCase()==='member' ? 'text-[#363F6C]' : 'text-[#87D936]'}`}>{member.user_role}</div>
                <div className="w-[24px] h-[24px] cursor-pointer" onClick={()=>{setShowDetail(true);}}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.78345 15.8239C9.66593 15.7111 9.5999 15.5582 9.5999 15.3987C9.5999 15.2392 9.66593 15.0863 9.78345 14.9735L12.8867 11.9964L9.78345 9.01926C9.66926 8.90583 9.60607 8.7539 9.6075 8.59621C9.60892 8.43851 9.67486 8.28767 9.79109 8.17616C9.90733 8.06464 10.0646 8.00139 10.2289 8.00002C10.3933 7.99865 10.5517 8.05927 10.6699 8.16883L14.2164 11.5712C14.3339 11.6839 14.3999 11.8369 14.3999 11.9964C14.3999 12.1558 14.3339 12.3088 14.2164 12.4216L10.6699 15.8239C10.5523 15.9367 10.3929 16 10.2267 16C10.0604 16 9.90102 15.9367 9.78345 15.8239Z" fill="black"/>
                    </svg>
                </div>
            </div>
        </div>
        {showDetail && 
            <ChatMemberDetail setShowForm={setShowDetail} />
        }
        </>
    )
}

const ChatGroupAddMember = ({setShowForm, team, groupMembers, convId, setGroupMembers}) =>{
    const [searchParam] = useState(["name"]);
    const [q, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [membersList, setMembersList] = useState([]);
    const [allMembersList, setAllMembersList] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [teamGroups, setTeamGroups] = useState([]);
    const [errorMsg, setErorrMsg] = useState(null);
    const [openFilterMenu,setFilterMenu] = useState(false);
    const ref = useRef();

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const fetchMembers = async() =>{
        setLoading(true);
        let [membersListdata, teamGroupsData] = await Promise.all([
            getMembersForChat(token, team._id),
            getGroupsV2(team._id, token)
        ]);
        let tmpmemberlist = [];
        membersListdata.data.forEach((member)=>{
            let groups = [];
            member.groups_new.forEach((tgrp)=>{
                groups.push(tgrp._id);
            });
            member.groups=groups;
            tmpmemberlist.push(member);
        })
        setAllMembersList(tmpmemberlist);
        setMembersList(tmpmemberlist);
        if(teamGroupsData.length>0){
            let teamGroupsList = [];
            teamGroupsData.forEach(element => {
                teamGroupsList.push(element);
                if(element.nest_groups.length > 0){                    
                    element.nest_groups.forEach(celm =>{
                        teamGroupsList.push(celm);
                    });                    
                }                
            });
            setTeamGroups(teamGroupsList);
        }
        setLoading(false);
    }

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]?
                        item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                    :
                    null
                );
            });
        });
    }

    const handleSubmitAddMembers=async()=>{
        setErorrMsg(null);
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "add_group_member");
        formdata.append("conversation_id", convId);
        formdata.append("user_sub_ids",selectedMembers.join('@#'));
        formdata.append("separate_by",'@#');
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status===1){
                    let responsedata = rdata.data;
                    setGroupMembers(responsedata.members);
                    setShowForm(false);
                }
                else{
                    alert(rdata.message);
                }
            })
            .catch(error => console.log('error', error));
    }

    const filterMembers=(group)=>{
        if(group==='all'){
            setMembersList(allMembersList);
        }
        else{
            let filteredList = allMembersList.filter((item) => {
                return (item.groups.includes(group));
            });  
            setMembersList(filteredList);
        }        
    }

    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (ref.current && !ref.current.contains(e.target)) {
                setFilterMenu(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[setFilterMenu]);

    useEffect(()=>{ 
        fetchMembers();
    },[]);

    return(
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails !max-w-[600px] relative !px-2.5 sm:!px-8">                    
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                <div className="relative">
                    {!loading ?
                    <>
                        <div className="chat-members-list" style={{maxHeight: '70vh', overflowY: 'auto', paddingBottom: '40px', paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                                <h2 className="title mr-5 uppercase">Add Member</h2>
                                <div className="sep mr-4"></div>
                                <button type="button" className="button button--yellow button-medium">Select All</button>                        
                            </div>
                            <div className="search-form-wrap flex mb-5 relative" ref={ref}>
                                <label htmlFor="search-form" className="search-field relative mr-3.5 flex-1">
                                    <input
                                        type="search"
                                        name="search-field"
                                        id="search-form"
                                        className="search-input lg:!py-[11px]"
                                        placeholder="Search Now"
                                        value={q}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);                                                            
                                        }}
                                    />
                                </label>
                                {teamGroups.length > 0 &&
                                <>
                                    <div>
                                        <button type="button" className="w-[30px] lg:w-[40px] h-[30px] lg:h-[40px]" onClick={()=>setFilterMenu(!openFilterMenu)}>
                                            <svg className="w-full h-full" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="20" cy="20" r="20" fill="#363F6C"/>
                                                <path d="M24.9938 21.7284H21.1152C20.5033 21.7284 20.0071 22.2246 20.0071 22.8365C20.0071 23.4483 20.5033 23.9446 21.1152 23.9446H24.9938C25.6057 23.9446 26.1019 23.4483 26.1019 22.8365C26.1019 22.2246 25.6068 21.7284 24.9938 21.7284ZM29.4262 12.8636H21.1152C20.5033 12.8636 20.0071 13.3599 20.0071 13.9717C20.0071 14.5836 20.5033 15.0798 21.1152 15.0798H29.4262C30.0381 15.0798 30.5343 14.5836 30.5343 13.9717C30.5343 13.3599 30.0391 12.8636 29.4262 12.8636ZM22.7777 26.1607H21.084C20.4722 26.1607 19.9759 26.657 19.9759 27.2688C19.9759 27.8807 20.4722 28.3769 21.084 28.3769H22.7777C23.3895 28.3769 23.8546 27.8807 23.8546 27.2688C23.8546 26.657 23.3906 26.1607 22.7777 26.1607ZM27.21 17.296H21.1152C20.5033 17.296 20.0071 17.7922 20.0071 18.4041C20.0071 19.016 20.5033 19.5122 21.1152 19.5122H27.21C27.8219 19.5122 28.3181 19.016 28.3181 18.4041C28.3181 17.7922 27.8229 17.296 27.21 17.296ZM16.6645 23.207L15.5426 24.4328V13.9728C15.5426 13.3599 15.0474 12.8636 14.4345 12.8636C13.8216 12.8636 13.3264 13.3599 13.3264 13.9728V24.4304L12.2044 23.207C11.9859 22.9686 11.6871 22.8475 11.3869 22.8475C11.1097 22.8473 10.8426 22.9513 10.6386 23.1389C10.1874 23.5527 10.1572 24.2546 10.5704 24.7065L13.5865 28.0339C14.0062 28.4931 14.8005 28.4931 15.2206 28.0339L18.2366 24.7065C18.6501 24.2546 18.6196 23.5531 18.1685 23.1389C17.7795 22.7257 17.08 22.7568 16.6645 23.207Z" fill="#FBDB09"/>
                                            </svg>
                                        </button>                                                
                                    </div>
                                    <div className={`chat-conversation-menu !w-[175px] max-h-[300px] overflow-y-auto ${openFilterMenu ? 'flex flex-col' : 'hidden'}`}>
                                        <button className='chat-conversation-menu-item' onClick={()=>{filterMembers('all')}}>View All</button>
                                        {teamGroups.map((tgroup)=>{
                                            return(
                                                <button key={tgroup._id} className='chat-conversation-menu-item' onClick={()=>{filterMembers(tgroup._id)}}>{tgroup.name}</button>
                                            )
                                        })}
                                    </div>
                                </>
                                }
                            </div>
                            {search(membersList).length>0 ?
                            <>
                                {search(membersList).map((member,index)=>{
                                    return(
                                        <ChatMemberRow member={member} key={index} selectedMembers={selectedMembers} setSelectedMembers={setSelectedMembers} />
                                    )
                                })}
                            </>
                            :
                            <div>Member not found.</div>
                            }
                        </div>
                        {selectedMembers.length > 0 &&
                            <div className="absolute bottom-0 w-full flex justify-center">
                                <input className='button button--medium' type="button" value='Add' disabled={loading} onClick={()=>{handleSubmitAddMembers()}}/>
                            </div>
                        }
                    </>
                    :
                    <>
                        <div className="text-center py-5"><Spinner /></div>
                    </>
                    }
                </div>
            </div>
        </div>
    )
}

const ChatMemberRow = ({member, selectedMembers, setSelectedMembers}) =>{
    var selectedMemberArray = [...selectedMembers];
    const handleAddMember=()=>{
        if (selectedMemberArray.indexOf(member.sub) !== -1) {
            setSelectedMembers(selectedMembers.filter((item) => {return !item.includes(member.sub)}));
        }
        else{
            setSelectedMembers([...selectedMembers, member.sub]);
        }
    }
    
    return(
        <div className={`chat-member-row flex items-center flex-1 cursor-pointer ${selectedMemberArray.indexOf(member.sub) !== -1 ? 'selected' : ''}`} onClick={()=>handleAddMember()}>
            <div className='w-[24px] lg:w-[44px] mr-2.5'>
            {member.profilePicture !== '' ?  
                <img src={member.profilePicture} alt="" width={44} height={44} className="rounded-full w-[24px] lg:w-[44px] h-[24px] lg:h-[44px] cursor-pointer" />
            :
                <img src={placeholderImg} alt="" width={44} height={44} className="rounded-full w-[24px] lg:w-[44px] h-[24px] lg:h-[44px] cursor-pointer" />
            }
            </div>
            <div className='member-info flex-1'>
                <div className='pr-2.5'>{member.name}</div>
            </div>
            <div className="w-[20px] lg:w-[40px] lg:pl-2.5">
                {selectedMemberArray.indexOf(member.sub) !== -1 ?
                    <svg className="w-[18px] h-[17px] lg:w-[31px] lg:h-[30px]" width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="15.8486" cy="15" r="15" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M23.8486 15C23.8486 15.5523 23.4009 16 22.8486 16L8.84863 16C8.29635 16 7.84863 15.5523 7.84863 15C7.84863 14.4477 8.29635 14 8.84863 14L22.8486 14C23.4009 14 23.8486 14.4477 23.8486 15Z" fill="#363F6C"/>
                    </svg>                
                :
                    <svg className="w-[18px] h-[17px] lg:w-[31px] lg:h-[30px]" width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="15.8486" cy="15" r="15" fill="#363F6C"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.001 6.60413C16.5533 6.60413 17.001 7.05184 17.001 7.60413V21.6041C17.001 22.1564 16.5533 22.6041 16.001 22.6041C15.4487 22.6041 15.001 22.1564 15.001 21.6041V7.60413C15.001 7.05184 15.4487 6.60413 16.001 6.60413Z" fill="#FBDB09"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.001 14.6041C24.001 15.1564 23.5533 15.6041 23.001 15.6041L9.00098 15.6041C8.44869 15.6041 8.00098 15.1564 8.00098 14.6041C8.00098 14.0518 8.44869 13.6041 9.00098 13.6041L23.001 13.6041C23.5533 13.6041 24.001 14.0518 24.001 14.6041Z" fill="#FBDB09"/>
                    </svg>
                }
            </div>
        </div>
    )
}

const ChatGroupDetailComponent=({socket,group_id, setCurrentChatPage})=>{
    const { team, token, teamUpdating } = useContext(TeamContext);
    const [loading, setLoading] = useState(false);
    const [groupData, setGroupData] = useState([]);
    const [groupMembers, setGroupMembers] = useState([]);
    const [searchParam] = useState(["name"]);
    const [q, setSearchQuery] = useState("");
    const userAuthState = useAuthState();
    const userDetails = userAuthState.user;
    const [isAdmin, setIsAdmin] = useState(false);
    const [showAddMember, setShowAddMember] = useState(false);
    const [showEditGroup, setShowEditGroup] = useState(false);

    const fetchGroupDetail = async() =>{
        setLoading(true);

        let [groupDataResponse] = await Promise.all([
            getChatGroupDetails(token, team._id, group_id)
        ]);

        setGroupData(groupDataResponse.data);
        setGroupMembers(groupDataResponse.data.members);

        groupDataResponse.data.members.forEach(item=>{
            if(item.sub===userDetails.sub && item.user_role.toLowerCase()==='admin'){
                setIsAdmin(true);
            }
        });
        setLoading(false);
    }

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]?
                        item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                    :
                    null
                );
            });
        });
    }

    useEffect(()=>{
        fetchGroupDetail();
    },[]);

    useEffect(()=>{
        socket.on('role_changed',async(message) => {
            let messageObj = JSON.parse(message);
            if(messageObj.conversation_id === group_id){
                groupMembers.forEach(item=>{
                    if(item.sub===messageObj.user_sub){
                        item.user_role=messageObj.user_role;                        
                    }
                    if(item.sub ===userDetails.sub){                            
                        if(messageObj.user_role==='Admin'){
                            setIsAdmin(true);
                        }
                        else{
                            setIsAdmin(false);
                        }                        
                    }
                });
            }            
        });
    });

    return(
        <>
        {!loading && !teamUpdating ?
            <>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}`}>
                        <span>{team.name}</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink onClick={()=>setCurrentChatPage('')}>
                        <span>Chat</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Group Info
                </div>
                <div className="body px-5 lg:px-8 py-10">
                    <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                        <h2 className="title mr-5 uppercase">Group Info</h2>
                        <div className="sep mr-4"></div>
                        <button className="button button--yellow button-medium" onClick={()=>setCurrentChatPage('')}>Back</button>
                    </div>
                    <div className="w-full rounded lg:rounded-xl bg-white px-3 lg:px-8 py-5 lg:py-10">
                        <div className="lg:flex items-center">
                            <div className="w-full lg:w-1/2 lg:pr-9 mb-10 lg:mb-0">    
                                <div className="max-w-[430px] mx-auto">
                                    <div className="w-[185px] h-[185px] overflow-hidden mx-auto mb-6">
                                        {groupData.profilePicture !== '' ?  
                                            <img src={groupData.profilePicture} alt="" width={185} height={185} className="rounded-full w-[185px] h-[185px] object-fit" />
                                        :
                                            <img src={placeholderImg} alt="" width={185} height={185} className="rounded-full w-[185px] h-[185px] object-fit" />
                                        }
                                    </div>
                                    <div className="text-center font-extrabold text-[22px] leading-[33px] text-[#363F6C] mb-9">{groupData.name}</div>
                                    <div className="text-center font-light text-[14px] leading-[16px] text-black pt-0.5">{groupData.description}</div>
                                    {isAdmin && 
                                        <>
                                        <div className="text-center mt-5">
                                            <button type="button" className="button button--yellow button-medium" onClick={()=>{setShowEditGroup(true)}}>Edit Group</button>
                                        </div>
                                        {showEditGroup &&
                                            <EditGroupform setShowForm={setShowEditGroup} groupData={groupData} setGroupData={setGroupData} socket={socket} />
                                        }
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="w-full lg:w-1/2">
                                <div className="chat-members-list-wrap">                                    
                                    <div className="chat-members-list">
                                        <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                                            <h2 className="title mr-5 uppercase">Members</h2>
                                            <div className="sep mr-4"></div>
                                            {isAdmin && 
                                                <button type="button" className="button button--yellow button-medium" onClick={()=>{setShowAddMember(true)}}>Add Member</button>                        
                                            }
                                        </div>
                                        {showAddMember &&
                                            <ChatGroupAddMember setShowForm={setShowAddMember} team={team} groupMembers={groupMembers} fetchGroupDetail={fetchGroupDetail} convId={groupData.conversation_id} setGroupMembers={setGroupMembers} />
                                        }
                                        <div className="search-form-wrap flex mb-5">
                                            <label htmlFor="search-form" className="search-field relative mr-3.5 flex-1">
                                                <input
                                                    type="search"
                                                    name="search-field"
                                                    id="search-form"
                                                    className="search-input"
                                                    placeholder="Search Now"
                                                    value={q}
                                                    onChange={(e) => {
                                                        setSearchQuery(e.target.value);                                                            
                                                    }}
                                                />
                                            </label>
                                        </div>
                                        {search(groupMembers).length>0 ?
                                        <>
                                            {search(groupMembers).map((member,index)=>{
                                                return(
                                                    <ChatGroupMemberRow isAdmin={isAdmin} currentUserData={userDetails} convId={groupData.conversation_id} member={member} setGroupMembers={setGroupMembers} key={index} socket={socket} />
                                                )
                                            })}
                                        </>
                                        :
                                        <div>Member not found.</div>
                                        }
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        :
            <div className="text-center py-5"><Spinner /></div>
        }
        </>
    )
}

export default ChatGroupDetailComponent;