import { useEffect, useState, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getGroupsV2 } from "../../../libs/dataFunctions";
import * as yup from 'yup';
import Spinner from "../../../components/Spinner/Spinner";

const schema = yup.object({
    group_name: yup.string().required('Group name is required.'),
}).required();

const GroupForm = ({group, team, setShowForm, isAddForm, refreshGroups, isChild, hasChild, isListview}) =>{
    const [loading,setLoading] = useState(false);
    const [allGroups, setGroups] = useState([]);
    const [content, setContent] = useState('');
    const [name, setName] = useState('');
    const limitTextarea = 70;
    const limitInput = 20;

    const [errorMessage, setErrorMessage] = useState('');
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const setFormattedContent = useCallback(
        (text) => {
          setContent(text.slice(0, limitTextarea));
        },
        [limitTextarea, setContent]
    );

    const setFormattedName = useCallback(
        (text) => {
            setName(text.slice(0, limitInput));
        },
        [limitInput, setName]
    );

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const navigate = useNavigate();
    
    const createGroup= async(data) =>{
        setErrorMessage('');
        console.log(data);
        var formdata = new FormData();
        formdata.append("type", "create_group_v2");
        formdata.append("group_name", data.group_name);
        if(data.nest_group_id!==undefined && data.nest_group_id!==""){
            formdata.append("nest_group_id",data.nest_group_id);
        }        
        formdata.append("team_id", team._id);
        formdata.append("description", data.group_desc);
        
        setLoading(true);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status===0){
                setErrorMessage(rdata.message);
            }
            else{
                setShowForm(false);
                refreshGroups();
            }
        })
        .catch(error => console.log('error', error));
        setLoading(false);
    }
    const updateGroup= async(data) =>{
        setErrorMessage('');
        var formdata = new FormData();
        formdata.append("type", "update_group_v2");
        formdata.append("group_id",group._id);
        formdata.append("group_name", data.group_name);
        formdata.append("team_id", team._id);
        formdata.append("description", data.group_desc);
        if(data.nest_group_id!==undefined && data.nest_group_id!==""){
            formdata.append("nest_group_id",data.nest_group_id);
        }
        setLoading(true);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status===0){
                setErrorMessage(rdata.message);
            }
            else{
                setShowForm(false);
                if(isListview){
                    refreshGroups();
                }
                else{
                    navigate('/team/'+team._id+'/groups');
                }
            }
        })
        .catch(error => console.log('error', error));
        setLoading(false);
    }
    const onSubmit = (data) => {
        if(isAddForm){
            createGroup(data);
        }
        else{
            updateGroup(data);
        }
    }
    async function fetchGroups(){
        setLoading(true);
        let groupsData = await getGroupsV2(team._id, token);
        setGroups(groupsData);        
        setLoading(false);
    }
    useEffect(() => {
        fetchGroups();
        if(!isAddForm){
            setValue('group_name',group.name.slice(0, limitInput));
            setName(group.name.slice(0, limitInput));
            setContent(group.description.slice(0, limitTextarea));
            setValue('group_desc',group.description.slice(0, limitTextarea));
            if(group.is_child_group){
               setValue('nest_group_id',group.parent_group_id);
            }
        }
    },[]);

    return(
        !loading ?
        <>
            <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
            <div className="form-heading text-center mb-[30px]">{isAddForm ? 'New Group' : 'Edit Group'}</div>
            <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                <div className='form-field-wrap mb-5 user-field-wrap relative'>
                    <input 
                        type="text" 
                        id="group-name" 
                        disabled={loading} 
                        placeholder="Group Name *" 
                        className='text-field'
                        {...register("group_name")}
                        onChange={e => setFormattedName(e.target.value)}
                        aria-invalid={errors.group_name ? "true" : "false"} 
                        value={name}
                    />
                    <p className="text-right text-[12px] leading-[12px]">
                        {name.length}/{limitInput}
                    </p>
                </div>
                {(isAddForm || (!isAddForm && (isChild || !hasChild))) &&
                    <>
                    {allGroups.length > 0 &&
                        <div className='form-field-wrap mb-5 user-field-wrap relative before:z-10'>
                            <select {...register("nest_group_id")} className='text-field' onChange={(e)=>{setValue('nest_group_id',e.target.value)}}>
                                <option value="">Nest Group</option>
                                {allGroups.map((g,index)=>{
                                    if(g.name.toLowerCase()!=='all members'){
                                        return(
                                            <option key={index} value={g._id}>{g.name}</option>
                                        );
                                    }
                                    else{
                                        return null;
                                    }
                                })}
                            </select>
                        </div>
                    }
                    </>
                }
                <div className='form-field-wrap mb-5 relative'>
                    <textarea
                        id="group-desc" 
                        disabled={loading} 
                        className='textarea-field'
                        {...register("group_desc")}
                        onChange={e => setFormattedContent(e.target.value)}
                        value={content}
                    />
                    <p className="text-right text-[12px] leading-[12px]">
                        {content.length}/{limitTextarea}
                    </p>
                </div>
                {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                <div className="w-full px-2.5">
                    <div className='flex justify-center'>
                        <input className='button' type="submit" value={isAddForm ? 'Create' : 'Update'} disabled={loading}/>
                    </div>
                </div>
            </form>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}

export default GroupForm;