import { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as yup from 'yup';
import placeholderImg from '../../../assets/images/placeholder.png';
import Spinner from '../../../components/Spinner/Spinner';
import { getGroupsV2 } from '../../../libs/dataFunctions';
import CheckTreePicker from 'rsuite/CheckTreePicker';

const RequestRow = ({request, team}) =>{  
    
    const [showJoinForm, setShowJoinForm]= useState(false);
    const [loadingDecline, setLoadingDecline] = useState(false);
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    
    const schema = yup.object().shape({
        groups: yup
        .array()
        .min(1, "At least one group must be selected")
        .nullable()
        .required("At least one group must be selected"),        
    });

    const TeamJoinForm = ({setShowForm}) =>{
        const [loading, setLoading] = useState(false);
        const [groups, setGroups] = useState([]);
        const [selectedGroups, setSelectedGroups] = useState([]);
        const [errorMessage,setErrorMessage] = useState(false);
        const contentRef=useRef();
        const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
            resolver: yupResolver(schema)
        }); 

        async function refreshGroups(){
            setLoading(true);
            const [groupsData] = await Promise.all([
                getGroupsV2(team._id, token),
            ]); 
            let groupOptions = [];
            groupsData.forEach(element => {
                let childOptions = [];
                if(element.nest_groups.length > 0){
                    element.nest_groups.forEach(celm =>{
                        childOptions.push({'value':celm._id, 'label':celm.name});
                    });
                    groupOptions.push({'value':element._id, 'label':element.name, 'children':childOptions});
                }
                else{
                    groupOptions.push({'value':element._id, 'label':element.name});
                }
            });       
            setGroups(groupOptions);          
            setLoading(false);      
        }
    
        const onSubmit=async(data)=>{
            setErrorMessage('');
            setLoading(true);
            let formdata = new FormData();
            formdata.append('type', 'accept_request');
            formdata.append('team_id', team._id);
            formdata.append('request_sub', request.sub);
            formdata.append("group_ids", selectedGroups.join('@#'));           
            formdata.append('separate_by','@#');
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    setErrorMessage(rdata.message);
                }
                else{
                    setShowForm(false);
                    window.location.href = '/team/'+team._id;
                }
            })
            .catch(error => console.log('error', error));       
        } 
        useEffect(() => {
            refreshGroups();
        },[]);
        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-full !max-w-[400px]">
                    {!loading ?
                    <>
                        <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                        <div className="form-heading text-center mb-[30px]">Select a group</div>                        
                        <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">                
                        <div className='form-field-wrap mb-5 select-field-wrap groups-field-wrap relative' ref={contentRef}>
                            <Controller
                            control={control}
                            name='groups'
                            render={({ field: { onChange, value, ref }}) => (
                                <>
                                <CheckTreePicker
                                    defaultExpandAll
                                    placeholder="Select Groups*"
                                    data={groups}
                                    onChange={val => {
                                        onChange(val.map(c => c.value));
                                        setValue('groups',val);
                                        setSelectedGroups(val);
                                    }}
                                    container={()=>contentRef.current}
                                    defaultValue={selectedGroups}
                                    menuStyle={{zIndex: 100000000}}
                                    value={selectedGroups}
                                    style={{
                                        width:'100%',
                                        maxWidth:'500px',
                                        borderWidth:0,
                                        backgroundColor: '#EFF1F9',
                                        padding: '8px 7px 8px 46px',
                                        height: '52px',
                                        borderRadius: 8
                                    }}
                                    onSelect={(item,val,event)=>{
                                            if(item.label.toLowerCase()==="all members"){
                                                if(item.check){
                                                    let groupIds=[];
                                                    groups.forEach((item)=>{
                                                        groupIds.push(item.value);
                                                    });
                                                    setSelectedGroups(groupIds);
                                                }
                                                else{
                                                    setSelectedGroups([]);
                                                }
                                            }
                                        }
                                    }
                                />                                    
                                </>                                    
                            )}
                        />
                        <p className="mt-1 uppercase font-medium" style={{ fontSize: '12px', lineHeight: '18px', color: "#6E7079"}}>
                            {selectedGroups.length===0 ? 
                                        'NO GROUP SELECTED.':
                                        (selectedGroups.length > 1) ? selectedGroups.length + ' GROUPS SELECTED' : selectedGroups.length + ' GROUP SELECTED'}
                        </p>
                        </div>
                        {errors.groups?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.groups?.message}</p> }
                        {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                        <div className="w-full px-2.5">
                            <div className='flex justify-center'>
                                <input className='button button--large' type="submit" value="Add" disabled={loading}/>
                            </div>
                        </div>
                        </form>
                </>
                :
                <>
                    <div className="py-5 text-center"><Spinner /></div>
                </>
                }
                </div>
            </div>
        )
    }

    const declineRequest = async() =>{
        if(window.confirm("Are you sure you want to decline this request?")){
        
            setLoadingDecline(true);
            let formdata = new FormData();
            formdata.append('type', 'decline_request');
            formdata.append('team_id', team._id);
            formdata.append('request_sub', request.sub);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoadingDecline(false);
                let rdata = JSON.parse(result);
                if(rdata.status === 1){
                    window.location.href = '/team/'+team._id;
                }
            })
            .catch(error => console.log('error', error));
        }
    }
    return(
        <tr>
            <td className="pl-[30px] pr-[40px] py-2.5">
                <div className="overflow-hidden rounded-full w-[50px] h-[50px] relative">
                    {request.profilePicture && request.profilePicture!=="" ? 
                    <img src={request.profilePicture} alt="" className="absolute object-fit w-full h-full" />
                    :
                    <img src={placeholderImg} alt="" className="absolute object-fit w-full h-full" />
                    }
                </div>
            </td>
            <td className="p-2.5">
                <span className='text-[#363F6C] font-bold'>{request.firstName} {request.lastName}</span><br/>
                {request.childrens_name.length > 0 &&
                    <>
                    <span className='text-[#363F6C] text-[14px]'>Parent Of {request.childrens_name.join(', ')}</span><br/>
                    </>
                }
                <span className='text-[10px] text-slate-500'>{dayjs(request.created_at).format('DD MMM YYYY || hh:mm A')}</span>
            </td>            
            <td className="pr-[30px] text-right">
                {!loadingDecline ? 
                    <>
                        <button className="button button-medium button-blue mr-2.5" onClick={()=>{setShowJoinForm(true)}}>Accept</button>
                        <button className="button button-medium button-red" onClick={()=>{declineRequest();}}>Decline</button>
                        {showJoinForm &&
                            <TeamJoinForm setShowForm={setShowJoinForm} />
                        }
                    </>
                    :
                    <>
                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
                    </>
                }
            </td>
        </tr>
    );
}
export default RequestRow;