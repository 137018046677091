import { useState, useEffect, useContext, useRef } from 'react';
import { useParams, NavLink } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import DatePicker from "react-datepicker";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import { TeamContextProvider, TeamContext } from "../../../contexts/TeamContext";
import Spinner from '../../../components/Spinner/Spinner';
import { getEventAvailability } from '../../../libs/dataFunctions';
import placeholderImg from '../../../assets/images/placeholder.png';
import "react-datepicker/dist/react-datepicker.css";
dayjs.extend(utc);
dayjs.extend(tz);

const AvailabilityBoard=()=>{
    const { team, teamUpdating } = useContext(TeamContext);
    const [loading,setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [users,setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [searchDate, setSearchDate] = useState(new Date());
    const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [showSubmitEventsPopup, setsubmitEventsPopup] = useState(false);
    const [showFilters,setShowFilters] = useState(false);
    const [showShorters,setShowShorters] = useState(false);
    const [filterType, setFilterType] = useState('All');
    const [sortType, setSortType] = useState('');    
    const ref = useRef();

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const fetchAvaiblityData=async()=>{
        setLoading(true);
        const [availabilityData] = await Promise.all([
            getEventAvailability(token, team._id, dayjs(searchDate).format('YYYY-MM-DD'))
        ]);
        var tmpEvents=[];
        availabilityData.data.events.forEach((item)=>{
            let tmpItem = item;
            tmpItem.selectedUsers = []
            tmpEvents.push(tmpItem);
        });
        setEvents(tmpEvents);
        setUsers(availabilityData.data.users);
        setAllUsers(availabilityData.data.users);
        setLoading(false);        
    }

    const filterBy = (filterKey) => {
        if(filterKey.toLowerCase()==='all'){
            setUsers(allUsers);
        }
        else{
            let tmpAllusr=allUsers.filter((uitem)=>uitem.events.filter((eitem)=>eitem.availability.toLowerCase()===filterKey.toLowerCase()).length>0);
            setUsers(tmpAllusr);
        }
        setSortType('');
        setFilterType(filterKey);
        setShowFilters(false);
    }

    const sortBy = (s)=>{
        let sorted_members;
        if(s.toLowerCase()==='a-z'){
            if(sortType!=='a-z' && sortType!=='z-a'){
                sorted_members = allUsers.sort((a,b) => {
                    return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
                });
            }
            else{
                sorted_members = users.sort((a,b) => {
                    return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
                });
            }                        
            setUsers(sorted_members);
        }
        else if(s.toLowerCase()==='z-a'){
            if(sortType!=='a-z' && sortType!=='z-a'){
                sorted_members = allUsers.sort((a,b) => {
                    return b.name.toUpperCase() > a.name.toUpperCase() ? 1 : -1;
                });
            }
            else{
                sorted_members = users.sort((a,b) => {
                    return b.name.toUpperCase() > a.name.toUpperCase() ? 1 : -1;
                });
            }
        }
        else{
            let tmpAllusr=allUsers.filter((uitem)=>uitem.events.filter((eitem)=>eitem._id===s).length>0);
            setUsers(tmpAllusr);
        }
        setSortType(s);
        setShowShorters(false);
    }

    const handleDateChange=(date)=>{
        setSearchDate(date);
    }

    const openDatePicker=()=>{
        setDatePickerIsOpen(!datePickerIsOpen);
    }

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "rgba(239, 241, 249, 1)" : "",
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 20,
        border: isDraggingOver ? '0px' : '1px dashed rgba(239, 241, 249, 1)',
        content: 'Drag here'
    });

    const SubmitEventsPopup=({setShowForm})=>{
        const submitEventsAction=async(isNotify)=>{
            setErrorMessage(null);
            setSuccessMessage(null);
            var submitData = [];
            events.forEach((element)=>{
                if(element.selectedUsers.length>0){
                    let selectedMembers = element.selectedUsers;
                    selectedMembers.forEach(selement => {
                        let tmpArr = {};
                        tmpArr.event_id = element._id;
                        let reqId = selement.events.filter((titem)=>titem._id===element._id);
                        tmpArr.request_id = reqId[0].req_id;
                        tmpArr.status='approved';
                        submitData.push(tmpArr);
                    });
                }
            });
    
            if(submitData.length>0){
                var formdata = new FormData();
                formdata.append("type", "set_event_availability_from_date");
                formdata.append("team_id", team._id);
                formdata.append("date",dayjs(searchDate).format('YYYY-MM-DD'));
                formdata.append("notify_user",isNotify);
                formdata.append("change_requests",JSON.stringify(submitData));
                
                var secureHeader = new Headers();
                secureHeader.append('Authorization','Bearer ' + token);
                secureHeader.append('device_type','W');
    
                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow',
                    headers: secureHeader
                };
                setLoading(true);
                await fetch(process.env.REACT_APP_APIURL + '/app_event', requestOptions)
                .then(response => response.text())
                .then(result => {
                    let rdata = JSON.parse(result);
                    if(rdata.status===1){
                        setSuccessMessage(rdata.message);
                        fetchAvaiblityData();
                    }
                    else{
                        setLoading(false);
                        setErrorMessage(rdata.message);
                    }
                    setShowForm(false);
                });
            }
            else{
                setErrorMessage("Please make selection.");
                setShowForm(false);
            }
        }

        return(
        <div className="popup-form">
            <div className="popup-form-wrap relative w-full !max-w-[600px]">
                <button type="button" className="close-popup" onClick={(e) => {setShowForm(false);}}><span>close</span></button>
                <div className="form-heading text-center mb-[30px]">CONFIRM SELECTIONS</div>
                <div className='text-center'>
                    <p className='mb-5'>On confirming these selections, all selected members will receive a NOTIFICATION advising of their selection and their selection will present within their EVENTS tab.</p>
                    <p className='mb-8'>Alternatively, you can confirm your selections WITHOUT notification via the button below...</p>
                    <div className='flex flex-col gap-5.5 mx-auto max-w-[352px]'>
                        <button type='button' className='bg-[var(--color-yellow)] uppercase text-base py-2 hover:bg-[var(--color-darkblue)] hover:text-white rounded-[70px] font-semibold' onClick={()=>submitEventsAction(true)}>Confirm &amp; Notify</button>
                        <button type='button' className='uppercase border border-[var(--color-darkblue)] text-base py-2 hover:bg-[var(--color-darkblue)] hover:text-white rounded-[70px] font-semibold' onClick={()=>submitEventsAction(false)}>CONFIRM WITHOUT NOTIFICATIONS</button>
                    </div>
                </div>
            </div>
        </div>
        )
    }

    const submitEvent=()=>{
        var submitData = [];
        events.forEach((element)=>{
            if(element.selectedUsers.length>0){
                let selectedMembers = element.selectedUsers;
                selectedMembers.forEach(selement => {
                    let tmpArr = {};
                    tmpArr.event_id = element._id;
                    let reqId = selement.events.filter((titem)=>titem._id===element._id);
                    tmpArr.request_id = reqId[0].req_id;
                    tmpArr.status='approved';
                    submitData.push(tmpArr);
                });
            }
        });
    
        if(submitData.length>0){
            setsubmitEventsPopup(true);
        }
        else{
            setErrorMessage("Please make selection.");
        }
    }

    const onDragEnd = (result)=>{
        const { source, destination, draggableId } = result;
        var isMoved = false;
        if (!destination) {
            return;
        }
        
        const sInd = source.droppableId;
        const dInd = destination.droppableId;
        var selectedUserObj=[];
        if(sInd==='users'){
            selectedUserObj = users.filter((userItem)=>userItem.sub===draggableId);
        }
        else{
            let sourceEvent=events.filter((eitem)=>eitem._id===sInd);
            if(sourceEvent[0].selectedUsers.length <= 0 ){
                return;
            }
            selectedUserObj = sourceEvent[0].selectedUsers.filter((userItem)=>userItem.sub===draggableId);
        }
        if (sInd !== dInd) {
            let updatedEvents=events.map((item)=>{
                if(dInd===item._id){                    
                    if(item.users.filter((usrItem)=>usrItem.sub===draggableId && usrItem.status!=='approved').length>0){
                        let insertUsr=item.selectedUsers;
                        if(insertUsr.length > 0){
                            let foundUsr = insertUsr.filter((usrItem)=>usrItem.sub===draggableId);
                            if(foundUsr.length<=0){
                                insertUsr.push(selectedUserObj[0]);
                                item.selectedUsers=insertUsr;
                                isMoved = true;
                                if(sInd==='users'){
                                    let updatedUserList=users.filter((usr)=>usr.sub!==draggableId);
                                    let updatedAllUsersList = allUsers.filter((usr)=>usr.sub!==draggableId);
                                    setUsers(updatedUserList);
                                    setAllUsers(updatedAllUsersList);
                                }
                            }
                        }
                        else{
                            insertUsr.push(selectedUserObj[0]);
                            item.selectedUsers=insertUsr;
                            isMoved = true;
                            if(sInd==='users'){                                
                                let updatedUserList=users.filter((usr)=>usr.sub!==draggableId);
                                let updatedAllUsersList = allUsers.filter((usr)=>usr.sub!==draggableId);
                                setUsers(updatedUserList);
                                setAllUsers(updatedAllUsersList);
                            }
                        }
                    }
                }
                else if(dInd==='users'){
                    let updatedUserList=users;
                    let updatedAllUserList=allUsers;
                    let insertUsr=item.selectedUsers;
                    let foundUsr = insertUsr.filter((usrItem)=>usrItem.sub===draggableId);
                    if(foundUsr.length>0){
                        isMoved = true;
                        updatedUserList.push(foundUsr[0]);
                        updatedAllUserList.push(foundUsr[0]);
                        setUsers(updatedUserList);
                        setAllUsers(updatedAllUserList);
                    }
                }
                return item;
            });

            updatedEvents=updatedEvents.map((item)=>{                
                if(sInd!=='users' && item._id===sInd){
                    let insertUsr=item.selectedUsers;
                    if(insertUsr.length > 0){
                        let foundUsr = insertUsr.filter((usrItem)=>usrItem.sub!==draggableId);
                        if(foundUsr.length>0 && isMoved){
                            item.selectedUsers=foundUsr;
                        }
                        else if(isMoved){
                            item.selectedUsers=[];
                        }
                    }
                }
                return item;
            });
            if(!isMoved){
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                        <div className='confirm-ui'>
                            <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                            <p className="text-center text-[14px] leading-[18px] mb-5">You can't select this user for this event because he hasn't submitted his availability.</p>
                            <div className="flex justify-center">
                                <button className="button button-blue mx-1" onClick={onClose}>Ok</button>
                            </div>
                        </div>
                        );
                    }
                });
            }
            setEvents(updatedEvents);
        }
    }
    
    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (showFilters && ref.current && !ref.current.contains(e.target)) {
                setShowFilters(false);
            }
            if (showShorters && ref.current && !ref.current.contains(e.target)) {
                setShowShorters(false);
            }
        }      
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[showFilters, showShorters]);

    useEffect(()=>{
        setLoading(true);
        if(!teamUpdating){
            fetchAvaiblityData()
        }
    },[teamUpdating, searchDate]);

    return(
        <>
            {!loading ?
                <>
                    <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                        <NavLink to="/" className='home'>
                            <span>Dashboard</span>
                        </NavLink>
                        <span className='separator'>&lt;</span>
                        <NavLink to={`/team/${team._id}/events`}>
                            Events
                        </NavLink>
                        <span className='separator'>&lt;</span>
                        Team Selection Board
                    </div>
                    <div className='body px-5 lg:px-8 py-10'>
                        <div className='rounded-xl bg-white pb-2.5'>
                            <div className="tab-actionbar px-2.5 sm:px-7.5 py-4.5 flex flex-col md:flex-row justify-between items-center">
                                <div className="title-col max-w-[320px] md:max-w-full mx-auto md:mx-0 mb-2.5 md:mb-0">Availability</div>
                                <div className="flex items-center gap-2.5 max-w-[150px]">
                                    <button onClick={openDatePicker} className='button-icon button-icon-calendar'></button>
                                    <div className='flex-1'>
                                        <DatePicker
                                            selected={searchDate}
                                            onChange={handleDateChange}
                                            onClickOutside={openDatePicker}
                                            open={datePickerIsOpen}
                                            className='!p-0 !bg-white !font-semibold text-[var(--color-darkblue)] !text-base'
                                            dateFormat="dd MMM yyyy"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='px-5 lg:px-8'>
                                {events.length>0 ?
                                    <>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <div className='flex gap-5 items-start'>
                                                <Droppable droppableId={`users`}>
                                                {(providedA, snapshot) => (
                                                    <div className='py-7.5 w-[270px]'>
                                                        <div className='rounded-[20px] p-5 bg-[rgba(239,241,249,1)] w-full'>
                                                            <div className='flex justify-between mb-5 relative' ref={ref}>
                                                                <div className='text-xl font-extrabold uppercase text-[var(--color-darkblue)]'>Responses</div>
                                                                <div className='flex gap-2.5 justify-end max-w-[68px]'>
                                                                    <button type="button" className={`button-icon button-icon-filter`} onClick={()=>{setShowShorters(false); setShowFilters(!showFilters)}}><span>Filter</span></button>
                                                                    <button type="button" className={`button-icon button-icon-sort`} onClick={()=>{setShowFilters(false); setShowShorters(!showShorters)}}><span>Short</span></button>
                                                                </div>
                                                                <ul className={`sort-actions absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showFilters ? 'hidden' : ''}`}>
                                                                    <li className={filterType==='All' ? 'active' : ''}><button type='button' className='uppercase w-full text-left' onClick={()=>{filterBy('All');}}>All</button></li>
                                                                    <li className={filterType==='Available' ? 'active' : ''}><button type='button' className='uppercase w-full text-left' onClick={()=>{filterBy('Available');}}>Available</button></li>
                                                                    <li className={filterType==='Not Available' ? 'active' : ''}><button type='button' className='uppercase w-full text-left' onClick={()=>{filterBy('Not Available');}}>Not Available</button></li>
                                                                    <li className={filterType==='Needs More Time' ? 'active' : ''}><button type='button' className='uppercase w-full text-left' onClick={()=>{filterBy('Needs More Time');}}>Needs More Time</button></li>
                                                                </ul>
                                                                <ul className={`sort-actions absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showShorters ? 'hidden' : ''}`}>
                                                                    <li className={sortType==='A-Z' ? 'active' : ''}><button type='button' className='uppercase w-full text-left' onClick={()=>{sortBy('A-Z');}}>Players A-Z</button></li>
                                                                    <li className={sortType==='Z-A' ? 'active' : ''}><button type='button' className='uppercase w-full text-left' onClick={()=>{sortBy('Z-A');}}>Players Z-A</button></li>
                                                                    {events.length>0 &&
                                                                        events.map((seitem)=>{
                                                                            return(
                                                                                <li className={sortType===seitem._id ? 'active' : ''}><button type='button' className='uppercase w-full text-left' onClick={()=>{sortBy(seitem._id);}}>{seitem.name}</button></li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                            <div
                                                                ref={providedA.innerRef}
                                                                //style={getListStyle(snapshot.isDraggingOver)}
                                                                {...providedA.droppableProps}
                                                            >
                                                                {users.length>0 && users.map((usr,index)=>(
                                                                    <Draggable
                                                                        key={usr.sub}
                                                                        draggableId={usr.sub}
                                                                        index={index}
                                                                        >
                                                                    {(providedB, snapshot) => (
                                                                        <div
                                                                        ref={providedB.innerRef}
                                                                        {...providedB.draggableProps}
                                                                        {...providedB.dragHandleProps}
                                                                        >
                                                                        <div className='flex gap-1.5 rounded-[20px] px-2.5 py-2 bg-white mb-5'>
                                                                                <div className='w-8.5 h-8.5'>
                                                                                    {usr.profilePicture !== '' ?  
                                                                                    <img src={usr.profilePicture} alt="" width={34} height={34} className="rounded-full w-full" />
                                                                                    :
                                                                                    <img src={placeholderImg} alt="" width={34} height={34} className="rounded-full w-full" />
                                                                                    }
                                                                                </div>
                                                                                <div className='flex-1'>
                                                                                    <div className='font-medium text-sm text-[var(--color-dakrblue)] mb-1.5'>{usr.name}</div>
                                                                                    <div className='flex gap-1'>
                                                                                        {usr.events.length > 0 && usr.events.map((uevent)=>{
                                                                                            return(
                                                                                                <span key={uevent._id} className='w-3 h-3 rounded-full' style={{backgroundColor:uevent.color}}></span>
                                                                                            )
                                                                                        })}
                                                                                        <span></span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}        
                                                                    </Draggable>
                                                                ))}
                                                                {providedA.placeholder}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}                                                
                                                </Droppable>
                                                <div className="overflow-x-auto flex gap-5 px-7.5 py-7.5 flex-1">
                                                    {events.map((eitem)=>(
                                                        <div className='shadow-[0_12px_40px_0_rgba(0,4,30,0.1)] rounded-[20px] p-5 w-[270px] shrink-0' key={eitem._id}>
                                                            <div className='flex gap-2.5 justify-between'>
                                                                <div className='font-medium text-lg mb-5 flex-1'>{eitem.name}</div>
                                                                <span className='w-4.5 h-4.5 rounded-full block' style={{backgroundColor:eitem.color}}></span>
                                                            </div>
                                                            <div className='flex gap-2.5 text-xs items-center mb-2.5'>
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8 2C4.68661 2 2 4.68661 2 8C2 11.3134 4.68661 14 8 14C11.3134 14 14 11.3134 14 8C14 4.68661 11.3134 2 8 2ZM10.3638 9.8442L9.9808 10.3665C9.97247 10.3779 9.96198 10.3875 9.94992 10.3948C9.93786 10.4021 9.92447 10.407 9.91054 10.4091C9.8966 10.4112 9.88238 10.4105 9.86869 10.4071C9.85501 10.4037 9.84213 10.3977 9.8308 10.3893L7.61562 8.77411C7.60182 8.7642 7.59061 8.75112 7.58292 8.73597C7.57522 8.72082 7.57129 8.70404 7.57143 8.68705V5C7.57143 4.94107 7.61964 4.89286 7.67857 4.89286H8.32277C8.3817 4.89286 8.42991 4.94107 8.42991 5V8.31473L10.3397 9.69554C10.3879 9.72902 10.3987 9.79598 10.3638 9.8442Z" fill="#363F6C"/>
                                                                </svg>
                                                                <div>Start Time:<strong className='font-bold'>{dayjs('1/1/1 '+eitem.timeStart_display).format('hh:mm A')}</strong></div>
                                                            </div>
                                                            <div className='flex gap-2.5 text-xs items-center mb-7.5'>
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8 1C9.5913 1 11.1174 1.63214 12.2426 2.75736C13.3679 3.88258 14 5.4087 14 7C14 9.484 12.1067 12.0867 8.4 14.8667C8.2846 14.9532 8.14425 15 8 15C7.85575 15 7.7154 14.9532 7.6 14.8667C3.89333 12.0867 2 9.484 2 7C2 5.4087 2.63214 3.88258 3.75736 2.75736C4.88258 1.63214 6.4087 1 8 1ZM8 5C7.46957 5 6.96086 5.21071 6.58579 5.58579C6.21071 5.96086 6 6.46957 6 7C6 7.53043 6.21071 8.03914 6.58579 8.41422C6.96086 8.78929 7.46957 9 8 9C8.53043 9 9.03914 8.78929 9.41421 8.41422C9.78929 8.03914 10 7.53043 10 7C10 6.46957 9.78929 5.96086 9.41421 5.58579C9.03914 5.21071 8.53043 5 8 5Z" fill="#363F6C"/>
                                                                </svg>
                                                                <div>{eitem.location}</div>
                                                            </div>
                                                            <Droppable droppableId={eitem._id}>
                                                                {(providedC, snapshot) => (
                                                                    <div
                                                                    ref={providedC.innerRef}
                                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                                    {...providedC.droppableProps}
                                                                    >
                                                                        {eitem.selectedUsers.length > 0 && eitem.selectedUsers.map((usr,index)=>(
                                                                            <Draggable
                                                                                key={usr.sub}
                                                                                draggableId={usr.sub}
                                                                                index={index}
                                                                                >
                                                                            {(providedB, snapshot) => (
                                                                                <div
                                                                                ref={providedB.innerRef}
                                                                                {...providedB.draggableProps}
                                                                                {...providedB.dragHandleProps}
                                                                                >
                                                                                    <div className='shadow-[0_12px_40px_0_rgba(0,4,30,0.1)] flex gap-1.5 rounded-[20px] px-2.5 py-2 bg-white mb-2.5'>
                                                                                        <div className='w-8.5 h-8.5'>
                                                                                            {usr.profilePicture !== '' ?  
                                                                                            <img src={usr.profilePicture} alt="" width={34} height={34} className="rounded-full w-full" />
                                                                                            :
                                                                                            <img src={placeholderImg} alt="" width={34} height={34} className="rounded-full w-full" />
                                                                                            }
                                                                                        </div>
                                                                                        <div className='flex-1'>
                                                                                            <div className='font-medium text-sm text-[var(--color-dakrblue)]'>{usr.name}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}        
                                                                            </Draggable>
                                                                        ))}
                                                                        {providedC.placeholder}
                                                                    </div>
                                                                )}                                                                
                                                            </Droppable>
                                                            {eitem.users.filter((eusr)=>eusr.status==='approved').length>0 &&
                                                                <div className='mt-2.5'>
                                                                    <div className='uppercase font-medium text-xs text-[var(--dark-blue)] mb-2.5'>Approved Members</div>
                                                                    <div>
                                                                        {eitem.users.filter((eusr)=>eusr.status==='approved').map((approvedMem)=>{
                                                                            return(
                                                                                <div className='shadow-[0_12px_40px_0_rgba(0,4,30,0.1)] flex items-center gap-1.5 rounded-[20px] px-2.5 py-2 bg-white mb-2.5' key={approvedMem.sub}>
                                                                                    <div className='w-8.5 h-8.5'>
                                                                                        {approvedMem.profilePicture !== '' ?  
                                                                                        <img src={approvedMem.profilePicture} alt="" width={34} height={34} className="rounded-full w-full" />
                                                                                        :
                                                                                        <img src={placeholderImg} alt="" width={34} height={34} className="rounded-full w-full" />
                                                                                        }
                                                                                    </div>
                                                                                    <div className='flex-1 font-medium text-sm text-[var(--color-dakrblue)]'>{approvedMem.name}</div>                                                                                    
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </DragDropContext>
                                        <div className='flex pt-10 justify-center'>
                                            <button className='button button--large' type="button" disabled={loading} onClick={()=>{submitEvent()}}>Confirm Selections</button>
                                        </div>
                                        {errorMessage!==null &&
                                            <p className='text-red-600 error my-5 px-4 text-center'>{errorMessage}</p>
                                        }
                                        {successMessage!==null &&
                                            <p className='text-green-700 error my-5 px-4 text-center'>{successMessage}</p>
                                        }
                                        {showSubmitEventsPopup &&
                                            <SubmitEventsPopup setShowForm={setsubmitEventsPopup} />
                                        }
                                    </>
                                    :
                                    <>
                                        <div className='text-center'>Data not found.</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="py-5 text-center"><Spinner /></div>
                </>
            }
        </>
    )
}

const Availability=()=>{
    const { id } = useParams();
    return(
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="">
                    <AdminNavbar heading="Team Selection Board" />  
                    <AvailabilityBoard />              
                </div>
            </TeamContextProvider>
        </>
    )
}

export default Availability;