import { useState, useEffect, useRef } from "react";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import GalleryForm from "./GalleryForm";
import Spinner from "../../../components/Spinner/Spinner";

const FolderCard=({galfolder,setGalleryMedia, teamId, fetchGalleryFolders, token, cssClass})=>{
    const [showEditFolder, setShowEditFolder] = useState(false);
    const [showMoreOpt, setShowMoreOpt] = useState(false);
    const [loading, setLoading] = useState(false);
    const ref = useRef();

    const deleteFolderAction = async() =>{
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "delete_folder");
        formdata.append("media_folder_id",galfolder._id);
        formdata.append("team_id", teamId);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_gallery' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            fetchGalleryFolders();
        })
        .catch(error => console.log('error', error));
    }

    const deleteFolder=()=>{
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                <div className='confirm-ui'>
                    <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                    <p className="text-center text-[14px] leading-[18px] mb-5">Are you sure you want to delete this folder?</p>
                    <div className="flex justify-center">
                        <button className="button button-blue mx-1" onClick={onClose}>No</button>
                        <button className="button mx-1"
                        onClick={() => { 
                            deleteFolderAction();                       
                            onClose();
                        }}
                        >
                        Yes
                        </button>
                    </div>
                </div>
                );
            }
        });
    }
    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if ((showMoreOpt) && ref.current && !ref.current.contains(e.target)) {
                setShowMoreOpt(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [showMoreOpt]);
    return(
        <>
        {!loading ?
        <>
        <div className={`gallery-grid-item rounded-[28px] ${cssClass}`}>
            <img src={galfolder.image} alt={galfolder.name} className="rounded-[28px]" onClick={()=>{setGalleryMedia(galfolder._id)}} />
            <div className="absolute w-full h-full bg-[rgba(54,63,108,0.33)] top-0 left-0 rounded-[28px]"></div>
            {galfolder.name!=='All Images' ?
                <div ref={ref} className="absolute right-2 top-2">
                    <button type="button" className="w-[28px] h-[26px]" onClick={()=>setShowMoreOpt(true)}>
                        <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.7921 21.2808C13.2248 21.2808 12.7393 21.0957 12.3357 20.7255C11.9314 20.3547 11.7292 19.9092 11.7292 19.3889C11.7292 18.8686 11.9314 18.4231 12.3357 18.0523C12.7393 17.6821 13.2248 17.497 13.7921 17.497C14.3593 17.497 14.8451 17.6821 15.2495 18.0523C15.6531 18.4231 15.8549 18.8686 15.8549 19.3889C15.8549 19.9092 15.6531 20.3547 15.2495 20.7255C14.8451 21.0957 14.3593 21.2808 13.7921 21.2808ZM13.7921 14.6592C13.2248 14.6592 12.7393 14.4738 12.3357 14.103C11.9314 13.7328 11.7292 13.2876 11.7292 12.7673C11.7292 12.2471 11.9314 11.8015 12.3357 11.4307C12.7393 11.0606 13.2248 10.8755 13.7921 10.8755C14.3593 10.8755 14.8451 11.0606 15.2495 11.4307C15.6531 11.8015 15.8549 12.2471 15.8549 12.7673C15.8549 13.2876 15.6531 13.7328 15.2495 14.103C14.8451 14.4738 14.3593 14.6592 13.7921 14.6592ZM13.7921 8.03766C13.2248 8.03766 12.7393 7.85225 12.3357 7.48144C11.9314 7.11127 11.7292 6.66605 11.7292 6.14578C11.7292 5.62552 11.9314 5.18029 12.3357 4.81012C12.7393 4.43931 13.2248 4.25391 13.7921 4.25391C14.3593 4.25391 14.8451 4.43931 15.2495 4.81012C15.6531 5.18029 15.8549 5.62552 15.8549 6.14578C15.8549 6.66605 15.6531 7.11127 15.2495 7.48144C14.8451 7.85225 14.3593 8.03766 13.7921 8.03766Z" fill="white"/>
                        </svg>
                    </button>
                    <ul className={`w-[100px] text-[14px] leading-[18px] text-black absolute overflow-hidden rounded-lg bg-white right-0 top-[40px] text-left ${!showMoreOpt ? 'hidden' : ''}`}>                                            
                        <li><div className='cursor-pointer py-1.5 px-1.5' onClick={(e) => { setShowMoreOpt(false); setShowEditFolder(true);}}>Edit</div></li>
                        <li><div className='cursor-pointer py-1.5 px-1.5' onClick={(e) => { setShowMoreOpt(false); deleteFolder();}}>Delete</div></li>
                    </ul>
                </div>
                :
                <div ref={ref} className="absolute right-2 top-2">
                    <button type="button" className="w-[28px] h-[26px]" onClick={()=>setShowMoreOpt(true)}>
                        <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.7921 21.2808C13.2248 21.2808 12.7393 21.0957 12.3357 20.7255C11.9314 20.3547 11.7292 19.9092 11.7292 19.3889C11.7292 18.8686 11.9314 18.4231 12.3357 18.0523C12.7393 17.6821 13.2248 17.497 13.7921 17.497C14.3593 17.497 14.8451 17.6821 15.2495 18.0523C15.6531 18.4231 15.8549 18.8686 15.8549 19.3889C15.8549 19.9092 15.6531 20.3547 15.2495 20.7255C14.8451 21.0957 14.3593 21.2808 13.7921 21.2808ZM13.7921 14.6592C13.2248 14.6592 12.7393 14.4738 12.3357 14.103C11.9314 13.7328 11.7292 13.2876 11.7292 12.7673C11.7292 12.2471 11.9314 11.8015 12.3357 11.4307C12.7393 11.0606 13.2248 10.8755 13.7921 10.8755C14.3593 10.8755 14.8451 11.0606 15.2495 11.4307C15.6531 11.8015 15.8549 12.2471 15.8549 12.7673C15.8549 13.2876 15.6531 13.7328 15.2495 14.103C14.8451 14.4738 14.3593 14.6592 13.7921 14.6592ZM13.7921 8.03766C13.2248 8.03766 12.7393 7.85225 12.3357 7.48144C11.9314 7.11127 11.7292 6.66605 11.7292 6.14578C11.7292 5.62552 11.9314 5.18029 12.3357 4.81012C12.7393 4.43931 13.2248 4.25391 13.7921 4.25391C14.3593 4.25391 14.8451 4.43931 15.2495 4.81012C15.6531 5.18029 15.8549 5.62552 15.8549 6.14578C15.8549 6.66605 15.6531 7.11127 15.2495 7.48144C14.8451 7.85225 14.3593 8.03766 13.7921 8.03766Z" fill="white"/>
                        </svg>
                    </button>
                    <ul className={`w-[100px] text-[14px] leading-[18px] text-black absolute overflow-hidden rounded-lg bg-white right-0 top-[40px] text-left ${!showMoreOpt ? 'hidden' : ''}`}>                                            
                        <li><div className='cursor-pointer py-1.5 px-1.5' onClick={(e) => { setShowMoreOpt(false); setShowEditFolder(true);}}>Edit</div></li>                        
                    </ul>
                </div>
            }
            <div className="absolute bottom-0 left-0 px-4 pb-5" onClick={()=>{setGalleryMedia(galfolder._id)}}>
                <div className="flex items-center justify-end">
                {galfolder.files.length > 0 &&
                  <div className="bg-[#FBDB09] p-1.5 rounded-full">
                        {galfolder.files.map((imgfile,index)=>{
                            if(index > 2){return ''}
                            let eclass='';
                            if(index!==0){
                                eclass = '-ml-2.5';
                            }                        
                            return <img src={imgfile.image} key={imgfile._id} alt="" className={`inline-block relative object-cover object-center rounded-full w-[20px] h-[20px] ${eclass}`} />
                        })}
                        <span className='text-[#363F6C] text-[12px] ml-1.5 font-semibold'>{galfolder.files.length}+</span>                        
                  </div>
                }
                </div>
                <div className="text-white text-[16px] font-bold">{galfolder.name}</div>
            </div>
        </div>
        {showEditFolder &&
            <GalleryForm setShowForm={setShowEditFolder} teamId={teamId} isNew={false} fetchGalleryFolders={fetchGalleryFolders} isInsideFolder={true} galfolderData={galfolder} />
        }
        </>
        :
        <div className="text-center"><Spinner /></div>
        }
        </>
    )
}

export default FolderCard;