import { useState } from "react";
import DocumentView from "../../../components/DocumentView/DocumentView";
import EditDocumentsForm from "./EditDocumentForm";

const MyDocumentsRow = ({doc,refreshMyDocs})=>{
    const [showEditForm, setShowEditDocForm] = useState(false);
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    
    let ext = doc.document.split(".").pop();

    const EditDocument = ({setShowEditForm}) => {
        return(
            <div className="popup-form">
                <div className="popup-form-wrap popup-form-product w-full max-w-[800px] relative">                    
                    <EditDocumentsForm doc={doc} setShowForm={setShowEditDocForm} refreshMyDocs={refreshMyDocs} />
                </div>
            </div>
        )
    }

    async function deletDocument(doc){
        if(window.confirm("Are you sure you want to delete this document?")){
            var formdata = new FormData();
            formdata.append("type", "delete_document");
            formdata.append('document_id',doc._id);

            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_documents' , requestOptions)
            .then(response => response.text())
            .then(result => {
                refreshMyDocs();
            })
            .catch(error => console.log('error', error));
        }
    }
    
    return(
        <>
            <div className='mb-5 p-[12px] relative w-full h-full bg-[#EFF1F9] rounded-[16px]'>
                <div className="flex flex-wrap">
                    <div className="w-[80px] h-[100px]">
                        {ext==='pdf' ?
                            <DocumentView doc={doc.document} width={'80px'} height={'100px'} />
                        :
                            <img src={doc.document} alt="" width={'80px'} height={'100px'} />
                        }
                    </div>
                    <div className="pl-5 flex-1">
                        <h4 className="text-[18px] leading-[20px] text-[#363F6C] font-semibold mb-[15px]">{doc.name}</h4>
                        <div className="flex">
                            <button type="button" className='button-tiny blue delete rounded-full mr-5' onClick={()=>{deletDocument(doc)}}></button>
                            <button type="button" className='button-tiny blue edit rounded-full' onClick={()=>{setShowEditDocForm(true)}}></button>
                        </div>
                    </div>    
                </div>
                <div className="pt-5">
                    {doc.teams && doc.teams.length>0 &&
                        <>
                        <div className="pb-[8px] mb-4 flex border-b border-[#FBDB03]">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="9.74731" cy="9.56909" r="9" fill="#363F6C" fill-opacity="0.15"/>
                                <path d="M9.74726 9.58394C10.8915 9.58394 11.7547 8.72074 11.7547 7.57649C11.7547 6.43224 10.8915 5.56903 9.74726 5.56903C8.60301 5.56903 7.73981 6.43224 7.73981 7.57649C7.73981 8.72074 8.60301 9.58394 9.74726 9.58394ZM10.3208 10.1277H9.1737C7.5924 10.1277 6.30591 11.4142 6.30591 12.9955V13.569H13.1886V12.9955C13.1886 11.4142 11.9021 10.1277 10.3208 10.1277Z" fill="#363F6C"/>
                            </svg>
                            <div className="text-[14px] leading-[21px] font-semibold text-[#363F6C] flex-1 pl-2.5">
                                Users
                            </div>
                        </div>
                        <div className='flex flex-wrap items-center'>
                            {doc.users && doc.users.map((user,index)=>{
                                return(
                                    <div className="mr-3 mb-3 text-[12px] leading-[12px] py-2 px-3.5 rounded-[14px] bg-[#363F6C0d]" key={index}>{user.name}</div>
                                )})
                            }
                        </div>
                        </>
                    }
                    {doc.teams && doc.teams.length>0 &&
                        <>
                        <div className="mt-10 pb-[8px] mb-4 flex border-b border-[#FBDB03]">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="9.46289" cy="9.2688" r="9" fill="#363F6C" fill-opacity="0.15"/>
                                <path d="M8.21754 9.2688C8.21754 9.58771 8.34422 9.89356 8.56973 10.1191C8.79523 10.3446 9.10108 10.4713 9.41999 10.4713C9.7389 10.4713 10.0447 10.3446 10.2703 10.1191C10.4958 9.89356 10.6224 9.58771 10.6224 9.2688C10.6224 8.94989 10.4958 8.64404 10.2703 8.41854C10.0447 8.19303 9.7389 8.06634 9.41999 8.06634C9.10108 8.06634 8.79523 8.19303 8.56973 8.41854C8.34422 8.64404 8.21754 8.94989 8.21754 9.2688ZM14.0816 8.99181C13.0639 6.84779 11.5254 5.7688 9.46293 5.7688C7.39944 5.7688 5.86201 6.84779 4.84422 8.99288C4.8034 9.07932 4.78223 9.17374 4.78223 9.26934C4.78223 9.36493 4.8034 9.45935 4.84422 9.54579C5.86201 11.6898 7.40051 12.7688 9.46293 12.7688C11.5264 12.7688 13.0639 11.6898 14.0816 9.54472C14.1643 9.37079 14.1643 9.16895 14.0816 8.99181ZM9.41999 11.1584C8.37643 11.1584 7.53042 10.3124 7.53042 9.2688C7.53042 8.22524 8.37643 7.37923 9.41999 7.37923C10.4635 7.37923 11.3096 8.22524 11.3096 9.2688C11.3096 10.3124 10.4635 11.1584 9.41999 11.1584Z" fill="#363F6C"/>
                            </svg>
                            <div className="text-[14px] leading-[21px] font-semibold text-[#363F6C] flex-1 pl-2.5">
                                Visible to
                            </div>
                        </div>
                        <div className='flex flex-wrap items-center'>
                            {doc.teams && doc.teams.map((team,index)=>{
                                return(
                                    <div className="mr-3 mb-3 text-[12px] leading-[12px] py-2 px-3.5 rounded-[14px] bg-[#363F6C0d]" key={index}>{team.name}</div>
                                )})
                            }
                        </div>
                        </>
                    }
                </div>                    
                {showEditForm &&
                    <EditDocument setShowEditForm={setShowEditDocForm} />
                }
            </div>
        </>
    );
}
export default MyDocumentsRow;