import { useState } from 'react';
import dayjs from 'dayjs';
import placeholderImg from '../../../assets/images/placeholder.png';

const CoachItem = ({coach}) =>{
    
    const [isViewCoachDetail, setViewCoachDetail] = useState(false);

    const ViewCoachDetail=({setShowForm})=>{
        return(
            <>
            <div className="popup-form">
                <div className="popup-form-wrap popup-form-memberdetails relative !px-2.5 sm:!px-8">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                    <div className="form-heading text-center mb-[30px]">Coach Info</div>
                    <div className='flex flex-col md:flex-row items-center w-full pb-5 border-b border-[#53545C]/30'>
                        <div className='md:pr-5'>
                            {coach.profilePicture !== '' ?  
                            <img src={coach.profilePicture} alt="" width={100} height={100} className="rounded-full cursor-pointer"/>
                            :
                            <img src={placeholderImg} alt="" width={100} height={100} className="rounded-full cursor-pointer"/>
                            }
                        </div>
                        <div>
                            <div className='font-semibold text-[#363F6C]'>{coach.name}</div>
                            <div className='text-[10px] text-slate-500'>{coach.coach_role}</div>
                            <div className='break-words text-[#363F6C]'>{coach.email}</div>
                        </div>
                    </div>
                    <div className='flex flex-col items-center sm:flex-row sm:justify-between py-2.5 text-[#363F6C]'>
                        <div className='flex items-center mb-2.5 sm:mb-0 text-sm font-medium'><div className='button-tiny phone rounded-full mr-1.5'></div>{coach.phoneCode+' '+coach.phoneNumber}</div>
                        <div className='flex items-center mb-2.5 sm:mb-0 text-sm font-medium'><div className='button-tiny date rounded-full mr-1.5'></div>
                            {(coach.dateOfBirth!=='' && coach.dateOfBirth!==null) ? dayjs(coach.dateOfBirth).format('DD / MMM / YYYY'): (coach.is_above_18 ? 'Over 18 years' : 'Under 18 years')}
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }

    return(
        <>
            <li>
                <div className='coach flex items-center p-2.5 mr-5 mb-5 cursor-pointer' onClick={()=>{setViewCoachDetail(true);}}>
                    {coach.profilePicture && coach.profilePicture !== "" ?
                        <img className='rounded-full mr-2.5' src={coach.profilePicture} width="40px" height="40px" alt={coach.name} />
                        :
                        <img className='rounded-full mr-2.5' src={placeholderImg} width="40px" height="40px" alt={coach.name} />
                    }
                    <div>
                        {coach.name}<br />
                        <span className='text-[10px] text-slate-500'>{coach.coach_role}</span>
                    </div>
                </div>
                {isViewCoachDetail &&
                    <ViewCoachDetail setShowForm={setViewCoachDetail} />
                }
            </li>
        </>
    );
}

export default CoachItem;