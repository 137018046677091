import { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import copyrighticon from '../../../assets/images/copy-right-icon.svg';
import Spinner from "../../../components/Spinner/Spinner";
import { getMyOrders } from "../../../libs/dataFunctions";

const Myorder=()=>{   
    const [myorderLoading, setMyorderLoading] = useState(false);
    const [totalIncome, setTotalIncome] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [myOrdersList, setMyOrdersList] = useState([]);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    async function refreshMyorders(){
        setMyorderLoading(true);
        let myOrdersData = await getMyOrders(token);
        setMyOrdersList(myOrdersData.data);
        setTotalOrders(myOrdersData.data.length);
        
        setMyorderLoading(false);
    }
    useEffect(()=>{
        refreshMyorders();
    },[]);
    return(
        !myorderLoading ?
        <>
            <div className="my_order_section">
                <div className="my_order_top">
                    <div className="ln-header flex justify-between items-center w-full pb-5 relative">
                        <h2 className="title mr-5">My Orders</h2>
                        <div className="sep mr-5 sm:mr-11"></div> 
                    </div>
                </div>
                <div className="my_order_bottom">
                    {/*
                    <div className='ordersums flex flex-col md:flex-row mb-10'>
                        <div className='ordercube mb-10 md:mb-0 rounded-xl py-[9px] px-[60px] mr-5 team-header text-center'>
                            <h4>{totalIncome}</h4>
                            <p>TOTAL INCOME</p>
                        </div>
                        <div className='ordercube rounded-xl py-[9px] px-[60px]  body text-center'>
                            <h4>{totalOrders}</h4>
                            <p>TOTAL ORDERS</p>
                        </div>
                    </div>
                    */}
                    {myOrdersList.length ?
                        myOrdersList.map((myorder,index)=>{
                            let statusClass;
                            let textColor;
                            if(myorder.status.toLowerCase() === 'succeeded' || myorder.status.toLowerCase() === 'cash'){
                                statusClass='sucess';
                                textColor = 'text-[#257d3e]';
                            }
                            else if(myorder.status.toLowerCase() === 'requested'){
                                statusClass='info';
                                textColor = 'text-[#4a60a5]';
                            }
                            else if(myorder.status.toLowerCase() === 'incomplete'){
                                statusClass='red';
                                textColor = 'text-[#f01515]';
                            }
                            else if(myorder.status.toLowerCase() === 'cancelled'){
                                statusClass='red-dark';
                                textColor = 'text-[#f01515]';
                            }
                            else if(myorder.status.toLowerCase() === 'overdue'){
                                statusClass='red-dark';
                                textColor = 'text-[#f01515]';
                            }
                            else if(myorder.status.toLowerCase() === 'failed'){
                                statusClass='red-dark';
                                textColor = 'text-[#f01515]';
                            }
                            else if(myorder.status.toLowerCase() === 'schedule'){
                                statusClass='orange';
                                textColor = 'text-[#f2a93b]';
                            }
                            else{
                                statusClass='orange';
                                textColor = 'text-[#f2a93b]';
                            }
                            return(
                                <div className="my_order_list_box bg-white mb-5 py-3.5 px-5" key={index}>
                                    <div className="my_order_list_top flex flex-col md:flex-row w-full justify-between items-center">
                                        <div className="left_list_top w-1/3">
                                            <h4 className="text-center md:text-left">{myorder.product_name}</h4>
                                            <h3 className="text-center md:text-left">{myorder.amount}</h3>
                                            {myorder.installment_number!=="" &&
                                                <div className={`text-[12px] font-medium ${textColor}`}>{myorder.installment_number}</div>
                                            }
                                        </div>
                                        <div className="order_detail_box mb-5 md:mb-0 md:w-1/3">
                                            <p>Date & Time</p>
                                            <h4>{dayjs(myorder.date).format("ddd MMM DD YYYY hh:mm A")}</h4>
                                        </div>
                                        <div className="right_list_top">
                                            <span className={`status-lable ${statusClass} py-1 px-5 rounded-full capitalize`}>{myorder.status_title}</span>
                                        </div>
                                    </div>                                    
                                </div>
                            )
                        })
                    :null
                    }
                </div>
            </div>
        </>
       :
       <>
           <div className="py-5 text-center"><Spinner /></div>
       </>
    );
}

export default Myorder;