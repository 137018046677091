import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { logout, useAuthDispatch, useAuthState } from '../../contexts/AuthProvider';
import logo from '../../assets/images/logo-main.svg';
import team from '../../assets/images/team.svg';
import logoShort from '../../assets/images/logo-small.svg';
import dashboard from '../../assets/images/dashboard.svg';
//import help from '../../assets/images/help.svg';
import settings from '../../assets/images/settings.svg';
import superadminicon from '../../assets/images/superadmin.svg';
import IsMobile from '../isMobile';
import './Sidebar.scss';

export default function Sidebar({type}){
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    const [loading,setLoading] = useState(false);
    const [sidebarCollapse, setSidebarCollapse] = useState(type && type==='thin' ? true : false);
    const dispatch = useAuthDispatch(); // read dispatch method from context
    const userDetails = useAuthState();
    const ismobile = IsMobile(window);
    const handleLogoutClick = () =>{
        logout(dispatch);
        window.location.href = '/signin';
    }
    const handleClickMobmenu = (event) => {
        document.getElementById('sidebar-menu').classList.toggle('expand');
        const menuBtn = document.getElementsByClassName('mobile-nav');
        for (let i = 0; i < menuBtn.length; i++) {
            menuBtn[i].classList.toggle('expand');
        }
    };
    const handleCollapseMenu = (event)=>{
        document.getElementById('page').classList.toggle('thin');
        document.getElementById('menu-collapse-switch').classList.toggle('shrink');
        setSidebarCollapse(!sidebarCollapse);
    }
    const LogoutConfirm = ({setShowForm}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-[280px]">
                    <div className='flex flex-col align-center justify-center'>
                        <img src={logoShort} alt="Plaisport" width={100} className="mb-5 mx-auto" />
                        <div className='mb-10 text-center'>Are you sure you want to logout of PLAI?</div>
                        {!loading ?
                            <div className='flex justify-center'>
                                <button type="button" className='button button-large mr-2.5' onClick={()=>handleLogoutClick()}>Yes</button>
                                <button type="button" className='button button-large button-blue' onClick={()=>setShowForm(false)}>No</button>
                            </div>
                        :
                            <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
                        }
                    </div>
                </div>
            </div>
        )
    }
    useEffect(()=>{
        if(sidebarCollapse){
            document.getElementById('page').classList.toggle('thin');
        }
    },[]);
    return(
        <>
        <div id='sidebar-menu' className={`site-sidebar ${!ismobile && sidebarCollapse ? 'sidebar-thin' : ''} h-screen fixed top-0 overflow-y-auto flex flex-col flex-nowrap shadow-xl bg-white z-10 transition-all duration-300`}>
            <div className={`site-logo flex ${!ismobile && sidebarCollapse ? 'sidebar-thin justify-center' : 'justify-between'} items-center`}>
                {!ismobile && sidebarCollapse ?
                    <NavLink to="/"><img src={logoShort} alt="Plaisport" width={35} height={35} /></NavLink>
                    :
                    <NavLink to="/"><img src={logo} alt="Plaisport" width={130} height={48} /></NavLink>
                }
                <button type='button' className={`mobile-nav justify-self-end`} onClick={(e)=>handleClickMobmenu(e)}><span className='hidden'>Menu</span></button>
            </div>
            <div className="flex flex-1 flex-col mx-8 menu-wrapper">
                <button type='button' id="menu-collapse-switch" className={`hidden md:block menu-collapse-switch ${!ismobile && sidebarCollapse ? 'shrink' :''}`} onClick={()=>handleCollapseMenu()}><span>Switch</span></button>
                <div className='flex flex-col mt-14 mb-16 wlcmsg'>
                    Hello {userDetails.user.FirstName}!
                    <span>Welcome</span>
                </div>
                <ul className="main-menu flex-col min-w-full flex list-none">
                    <li className="rounded-lg mb-2.5 flex">
                        <NavLink
                            to="/"                            
                            className="flex items-center gap-4 text-sm text-white font-light pl-4 py-3 rounded-lg"
                            activeclassname="text-white shadow-md"
                        >
                            <img src={dashboard} alt="Plaisport" width={24} height={24} /><span>Dashboard</span>
                        </NavLink>
                    </li>
                    <li className="rounded-lg mb-2.5 flex">
                        <NavLink
                            to="/create-team"                            
                            className="flex items-center gap-4 text-sm text-white font-light pl-4 py-3 rounded-lg"
                            activeclassname="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                        >
                            <img src={team} alt="Plaisport" width={24} height={24} /><span>Create a Team</span>
                        </NavLink>
                    </li>
                    {/*
                    <li className="rounded-lg mb-2.5 flex">
                        <a href="https://plaisport.com/support/" target="_blank" rel='noreferrer'
                            className="flex items-center gap-4 text-sm text-white font-light pl-4 py-3 rounded-lg"
                            activeclassname="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                        >
                            <img src={help} alt="Plaisport" width={24} height={24} /><span>Help</span>
                        </a>
                    </li>
                    */}
                    <li className="rounded-lg mb-2.5 flex">
                        <NavLink
                            to="/settings"
                            className="flex items-center gap-4 text-sm text-white font-light pl-4 py-3 rounded-lg"
                            activeclassname="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                        >
                            <img src={settings} alt="Plaisport" width={24} height={24} /><span>Settings</span>
                        </NavLink>
                    </li>
                    {userDetails?.user?.superAdmin &&
                    <li className="rounded-lg mb-2.5 flex">
                        <a
                            href={`https://dashboard.plaisport.com/login/${userDetails?.user?.auth_key}`}
                            className="flex items-center gap-4 text-sm text-white font-light pl-4 py-3 rounded-lg"
                            activeclassname="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={superadminicon} alt="Plaisport" width={20} height={20} /><span>Superadmin Panel</span>
                        </a>
                    </li>
                    }
                    <li className="lg:hidden rounded-lg mb-2.5 flex pl-4">
                        <button type="button" className='logout-btn relative !pl-[56px]' onClick={()=>{setShowLogoutConfirm(true)}}><span>Logout</span></button>
                    </li>                  
                </ul>                
            </div>
            <div className='hidden lg:block justify-self-end mx-8 mb-[50px]'>
                <button type="button" className='logout-btn relative' onClick={()=>{setShowLogoutConfirm(true)}}><span>Logout</span></button>
            </div>
            {showLogoutConfirm &&
                <LogoutConfirm setShowForm={setShowLogoutConfirm} />
            }
        </div>
        </>
    )
}