import { useEffect, useState, useRef } from "react";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import CheckTreePicker from 'rsuite/CheckTreePicker';
import DatePicker from "rsuite/DatePicker";

import ReactDatePicker from "react-datepicker";
import PlaiImageCropper from "../../../components/ImageCropper/ImageCropper";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../../../components/Spinner/Spinner";
import TextEditor from "../../../components/TextEditor";
import { getUserAsignedGroup } from "../../../libs/dataFunctions";
import "./NewsForm.scss";
import { FilePond, registerPlugin } from "react-filepond";
import { generateRandomName } from "../../../components/ImageCropper/cropUtils";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';

registerPlugin(FilePondPluginFileValidateType);
dayjs.extend(utc);
dayjs.extend(tz);

const schema = yup.object().shape({
    articletitle: yup.string().required('Title is required.'),
    groups: yup
    .array()
    .min(1, "At least one group must be selected")
    .nullable()
    .required("At least one group must be selected"),
});

const NewsEditForm = ({news, team, setShowForm}) =>{
    const [loading,setLoading] = useState(false);
    const [editorContent,setEditorContent] = useState('');
    const [publish,setPublishStatus] = useState('true');
    const [newsImage, setNewsImage] = useState(null);
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);    
    const [pdfFiles, setPdfFile] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [timepickerVal, setTimepickerVal] = useState(new Date());
    const [disabledGroupsOpt, setDisableGroupsOpt] = useState([]);
    const contentRef=useRef();

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    
    
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
            resolve(base64String);
        }
        reader.onerror = error => reject(error);
    });
    
    const UpdateNewsArticle=async (data)=>{
        setErrorMessage('');
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "update_news");
        formdata.append("title", data.articletitle);
        if(data.publish==='false' && data.newsdate!==''){
            formdata.append("hour", data.time);
            formdata.append("date", dayjs(data.newsdate).format("YYYY-MM-DD"));
        }
        else{
            formdata.append("hour", dayjs(new Date()).format("HH:mm"));
            formdata.append("date", dayjs(new Date()).format("YYYY-MM-DD"));
        }

        formdata.append("published", data.publish);
        if(selectedGroups.length > 0){
            formdata.append("group_ids", selectedGroups.join('@#'));
        }
        else{
            var preGroups = [];
            news.groups_new.forEach((item)=>{
                preGroups.push(item._id);
            });
            formdata.append("group_ids", preGroups.join('@#'));
        }
        formdata.append("details", editorContent);
        formdata.append("commentsEnabled", data.allowcomments ? 'true' : 'false');

        formdata.append("notify_email",data.notifymail ? 'Yes' : 'No');
        formdata.append("notify_notification", data.notify_notification ? 'Yes' : 'No');

        formdata.append("team_id", team._id);
        formdata.append("news_id", news._id);
        formdata.append("is_draft", news.is_draft);

        if(pdfFiles!==undefined && pdfFiles.length > 0){
            let pdfcodes = [];
            let exts = [];
            for(let i=0; i<pdfFiles.length; i++){
                pdfcodes[i] = await toBase64(pdfFiles[i].file);
                exts[i] = pdfFiles[i].fileExtension;
            };
            formdata.append("document_content", pdfcodes.join('@#'));
            formdata.append("document_extension", exts.join('@#'));
        }
        formdata.append('separate_by','@#');
        
        if(newsImage!=null){
            var formdatas3 = new FormData();
            let tmpFilename = generateRandomName();
            console.log(newsImage.type);
            const imgFileObj = new File([newsImage], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: newsImage.type })
            formdatas3.append("files[]", imgFileObj);
            var requestOptionss3 = {
                method: 'PUT',
                body: formdatas3,
                redirect: 'follow'
            };
            const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
            const resultsApp = await responsesApp.text();
            const rdatasApp = JSON.parse(resultsApp);
            formdata.append('content',rdatasApp.data[0]);
        }

        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_news' , requestOptions)
        .then(response => response.text())
        .then(result => {                
            let rdata = JSON.parse(result);
            if(rdata.status!==1){
                setLoading(false);
                setErrorMessage(rdata.message);
            }
            else{
                setShowForm(false);
                window.location.href = '/team/'+team._id+'/news/'+news._id;
            }
        })
        .catch(error => console.log('error', error));      
    }

    const onSubmit = (data) => {
        UpdateNewsArticle(data);        
    }

    const handleChange = (e, key) => {
        const { value } = e.target        
        setEditorContent(value);
    }

    async function refreshGroups(){
        setLoading(true);
        let groupsData = await getUserAsignedGroup(token, team._id, news._id, 'news');
        let disableGrps=groupsData.filter(item=>{return !item.canSelect});
        let groupOptions = [];        
        let disableGOpt=[];
        
        if(groupsData.length>0){                        
            groupsData.forEach(element => {
                let childOptions = [];
                if(!element.canSelect){
                    disableGOpt.push(element._id);
                }
                if(element.nest_groups.length > 0){
                    element.nest_groups.forEach(celm =>{
                        childOptions.push({'value':celm._id, 'label':celm.name});  
                        if(!celm.canSelect){
                            disableGOpt.push(celm._id);
                        }                      
                    });
                    groupOptions.push({'value':element._id, 'label':element.name, 'children':childOptions});
                }
                else{
                    groupOptions.push({'value':element._id, 'label':element.name});
                }                
            });            
        }
        setDisableGroupsOpt(disableGOpt);
        setGroups(groupOptions);
        setLoading(false);      
    }

    useEffect(() => {
        refreshGroups();
        setValue('articletitle', news.title);
        setEditorContent(news.details);
        setValue('publish',news.published ? "true" : "false");
        setPublishStatus(news.published ? "true" : "false");
        setValue('date',new Date(news.date));
        setValue('newsdate',new Date(news.date));
        setValue('time',news.hour_new);
        setValue('allowcomments', news.commentsEnabled ? true : false);
        setValue('notify_notification', news.notify_notification);
        setValue('notifymail', news.notify_email);        
        setSelectedGroups(news.group_ids);
        setValue('groups', news.group_ids);

        let startDateArr = news.date.split('-');
        let startTimeArr = news.hour_new.split(':');
        
        setTimepickerVal(new Date(startDateArr[0],startDateArr[1],startDateArr[2],startTimeArr[0],startTimeArr[1],'0'));

    },[]);

    return(
        !loading ?
        <>
            <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
            <div className="bg-[#363F6C] py-7.5 px-5 rounded-xl">
                <div>
                    <div className="form-heading text-center mb-[30px] !text-white">Edit Article</div>
                    <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper news-form-wrapper">
                        <div className="form-field-container mb-12">
                            <div className="label">Article Title*</div>
                            <div className='form-field-wrap user-field-wrap relative'>
                                <input 
                                    type="text" id="article-name" disabled={loading} placeholder="Article Title *" className='text-field'
                                    {...register("articletitle")}
                                    aria-invalid={errors.articletitle ? "true" : "false"} 
                                />                                
                            </div>
                            {errors.articletitle && <p className="error mb-2.5" role="alert">{errors.articletitle?.message}</p>}
                        </div>
                        <div className='form-field-wrap mb-12 relative'>
                            <TextEditor
                                value={editorContent}
                                placeholderText="Your text goes here"
                                height={200}
                                onChange={(e) => handleChange({ target: { value: e } }, 'editor')}
                            />
                        </div>
                        <div className="flex flex-wrap mb-12">
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container mb-12">
                                    <div className="label">I'd like to...*</div>
                                    <div className='form-field-wrap select-field-wrap publish-field-wrap relative'>
                                        <select {...register("publish")} onChange={(e)=>{setValue('publish', e.target.value); setPublishStatus(e.target.value)}}>
                                            <option value="true">Published</option>
                                            <option value="false">Unpublished</option>
                                        </select>
                                    </div>
                                    {publish==='false' && <p className="mt-2.5 mb-2.5 text-[12px] leading-[16px] text-white">The PLAI scheduled send feature runs on Coordinated Universal Time (UTC). Current time in UTC is {dayjs.utc().format('HH:mm')}</p>}
                                </div>
                            </div>
                            {publish==='false' &&
                            <div className="w-full xl:w-1/3 flex flex-wrap">
                                <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container mb-12">
                                        <div className="label">Select Publish Date*</div>
                                        <div className='form-field-wrap select-field-wrap date-field-wrap relative'>
                                            <Controller
                                                control={control}
                                                name='newsdate'
                                                render={({ field }) => (
                                                <ReactDatePicker
                                                    placeholderText='Select date'
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value}
                                                    mask="____-__-__"
                                                    dateFormat="dd-MM-yyyy"
                                                    minDate={new Date()}                                        
                                                />
                                            )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container mb-12">
                                        <div className="label">Select Publish Time*</div>
                                        <div className='form-field-wrap select-field-wrap time-field-wrap relative'>
                                            <Controller
                                                control={control}
                                                name='time'
                                                errors={errors}
                                                render={({ field }) => (
                                                    <DatePicker 
                                                        placeholder='Select Time'
                                                        format="hh:mm aa" 
                                                        onChange={(date) => {
                                                            field.onChange(dayjs(date).format('HH:mm'));
                                                        }}
                                                        caretAs={null} 
                                                        cleanable={false}
                                                        defaultValue={timepickerVal}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container relative">
                                    <div className="flex justify-between">
                                        <div className="label">Send this to*</div>
                                        <p className="mb-1 font-medium text-[12px] leading-[18px] text-white">
                                            {selectedGroups.length===0 ? 
                                                '0 Groups Selected':
                                                (selectedGroups.length > 1) ? selectedGroups.length + ' Groups Selected' : selectedGroups.length + ' Group Selected'}
                                        </p>
                                    </div>
                                    <div className='form-field-wrap select-field-wrap groups-field-wrap relative' ref={contentRef}>                            
                                        <Controller
                                            control={control}
                                            name='groups'
                                            render={({ field: { onChange, value, ref }}) => (
                                                <CheckTreePicker
                                                    defaultExpandAll
                                                    placeholder="Select Groups*"
                                                    data={groups}
                                                    onChange={val => {
                                                        onChange(val.map(c => c.value));
                                                        setValue('groups',val);
                                                        setSelectedGroups(val);
                                                        //handleSelectChange(val);
                                                    }}
                                                    cleanable={false}
                                                    disabledItemValues={disabledGroupsOpt}
                                                    container={()=>contentRef.current}
                                                    menuStyle={{zIndex: 100000000}}
                                                    defaultValue={selectedGroups}
                                                    value={selectedGroups}
                                                    style={{
                                                        width:'100%',
                                                        maxWidth:'500px',
                                                        borderWidth:0,
                                                        backgroundColor: '#EFF1F9',
                                                        padding: '8px 7px 8px 46px',
                                                        height: '52px',
                                                        borderRadius: 8
                                                    }}
                                                    onSelect={(item,val,event)=>{
                                                            if(item.label.toLowerCase()==="all members"){
                                                                if(item.check){
                                                                    let groupIds=[];
                                                                    groups.forEach((item)=>{
                                                                        groupIds.push(item.value);
                                                                    });
                                                                    setSelectedGroups(groupIds);
                                                                }
                                                                else{
                                                                    setSelectedGroups([]);
                                                                }
                                                            }
                                                        }
                                                    }
                                                />
                                            )}
                                        />                                
                                    </div>
                                    {errors.groups?.message && <p className="error mb-2.5" role="alert">{errors.groups?.message}</p> }
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-12">
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className='form-field-wrap mb-5 relative'>
                                    <PlaiImageCropper editImage={(news.images && news.images !== "") ? news.images : ''} onFinalChange={(newImage)=>{setNewsImage(newImage)}} aspect={2 / 1}/>
                                </div>
                                
                            </div>
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className='form-field-wrap mb-5 relative'>
                                    <FilePond
                                        files={pdfFiles}
                                        onupdatefiles={setPdfFile}                                    
                                        allowMultiple={true}
                                        credits={false}
                                        allowFileTypeValidation={true}
                                        acceptedFileTypes={['application/pdf']}
                                        labelFileTypeNotAllowed='File of invalid type'
                                        //maxFiles={1}                                    
                                        labelIdle='
                                            <div class="upload-text">
                                                <h4>Upload a PDF</h4>
                                                <p class="text-center">Maximum upload file size: 2MB</p>
                                            </div>'
                                        {...register("pdfdocuments")}
                                    />
                                </div>                                
                            </div>
                            <div className="w-full xl:w-1/3 flex flex-col justify-between">
                                <div>
                                    <div className="flex flex-wrap">
                                        <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                            <div className='form-field-wrap new-check-field-wrap relative'>                                
                                                <label htmlFor="notifymail" className=''>
                                                    <input type="checkbox" id="notifymail" {...register("notifymail")} className='checkbox-field appearance-none'/>
                                                    Send Email
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                            <div className='form-field-wrap new-check-field-wrap relative'>                                
                                                <label htmlFor="notify_notification" className=''>
                                                    <input type="checkbox" id="notify_notification" {...register("notify_notification")} className='checkbox-field appearance-none'/>
                                                    Send Notification
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                            <div className='form-field-wrap new-check-field-wrap relative'>                                
                                                <label htmlFor="allowcomments" className=''>
                                                    <input type="checkbox" id="allowcomments" {...register("allowcomments")} className='checkbox-field appearance-none'/>
                                                    Allow Comments
                                                </label>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                                <div className="w-full md:px-2.5 xl:px-3.5 pt-5">
                                    <div className='flex justify-center lg:justify-end'>
                                        <input className='button button--large' type="submit" value="Update" disabled={loading}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {errorMessage!=="" && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p> }                                  
                    </form>
                </div>
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}

export default NewsEditForm;