import { useState, useEffect } from "react";
import { confirmAlert } from 'react-confirm-alert';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import tinyCalendarIcon from "../../../assets/images/icon-tiny-calendar.svg";
import placeholderImg from '../../../assets/images/placeholder.png';
import iconTimeTiny from '../../../assets/images/icon-time-tiny.svg';
import { getFormResponses } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";

dayjs.extend(utc);
dayjs.extend(tz);

const ViewResponsesPopup = ({setShowForm, teamID, formItem}) =>{
    const [loading, setLoading]=useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [membersList, setMembersList] = useState([]);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const fetchMemberList = async() =>{
        setLoading(true);
        let [membersListdata] = await Promise.all([
            getFormResponses(token, teamID, formItem._id)
        ]);

        setMembersList(membersListdata.data);        
        setLoading(false);
    }

    const handleViewSheet=async()=>{
        setButtonLoading(true);
        var formdata=new FormData();        
        formdata.append("type", "download_form_data_in_sheet");
        formdata.append("team_id", teamID);
        formdata.append("form_id",formItem._id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_forms' , requestOptions)
        .then(response => response.text())
        .then(result => {
            var res=JSON.parse(result)
            window.open(res.data.pdf, '_blank');
            setButtonLoading(false);
        })
        .catch(error => console.log('error', error));
    }

    useEffect(()=>{ 
        fetchMemberList();
    },[]);

    return(                
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails !max-w-[670px] relative !px-2.5 sm:!px-8">                    
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                <div className="relative">
                {!loading ?
                <>
                    {membersList.length > 0 ?
                        <>
                        <div className="custom-scroll xl:max-h-[70vh]">
                            <div className="mb-7">
                                <h2 className="text-[22px] leading-[33px] text-center font-semibold">Form Responses</h2>
                            </div>                            
                            <div>
                            {membersList.length>0 ?
                            <>
                                {membersList.map((member)=>{
                                    return(
                                        <a href={`/team/${teamID}/formview/${member._id}`} key={member._id} className={`rounded-xl border border-[rgba(0,0,0,0.5)] hover:border-[var(--color-yellow)] py-2.5 px-3 flex gap-2.5 items-center mb-6`}>
                                            <div className='w-6 lg:w-12.5 mr-2.5'>
                                            {member.profilePicture !== '' ?  
                                                <img src={member.profilePicture} alt="" width={50} height={50} className="rounded-full w-6 lg:w-12.5 h-6 lg:h-12.5" />
                                            :
                                                <img src={placeholderImg} alt="" width={50} height={50} className="rounded-full w-6 lg:w-12.5 h-6 lg:h-12.5" />
                                            }
                                            </div>
                                            <div className='flex-1'>
                                                <div className='pr-2.5 text-base font-semibold uppercase'>{member.name}</div>
                                                <div className="flex gap-3.5">                                                                                                        
                                                    <div className='flex gap-2 items-center'>
                                                        <img src={tinyCalendarIcon} width={12} height={12} alt="time" />
                                                        <div className='text-xs'>{dayjs.utc(member.created_at).local().format('DD MMM YYYY')}</div>
                                                    </div>
                                                    <div className='flex gap-2 items-center'>
                                                        <img src={iconTimeTiny} width={12} height={12} alt="time" />
                                                        <div className='text-xs'>{dayjs.utc(member.created_at).local().format('hh:mm A')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <svg className="w-10" width="40" height="6" viewBox="0 0 40 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z" fill="#363F6C"/>
                                            </svg>                                            
                                        </a>
                                    )
                                })}
                                <div className="flex justify-center mt-10">
                                    {!buttonLoading ?
                                    <button type="button" className="button" onClick={()=>{handleViewSheet()}}>View In Sheets</button>
                                    :
                                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
                                    }
                                </div>
                            </>                            
                            
                            :
                            <div>Response not found.</div>
                            }
                            </div>
                        </div>                        
                        </>
                    :
                        <div>No response available.</div>
                    }
                </>
                :
                <>
                    <div className="text-center py-5"><Spinner /></div>
                </>
                }
                </div>
            </div>
        </div>
    )
}

const AdminFormCard=({formItem, teamID, token, fetchForms})=>{
    const [loading, setLoading] = useState(false);
    const [acceptResponseStatus, setAcceptResponseStatus] = useState(null);
    const [showResponses, setShowResponses] = useState(false);
    var formLink = `/team/${teamID}/your-form/${formItem._id}`;
    const toggleBookingStatus=async()=>{
        setLoading(true);
        var formdata=new FormData();
        var updatedStatus = acceptResponseStatus.toLowerCase()==='yes' ? 'No' : 'Yes';
        formdata.append("type", "update_form_status");
        formdata.append("team_id", teamID);
        formdata.append("form_id",formItem._id);
        formdata.append("accept_response", updatedStatus);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_forms' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setAcceptResponseStatus(updatedStatus);
            setLoading(false);
        })
        .catch(error => console.log('error', error));
    }

    const deleteFormAction=async()=>{
        var formdata = new FormData();
        formdata.append("type", "delete_form");
        formdata.append("form_id",teamID);
        formdata.append("team_id", formItem.team_id);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_forms' , requestOptions)
        .then(response => response.text())
        .then(result => {
            fetchForms();
        })
        .catch(error => console.log('error', error));
    }

    const deleteForm=()=>{
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                <div className='confirm-ui'>
                    <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                    <p className="text-center text-[14px] leading-[18px] mb-5">Are you sure you want to delete this Form?</p>
                    <div className="flex justify-center">
                        <button className="button button-blue mx-1" onClick={onClose}>No</button>
                        <button className="button mx-1"
                        onClick={() => { 
                            deleteFormAction();                       
                            onClose();
                        }}
                        >
                        Yes
                        </button>
                    </div>
                </div>
                );
            }
        });
    }

    const confirmEdit=()=>{
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                <div className='confirm-ui'>
                    <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                    <p className="text-center text-[14px] leading-[18px] mb-5">YIKES! As you’ve started collecting responses, you’re unable to EDIT this form but simply DUPLICATE this one and AMEND on a new form if you need to make adjustments!</p>
                    <div className="flex justify-center">
                        <button className="button button-blue mx-1" onClick={onClose}>Ok</button>                        
                    </div>
                </div>
                );
            }
        });
    }

    useEffect(()=>{
        setAcceptResponseStatus(formItem.accept_response);
    },[]);

    return(
        <>
        <div className="form-card bg-white pt-6 !pb-6 px-5 relative">
            <div className="flex justify-between gap-5 mb-4.5 items-start">
                <a href={formLink} className="form-card-title font-bold text-lg/6 block mt-7 sm:mt-0 sm:flex-1">{formItem.title}</a>
                <div className="flex gap-2.5 justify-center flex-wrap sm:justify-end items-center absolute top-6 right-5 sm:relative sm:top-[inherit] sm:right-[inherit] w-[98px]">
                    {formItem.total_response <= 0 ?
                        <a href={`/team/${teamID}/editform/${formItem._id}`} className="button-tiny edit rounded-full"><span className="hidden">Edit</span></a>
                        :
                        <button type="button" className="button-tiny edit rounded-full" onClick={()=>{confirmEdit()}}><span className="hidden">Edit</span></button>
                    }
                    <a href={`/team/${teamID}/duplicateform/${formItem._id}`} className="button-tiny duplicate rounded-full"><span className="hidden">Clone</span></a>
                    <button type="button" className="button-tiny delete rounded-full" onClick={()=>{deleteForm()}}></button>
                </div>
            </div>
            <div className="max-h-8 overflow-hidden tracking-[0.01em] font-light text-xs mb-7">{formItem.description}</div>
            <div className="flex items-center mb-8">
                <img src={tinyCalendarIcon} className="w-4 h-4 mr-1.5" alt=""/>
                <div className="flex flex-1">
                    <div>
                        <div className="tracking-[0.01em] text-xs">Created on</div>
                        <div className="tracking-[0.01em] text-sm font-semibold">{dayjs(formItem.created_at).format("DD MMM YYYY")}</div>
                    </div>
                    {formItem.expire_date!=="" &&
                        <>
                        <svg className="mx-4" width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.5 1L0.499999 35.641" stroke="#363F6C" strokeWidth="0.5"/>
                        </svg>
                        <div>
                            <div className="tracking-[0.01em] text-xs">Expires</div>
                            <div className="tracking-[0.01em] text-sm font-semibold">{dayjs(formItem.expire_date).format("DD MMM YYYY")}</div>
                        </div>
                        </>
                    }
                </div>
            </div>
            <div className="flex flex-col sm:flex-row sm:justify-between items-center gap-4">
                <div className="flex bg-white h-full items-center">
                    {!loading ?
                        <label className="relative inline-flex items-center cursor-pointer mb-0">
                            <input type="checkbox" value="" className="sr-only peer" onChange={()=>toggleBookingStatus()} checked={acceptResponseStatus!==null && acceptResponseStatus.toLowerCase()==='yes' ? true : false} />
                            <div className="w-10 h-5.5 bg-[rgba(54,63,108,0.1)] peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:bg-[var(--color-yellow)] after:content-[''] after:absolute after:top-[3px] after:left-[3px] after:bg-[var(--color-darkblue)] after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-[var(--color-darkblue)]"></div>
                        </label>
                    :
                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={12} height={12} alt="" />
                    }
                    <span className={`text-[9px] leading-[10px] ml-1.5 mr-2.5 max-w-[65px] ${(acceptResponseStatus!==null && acceptResponseStatus.toLowerCase()==='yes') ? 'text-green-600' : 'text-red-600'}`}>{acceptResponseStatus!==null && acceptResponseStatus.toLowerCase()==='yes' ? 'Accepting Responses' : 'Not Accepting Responses'}</span>
                    {formItem.submited_at!=="" ?
                    <>
                        <span className="rounded-full px-4 py-1 block text-center text-xs bg-[#e1fde9] text-[#257d3e]">Completed</span>
                    </>
                    :
                    <>
                        <span className="rounded-full px-4 py-1 block text-center text-xs bg-[rgba(242,169,59,0.2)] text-[#f2a93b]">Incomplete</span>
                    </>
                    }
                </div>                
                <div>
                    <button type="button" className="button button-medium !normal-case font-semibold" onClick={()=>setShowResponses(true)}>View Responses</button>
                </div>
            </div>
        </div>
        {showResponses &&
            <ViewResponsesPopup setShowForm={setShowResponses} teamID={teamID} formItem={formItem} />
        }
        </>
    )
}

export default AdminFormCard;