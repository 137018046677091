import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Spinner from "../../../components/Spinner/Spinner";
import { toCurrentOffset } from "../../../libs/utils";

const schema = yup.object({
    promocode: yup.string().required('Set Discount Code.'),
    promocode_type: yup.string().required('Select Discount Type.'),
    promocode_value: yup.string().required('Set Discount Percentage/Amount.'),    
}).required();

const DiscountCodeForm = ({setShowForm, teamID, isNew, promoItem, fetchDiscountCodes}) =>{
    const [loading, setLoading]=useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const submitNewDiscountCode=async(data)=>{
        setErrorMessage(null);
        setLoading(true);
        var formdata=new FormData();
        formdata.append("type", "create_promocode");
        formdata.append("team_id", teamID);
        formdata.append("promocode", data.promocode);
        formdata.append("promocode_type", data.promocode_type);
        formdata.append("value", data.promocode_value);
        if(data.end_date!=="" && data.end_date!==null && data.end_date!==undefined){
            formdata.append("end_date", dayjs(data.end_date).format("YYYY-MM-DD"));
        }
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_promocode' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status===1){
                //window.location=`/team/${teamID}/shop/manage-discount-codes`;
                setShowForm(false);
                fetchDiscountCodes();
            }
            else{
                setErrorMessage(rdata.message);
            }
        })
        .catch(error => console.log('error', error));
    }

    const updateDiscountCode=async(data)=>{
        setErrorMessage(null);
        setLoading(true);
        var formdata=new FormData();
        formdata.append("type", "update_promocode");
        formdata.append("team_id", teamID);
        formdata.append("promocode_id", promoItem._id);
        formdata.append("promocode", data.promocode);
        formdata.append("promocode_type", data.promocode_type);
        formdata.append("value", data.promocode_value);
        
        if(data.end_date!=="" && data.end_date!==null && data.end_date!==undefined){
            formdata.append("end_date", dayjs(data.end_date).format("YYYY-MM-DD"));
        }
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_promocode' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status===1){
                setShowForm(false);
                //window.location=`/team/${teamID}/shop/manage-discount-codes`;
                fetchDiscountCodes();
            }
            else{
                setErrorMessage(rdata.message);
            }
        })
        .catch(error => console.log('error', error));
    }

    const onSubmit=(data)=>{
        if(isNew){
            submitNewDiscountCode(data);
        }
        else{
            updateDiscountCode(data);
        }
    }

    useEffect(()=>{
        if(!isNew){
            setValue('promocode', promoItem.promocode);
            setValue('promocode_type', promoItem.promocode_type);
            setValue('promocode_value', promoItem.value);
            if(promoItem.end_date!=="" && promoItem.end_date!=="Invalid Date"){
                setValue('end_date',toCurrentOffset(promoItem.end_date));
            }
        }
    },[]);

    return(                
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails !max-w-[670px] relative !px-2.5 sm:!px-8">                    
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                <div className="form-heading text-center mb-[30px]">{isNew ? 'Create Discount Code' : 'Edit Discount Code'}</div>
                <div className="relative">
                {!loading ?
                <>
                    <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper ads-form-wrapper">                
                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <div className='form-field-wrap mb-5 discountcode-field-wrap relative'>
                                    <input 
                                        type="text" disabled={loading} placeholder="Set Discount Code*" className='text-field'
                                        {...register("promocode")}
                                        aria-invalid={errors.promocode ? "true" : "false"} 
                                    />                                
                                </div>
                                {errors.promocode && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.promocode?.message}</p>}
                                <div className='form-field-wrap mb-5 select-field-wrap discountcodetype-field-wrap relative'>
                                    <select placeholder="Discount Type*" {...register("promocode_type")} onChange={(e)=>{setValue('promocode_type',e.target.value)}}>
                                        <option value="">Discount Type*</option>
                                        <option value="percentage">Percentage</option>
                                        <option value="fixed">Fixed</option>
                                    </select>
                                </div>
                                {errors.promocode_type && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.promocode_type?.message}</p>}
                                <div className='form-field-wrap mb-5 discountcodetype-field-wrap relative'>
                                    <input 
                                        type="text" disabled={loading} placeholder="Discount Percentage/Amount*" className='text-field'
                                        {...register("promocode_value")}
                                        aria-invalid={errors.promocode_value ? "true" : "false"} 
                                    />                                
                                </div>
                                {errors.promocode_value && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.promocode_value?.message}</p>}
                                <div className='form-field-wrap mb-5 date-field-wrap relative'>
                                    <Controller
                                        control={control}
                                        name='end_date'
                                        errors={errors}
                                        render={({ field, ref }) => (
                                            <ReactDatePicker
                                                placeholderText='Expiry Date'
                                                minDate={new Date()}
                                                onChange={(date) => {field.onChange(date)}}
                                                selected={field.value}
                                                mask="____-__-__"
                                                dateFormat="dd-MM-yyyy"
                                                inputRef={ref}
                                            />
                                        )}
                                    />                              
                                </div>
                                {/*errors.end_date && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.end_date?.message}</p>*/}
                            </div>                            
                        </div>
                        {!loading && errorMessage!==null ? <p className='w-full error text-center mb-2.5'>{errorMessage}</p> : null}
                        <div className="w-full px-2.5">
                            <div className='flex justify-center'>
                                <input className='button button--large' type="submit" value={isNew ? 'Create' : 'Update'} />
                            </div>
                        </div>                        
                    </form>
                </>
                :
                <>
                    <div className="text-center py-5"><Spinner /></div>
                </>
                }
                </div>
            </div>
        </div>
    )
}

export default DiscountCodeForm;