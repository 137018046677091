import Sidebar from "../../components/Sidebar/Sidebar";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import TeamForm from "../../components/TeamForm/TeamForm";
import { NavLink } from "react-router-dom";

function CreateTeam(){
    return(
        <>
            <Sidebar type="thin"/>
            <div id="page" className="create-team-page">
                <AdminNavbar heading="Create team"/>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Create a Team
                </div>
                <TeamForm />
            </div>
        </>
    )
}

export default CreateTeam;