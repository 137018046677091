import { useNavigate } from 'react-router-dom';
import placeholderImg from '../../assets/images/placeholder.png';
const SlideItem = ({team}) =>{
    const navigate = useNavigate();
    const handleTeamClick = (teamItem) =>{
        navigate('/team/'+teamItem._id);
    }
    return(
    <>
        <div className="team flex-col flex sm:flex-row items-center sm:items-start  p-3.5 cursor-pointer" onClick={()=>{handleTeamClick(team)}}>
            <div className='logo-wrapper relative w-[80px] h-[80px] mb-2.5 sm:mb-0 sm:mr-5 inline-block rounded-full overflow-hidden'>
                {team.logo && team.logo!=="" ?
                    <img src={team.logo} alt={team.name} className="absolute object-cover w-full h-full" width="80" height="80"/>
                :
                    <img src={placeholderImg} alt={team.name} className="absolute object-cover w-full h-full" width="80" height="80"/>
                }
            </div>
            <div className='info sm:text-left text-center flex-1 min-w-[130px]'>
                <h5 className="title truncate hover:text-clip">{team.name}</h5>
                <p className={`role ${team.user_role==='admin' && 'isadmin'} ${team.user_role==='group_admin' && 'isgroupadmin'} mb-2.5 table mx-auto sm:mx-0 uppercase`}>{team.user_role.replace(/_/g, ' ')}</p>
                {team.members.length > 0 &&
                <div className="members">
                    {team?.members.map((teamMember,index)=>{
                        if(index > 4){return ''}
                        let eclass='';
                        let profilePic='';
                        profilePic=(teamMember.profilePicture && teamMember.profilePicture !== "") ? teamMember.profilePicture : placeholderImg;
                        if(index!==0){
                            eclass = '-ml-2.5';
                        }                        
                        return <img src={profilePic} key={index} alt="" className={`inline-block relative object-cover object-center rounded-full w-7 h-7 ${eclass}`} />
                    })}
                </div>
                }
            </div>
        </div>
    </>
    );
}

export default SlideItem;