import { useContext, useState, useEffect, useRef } from "react";
import { TeamContext } from "../../../contexts/TeamContext";
import DutiesRow from "./DutiesRow";
import rduties from '../../../assets/images/duties.svg';
import Spinner from "../../../components/Spinner/Spinner";
import { getDuties, getDutiesPast, getDutiesUpcoming } from "../../../libs/dataFunctions";

const TabDuties=()=>{
    const {team, token } = useContext(TeamContext);
    const [duties, setDuties] = useState([]);
    const [dutiesLoading, setDutiesLoading] = useState(false);
    const [q, setSearchQuery] = useState("");    
    const [searchParam] = useState(["name"]);
    const [dutiesType, setDutiesType] = useState('all');
    const [showSortingOpt, setShowSortingOpt] = useState(false);

    const sortBy = async(orderby) =>{
        if(orderby==='past'){
            setDutiesLoading(true);
            let dutiesData = await getDutiesPast(team._id, token);
            setDuties(dutiesData);
            setDutiesLoading(false);
            setDutiesType('past');
            setShowSortingOpt(false);
        }        
        else if(orderby==='upcoming'){
            setDutiesLoading(true);
            let dutiesData = await getDutiesUpcoming(team._id, token);
            setDuties(dutiesData);
            setDutiesLoading(false);
            setDutiesType('upcoming');
            setShowSortingOpt(false);
        }
        else{
            setDutiesLoading(true);
            let dutiesData = await getDuties(team._id, token);
            setDuties(dutiesData);
            setDutiesLoading(false);
            setDutiesType('all');
            setShowSortingOpt(false);
        }        
    }
    
    async function refreshDuties(){
        setDutiesLoading(true);
        let dutiesData = await getDuties(team._id, token);
        setDuties(dutiesData);
        setDutiesLoading(false);
    }
    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }
    
    useEffect(()=>{      
        refreshDuties();         
    },[]);
    return(
        !dutiesLoading ?
        <>
            <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center mb-[30px] relative">
                <div className="title-col mb-2.5 md:mb-0">
                    {dutiesType==='all' ? 'Duties': (dutiesType==='upcoming' ? 'Upcoming Duties' : 'Past Duties')}
                </div>
                <div className="cta-col flex flex-col md:flex-row items-center relative">
                    <label htmlFor="search-form" className="search-field relative mr-3.5">
                        <input
                            type="search"
                            name="search-field"
                            id="search-form"
                            className="search-input"
                            placeholder="Search Duties"
                            value={q}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />                        
                    </label>
                    <button type="button" className={`button-icon button-icon-sort`} onClick={(e)=>{setShowSortingOpt(!showSortingOpt); }}><span>Filter</span></button>
                    <ul className={`sort-actions absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showSortingOpt ? 'hidden' : ''}`}>
                        <li className={dutiesType==='all' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('all');}}>All</div></li>
                        <li className={dutiesType==='upcoming' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('upcoming');}}>Upcoming</div></li>
                        <li className={dutiesType==='past' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('past');}}>Past</div></li>        
                    </ul>
                </div>
            </div>
            <div className="duties-list lg:px-5">
                {duties && duties.length>0 ?
                    <div className="flex flex-wrap"> 
                        {search(duties).sort((a, b) => {
                                return new Date(b.dateStart).getTime() - new Date(a.dateStart).getTime()
                            }).map((duties,index) => {                        
                                return (
                                    <DutiesRow duties={duties} team={team} key={index} />
                                )
                            })
                        }
                    </div>
                :
                <div className="empty text-center">
                    <div className="image"><img src={rduties} alt="Plaisport" width={60} height={60} /></div>
                    <div className="title mb-5">No Duties Yet?</div>
                    <div className="description mb-8">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                </div>
                }
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default TabDuties;