import ChatConvesationRow from "./ChatConvesationRow";
import { useSelector } from "react-redux";

const ChatConversations = ({setActiveChatLoading, team, socket, enableArchiveMode, selectedMembers, setSelectedMembers, setmobShowChatboard}) =>{
    const conversationList = useSelector(state=>state.conversationList);
    return(
        <>
        {conversationList.length > 0 ? 
            conversationList.map((conversation)=>{
                return(
                    <ChatConvesationRow 
                        key={conversation.conversation_id}
                        conversation={conversation}
                        team={team} 
                        setActiveChatLoading={setActiveChatLoading} 
                        socket={socket} 
                        enableArchiveMode={enableArchiveMode} 
                        selectedMembers={selectedMembers} 
                        setSelectedMembers={setSelectedMembers} 
                        setmobShowChatboard={setmobShowChatboard}/>
                )
            })
        :
            <div>No conversation available. Start new conversation.</div>
        }
        </>
    );
}

export default ChatConversations;