import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './DocumentView.scss';

const DocumentView = ({doc,hasLink,width, height})=>{
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }
    let dWdith = width===undefined ? 'w-[150px]' : 'w-['+width+']';
    let dheight = height===undefined ? 'h-[180px]' : 'h-['+height+']';
    return(
        hasLink===undefined ?
        <a href={doc} target="_blank" className={`block ${dWdith} ${dheight} relative pdf-view`} rel="noreferrer">
            <Document file={doc} onLoadSuccess={onDocumentLoadSuccess} renderMode="svg">
                <Page pageNumber={pageNumber} />
            </Document>
        </a>
        :
        <div className={`block ${dWdith} ${dheight} relative pdf-view`}>
            <Document file={doc} onLoadSuccess={onDocumentLoadSuccess} renderMode="svg">
                <Page pageNumber={pageNumber} />
            </Document>
        </div>
    )
}

export default DocumentView;
