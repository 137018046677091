import { useState, useEffect } from "react";
import { getMembershipCards } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";
import MembershipCard from "./MembershipCard";

const MembershipCards=()=>{
    const [loading,setLoading] = useState();
    const [membershipCards,setMembershipCards] = useState([]);
    const [cardsFetchError, setCardsFetchError] = useState('');

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    async function refreshMembershipCards(){
        setLoading(true);
        setCardsFetchError('');
        let membershipData = await getMembershipCards(token);
        if(membershipData.data && membershipData.data.length > 0){
            setMembershipCards(membershipData.data);
        }
        else{
            setCardsFetchError(membershipData.message);
        }
        setLoading(false);
    }
    
    useEffect(()=>{
        refreshMembershipCards();
    },[]);
    
    return(
        !loading ?
        <>
            <div className="my-documents">
                <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                    <h2 className="title mr-5">Membership Card</h2>
                    <div className="sep mr-5 sm:mr-11"></div>
                </div>                
                {membershipCards.length > 0 ?
                <>
                    <ul className="flex flex-wrap">
                        {membershipCards.map((mcard,index) => { 
                            return (
                                <li key={index} className="w-full md:w-1/2 md:px-[15px] lg:w-full lg:px-0 xl:w-1/2 xl:px-[15px] mb-[30px] max-w-[412px]">
                                    {<MembershipCard cardDetail={mcard} />}
                                </li>
                            )
                        })}
                    </ul>
                </>
                :
                <p className="mb-5">{cardsFetchError}</p>
                }
            </div>
        </>
        :
        <>
           <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}
export default MembershipCards