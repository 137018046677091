import { useState, useEffect } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import placeholderImg from '../../../assets/images/placeholder.png';
import { useDispatch, useSelector } from "react-redux";
import { getConversations, getArchiveConversations } from "../../../libs/dataFunctions";
import { fetchConversationListAction } from "../../../Redux/conversationListSlice";

const FarwardMessage = ({setShowForm, team, ffconvID, socket, isArchive}) =>{
    const conversationList = useSelector(state=>state.conversationList);
    const [searchParam] = useState(["name"]);
    const [q, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [membersList, setMembersList] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [errorMsg, setErorrMsg] = useState(null);
    const dispatch = useDispatch();
    
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]?
                        item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                    :
                    null
                );
            });
        });
    }

    const handleSubmitFarward=async()=>{
        setErorrMsg(null);
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "forward_message");
        formdata.append("conversation_details_id", ffconvID.conversation_details_id);
        formdata.append("conversation_ids",selectedMembers.join('@#'));
        formdata.append("separate_by",'@#');
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status===1){
                    rdata.data.forEach((rd)=>{
                        console.log(rd);
                        socket.emit('send_message', JSON.stringify({"conversation_details_id":rd.conversation_details_id}));
                    })                    
                    setShowForm(false);                    
                }
                else{
                    alert(rdata.message);
                }
            })
            .catch(error => console.log('error', error));
        if(!isArchive){
            let [conversationListdata] = await Promise.all([
                getConversations(token, team._id)
            ]);
            dispatch(fetchConversationListAction(conversationListdata.data));
        }
        else{
            let [conversationListdata] = await Promise.all([
                getArchiveConversations(token, team._id)
            ]);
            dispatch(fetchConversationListAction(conversationListdata.data));
        }
    }

    useEffect(()=>{ 
        const newState = conversationList.filter(obj => {
            return obj.is_leave_from_group === false;
        });
        setMembersList(newState);
    },[conversationList]);

    return(
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails !max-w-[600px] relative !px-2.5 sm:!px-8">                    
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                <div className="relative">
                    {!loading ?
                    <>
                    {membersList.length > 0 ?
                        <>
                        <div className="chat-members-list-wrap">
                            <div className="chat-members-list" style={{maxHeight: '70vh', overflowY: 'auto', paddingBottom: '40px', paddingLeft: '15px', paddingRight: '15px'}}>
                                <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                                    <h2 className="title mr-5 uppercase">Farward Message</h2>
                                    <div className="sep mr-4"></div>
                                    <button type="button" className="button button--yellow button-medium">Select All</button>                        
                                </div>
                                <div className="search-form-wrap flex mb-5">
                                    <label htmlFor="search-form" className="search-field relative mr-3.5 flex-1">
                                        <input
                                            type="search"
                                            name="search-field"
                                            id="search-form"
                                            className="search-input"
                                            placeholder="Search Now"
                                            value={q}
                                            onChange={(e) => {
                                                setSearchQuery(e.target.value);                                                            
                                            }}
                                        />
                                    </label>
                                </div>
                                {search(membersList).length>0 ?
                                <>
                                    {search(membersList).map((member,index)=>{
                                        return(
                                            <FWChatMemberRow member={member} key={index} selectedMembers={selectedMembers} setSelectedMembers={setSelectedMembers} />
                                        )
                                    })}
                                </>
                                :
                                <div>Member not found.</div>
                                }
                            </div>
                            {selectedMembers.length > 0 &&
                                <div className="absolute bottom-0 w-full flex justify-center">
                                    <input className='button button--medium' type="button" value='Farward' disabled={loading} onClick={()=>{handleSubmitFarward()}}/>
                                </div>
                            }
                        </div>
                        </>
                    :
                        <div>No members available.</div>
                    }
                    </>
                    :
                    <>
                        <div className="text-center py-5"><Spinner /></div>
                    </>
                    }
                </div>
            </div>
        </div>
    )
}

export default FarwardMessage;

const FWChatMemberRow = ({member, selectedMembers, setSelectedMembers}) =>{
    var selectedMemberArray = [...selectedMembers];
    const handleFFMember=()=>{
        if (selectedMemberArray.indexOf(member.conversation_id) !== -1) {
            setSelectedMembers(selectedMembers.filter((item) => {return !item.includes(member.conversation_id)}));
        }
        else{
            setSelectedMembers([...selectedMembers, member.conversation_id]);
        }
    }
    
    return(
        <div className={`chat-member-row flex items-center flex-1 cursor-pointer ${selectedMemberArray.indexOf(member.conversation_id) !== -1 ? 'selected' : ''}`} onClick={()=>handleFFMember()}>
            <div className='w-[44px] mr-2.5'>
            {member.profilePicture !== '' ?  
                <img src={member.profilePicture} alt="" width={44} height={44} className="rounded-full w-[44px] h-[44px] cursor-pointer" />
            :
                <img src={placeholderImg} alt="" width={44} height={44} className="rounded-full w-[44px] h-[44px] cursor-pointer" />
            }
            </div>
            <div className='member-info flex-1'>
                <div className='pr-2.5'>{member.name}</div>
            </div>
            <div className="w-[40px] pl-2.5">
                {selectedMemberArray.indexOf(member.conversation_id) !== -1 ?
                    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="15.8486" cy="15" r="15" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M23.8486 15C23.8486 15.5523 23.4009 16 22.8486 16L8.84863 16C8.29635 16 7.84863 15.5523 7.84863 15C7.84863 14.4477 8.29635 14 8.84863 14L22.8486 14C23.4009 14 23.8486 14.4477 23.8486 15Z" fill="#363F6C"/>
                    </svg>                
                :
                    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="15.8486" cy="15" r="15" fill="#363F6C"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.001 6.60413C16.5533 6.60413 17.001 7.05184 17.001 7.60413V21.6041C17.001 22.1564 16.5533 22.6041 16.001 22.6041C15.4487 22.6041 15.001 22.1564 15.001 21.6041V7.60413C15.001 7.05184 15.4487 6.60413 16.001 6.60413Z" fill="#FBDB09"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.001 14.6041C24.001 15.1564 23.5533 15.6041 23.001 15.6041L9.00098 15.6041C8.44869 15.6041 8.00098 15.1564 8.00098 14.6041C8.00098 14.0518 8.44869 13.6041 9.00098 13.6041L23.001 13.6041C23.5533 13.6041 24.001 14.0518 24.001 14.6041Z" fill="#FBDB09"/>
                    </svg>
                }
            </div>
        </div>
    )
}