import { useState } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Spinner from "../../../components/Spinner/Spinner";
import * as yup from 'yup';

const schema = yup.object({
    reason: yup
    .string()
    .required("Select reason for decline response."),
}).required();

const DeclineResponseForm =({setShowForm, teamID, eventID, resonseID, refreshEvent, setEventItem, setActionLoading})=>{
    const [errorMessage,setErrorMessage] = useState(false);
    const [successMessage,setSuccessMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reasonType, setReasonType] = useState(null);
    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';
    
    const onSubmit = async(data) => {
        setErrorMessage(false);
        setSuccessMessage(false);
        var formdata = new FormData();
        if(data.reason==='OTHER'){
            if(data.other_reason!==""){
                formdata.append("reason",data.other_reason);
            }
            else{
                setErrorMessage("Please enter text for other reason to decline this response.");
                return;
            }
        }
        else{
            formdata.append("reason",data.reason);
        }
        
        formdata.append("type", "change_request_status");
        formdata.append("team_id", teamID);
        formdata.append("event_id", eventID);
        formdata.append("request_id", resonseID);
        formdata.append("status",'rejected');

        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        setLoading(true);
        setActionLoading(true);
        await fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(rresponse => rresponse.text())
        .then(result => {
            setLoading(false);
            setShowForm(false);
            let rdata=JSON.parse(result)
            setEventItem(rdata.data);
            setActionLoading(false);
            //refreshEvent();                
        })
        .catch(error => console.log('error', error));
    }
    return(
        !loading ?
        <>
        <div className="bg-[#363F6C] py-7.5 px-5 rounded-xl">
            <div>
                <div className="form-heading text-center mb-[30px] !text-white">Reason to decline</div>
                <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">                
                    <div>
                        <div className="form-field-container">
                            <div className="label">Select Reason Type*</div>
                            <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                <select {...register("reason")} onChange={(e)=>{setValue('reason', e.target.value, { shouldValidate: true }); setReasonType(e.target.value);}}>
                                    <option value="">Select Reason Type*</option>
                                    <option value="EVENT OVERSUBSCRIBED">EVENT OVERSUBSCRIBED</option>
                                    <option value="SELECTED FOR ANOTHER EVENT ON THE SAME DAY">SELECTED FOR ANOTHER EVENT ON THE SAME DAY</option>
                                    <option value="IN ARREARS">IN ARREARS</option>
                                    <option value="OTHER">OTHER</option>
                                </select>
                            </div>
                            {errors.reason?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.reason?.message}</p> }
                        </div>
                        {reasonType==='OTHER' &&
                            <>
                            <div className="form-field-container">
                                <div className="label">Other reason *</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                    <textarea disabled={loading} placeholder="Type your reason" className='text-field'
                                        {...register("other_reason")}
                                    />                                
                                </div>                                
                            </div>                            
                            </>
                        }
                    </div>
                    {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                    {successMessage!=='' && <p className="text-green-600 mb-2.5 text-center" role="alert">{successMessage}</p>}
                    <div className="w-full px-2.5">
                        <div className='flex justify-center'>
                            <input className='button' type="submit" value="Submit" disabled={loading}/>
                        </div>
                    </div>                
                </form>
            </div>
        </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default DeclineResponseForm;