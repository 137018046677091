import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Select, { components } from "react-select";
import * as yup from 'yup';
import Spinner from "../../../components/Spinner/Spinner";
import DownArrowSvg from "../../../assets/images/icon-down.svg";
import { getEventResponseJoinMembers } from "../../../libs/dataFunctions";

const schema = yup.object({
    attendees: yup
    .array()
    .min(1, "Select at least one attendee for this link")
    .nullable()
    .required("Select at least one attendee for this link"),
    availability: yup.string().required('Please select availability.'),
}).required();

const ResponseForm = ({ team, eventitem, setShowForm, refresh }) =>{
    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(false);

    const [attendees, setAttendees] = useState([]);
    const [selectedAttendees, setSelectedAttendees] = useState([]);
    const [selectedAttendeesOut, setSelectedAttendeesOut] = useState(selectedAttendees);
    
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const ResponseSubmit = async(data)=>{
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "submit_response");
        formdata.append("team_id", team._id);
        formdata.append("event_id", eventitem._id);
        formdata.append("availability", data.availability);
        formdata.append("description",data.response_desc);
        formdata.append("user_sub_ids", data.attendees.join('@#'));
        formdata.append('separate_by','@#');
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                setShowForm(false);
                refresh();
            }
        })
        .catch(error => console.log('error', error));
    }

    
    const onSubmit = async(data) => {
        ResponseSubmit(data);        
    }

    const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <img src={DownArrowSvg} width={24} height={24} alt="" />
          </components.DropdownIndicator>
        );
    };
    function handleSelectChange(event) {
        const current = [...event];
        var current_attendees = current.map((attend) => {
            return attend.value
        });
        setSelectedAttendees(current_attendees);
        setSelectedAttendeesOut(event);
    }
    const refreshChildren = async() =>{
        setLoading(true);        
        const [joinMemberData] = await Promise.all([
            getEventResponseJoinMembers(token,team._id,eventitem._id),
        ]);
        let atndee=[];
        if(joinMemberData.users.length > 0){
            joinMemberData.users.forEach(element => {
                atndee.push({'value':element.sub, 'label':element.name});
            });
            if(joinMemberData.user_added_response.length>0){
                let selectedAtndee = [];
                joinMemberData.user_added_response.forEach(element => {
                    selectedAtndee.push({'value':element.sub, 'label':element.name});
                });
                setSelectedAttendees(selectedAtndee);
                setSelectedAttendeesOut(selectedAtndee);
                setValue('attendees', selectedAtndee);
            }
            else{
                setSelectedAttendees(atndee[0].value);
                setSelectedAttendeesOut(atndee[0]);
                setValue('attendees', [atndee[0].value]);
            }
        }        
        setAttendees(atndee);
        setLoading(false);
    }
    useEffect(() => {
        refreshChildren();
    },[]);

    return (
        !loading ?
        <>
            <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
            <div className="form-heading text-center mb-[30px]">Submit Response</div>
            {!loading && errorMessage ? <p className='error text-center mb-2.5'>{errorMessage}</p> : null}
            <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">                
                <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2 md:px-2.5">
                        <div className='form-field-wrap mb-5 user-field-wrap relative'>
                            <Controller
                                control={control}
                                name='attendees'
                                render={({ field: { onChange, value, ref }}) => (
                                    <Select
                                        inputRef={ref}
                                        value={selectedAttendeesOut}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        components={{ DropdownIndicator }}
                                        placeholder={<div>Select Attendees *</div>}
                                        onChange={val => {
                                            onChange(val.map(c => c.value));
                                            handleSelectChange(val);
                                        }}
                                        options={attendees}
                                        isMulti
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderWidth: 0,
                                                backgroundColor: 'rgba(239, 241, 249, 0.6)',
                                                padding: '6px 7px 6px 46px'
                                            }),
                                            menu: (baseStyles) => ({
                                                ...baseStyles,
                                                zIndex:1000,
                                                backgroundColor: '#ffffff'
                                            })
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,                                                
                                        })}
                                    />
                                )}
                            />
                        </div>
                        {errors.attendees?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.attendees?.message}</p> }
                    </div>                    
                    <div className="w-full md:w-1/2 md:px-2.5">
                        <div className='form-field-wrap mb-5 user-field-wrap relative'>
                            <select {...register("availability")} onChange={(e)=>setValue('availability', e.target.value, { shouldValidate: true })}>
                                <option value="Available">Available</option>
                                <option value="More time needed">More time needed</option>
                                <option value="Unavailable">Unavailable</option>                    
                            </select>
                        </div>
                        {errors.availability && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.availability?.message}</p>}                        
                    </div>
                    <div className="w-full">
                        <div className='form-field-wrap mb-5 relative'>
                            <textarea id="response-desc" disabled={loading} placeholder="Add a note (optional)" className='textarea-field'
                                {...register("response_desc")}
                            ></textarea>
                        </div>
                    </div>
                </div>                
                <div className="w-full px-2.5">
                    <div className='flex justify-center'>
                        <input className='button button--large' type="submit" value="Submit" disabled={loading}/>
                    </div>
                </div>
            </form>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default ResponseForm;