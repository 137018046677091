import { useEffect, useState, useContext } from "react";
import { useParams, NavLink } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import { TeamContext, TeamContextProvider } from "../../../contexts/TeamContext";
import placeholderImg from '../../../assets/images/placeholder.png';
import lineImg from '../../../assets/images/line.svg';
import fantacyLogo from '../../../assets/images/Fantasy-PLAI_LOGO.png';
import { getPlayers, getRule } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";
import JoinGamePlayer from "./JoinGamePlayer";
import CFGMultiRangeSlider from "./CFGMultiRangeSlider";
import './fantacygame.scss';

const JoinFantacyGameSteps = () =>{
    const { team, token, teamUpdating } = useContext(TeamContext);
    const [loading,setLoading] = useState(false);
    const [players, setPlayers] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [gameRule, setGameRule] = useState([]);
    const [totalBudget, setTotalBudget] = useState(0);
    const [spendAmount, setSpendAmount] = useState(0);
    const [maxPlayer, setMaxPlayers] = useState(0);
    const [threeStarTotal, setThreeStarTotal] = useState(0);
    const [twoStarTotal, setTwoStarTotal] = useState(0);
    const [captain, setCaptain] = useState(null);
    const [vcaptain, setVCaptain] = useState(null);
    const [errorMessage,setErrorMessage] = useState(null);
    const [showSortPopup, setShowSortPopup] = useState(false);
    const [showFilterPopup, setShowFilterPopup] = useState(false);
    const [sortby, setSortBy] = useState(null);
    const [filterBy, setFilterBy] = useState(null);
    const [filterPriceMin, setFilterPriceMin] = useState(1);
    const [filterPriceMax, setFilterPriceMax] = useState(30);
    const [playersMainList, setPlayersMainList] = useState([]);
    const [q, setSearchQuery] = useState("");    
    const [searchParam] = useState(["name"]);

    async function refreshPlayers(){
        setLoading(true);
        const [teamPlayerData, gameRuleData] = await Promise.all([
            getPlayers(team._id, token),
            getRule(team._id, token)
        ]);
        if(teamPlayerData.status===1){
            setPlayers(teamPlayerData.data);
            setGameRule(gameRuleData.data);
            setMaxPlayers(gameRuleData.data?.max_player);
            setThreeStarTotal(gameRuleData.data?.three_star_player);            
            setTwoStarTotal(gameRuleData.data?.two_star_player);
            var tmpMembers = [];
            var tmpSpendAmt = 0;
            var tmpBudgetAmt = gameRuleData.data?.total_budget;

            teamPlayerData.data.forEach((member)=>{
                if(member.is_selected){
                    tmpMembers.push(member);
                    tmpSpendAmt = tmpSpendAmt + member.price;
                    tmpBudgetAmt = tmpBudgetAmt - member.price
                }
                if(member.is_captain){
                    setCaptain(member.sub);
                }
                if(member.is_vice_captain){
                    setVCaptain(member.sub);
                }
            });
            if(tmpMembers.length>0){
                setSelectedPlayers(tmpMembers);
                setTotalBudget(tmpBudgetAmt);
                setSpendAmount(tmpSpendAmt);
            }
            else{
                setTotalBudget(gameRuleData.data?.total_budget);
            }
            setPlayersMainList(teamPlayerData.data);
        }
        setLoading(false);
    }
    
    useEffect(() => {
        if(!teamUpdating){
            refreshPlayers();            
        }
    },[teamUpdating]);
    
    const playersSortBy = () =>{
        let sorted_players;
        if(sortby==='highprice'){
            sorted_players = players.sort((a,b)=>(b.price > a.price) ? 1 : -1);            
        }
        else if(sortby==='lowprice'){
            sorted_players = players.sort((a,b)=>(a.price > b.price) ? 1 : -1);            
        }
        else if(sortby==='atoz'){
            sorted_players = players.sort((a,b)=>(a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);            
        }
        else if(sortby==='trending'){
            sorted_players = players.sort((a,b)=>(b.trending > a.trending) ? 1 : -1);
        }
        else{
            sorted_players = playersMainList;
        }
        setPlayers(sorted_players);
    }

    const resetSorting = () =>{
        let filteredPlayers;
        if(filterBy === 'icon' ){
            filteredPlayers = players.filter((playerItem)=>(playerItem.rating===3));
        }
        else if(filterBy === 'pro'){
            filteredPlayers = players.filter((playerItem)=>(playerItem.rating===2));
        }
        else if(filterBy === 'rookie'){
            filteredPlayers = players.filter((playerItem)=>(playerItem.rating===1));
        }
        else{
            filteredPlayers = playersMainList;
        }
        filteredPlayers = filteredPlayers.filter((playerItem)=>(playerItem.price >= filterPriceMin && playerItem.price <= filterPriceMax));
        setPlayers(filteredPlayers);
        setSortBy(null);
    }
    const resetFilter = () =>{
        let sorted_players;
        if(sortby==='highprice'){
            sorted_players = playersMainList.sort((a,b)=>(b.price > a.price) ? 1 : -1);            
        }
        else if(sortby==='lowprice'){
            sorted_players = playersMainList.sort((a,b)=>(a.price > b.price) ? 1 : -1);            
        }
        else if(sortby==='atoz'){
            sorted_players = playersMainList.sort((a,b)=>(a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);            
        }
        else if(sortby==='trending'){
            sorted_players = playersMainList.sort((a,b)=>(b.trending > a.trending) ? 1 : -1);
        }
        else{
            sorted_players = playersMainList;
        }
        setPlayers(sorted_players);
        setFilterBy(null);
        setFilterPriceMin(1);
        setFilterPriceMax(30);
    }
    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }
    const playersFilterBy=(pricemin,pricemax)=>{
        setFilterPriceMax(pricemax);
        setFilterPriceMin(pricemin);
        let filteredPlayers;
        let sorted_players;
        if(sortby==='highprice'){
            sorted_players = playersMainList.sort((a,b)=>(b.price > a.price) ? 1 : -1);            
        }
        else if(sortby==='lowprice'){
            sorted_players = playersMainList.sort((a,b)=>(a.price > b.price) ? 1 : -1);            
        }
        else if(sortby==='atoz'){
            sorted_players = playersMainList.sort((a,b)=>(a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);            
        }
        else if(sortby==='trending'){
            sorted_players = playersMainList.sort((a,b)=>(b.trending > a.trending) ? 1 : -1);
        }
        else{
            sorted_players = playersMainList;
        }
        if(filterBy === 'icon' ){
            filteredPlayers = sorted_players.filter((playerItem)=>(playerItem.rating===3));
        }
        else if(filterBy === 'pro'){
            filteredPlayers = sorted_players.filter((playerItem)=>(playerItem.rating===2));
        }
        else if(filterBy === 'rookie'){
            filteredPlayers = sorted_players.filter((playerItem)=>(playerItem.rating===1));
        }
        else{
            filteredPlayers = sorted_players;
        }
        filteredPlayers = filteredPlayers.filter((playerItem)=>(playerItem.price >= pricemin && playerItem.price <= pricemax));
        setPlayers(filteredPlayers);
    }
    const getPlayerBarContent = () => {
        let barContent = [];
        for (let i = 1; i <= maxPlayer; i++) {
            if(i<=selectedPlayers.length){
                barContent.push(<div key={i} className="w-[20px] h-[8px] md:w-[30px] md:h-[16px] rounded-[4px] bg-white"></div>);
            }
            else{
                barContent.push(<div key={i} className="w-[20px] h-[8px] md:w-[30px] md:h-[16px] rounded-[4px] bg-white opacity-40"></div>);
            }
        }
        return barContent;
    };    
    const getRatingContent = (player) => {
        let ratingContent = [];
        for (let i = 1; i <= player.rating; i++) {
            ratingContent.push(<span className="star" key={i}>&#9733;</span>);
        }
        return ratingContent;
    };

    const handleSubmit = async() =>{
        var formdata = new FormData();
        formdata.append("type", "member_create_fantasy_game");
        formdata.append("team_id", team._id);
        let selectedGamePlayers = [];
        if(selectedPlayers.length > 0){
            selectedPlayers.forEach((member)=>{
                selectedGamePlayers.push(member.sub);
            });
        }
        
        formdata.append("player_sub_ids",selectedGamePlayers.join('@#'));
        formdata.append("separate_by",'@#');
        formdata.append("captain_sub_id",captain);
        formdata.append("vice_captain_sub_id",vcaptain);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        }
        
        await fetch(process.env.REACT_APP_APIURL + '/app_fantasy' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                window.location.href = '/team/'+team._id;
            }
        })
        .catch(error => console.log('error', error));
    }

    const SortPopup = ({setShowForm}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !max-w-[400px] !px-7.5">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <div className="w-full">
                        <h3 className="uppercase text-[22px] leading-[33px] font-semibold mb-[22px] text-center">Sort By</h3>
                        <div className="flex flex-wrap mb-5">
                            <div className={`w-1/2 uppercase cursor-pointer flex items-center text-[16px] leading-[24px] text-[#363F6C] ${sortby==='highprice' ? 'font-semibold' : 'font-normal'}`} onClick={()=>{setSortBy('highprice');}}>
                                {sortby==='highprice' ?
                                    <svg className="mr-2.5" fill="none" width="13.33px" height="13.33px" xmlns="http://www.w3.org/2000/svg" viewBox="0.84 0.21 14.63 14.63"> <path d="M5.125 7.33318L7.40084 9.60902L11.5732 5.43665" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> <rect x="1.49316" y="0.856201" width="13.3333" height="13.3333" rx="2" stroke="black" strokeWidth="1.3" strokeLinejoin="round"></rect> </svg>
                                :
                                    <svg className="mr-2.5" fill="none" width="13.33px" height="13.33px" xmlns="http://www.w3.org/2000/svg" viewBox="0.84 0.21 14.63 14.63"> <rect x="1.49316" y="0.856201" width="13.3333" height="13.3333" rx="2" stroke="black" strokeWidth="1.3" strokeLinejoin="round"></rect></svg>
                                }
                                High Price</div>
                            <div className={`w-1/2 uppercase cursor-pointer flex items-center text-[16px] leading-[24px] text-[#363F6C] ${sortby==='lowprice' ? 'font-semibold' : 'font-normal'}`} onClick={()=>{setSortBy('lowprice');}}>
                                {sortby==='lowprice' ?
                                    <svg className="mr-2.5" fill="none" width="13.33px" height="13.33px" xmlns="http://www.w3.org/2000/svg" viewBox="0.84 0.21 14.63 14.63"> <path d="M5.125 7.33318L7.40084 9.60902L11.5732 5.43665" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> <rect x="1.49316" y="0.856201" width="13.3333" height="13.3333" rx="2" stroke="black" strokeWidth="1.3" strokeLinejoin="round"></rect> </svg>
                                :
                                    <svg className="mr-2.5" fill="none" width="13.33px" height="13.33px" xmlns="http://www.w3.org/2000/svg" viewBox="0.84 0.21 14.63 14.63"> <rect x="1.49316" y="0.856201" width="13.3333" height="13.3333" rx="2" stroke="black" strokeWidth="1.3" strokeLinejoin="round"></rect></svg>
                                }
                                Low price</div>
                            <div className={`w-1/2 uppercase cursor-pointer flex items-center text-[16px] leading-[24px] text-[#363F6C] ${sortby==='atoz' ? 'font-semibold' : 'font-normal'}`} onClick={()=>{setSortBy('atoz');}}>
                                {sortby==='atoz' ?
                                    <svg className="mr-2.5" fill="none" width="13.33px" height="13.33px" xmlns="http://www.w3.org/2000/svg" viewBox="0.84 0.21 14.63 14.63"> <path d="M5.125 7.33318L7.40084 9.60902L11.5732 5.43665" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> <rect x="1.49316" y="0.856201" width="13.3333" height="13.3333" rx="2" stroke="black" strokeWidth="1.3" strokeLinejoin="round"></rect> </svg>
                                :
                                    <svg className="mr-2.5" fill="none" width="13.33px" height="13.33px" xmlns="http://www.w3.org/2000/svg" viewBox="0.84 0.21 14.63 14.63"> <rect x="1.49316" y="0.856201" width="13.3333" height="13.3333" rx="2" stroke="black" strokeWidth="1.3" strokeLinejoin="round"></rect></svg>
                                }
                                A-Z</div>
                            <div className={`w-1/2 uppercase cursor-pointer flex items-center text-[16px] leading-[24px] text-[#363F6C] ${sortby==='trending' ? 'font-semibold' : 'font-normal'}`} onClick={()=>{setSortBy('trending');}}>
                                {sortby==='trending' ?
                                    <svg className="mr-2.5" fill="none" width="13.33px" height="13.33px" xmlns="http://www.w3.org/2000/svg" viewBox="0.84 0.21 14.63 14.63"> <path d="M5.125 7.33318L7.40084 9.60902L11.5732 5.43665" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> <rect x="1.49316" y="0.856201" width="13.3333" height="13.3333" rx="2" stroke="black" strokeWidth="1.3" strokeLinejoin="round"></rect> </svg>
                                :
                                    <svg className="mr-2.5" fill="none" width="13.33px" height="13.33px" xmlns="http://www.w3.org/2000/svg" viewBox="0.84 0.21 14.63 14.63"> <rect x="1.49316" y="0.856201" width="13.3333" height="13.3333" rx="2" stroke="black" strokeWidth="1.3" strokeLinejoin="round"></rect></svg>
                                }
                                Trending</div>
                        </div>
                        <div className="flex justify-center flex-col max-w-[160px] mx-auto items-center">
                            <button className="button" type="button" onClick={()=>{playersSortBy(); setShowForm(false);}}>Apply now</button>
                            <button className="mt-5" onClick={()=>{setSortBy(null); resetSorting(); setShowForm(false);}}>Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    const FilterPopup = ({setShowForm}) =>{  
        const [priceMin,setPriceMin] = useState(0);
        const [priceMax, setPriceMax] = useState(30);
        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !max-w-[500px] !px-7.5">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <div className="w-full">
                        <h3 className="uppercase text-[22px] leading-[33px] font-semibold mb-[22px] text-center">Filter By</h3>
                        <div className="flex flex-wrap mb-5">
                            <div className={`w-1/3 uppercase cursor-pointer flex flex-wrap items-center text-[16px] leading-[24px] text-[#363F6C] ${filterBy==='icon' ? 'font-semibold' : 'font-normal'}`} onClick={()=>{setFilterBy('icon');}}>
                                {filterBy==='icon' ?
                                    <svg className="mr-2.5" fill="none" width="13.33px" height="13.33px" xmlns="http://www.w3.org/2000/svg" viewBox="0.84 0.21 14.63 14.63"> <path d="M5.125 7.33318L7.40084 9.60902L11.5732 5.43665" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> <rect x="1.49316" y="0.856201" width="13.3333" height="13.3333" rx="2" stroke="black" strokeWidth="1.3" strokeLinejoin="round"></rect> </svg>
                                :
                                    <svg className="mr-2.5" fill="none" width="13.33px" height="13.33px" xmlns="http://www.w3.org/2000/svg" viewBox="0.84 0.21 14.63 14.63"> <rect x="1.49316" y="0.856201" width="13.3333" height="13.3333" rx="2" stroke="black" strokeWidth="1.3" strokeLinejoin="round"></rect></svg>
                                }
                                Icons
                                <div className="flex w-full pl-6">
                                    <span className="star text-[#FBDB03] mr-1">&#9733;</span><span className="star text-[#FBDB03] mr-1">&#9733;</span><span className="star text-[#FBDB03]">&#9733;</span>
                                </div>
                            </div>
                            <div className={`w-1/3 uppercase cursor-pointer flex flex-wrap items-center text-[16px] leading-[24px] text-[#363F6C] ${filterBy==='pro' ? 'font-semibold' : 'font-normal'}`} onClick={()=>{setFilterBy('pro');}}>
                                {filterBy==='pro' ?
                                    <svg className="mr-2.5" fill="none" width="13.33px" height="13.33px" xmlns="http://www.w3.org/2000/svg" viewBox="0.84 0.21 14.63 14.63"> <path d="M5.125 7.33318L7.40084 9.60902L11.5732 5.43665" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> <rect x="1.49316" y="0.856201" width="13.3333" height="13.3333" rx="2" stroke="black" strokeWidth="1.3" strokeLinejoin="round"></rect> </svg>
                                :
                                    <svg className="mr-2.5" fill="none" width="13.33px" height="13.33px" xmlns="http://www.w3.org/2000/svg" viewBox="0.84 0.21 14.63 14.63"> <rect x="1.49316" y="0.856201" width="13.3333" height="13.3333" rx="2" stroke="black" strokeWidth="1.3" strokeLinejoin="round"></rect></svg>
                                }
                                Pros
                                <div className="flex w-full pl-6">
                                    <span className="star text-[#FBDB03] mr-1">&#9733;</span><span className="star text-[#FBDB03]">&#9733;</span>
                                </div>
                            </div>
                            <div className={`w-1/3 uppercase cursor-pointer flex flex-wrap items-center text-[16px] leading-[24px] text-[#363F6C] ${filterBy==='rookie' ? 'font-semibold' : 'font-normal'}`} onClick={()=>{setFilterBy('rookie');}}>
                                {filterBy==='rookie' ?
                                    <svg className="mr-2.5" fill="none" width="13.33px" height="13.33px" xmlns="http://www.w3.org/2000/svg" viewBox="0.84 0.21 14.63 14.63"> <path d="M5.125 7.33318L7.40084 9.60902L11.5732 5.43665" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> <rect x="1.49316" y="0.856201" width="13.3333" height="13.3333" rx="2" stroke="black" strokeWidth="1.3" strokeLinejoin="round"></rect> </svg>
                                :
                                    <svg className="mr-2.5" fill="none" width="13.33px" height="13.33px" xmlns="http://www.w3.org/2000/svg" viewBox="0.84 0.21 14.63 14.63"> <rect x="1.49316" y="0.856201" width="13.3333" height="13.3333" rx="2" stroke="black" strokeWidth="1.3" strokeLinejoin="round"></rect></svg>
                                }
                                Rookies
                                <div className="flex w-full pl-6">
                                    <span className="star text-[#FBDB03]">&#9733;</span>
                                </div>
                            </div>
                        </div>
                        <CFGMultiRangeSlider 
                                min={1} 
                                max={30}
                                defvalue={{min:filterPriceMin, max:filterPriceMax}}
                                onChange={({min,max}) => { setPriceMin(min); setPriceMax(max); }}/>

                        <div className="flex justify-center flex-col max-w-[160px] mx-auto items-center">
                            <button className="button" type="button" onClick={()=>{playersFilterBy(priceMin,priceMax); setShowForm(false);}}>Apply now</button>
                            <button className="mt-5" onClick={()=>{resetFilter(); setShowForm(false);}}>Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    return(
        !loading && !teamUpdating ?
        <>            
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}`}>
                    <span>{team.name}</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                Join Fantasy Game
            </div>
            <div className="body px-5 lg:px-8 py-10">
                <div className="fantacygame-header px-5 lg:px-10 pt-9 pb-7 rounded-xl mb-[26px]">
                    <div className="flex flex-col lg:flex-row items-center justify-between">
                        <div className="flex items-center justify-between mb-5 md:mb-0">
                            <div className="image-wrapper rounded-full w-[65px] h-[65px] border-2 border-white border-solid overflow-hidden relative mb-2.5 sm:mb-0 sm:ml-[18px]">
                            {team.logo && team.logo !== "" ?                                
                                <img src={team.logo} alt={team.name} className="absolute object-cover w-full h-full" />
                                :
                                <img src={placeholderImg} alt={team.name} className="absolute object-cover w-full h-full" />
                            }
                            </div>
                            <div className="mr-4 ml-4 md:ml-7"><img src={lineImg} alt="" /></div>
                            <div className="flex-1"><img src={fantacyLogo} alt="" /></div>
                        </div>
                        <div className="text-[#FBDB03] text-[24px] leading-[36px] font-semibold mb-5 lg:mb-0">{team.name}</div>
                        <div className="flex justify-between w-full max-w-[435px] flex-wrap">
                            <div className="text-left w-[1/3]">
                                <label className="text-[16px] leading-[24px] font-light block text-white">Players</label>
                                <div className="text-[16px] leading-[30px] font-bold text-white flex items-center">
                                    <span className={`text-[26px] leading-[26px] md:text-[30px] md:leading-[30px] mr-2 ${selectedPlayers.length===maxPlayer ? 'text-[#1AB900]' :'text-[#ff0000]'}`}>{selectedPlayers.length}</span> of {maxPlayer}
                                </div>
                            </div>
                            <div className="text-center w-[1/3]">
                                <label className="text-[16px] leading-[24px] font-light block text-white">Spent</label>
                                <div className="text-[18px] leading-[22px] md:text-[24px] md:leading-[36px] font-bold text-white">${spendAmount}m</div>
                            </div>
                            <div className="text-center w-[1/3]">
                                <label className="text-[16px] leading-[24px] font-light block text-white">Remaining</label>
                                <div className="text-[18px] leading-[22px] md:text-[24px] md:leading-[36px] font-bold text-white">${totalBudget}m</div>
                            </div>
                            <div className="flex justify-between w-full pt-5">
                                {getPlayerBarContent()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="join-game-page w-full rounded-xl bg-white">
                    <div className="flex flex-col lg:flex-row px-2.5 py-2.5 md:px-7 md:py-7">
                        <div className="w-full md:w-autp md:flex-1 md:pr-[34px]">
                            <div className="w-full sm:max-w-[230px] mb-5">
                                <label htmlFor="search-form" className="search-field relative">
                                    <input
                                        type="search"
                                        name="search-field"
                                        id="search-form"
                                        className="search-input"
                                        placeholder="Search Player"
                                        value={q}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />                        
                                </label>
                            </div>
                            <div className="pb-3 mb-3 border-b border-solid border-b-[#dfdfdf] flex flex-col md:flex-row lg:flex-col xl:flex-row justify-between items-center">
                                <h3 className="uppercase text-[#363F6C] text-[22px] leading-[33px] mb-0">SELECT YOUR TEAM</h3>
                                <div className="flex items-center">
                                    <button className="btn-with-icon mr-3.5" onClick={()=>{setShowSortPopup(true)}}>
                                        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.6946 7.17478H8.69465C8.22139 7.17478 7.83758 7.55859 7.83758 8.03185C7.83758 8.50511 8.22139 8.88892 8.69465 8.88892H11.6946C12.1679 8.88892 12.5517 8.50511 12.5517 8.03185C12.5517 7.55859 12.1687 7.17478 11.6946 7.17478ZM15.1229 0.318237H8.69465C8.22139 0.318237 7.83758 0.702043 7.83758 1.17531C7.83758 1.64857 8.22139 2.03237 8.69465 2.03237H15.1229C15.5962 2.03237 15.98 1.64857 15.98 1.17531C15.98 0.702043 15.597 0.318237 15.1229 0.318237ZM9.98051 10.6031H8.67054C8.19728 10.6031 7.81348 10.9869 7.81348 11.4601C7.81348 11.9334 8.19728 12.3172 8.67054 12.3172H9.98051C10.4538 12.3172 10.8135 11.9334 10.8135 11.4601C10.8135 10.9869 10.4546 10.6031 9.98051 10.6031ZM13.4088 3.74651H8.69465C8.22139 3.74651 7.83758 4.13032 7.83758 4.60358C7.83758 5.07684 8.22139 5.46065 8.69465 5.46065H13.4088C13.882 5.46065 14.2659 5.07684 14.2659 4.60358C14.2659 4.13032 13.8829 3.74651 13.4088 3.74651ZM5.2522 8.31843L4.38442 9.26657V1.17611C4.38442 0.702043 4.00142 0.318237 3.52735 0.318237C3.05329 0.318237 2.67028 0.702043 2.67028 1.17611V9.26469L1.8025 8.31843C1.63345 8.13408 1.40236 8.04042 1.17015 8.04042C0.955764 8.0402 0.749145 8.12065 0.591358 8.26578C0.24237 8.58584 0.219069 9.12874 0.538621 9.47826L2.87145 12.0519C3.19607 12.407 3.81048 12.407 4.13536 12.0519L6.46819 9.47826C6.78799 9.12874 6.76442 8.58611 6.41546 8.26578C6.11463 7.94615 5.5736 7.97025 5.2522 8.31843Z" fill="currentColor"/>
                                        </svg>
                                    Sort by</button>
                                    <button className="btn-with-icon" onClick={()=>{setShowFilterPopup(true)}}>
                                        <svg width="14.52" height="14" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.76826 6.44336V14.3184L5.84401 11.6934V6.44336L0.046875 0.318359H14.5654L8.76826 6.44336Z" fill="currentColor"/>
                                        </svg>
                                        Filter by</button>
                                </div>
                            </div>
                            {showSortPopup &&
                                <SortPopup setShowForm={setShowSortPopup} />
                            }
                            {showFilterPopup &&
                                <FilterPopup setShowForm={setShowFilterPopup} setFilterPriceMax={setFilterPriceMax} setFilterPriceMin={setFilterPriceMin} />
                            }
                            {search(players).length > 0 ?
                                <>
                                    <div className="flex flex-wrap justify-center lg:justify-start">
                                        {search(players).map((player,index)=>{
                                            return (
                                                <JoinGamePlayer 
                                                    iscreatedBefore = {team.is_member_create_fantasy_game}
                                                    player={player} 
                                                    players={players}
                                                    setPlayers={setPlayers}
                                                    playersMainList={playersMainList}
                                                    setPlayersMainList = {setPlayersMainList}
                                                    selectedPlayers={selectedPlayers} 
                                                    setSelectedPlayers={setSelectedPlayers} 
                                                    totalBudget={totalBudget}
                                                    setTotalBudget={setTotalBudget}
                                                    maxPlayer={maxPlayer}
                                                    spendAmount = {spendAmount}
                                                    setSpendAmount = {setSpendAmount}
                                                    threeStarTotal={threeStarTotal}
                                                    setThreeStarTotal={setThreeStarTotal}
                                                    twoStarTotal={twoStarTotal}
                                                    setTwoStarTotal={setTwoStarTotal}
                                                    gameRule={gameRule}
                                                    captain={captain}
                                                    setCaptain={setCaptain}
                                                    vcaptain = {vcaptain}
                                                    setVCaptain = {setVCaptain}
                                                    key={player._id} 
                                                />
                                            )
                                        })}                                    
                                    </div>
                                </>
                                :
                                null
                            }
                        </div>
                        <div className="w-full mx-auto max-w-[446px] rounded-[20px] pb-[30px] bg-[#EFF1F9]">
                            <div className="pb-3 pt-[18px] px-7 mb-3 border-b border-solid border-[#aeb2c7]">
                                <h3 className="uppercase text-[#363F6C] text-[20px] leading-[30px] text-center">SELECT YOUR CAPTAIN</h3>
                            </div>
                            <div className="flex flex-wrap items-center justify-center point-rewards sm:pt-7 mb-8">
                                <div className={`captain ${captain!==null ? 'active': ''} w-full sm:w-1/2 flex items-center px-5 mb-5 sm:mb-0 lg:pr-6`}><span></span><div>gets <strong>2x points</strong></div></div>
                                <div className={`vice-captain ${vcaptain!==null ? 'active': ''} w-full sm:w-1/2 flex items-center px-5 lg:pl-6`}><span></span><div>gets <strong>1.5x points</strong></div></div>
                            </div>
                            {selectedPlayers.length > 0 ?
                                <>
                                    <div className="joinplayerlist">
                                        {selectedPlayers.map((player)=>{
                                            return(
                                                <div className="w-full max-w-[375px] flex px-3 mb-5 mx-auto" key={`player-${player._id}`}>
                                                    <div className={`player-item w-full px-2.5 py-2.5 flex flex-wrap sm:flex-nowrap justify-between rounded-[16px] items-center`}>
                                                        <div className="flex w-full sm:w-auto items-center sm:flex-1">
                                                            {player.profilePicture !=="" ?
                                                                <img src={player.profilePicture} width="50" height="50" className="rounded-full w-[50px] mr-[18px]" alt="" />
                                                            :
                                                                <img src={placeholderImg} width="50" height="50" className="rounded-full w-[50px] mr-[18px]" alt="" />
                                                            }
                                                            <div className="flex-1 pr-2.5">
                                                                <div className="player-name text-[16px] leading-[24px] font-semibold">{player.name}</div>
                                                                <div className="flex text-[14px] leading-[21px] font-semibold items-center">
                                                                    <div className='player-price mr-1'>{`Price: $${player.price}`}m</div>
                                                                    {player.trending_status!=='equal' &&
                                                                        <>
                                                                            <div className={`${player.trending_status==='up' ? 'trending-up' : 'trending-down'}`}></div>   
                                                                        </>
                                                                    }  
                                                                    <div className='ratings'>{getRatingContent(player)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="w-[78px] ml-auto flex">
                                                            <button className={`btn-captain mr-2.5 ${captain===player.sub ? 'active' :''}`} onClick={()=>{if(vcaptain!==player.sub) { setCaptain(player.sub); }}}></button>
                                                            <button className={`btn-vcaptain ${vcaptain===player.sub ? 'active' :''}`} onClick={()=>{if(captain!==player.sub) { setVCaptain(player.sub); }}}></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}                                        
                                    </div>
                                    {(selectedPlayers.length === 11 && captain !== null && vcaptain !== null && !team.is_member_create_fantasy_game) &&
                                        <div className="flex max-w-[830px] justify-center">
                                            <button className="button button--medium" onClick={()=>{handleSubmit()}}>Confirm</button>
                                        </div>
                                    }
                                </>
                            :
                                <div className="w-full min-h-[300px] py-12 flex flex-col justify-center items-center">
                                    <div>
                                        <div className="rounded-full flex justify-center items-center mx-auto bg-[#F4F5FA] border border-[#E1E2E9] border-solid w-[110px] h-[110px]">
                                            <svg width="51" height="44" viewBox="0 0 51 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.988281 30.0733C1.19765 29.3649 1.32181 28.6126 1.63342 27.9553C2.8324 25.4064 4.853 23.9385 7.67821 23.7388C9.09021 23.639 10.5168 23.7218 11.9775 23.7218C11.9446 23.8581 11.9069 23.9774 11.8886 24.0991C11.7255 25.1825 11.4383 26.2658 11.4261 27.3516C11.3737 32.0294 11.3981 36.7073 11.4115 41.3863C11.4115 41.945 11.521 42.5038 11.5843 43.1063H9.90698C8.48281 43.1063 7.05621 43.0686 5.63326 43.1148C3.28278 43.1903 1.35468 41.6176 1.04185 39.3852C1.04185 39.3572 1.00654 39.3329 0.988281 39.3061V30.0733Z" fill="#FBDB09"/>
                                                <path d="M25.8571 43.1063H16.0937C14.7912 43.1063 14.2946 42.6194 14.2934 41.3328C14.2934 36.6367 14.2837 31.9418 14.2934 27.2469C14.3031 23.7169 16.5282 20.9173 19.9401 20.1248C20.4298 20.0166 20.9298 19.9627 21.4313 19.9642C24.3782 19.9483 27.3251 19.9337 30.2708 19.9642C33.7095 19.997 36.4787 22.2368 37.259 25.5878C37.3787 26.1404 37.4366 26.7046 37.4319 27.27C37.444 31.9994 37.444 36.7276 37.4319 41.4545C37.4319 42.5756 36.8926 43.1063 35.7569 43.1075C32.4598 43.1083 29.1599 43.1079 25.8571 43.1063Z" fill="#363F6C"/>
                                                <path d="M25.8817 0.910767C30.6131 0.919287 34.5009 4.78645 34.5082 9.49107C34.506 11.7842 33.5985 13.9836 31.983 15.611C30.3674 17.2384 28.1747 18.162 25.8817 18.1809C21.1429 18.2065 17.1918 14.2261 17.2308 9.46429C17.2577 7.18725 18.1808 5.01253 19.8001 3.41144C21.4194 1.81035 23.6044 0.911889 25.8817 0.910767Z" fill="#363F6C"/>
                                                <path d="M40.2326 43.0808C40.2667 41.9549 40.3263 40.8593 40.3299 39.765C40.3409 35.7969 40.3299 31.8299 40.3299 27.8617C40.3299 26.5227 40.2314 25.2105 39.7895 23.9385C39.776 23.8806 39.7686 23.8215 39.7676 23.762C39.809 23.7316 39.8345 23.6987 39.8577 23.6987C41.5545 23.7426 43.2781 23.6123 44.9421 23.8704C48.2286 24.3804 50.6631 27.3212 50.7276 30.654C50.7775 33.3575 50.7665 36.0634 50.7349 38.7681C50.7069 41.0882 49.0612 42.9469 46.8105 43.0577C44.6049 43.166 42.3846 43.0808 40.2326 43.0808Z" fill="#FBDB09"/>
                                                <path d="M17.1485 15.4336C17.1071 19.0038 14.1857 21.9105 10.674 21.8752C7.10141 21.8387 4.21169 18.9271 4.23847 15.391C4.24194 14.5394 4.41397 13.697 4.74462 12.9123C5.07527 12.1275 5.55801 11.416 6.16499 10.8188C6.77197 10.2215 7.4912 9.75036 8.28117 9.43243C9.07115 9.11451 9.91624 8.95612 10.7677 8.96641C14.3233 9.01023 17.1887 11.9158 17.1485 15.4336Z" fill="#FBDB09"/>
                                                <path d="M47.4982 15.3995C47.5128 18.9429 44.6182 21.8509 41.0529 21.8752C37.5339 21.8984 34.621 18.9928 34.5881 15.4239C34.5565 11.8829 37.4584 8.97252 41.0261 8.964C42.7373 8.96076 44.3798 9.63659 45.5932 10.8431C46.8066 12.0497 47.4917 13.6884 47.4982 15.3995Z" fill="#FBDB09"/>
                                            </svg>
                                        </div>
                                        <div className="text-center text-[14px] leading-[21px] mb-2 font-medium w-[188px] mx-auto">Please select a players</div>
                                        <div className="text-center text-[10px] leading-[12px] w-[188px] mx-auto text-[#8B8D97]">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}

const JoinFantacyGame = () =>{
    const { id } = useParams();
    return(
        <>
            <Sidebar type="thin"/>
            <TeamContextProvider id={id}>
                <div id="page" className="page-fantacy-game">
                    <AdminNavbar heading="Fantasy Game"/>
                    <JoinFantacyGameSteps />
                </div>
            </TeamContextProvider>
        </>
    )
}
export default JoinFantacyGame;
