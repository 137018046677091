import { useState, useEffect } from 'react';
import placeholderImg from '../../../assets/images/placeholder.png';
import dayjs from 'dayjs';
import { getParentChilds } from '../../../libs/dataFunctions';

const MemberParentItem = ({parentData, teamId}) =>{
    const [showParent,setShowParent] = useState(false);
    const [chlidrens, setChildrens] = useState([]);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    async function refreshChildrens(){
        //setLoading(true);
        const [childData] = await Promise.all([
            getParentChilds(teamId,parentData.sub,token),
        ]);
        setChildrens(childData);
        //setLoading(false);
    }

    useEffect(()=>{
        refreshChildrens();
    },[]);

    const ParentDetail = ({setShowParentPop}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap popup-form-memberdetails relative !px-2.5 sm:!px-8">                    
                    <button type="button" className="close-popup" onClick={(e)=>{setShowParentPop(false)}}><span>close</span></button>
                    <div className='text-center text-[18px] leading-[24px] font-semibold mb-5'>Parent Info</div>
                    <div className='member-card'>
                        <div className='flex flex-col sm:flex-row items-center w-full member-card-row pb-5 border-b border-[#53545C]/30'>
                            <div className='pr-5'>
                            {parentData.profilePicture !== '' ?  
                              <img src={parentData.profilePicture} alt="" width={100} height={100} className="rounded-full cursor-pointer"/>
                              :
                              <img src={placeholderImg} alt="" width={100} height={100} className="rounded-full cursor-pointer"/>
                            }
                            </div>
                            <div className='member-info grow flex flex-col sm:flex-row px-2.5 sm:px-0 py-2.5 sm:py-0 text-center sm:text-left sm:items-start'>
                                <div className='flex flex-col self-center grow'>
                                    <h3 className='member-name'>{parentData.name}</h3>
                                    <div className='member-email'>{parentData.email}</div>
                                </div>                            
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row w-full justify-between member-card-row py-5'>
                            {parentData.phoneNumber!=="" &&
                              <div className='member-phone flex items-center mb-2.5 sm:mb-0'><div className='button-tiny phone rounded-full mr-1.5'></div>{parentData.phoneCode!=="" ? parentData.phoneCode+' '+parentData.phoneNumber : parentData.phoneNumber}</div>
                            }
                            <div className='member-birthdate flex items-center'><div className='button-tiny date rounded-full mr-1.5'></div>{(parentData.dateOfBirth!=='' && parentData.dateOfBirth!==null) ? dayjs(parentData.dateOfBirth).format('DD / MMM / YYYY'): (parentData.is_above_18 ? 'Over 18 years' : 'Under 18 years')}</div>
                        </div>
                        {(chlidrens.length > 0) &&
                        <div className='member-card-row'>
                            <div className='uppercase flex items-center mb-4'><span className='mr-[23px] text-xs font-semibold text-black'>Children</span><span className='h-[1px] grow bg-[#53545C] opacity-30'></span></div>
                            <ul className='flex flex-wrap'>
                              {chlidrens.map((ch,index)=>{
                                return (<li key={ch.sub}><div className='member-group mr-2 mb-3 py-1.5 px-5 bg-[#F5F5F8] rounded-[100px]'>{ch.name}</div></li>);
                              })}
                            </ul>
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
        <li>
            <div className='member-cf mr-2 mb-3 py-1.5 px-5 bg-[#F5F5F8] hover:bg-[#d2d2df] rounded-[14px] cursor-pointer' onClick={()=>{setShowParent(true)}}>
            {parentData.name}
            </div>
        </li>
        {showParent && 
            <ParentDetail setShowParentPop={setShowParent} />
        }
        </>
    )
}

export default MemberParentItem;