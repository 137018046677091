import { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import iconCalendar from '../../../assets/images/icon-calendar-tiny.svg';
import DiscountCodeForm from "./DiscountCodeForm";
dayjs.extend(utc);
dayjs.extend(tz);

const DiscountCodeCard=({promoItem, fetchDiscountCodes, token, currencyCode})=>{
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const [showDiscountForm, setShowDiscountForm] = useState(false);

    const toggleCodeStatus=async()=>{
        setLoading(true);
        var formdata=new FormData();
        var updatedStatus = status.toLowerCase()==='active' ? 'deactive' : 'active';
        formdata.append("type", "change_promocode_status");
        formdata.append("team_id", promoItem.team_id);
        formdata.append("promocode_id",promoItem._id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_promocode' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setStatus(updatedStatus);
            setLoading(false);
        })
        .catch(error => console.log('error', error));
    }

    const deleteDiscountCodeAction=async()=>{
        var formdata = new FormData();
        formdata.append("type", "delete_promocode");
        formdata.append("promocode_id",promoItem._id);
        formdata.append("team_id", promoItem.team_id);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_promocode' , requestOptions)
        .then(response => response.text())
        .then(result => {
            fetchDiscountCodes();
        })
        .catch(error => console.log('error', error));
    }
    const deletePromoCode=()=>{
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                <div className='confirm-ui'>
                    <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                    <p className="text-center text-[14px] leading-[18px] mb-5">Are you sure you want to delete this Discount Code?</p>
                    <div className="flex justify-center">
                        <button className="button button-blue mx-1" onClick={onClose}>No</button>
                        <button className="button mx-1"
                        onClick={() => { 
                            deleteDiscountCodeAction();                       
                            onClose();
                        }}
                        >
                        Yes
                        </button>
                    </div>
                </div>
                );
            }
        });
    }
    useEffect(()=>{
        setStatus(promoItem.status);
    },[]);
    return(
        <>
        <div className="rounded-xl pt-2.5 pb-5 px-4 bg-[#EFF1F9]">
            <div className="flex flex-col sm:flex-row gap-2.5 justify-between items-center mb-6">
               <h3 className="text-[var(--color-darkblue)] text-xl font-bold">{promoItem.promocode}</h3>
               <span className="rounded-xl w-19 h-7 bg-[var(--color-darkblue)] text-center text-[var(--color-yellow)] text-xs font-semibold px-2 py-1.5">{promoItem.promocode_type==='percentage' ? promoItem.value+'% off' : currencyCode+promoItem.value+' off'}</span>
            </div>
            <div className="flex flex-col sm:flex-row gap-2.5 justify-between items-center">
                <div className='flex gap-2 items-center'>
                    <img src={iconCalendar} width={12} height={12} alt="time" />
                    <div>
                        <div className='text-[11px] leading-[11px] font-light'>Expires</div>
                        <div className='text-[13px] leading-[13px] font-semibold'>{(promoItem.end_date!=="" && promoItem.end_date!=="Invalid Date") ? dayjs(promoItem.end_date).format('DD MMM YYYY'): 'Not Set'}</div>
                    </div>
                </div>
                <div className="flex gap-4 justify-center flex-wrap sm:justify-end items-center">
                    {!loading ?
                        <label className="relative inline-flex items-center cursor-pointer mb-0">
                            <input type="checkbox" value="" className="sr-only peer" onChange={()=>toggleCodeStatus()} checked={status!==null && status.toLowerCase()==='active' ? true : false} />
                            <div className="w-10 h-5.5 bg-[rgba(54,63,108,0.1)] peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:bg-[var(--color-yellow)] after:content-[''] after:absolute after:top-[3px] after:left-[3px] after:bg-[var(--color-darkblue)] after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-[var(--color-darkblue)]"></div>
                        </label>
                    :
                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={12} height={12} alt="" />
                    }
                    <button type="button" className="button-tiny edit rounded-full !w-6 !h-6 !bg-[rgba(54,63,108,0.10)]" onClick={()=>{setShowDiscountForm(true)}}><span className="hidden">Edit</span></button>
                    <button type="button" className="button-tiny delete rounded-full !w-6 !h-6 !bg-[rgba(54,63,108,0.10)]" onClick={()=>{deletePromoCode()}}></button>
                </div>
            </div>
        </div>
        {showDiscountForm &&
            <DiscountCodeForm setShowForm={setShowDiscountForm} isNew={false} teamID={promoItem.team_id} promoItem={promoItem} fetchDiscountCodes={fetchDiscountCodes} />
        }
        </>
    )
}

export default DiscountCodeCard;