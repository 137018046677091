import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Select, { components } from "react-select";
import * as yup from 'yup';
import Spinner from "../../../components/Spinner/Spinner";
import DownArrowSvg from "../../../assets/images/icon-down.svg";
import MultiRangeSlider from "../../../components/MultiRangeSlider/MultiRangeSlider";
import PlaiImageCropper from "../../../components/ImageCropper/ImageCropper";
import { generateRandomName } from "../../../components/ImageCropper/cropUtils";

const schema = yup.object({
    name: yup.string().required('Link name is required.'),
    redirect_url: yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct URL!'
    ).required('Ad URL is required.'),
    gender: yup
    .array()
    .min(1, "Select at least one gender for this ads")
    .nullable()
    .required("Select at least one gender for this ads"),
}).required();

const AdForm = ({team, isNew, aditem, setShowForm, refreshAds}) =>{
    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [genderopt, setGenderOpt] = useState([]);
    const [selectedGenders, setSelectedGenders] = useState([]);
    const [selectedGendersOut, setSelectedGendersOut] = useState(selectedGenders);
    const [appImage, setAppImage] = useState(null);
    const [webImage, setWebImage] = useState(null);
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <img src={DownArrowSvg} width={24} height={24} alt="" />
          </components.DropdownIndicator>
        );
    };

    const newAdSubmit = async(data)=>{        
        setErrorMessage('');
        if(appImage===null || webImage===null){
            setErrorMessage('Please upload App and Web images');
            return;
        }
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "create_ads");
        formdata.append("team_id", team._id);
        formdata.append("title", data.name);
        formdata.append("redirect_url", data.redirect_url);
        formdata.append("gender", data.gender.join('@#'));
        formdata.append('age_start',data.age.min);
        formdata.append('age_end',data.age.max);
        formdata.append('separate_by','@#');

        var formdatasAppImage = new FormData();
        var tmpFilenameApp = generateRandomName();

        const imgFileAppObj = new File([appImage], tmpFilenameApp+'.png', { lastModified: new Date().getTime(), type: appImage.type })
        formdatasAppImage.append("files[]", imgFileAppObj);
        var requestOptionssApp = {
            method: 'PUT',
            body: formdatasAppImage,
            redirect: 'follow'
        };
        const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionssApp);
        const resultsApp = await responsesApp.text();
        const rdatasApp = JSON.parse(resultsApp);
        formdata.append('content',rdatasApp.data[0]);

        var formdatasWebImage = new FormData();
        var tmpFilenameWeb = generateRandomName();

        const imgFileWebbObj = new File([webImage], tmpFilenameWeb+'.png', { lastModified: new Date().getTime(), type: webImage.type })
        formdatasWebImage.append("files[]", imgFileWebbObj);
        var requestOptionssWeb = {
            method: 'PUT',
            body: formdatasWebImage,
            redirect: 'follow'
        };
        const responsesWeb = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionssWeb);
        const resultsWeb = await responsesWeb.text();
        const rdatasWeb = JSON.parse(resultsWeb);
        formdata.append('web_content',rdatasWeb.data[0]);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_ads' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                setShowForm(false);
                refreshAds();
            }
        })
        .catch(error => console.log('error', error));
    }

    const updateLinkSubmit = async(data)=>{
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "update_ads");
        formdata.append("team_id", team._id);
        formdata.append("ads_id", aditem._id);
        formdata.append("title", data.name);
        formdata.append("redirect_url", data.redirect_url);
        formdata.append("gender", data.gender.join('@#'));
        formdata.append('age_start',data.age.min);
        formdata.append('age_end',data.age.max);
        formdata.append('separate_by','@#');
        
        if(appImage!==null){
            var formdatasAppImage = new FormData();
            var tmpFilenameApp = generateRandomName();

            const imgFileAppObj = new File([appImage], tmpFilenameApp+'.png', { lastModified: new Date().getTime(), type: appImage.type })
            formdatasAppImage.append("files[]", imgFileAppObj);
            var requestOptionssApp = {
                method: 'PUT',
                body: formdatasAppImage,
                redirect: 'follow'
            };
            const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionssApp);
            const resultsApp = await responsesApp.text();
            const rdatasApp = JSON.parse(resultsApp);
            formdata.append('content',rdatasApp.data[0]);
        }
        if(webImage!==null){
            var formdatasWebImage = new FormData();
            var tmpFilenameWeb = generateRandomName();

            const imgFileWebbObj = new File([webImage], tmpFilenameWeb+'.png', { lastModified: new Date().getTime(), type: webImage.type })
            formdatasWebImage.append("files[]", imgFileWebbObj);
            var requestOptionssWeb = {
                method: 'PUT',
                body: formdatasWebImage,
                redirect: 'follow'
            };
            const responsesWeb = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionssWeb);
            const resultsWeb = await responsesWeb.text();
            const rdatasWeb = JSON.parse(resultsWeb);
            formdata.append('web_content',rdatasWeb.data[0]);
        }
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_ads' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                setShowForm(false);
                refreshAds();
            }
        })
        .catch(error => console.log('error', error));    
    }
    
    const onSubmit = async(data) => {
        if(isNew){
            newAdSubmit(data);
        }
        else{
            updateLinkSubmit(data);
        }
    }

    function handleSelectChange(event) {
        const current = [...event];
        var current_gender = current.map((gender) => {
            return gender.value
        });
        setSelectedGenders(current_gender);
        setSelectedGendersOut(event);        
    }

    useEffect(() => {
        let gOptions = [];
        gOptions.push({'value':'Male', 'label':'Male'});
        gOptions.push({'value':'Female', 'label':'Female'});
        gOptions.push({'value':'Prefer Not To Say', 'label':'Prefer Not To Say'});
        setGenderOpt(gOptions);
        if(!isNew){
            console.log(aditem.gender);
            setValue('name',aditem.title);
            setValue('redirect_url', aditem.redirect_url);
            let eOptions = [];
            aditem.gender.forEach(element => {
                eOptions.push({'value':element, 'label':element});
            });            
            setSelectedGendersOut(eOptions);
            setValue('gender', aditem.gender);
            setValue('age', {min:aditem.age_start, max:aditem.age_end});
        }
    },[]);

    return (
        !loading ?
        <>
            <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
            <div className="form-heading text-center mb-[30px]">{isNew ? 'Create Ad' : 'Edit Ad'}</div>
            <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper ads-form-wrapper">                
                <div className="flex flex-wrap">
                    <div className="w-full">
                        <div className='form-field-wrap mb-5 no-field-wrap relative'>
                            <input 
                                type="text" id="ad-name" disabled={loading} placeholder="Name *" className='text-field'
                                {...register("name")}
                                aria-invalid={errors.name ? "true" : "false"} 
                            />                                
                        </div>
                        {errors.name && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.name?.message}</p>}
                        <div className='form-field-wrap mb-5 no-field-wrap relative'>
                            <input 
                                type="text" id="ad-url" disabled={loading} placeholder="URL *" className='text-field'
                                {...register("redirect_url")}
                                aria-invalid={errors.redirect_url ? "true" : "false"} 
                            />                                
                        </div>
                        {errors.redirect_url && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.redirect_url?.message}</p>}
                        <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                            <Controller
                                control={control}
                                name='gender'
                                render={({ field: { onChange, value, ref }}) => (
                                    <Select
                                        inputRef={ref}
                                        value={selectedGendersOut}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        components={{ DropdownIndicator }}
                                        placeholder={<div>Select Gender*</div>}
                                        onChange={val => {
                                            onChange(val.map(c => c.value));
                                            handleSelectChange(val);
                                        }}
                                        options={genderopt}
                                        isMulti
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderWidth: 0,
                                                backgroundColor: 'rgba(239, 241, 249, 0.6)',
                                                padding: '6px 7px 6px 7px'
                                            }),
                                            menu: (baseStyles) => ({
                                                ...baseStyles,
                                                zIndex:1000,
                                                backgroundColor: '#ffffff'
                                            })
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,                                                
                                        })}
                                    />
                                )}
                            />
                        </div>
                        {errors.gender?.message && <p className="text-red-500 mb-2.5 text-center" role="alert">{errors.gender?.message}</p>}
                        <div className='form-field-wrap mb-5 no-field-wrap relative'>
                            <label>Age</label>
                            <Controller
                                control={control}
                                name="age"
                                render={({ field: { onChange, onBlur, value, name, ref },
                                    fieldState: { isTouched, isDirty, error },                                    
                                }) => <MultiRangeSlider 
                                min={0} 
                                max={100} 
                                defvalue={!isNew ? {min:aditem.age_start, max:aditem.age_end} : {min:0, max:100}}
                                onChange={onChange} />}
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 mb-5 pl-2">
                        <PlaiImageCropper editImage={!isNew && aditem.images ? aditem.images : null} default_label={'Upload App Ads Image*'} default_message={'Maximum upload file size: 2MB<br/>(1200 X 600)'} onFinalChange={(newImage)=>{setAppImage(newImage)}} aspect={2 / 1}/>
                    </div>
                    <div className="w-full md:w-1/2 mb-5 pl-2">
                        <PlaiImageCropper editImage={!isNew && aditem.web_images ? aditem.web_images : null} default_label={'Upload Web Ads Image*'} default_message={'Maximum upload file size: 2MB<br/>(1200 X 300)'} onFinalChange={(newImage)=>{setWebImage(newImage)}} aspect={4 / 1}/>
                    </div>
                </div>
                {!loading && errorMessage!=='' ? <p className='w-full error text-center mb-2.5'>{errorMessage}</p> : null}                
                <div className="w-full px-2.5">
                    <div className='flex justify-center'>
                        <input className='button button--large' type="submit" value={isNew ? 'Create' : 'Update'} disabled={loading}/>
                    </div>
                </div>
            </form>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default AdForm;