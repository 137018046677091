import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TeamContext } from "../../../contexts/TeamContext";
import redirect from '../../../assets/images/connect_stripe.png';
import Spinner from "../../../components/Spinner/Spinner";
import { getReferralTeams } from "../../../libs/dataFunctions";
import ReferralCard from "./ReferralCard";

const AffiliateDashboard=()=>{
    const { team, token } = useContext(TeamContext);
    const [affLoading,setAffLoading] = useState(false);
    const [referralTeams, setReferralTeams] = useState([]);
    const [apiMessage, setApiMessage] = useState(null);

    const refreshAffiliatesData=async()=>{
        setAffLoading(true);        
        const [referralTeamData] = await Promise.all([
            getReferralTeams(token, team._id)
        ]);
        setReferralTeams(referralTeamData.data);
        setApiMessage(referralTeamData.message);
        setAffLoading(false);
    }
    useEffect(()=>{
        refreshAffiliatesData();
    },[]);
    return(
        !affLoading ?
        <>
            <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center">
                <div className="title-col mb-2.5 md:mb-0">Affiliate Dashbaord</div>                
            </div>
            <div className="px-2.5 sm:px-7.5 pt-10 text-[14px] leading-[18px]">
                <p className="mb-1">With your unique 6 digit code - your team gets paid...</p>
                <ul className="list-disc pl-5">
                    <li className="mb-1"><strong>£10</strong> on TEAM CREATION once TEN MEMBERS join the new team referral.</li>
                    <li className="mb-1"><strong>£15</strong> once the first transaction from the new team is successfully processed.</li>
                    <li className="mb-1"><strong>£125</strong> of the first revenue PLAI receive from the new club.</li>
                </ul>
            </div>
            {referralTeams && referralTeams.length>0 ?
                <div className="px-2.5 sm:px-7.5 py-10">
                    <div className="grid md:grid-cols-2 lg:gird-cols-3 xl:grid-cols-4 gap-5">
                        {referralTeams.map((referralTeam, index)=>{
                            return(
                                <ReferralCard referralTeam={referralTeam} key={index} />
                            );
                        })}
                    </div>
                </div>
                :
                <>
                <div className="empty text-center">
                    <div className="image"><img src={redirect} alt="Plaisport" width={228} /></div>
                    <div className="description">{apiMessage}</div>
                </div>
                </>
            }
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}
export default AffiliateDashboard;