import { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Select, { components } from "react-select";
import * as yup from 'yup';
import Spinner from "../../../components/Spinner/Spinner";
import 'rsuite/dist/rsuite-no-reset.min.css';
import CheckTreePicker from 'rsuite/CheckTreePicker';
import { getGroupsV2, getUserAsignedGroup, getLinkIcons } from "../../../libs/dataFunctions";
import DownArrowSvg from "../../../assets/images/icon-down.svg";
import LinkIconInputsvg from "../../../assets/images/icon-linkicon.svg";
import PlaiImageCropper from "../../../components/ImageCropper/ImageCropper";
import { generateRandomName } from "../../../components/ImageCropper/cropUtils";

const schema = yup.object({
    name: yup.string().required('Link name is required.'),
    /*linkurl: yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct URL!'
    ).required('Link URL is required.'),*/
    linkurl: yup.string().required('Link URL is required.'),
    groups: yup
    .array()
    .min(1, "At least one group must be selected")
    .nullable()
    .required("At least one group must be selected"),
    image: yup.string().required('Select Link Icon.'),
}).required();

const LinkForm = ({team, isNew, linkitem, setShowForm, refreshLinks}) =>{
    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');

    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]); //For preselect on edit form
    const [linkIcons, setLinkIcons] = useState([]);
    const [selectedLinkIcons, setSelectedLinkIcons] = useState([]);
    const [selectedLinkIconsOut, setSelectedLinkIconsOut] = useState(selectedLinkIcons);
    const [imageFile, setImage] = useState("");
    const [disabledGroupsOpt, setDisableGroupsOpt]= useState([]);

    const contentRef= useRef();
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const newLinkSubmit = async(data)=>{        
        setErrorMessage('');
        /*if (selectedLinkIconsOut.label === 'Sponsor' && imageFile==='') {
            setErrorMessage('Please upload sponsor image.');
            return;
        }*/
        setLoading(true);        
        var formdata = new FormData();
        formdata.append("type", "create_link");
        formdata.append("team_id", team._id);
        formdata.append("name", data.name);
        formdata.append("link", data.linkurl);
        formdata.append("showLink", data.showLink);
        formdata.append("group_ids", selectedGroups.join('@#'));
        formdata.append("image", data.image);        
        formdata.append('separate_by','@#');
        if (imageFile !== '') {
            var formdatas3 = new FormData();
            let tmpFilename = generateRandomName();
            const imgFileObj = new File([imageFile], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: imageFile.type })
            formdatas3.append("files[]", imgFileObj);
            var requestOptionss3 = {
                method: 'PUT',
                body: formdatas3,
                redirect: 'follow'
            };
            const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
            const resultsApp = await responsesApp.text();
            const rdatasApp = JSON.parse(resultsApp);
            formdata.append('content',rdatasApp.data[0]);
        }
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_links' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                setShowForm(false);
                refreshLinks();
            }
        })
        .catch(error => console.log('error', error));
    }

    const updateLinkSubmit = async(data)=>{
        setErrorMessage('');
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "update_link");
        formdata.append("team_id", team._id);
        formdata.append("name", data.name);
        formdata.append("link", data.linkurl);
        formdata.append("showLink", data.showLink);
        formdata.append("group_ids", selectedGroups.join('@#'));
        formdata.append("image", data.image);
        formdata.append("link_id", linkitem._id);
        formdata.append('separate_by','@#');

        if (imageFile !== '') {
            var formdatas3 = new FormData();
            let tmpFilename = generateRandomName();
            const imgFileObj = new File([imageFile], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: imageFile.type })
            formdatas3.append("files[]", imgFileObj);
            var requestOptionss3 = {
                method: 'PUT',
                body: formdatas3,
                redirect: 'follow'
            };
            const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
            const resultsApp = await responsesApp.text();
            const rdatasApp = JSON.parse(resultsApp);
            formdata.append('content',rdatasApp.data[0]);
        }

        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_links' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                setShowForm(false);
                refreshLinks();
            }
        })
        .catch(error => console.log('error', error));       
    }
    
    const onSubmit = async(data) => {
        if(isNew){
            newLinkSubmit(data);
        }
        else{
            updateLinkSubmit(data);
        }
    }

    const refreshGroups=async()=>{
        setLoading(true);
        const [groupsData, linkIconsData] = await Promise.all([
            getGroupsV2(team._id, token),
            getLinkIcons(token),
        ]); 

        let groupOptions = [];
        groupsData.forEach(element => {
            let childOptions = [];
            if(element.nest_groups.length > 0){
                element.nest_groups.forEach(celm =>{
                    childOptions.push({'value':celm._id, 'label':celm.name});
                });
                groupOptions.push({'value':element._id, 'label':element.name, 'children':childOptions});
            }
            else{
                groupOptions.push({'value':element._id, 'label':element.name});
            }
        });

        setGroups(groupOptions);
        let linkOptions = [];
        linkIconsData.forEach(element => {
            linkOptions.push({'value':element.key, 'label':element.name, 'icon':element.value});
        }); 
        setLinkIcons(linkOptions);        
        setLoading(false);      
    }

    const refreshGroupsEdit=async()=>{
        setLoading(true);
        const [groupsData, linkIconsData] = await Promise.all([
            getUserAsignedGroup(token, team._id, linkitem._id, 'link'),
            getLinkIcons(token),
        ]); 

        let disableGOpt=[];
        let groupOptions = [];
        groupsData.forEach(element => {
            let childOptions = [];
            if(!element.canSelect){
                disableGOpt.push(element._id);
            }
            if(element.nest_groups.length > 0){
                element.nest_groups.forEach(celm =>{
                    childOptions.push({'value':celm._id, 'label':celm.name});
                    if(!celm.canSelect){
                        disableGOpt.push(celm._id);
                    }
                });
                groupOptions.push({'value':element._id, 'label':element.name, 'children':childOptions});                
            }
            else{
                groupOptions.push({'value':element._id, 'label':element.name});
            }            
        });
        setDisableGroupsOpt(disableGOpt);
        setGroups(groupOptions);
        let linkOptions = [];
        linkIconsData.forEach(element => {
            linkOptions.push({'value':element.key, 'label':element.name, 'icon':element.value});
        }); 
        setLinkIcons(linkOptions);        
        setLoading(false);      
    }

    const { Option, SingleValue } = components;
    const IconOption = props => (
        <Option {...props}>
            <div className="flex items-center">
                <img
                src={props.data.icon}
                className="mr-2.5 w-[30px]"
                alt={props.data.label}
                />
                {props.data.label}
            </div>
        </Option>
    );
    const SingleValueOption = (props) => ( 
        <SingleValue {...props}> 
            <div className="flex items-center">
                <img
                src={props.data.icon}
                className="mr-2.5 w-[30px]"
                alt={props.data.label}
                />
                {props.data.label}
            </div>
        </SingleValue> 
    );
    const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <img src={DownArrowSvg} width={24} height={24} alt="" />
          </components.DropdownIndicator>
        );
    };
    
    function handleSelectChangeIcon(current){
        setSelectedLinkIcons(current.value);
        setSelectedLinkIconsOut(current);        
    }
    useEffect(() => {        
        if(isNew){
            refreshGroups();
            setValue('showLink', 'Y');
        }
        else{
            refreshGroupsEdit();
            setValue('showLink', linkitem.showLink ? 'Y':'N');
            setValue('name', linkitem.name);
            setValue('image', linkitem.link_icon.key);
            setValue('linkurl', linkitem.link);
            let elinkOptions = {};
            elinkOptions.value=linkitem.link_icon.key;
            elinkOptions.label=linkitem.link_icon.name;
            elinkOptions.icon=linkitem.link_icon.value;
            
            setSelectedLinkIcons(linkitem.link_icon.key);
            setSelectedLinkIconsOut(elinkOptions);
            setValue('groups', linkitem.group_ids);
            setSelectedGroups(linkitem.group_ids);
        }
    },[]);
    
    return (
        !loading ?
        <>
            <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
            <div className="form-heading text-center mb-[30px]">{isNew ? 'Add Link' : 'Edit Link'}</div>
            <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">                
                <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2 md:px-2.5">
                        <div className='form-field-wrap mb-5 user-field-wrap relative'>
                            <input 
                                type="text" id="link-name" disabled={loading} placeholder="Link name *" className='text-field'
                                {...register("name")}
                                aria-invalid={errors.name ? "true" : "false"} 
                            />                                
                        </div>
                        {errors.name && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.name?.message}</p>}
                        <div className='form-field-wrap mb-5 select-field-wrap icons-field-wrap relative'>
                            <Controller
                                control={control}
                                name='image'
                                render={({ field: { onChange, value, ref }}) => (
                                    <Select
                                        inputRef={ref}
                                        value={selectedLinkIconsOut}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        components={{ DropdownIndicator, Option: IconOption, SingleValue: SingleValueOption }}
                                        placeholder={<div className="flex items-center"><img src={LinkIconInputsvg} className="mr-2.5" width={24} alt=""/>Select Icon*</div>}
                                        onChange={val => {
                                            onChange(val.value);
                                            handleSelectChangeIcon(val);
                                        }}
                                        options={linkIcons}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderWidth: 0,
                                                backgroundColor: 'rgba(239, 241, 249, 0.6)',
                                                padding: '6px 7px 6px 7px'
                                            }),
                                            menu: (baseStyles) => ({
                                                ...baseStyles,
                                                zIndex:1000,
                                                backgroundColor: '#ffffff'
                                            })
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,                                                
                                        })}
                                    />
                                )}
                            />
                        </div>
                        {errors.image && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.image?.message}</p>}
                        <div className="form-field-wrap button-group-wrap mb-5 flex justify-between items-center">
                            <div className="flex-1 button-grop-label">Show URL</div>
                            <div className="r-button-group w-[148px] flex">
                                <div className="mr-4">
                                    <input type="radio" {...register("showLink")} value="N" id="showlink-no" className="peer hidden" />
                                    <label htmlFor="showlink-no" className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black">
                                        No
                                    </label>
                                </div>
                                <div>
                                    <input type="radio" {...register("showLink")} value="Y" id="showlink-yes" className="peer hidden" />
                                    <label htmlFor="showlink-yes" className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black">
                                        Yes
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <div className="w-full md:w-1/2 md:px-2.5">
                        <div className='form-field-wrap mb-5 user-field-wrap relative'>
                            <input 
                                type="text" id="link-url" disabled={loading} placeholder="URL *" className='text-field'
                                {...register("linkurl")}
                                aria-invalid={errors.linkurl ? "true" : "false"} 
                            />                                
                        </div>
                        {errors.linkurl && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.name?.message}</p>}
                        <div className='form-field-wrap mb-5 select-field-wrap groups-field-wrap relative' ref={contentRef}>
                            <Controller
                                control={control}
                                name='groups'
                                render={({ field: { onChange, value, ref }}) => (
                                    <>
                                    <CheckTreePicker
                                        defaultExpandAll
                                        placeholder="Select Groups*"
                                        data={groups}
                                        onChange={val => {
                                            onChange(val.map(c => c.value));
                                            setValue('groups',val);
                                            setSelectedGroups(val);
                                            //handleSelectChange(val);
                                        }}
                                        disabledItemValues={disabledGroupsOpt}
                                        cleanable={false}
                                        container={()=>contentRef.current}
                                        defaultValue={selectedGroups}
                                        value={selectedGroups}
                                        style={{
                                            width:'100%',
                                            maxWidth:'500px',
                                            borderWidth:0,
                                            backgroundColor: '#EFF1F9',
                                            padding: '8px 7px 8px 46px',
                                            height: '52px',
                                            borderRadius: 8
                                        }}
                                        onSelect={(item,val,event)=>{
                                                if(item.label.toLowerCase()==="all members"){
                                                    if(item.check){
                                                        let groupIds=[];
                                                        groups.forEach((item)=>{
                                                            groupIds.push(item.value);
                                                        });
                                                        setSelectedGroups(groupIds);
                                                    }
                                                    else{
                                                        setSelectedGroups([]);
                                                    }
                                                }
                                            }
                                        }
                                    />                                    
                                    </>                                    
                                )}
                            />
                            <p className="mt-1 uppercase font-medium" style={{ fontSize: '12px', lineHeight: '18px', color: "#6E7079"}}>
                                {selectedGroups.length===0 ? 
                                        'NO GROUP SELECTED.':
                                (selectedGroups.length > 1) ? selectedGroups.length + ' GROUPS SELECTED' : selectedGroups.length + ' GROUP SELECTED'}
                            </p>
                        </div>
                        {errors.groups?.message && <p className="text-red-500 mb-2.5 text-center" role="alert">{errors.groups?.message}</p> }
                    </div>
                    {selectedLinkIconsOut && selectedLinkIconsOut.label === 'Sponsor' &&
                        <div className="w-full md:w-1/2 md:px-2.5">
                            <div className='form-field-wrap mb-5 relative'>
                            <PlaiImageCropper default_label={'Select file'} default_message={'Maximum upload file size: 2MB<br/>(500 X 500)'} editImage={(!isNew && linkitem.sponsor_image!==undefined && linkitem.sponsor_image !== "") ? linkitem.sponsor_image : ''} onFinalChange={(newImage)=>{setImage(newImage)}} aspect={1 / 1}/>
                            </div>
                        </div>
                    }
                </div>
                {!loading && errorMessage!=='' ? <p className='w-full error text-center mb-2.5'>{errorMessage}</p> : null}                
                <div className="w-full px-2.5">
                    <div className='flex justify-center'>
                        <input className='button button--large' type="submit" value={isNew ? 'Add Link' : 'Update Link'} disabled={loading}/>
                    </div>
                </div>
            </form>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default LinkForm;