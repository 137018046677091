import { useState } from "react";
import './comments.scss';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import CommentRow from "./CommentRow";
dayjs.extend(utc);
dayjs.extend(tz);


const Comments=({element,team,type,commentList,commentsEnabled, refreshData})=>{
    const [loading, setLoading] = useState(false);
    const [commentText,setCommentText] = useState('');
    const [error,setError] = useState('');
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    const handleCommentSubmit=async(e)=>{
        e.preventDefault();
        setLoading(true);
        if(commentText!==""){
            let formdata = new FormData();
            formdata.append('type', 'add_comment');
            formdata.append('team_id', team._id);
            formdata.append('comment',commentText);
            
            //Make change here based on type
            if(type==='news'){
                formdata.append('news_id', element._id);
            }
            else if(type==='event'){                
                formdata.append('event_id', element._id);
                formdata.append('timezone',timeZone);
            }
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            var apipoint='';
            if(type==='news'){
                apipoint = process.env.REACT_APP_APIURL + '/app_news';
            }
            else if(type==='event'){
                apipoint = process.env.REACT_APP_APIURL + '/app_event';
            }
            await fetch(apipoint , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){                
                }
                else{                    
                    refreshData();
                }
            })
            .catch(error => console.log('error', error));
        }
        else{
            setError("Please add comment");
        }
    }
    return(
        
        <>
            {commentsEnabled &&
            <>
                <div className="button button-blue inline-block button-medium mb-8">Comments</div>
                {commentList && commentList.length > 0 &&
                    commentList.map((comment,index)=>{
                        return(
                            <CommentRow type={type} team={team} element={element} comment={comment} key={comment.comment_id} refreshData={refreshData} />
                        )
                    })
                }
                <div className="comment-form-wrap">
                    {!loading? 
                    <form className="comment-form flex justify-center mx-auto" onSubmit={(e)=>{handleCommentSubmit(e)}}>
                        <input type="text" className="w-full flex-1" name="comment" placeholder="Add a comment..." onChange={(e)=>{setCommentText(e.target.value)}} />
                        <button type="submit" className="comment-submit-btn"><span>Submit</span></button>
                    </form>
                    :
                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={50} height={50} alt="" className="mx-auto" />
                    }
                </div>
            </>
            }
        </>        
    );
}
export default Comments;