import { useState, useEffect } from "react";
import { getWalletStatement } from "../../libs/dataFunctions";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import Spinner from "../Spinner/Spinner";
dayjs.extend(utc);
dayjs.extend(tz);


const WalletStatement=({setShowForm})=>{
    const [loading, setLoading] = useState(false);
    const [walletStatement, setWalletStatement] = useState([]);
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const fetchWalletStatement=async()=>{
        setLoading(true);
        const [mywalletData] = await Promise.all([
            getWalletStatement(token),
        ]);
        if(mywalletData.data && mywalletData.data.length > 0){
            setWalletStatement(mywalletData.data);            
        }
        setLoading(false);
    }

    useEffect(()=>{
        fetchWalletStatement();
    },[]);

    return(
        <>
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-wallet relative">
                <div className="form-heading text-center mb-[30px]">Wallet Statement</div>
                {!loading ?
                <>
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    {walletStatement.length>0 ?
                    <>
                        {walletStatement.map((item)=>{
                            return(
                                <div key={item._id} className="border-b border-black border-opacity-50 sm:flex sm:items-center sm:justify-between sm:gap-5 pb-5 mb-5">
                                    <div className="flex-1">
                                        <h4 className="text-lg font-bold text-black">{item.title}</h4>
                                        {item.club_name!=="" &&
                                            <div className="text-sm text-black">{item.club_name}</div>
                                        }
                                        <div className="text-sm text-black">{dayjs(new Date(item.created_at)).format('DD MMM YYYY')}</div>
                                    </div>
                                    <div className={`w-[100px] text-left sm:text-right text-lg font-bold ${item.is_deposit ? 'text-green-500' : 'text-red-500' }`}>
                                        {!item.is_deposit ? '-' : ''} {item.currency_symbol}{item.amount}
                                    </div>
                                </div>
                            )
                        })}
                    </>
                    :
                    <><div className="text-center">Wallet Statement Data Not Found.</div></>
                    }
                </>
                :
                <>
                    <div className="py-5 text-center"><Spinner /></div>
                </>
                }
            </div>
        </div>
        </>
    )
}

export default WalletStatement;