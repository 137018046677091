import { useState, useRef } from "react";
import { withTimeout } from "../../../libs/utils";
import { useSelector, useDispatch } from "react-redux";
import { newMessageAction } from "../../../Redux/activeChatSlice";
import { updateConversationAction, addConversationListAction } from "../../../Redux/conversationListSlice";

const ChatSendForm = ({socket, team}) =>{
    const conversationList = useSelector(state=>state.conversationList);
    const activeChat = useSelector(state=>state.activeChat);
    const activeChatSource = activeChat.activeChatSource;
    const dispatch = useDispatch();

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const hiddenFileInput = useRef(null);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
        ? localStorage.getItem('auth_token')
        : '';

    const handleSendMessage = async(e) => {
        e.preventDefault();
        if(message!==''){
            setLoading(true);
            var formdata = new FormData();
            formdata.append("type", "send_message");
            formdata.append("team_id", team._id);
            formdata.append("conversation_id", activeChatSource.conversation_id);
            formdata.append("message_type", "text");
            formdata.append("message", message);

            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
                .then(response => response.text())
                .then(result => {
                    let rdata = JSON.parse(result);
                    setLoading(false);
                    if(rdata.status===1){
                        dispatch(newMessageAction(rdata.data));
                        var foundflag = false;
                        foundflag = conversationList.some(obj=>obj.conversation_id === activeChatSource.conversation_id);
                        
                        if(foundflag){
                            let updateConversationList = [...conversationList];
                            let foundConversation = updateConversationList.filter(obj => {
                                return obj.conversation_id === activeChatSource.conversation_id;
                            })[0];

                            let updateConversation = {...foundConversation};
                            updateConversation.last_message_type = rdata.data.message_type;
                            updateConversation.last_message_conversation_details_id = rdata.data.conversation_details_id;
                            updateConversation.last_message_date = rdata.data.created_at;
                            updateConversation.last_message_read = false;
                            updateConversation.last_message_send_by_me = true;
                            updateConversation.last_message = rdata.data.message;
                            
                            dispatch(updateConversationAction(updateConversation));
                        }
                        else{
                            let updateConversation = {...activeChatSource};
                            updateConversation.last_message_type = rdata.data.message_type;
                            updateConversation.last_message_conversation_details_id = rdata.data.conversation_details_id;
                            updateConversation.last_message_date = rdata.data.created_at;
                            updateConversation.last_message_read = false;
                            updateConversation.last_message_send_by_me = true;
                            updateConversation.last_message = rdata.data.message;

                            dispatch(addConversationListAction(updateConversation));
                        }

                        socket.emit('send_message', JSON.stringify({"conversation_details_id":rdata.data.conversation_details_id}), withTimeout((response) => {
                            console.log("success!" + response);
                          }, (response) => {
                            console.log(response);
                          }, 1500));
                    }
                 })
                .catch(error => console.log('error', error));
        }
        setMessage('');
    };

    const handleClick = (e) => {
        hiddenFileInput.current.click();   
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
            //const extension = reader.result.split(';')[0].split('/')[1];            
            resolve(base64String);
        }
        reader.onerror = error => reject(error);
    });

    const handleChange = async(e) => {
        if(e.target.files.length > 0){
            const fileUploaded = e.target.files[0];
            var filetype = '';
            if(fileUploaded.type.indexOf('audio')!==-1){
                filetype = 'audio';
            }
            else if(fileUploaded.type.indexOf('image')!==-1){
                filetype = 'image';
            }

            const extension = fileUploaded.name.split('.').pop();
            
            const fileBase64 = await toBase64(fileUploaded);

            if(filetype!==''){
                setLoading(true);
                var formdata = new FormData();
                formdata.append("type", "send_message");
                formdata.append("team_id", team._id);
                formdata.append("conversation_id", activeChatSource.conversation_id);
                formdata.append("message_type", filetype);
                formdata.append("content", fileBase64);
                formdata.append("extension", extension);

                var secureHeader = new Headers();
                secureHeader.append('Authorization','Bearer ' + token);
                secureHeader.append('device_type','W');
                const requestOptions = {
                    method: 'POST',
                    redirect: 'follow',
                    body: formdata,
                    headers: secureHeader
                };

                await fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        let rdata = JSON.parse(result);
                        setLoading(false);
                        if(rdata.status===1){
                            dispatch(newMessageAction(rdata.data));
                            var foundflag = false;
                            foundflag = conversationList.some(obj=>obj.conversation_id === activeChatSource.conversation_id);
                            
                            if(foundflag){
                                let updateConversationList = [...conversationList];
                                let foundConversation = updateConversationList.filter(obj => {
                                    return obj.conversation_id === activeChatSource.conversation_id;
                                })[0];

                                let updateConversation = {...foundConversation};
                                updateConversation.last_message_type = rdata.data.message_type;
                                updateConversation.last_message_conversation_details_id = rdata.data.conversation_details_id;
                                updateConversation.last_message_date = rdata.data.created_at;
                                updateConversation.last_message_read = false;
                                updateConversation.last_message_send_by_me = true;
                                updateConversation.last_message = rdata.data.message;

                                dispatch(updateConversationAction(updateConversation));
                            }
                            else{
                                let updateConversation = {...activeChatSource};
                                updateConversation.last_message_type = rdata.data.message_type;
                                updateConversation.last_message_conversation_details_id = rdata.data.conversation_details_id;
                                updateConversation.last_message_date = rdata.data.created_at;
                                updateConversation.last_message_read = false;
                                updateConversation.last_message_send_by_me = true;
                                updateConversation.last_message = rdata.data.message;

                                dispatch(addConversationListAction(updateConversation));
                            }

                            socket.emit('send_message', JSON.stringify({"conversation_details_id":rdata.data.conversation_details_id}), withTimeout((response) => {
                                console.log("success!" + response);
                              }, (response) => {
                                console.log(response);
                              }, 1500));
                        }
                        })
                    .catch(error => console.log('error', error));
            }
            else{
                alert('Invalid File Type');
            }
        }
    };
    
    return(
        <>
        <div className="chatboard-form">
            {Object.keys(activeChatSource).length !==0 && !activeChatSource.is_leave_from_group &&
                <form className="flex w-full items-center" onSubmit={handleSendMessage}>
                    <div className="chatboard-form-attachment flex items-center">
                        <button type="button" onClick={(e)=>{handleClick(e)}}>
                            <svg width="30" height="30" className="w-[15px] lg:w-[30px]" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.66998 14.3313L14.2875 5.71255C14.8956 5.09749 15.6194 4.60872 16.4171 4.27435C17.2148 3.93998 18.0707 3.7666 18.9357 3.76419C19.8006 3.76177 20.6575 3.93037 21.4571 4.26029C22.2567 4.5902 22.9831 5.07493 23.5947 5.68658C24.2063 6.29824 24.6909 7.02476 25.0207 7.82437C25.3505 8.62398 25.519 9.4809 25.5165 10.3458C25.5139 11.2108 25.3404 12.0667 25.006 12.8644C24.6715 13.6621 24.1826 14.3857 23.5675 14.9938L13.625 24.9375C12.8045 25.7581 11.6916 26.219 10.5312 26.219C9.37085 26.219 8.258 25.7581 7.43748 24.9375C6.61697 24.117 6.15601 23.0042 6.15601 21.8438C6.15601 20.6834 6.61697 19.5706 7.43748 18.75L17.3825 8.8063C17.7951 8.40791 18.3477 8.18753 18.9213 8.19263C19.4949 8.19773 20.0435 8.4279 20.449 8.83356C20.8545 9.23922 21.0844 9.78792 21.0893 10.3615C21.0941 10.935 20.8735 11.4876 20.475 11.9L11.8562 20.5175" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                        <input 
                            type="file"
                            onChange={(e)=>{handleChange(e)}}
                            ref={hiddenFileInput}
                            style={{display:'none'}}
                            accept="image/*, .mp3, .wav, .ogg"
                        />
                    </div>
                    <div className="chatboard-form-field-wrapper flex-1">
                        <input 
                            type="text" 
                            placeholder="Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            disabled={loading}
                        />
                    </div>
                    <div className="chatboard-form-submit flex items-center">
                        {!loading ?
                        <button type="submit">
                            <svg width="36" height="36" className="w-[18px] lg:w-[36px]" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="18" cy="18" r="18" fill="#FBDB03"/>
                                <g clipPath="url(#clip0_3981_2056)">
                                    <path d="M26.5918 18.1606C26.6804 18.1198 26.7551 18.0538 26.8066 17.9708C26.858 17.8879 26.884 17.7917 26.8813 17.6942C26.8786 17.5966 26.8474 17.502 26.7914 17.4221C26.7354 17.3421 26.6572 17.2803 26.5665 17.2444L12.0428 11.4896L12.0421 11.4889L11.5913 11.3095C11.5059 11.2756 11.4128 11.2659 11.3222 11.2816C11.2317 11.2972 11.1472 11.3376 11.0782 11.3983C11.0091 11.4589 10.9582 11.5375 10.931 11.6253C10.9038 11.7131 10.9014 11.8067 10.9241 11.8957L11.0428 12.365L11.0422 12.3672L12.4819 18.092L11.3605 23.8877L11.3606 23.8905L11.2679 24.3656C11.2505 24.4557 11.2583 24.5488 11.2905 24.6347C11.3226 24.7206 11.3778 24.796 11.45 24.8526C11.5223 24.9092 11.6087 24.9447 11.6998 24.9553C11.7909 24.9659 11.8832 24.9512 11.9665 24.9127L26.5918 18.1606ZM23.969 18.2733L13.4056 18.5652L13.4815 18.173C13.4955 18.1011 13.4934 18.027 13.4755 17.956L13.3781 17.5686L23.9415 17.2767L25.1174 17.7429L23.9697 18.274L23.969 18.2733Z" fill="#363F6C"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_3981_2056">
                                        <rect width="15.9537" height="15.9537" fill="white" transform="translate(15.4997 6.72339) rotate(43.4174)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                        :
                        <>
                            <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23FBDB03' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23FBDB03' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={36} height={36} alt="" />
                        </>
                        }
                    </div>
                </form>
            }
        </div>
        </>
    );
}

export default ChatSendForm;