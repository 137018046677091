import { useState, useEffect, useContext } from 'react';
import { useParams, NavLink } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import { TeamContextProvider, TeamContext } from "../../../contexts/TeamContext";
import { getFacilities, getBookingDates, getBookingSlots, getGroupsV2, getMyPaymentCards, getWallet } from '../../../libs/dataFunctions';
import MemberWithGroupInput from '../../../components/MemberWithGroupInput/MemberWithGroupInput';
import { formatCreditCardNumber, formatExpirationDate, formatCVC, getIsUser } from '../../../libs/utils';
import CountriesList from '../../../libs/countries';
import Amex from '../../../assets/images/amex.svg';
import Discover from '../../../assets/images/discover.svg';
import DinnersClub from '../../../assets/images/dinersclub.svg';
import Jcb from '../../../assets/images/jcb.svg';
import Mastercard from '../../../assets/images/mastercard.svg';
import UnionPay from '../../../assets/images/unionpay.svg';
import Visa from '../../../assets/images/visa.svg';
import Spinner from '../../../components/Spinner/Spinner';
dayjs.extend(utc);
dayjs.extend(tz);

const CardIcon=({value})=>{
    const icons = {
        'amex':Amex,
        'mastercard':Mastercard,
        'visa':Visa,
        'discover':Discover,
        'jcb':Jcb,
        'dinersclub': DinnersClub,
        'uninpay': UnionPay,
        'elo':null,
        'hipercard':null,
        'troy':null,
        'maestro':null,
        'laser':null
    }
    if (!value) {
        return null;
    }
    
    return(
        icons[value]!==null ? <img src={icons[value]} alt="" width={24} /> : null
    )
}

const schema = yup.object({
    booking_facilities_id:yup.string().required('Select a facility.'),
    date: yup.string().required('Select a date.'),
    start_time: yup.string().required('Select a start time.'),    
}).required()

const MakeBookingForm=()=>{
    const { team, teamUpdating } = useContext(TeamContext);
    const [loading,setLoading] = useState(false);
    const [facilitiesList, setFacilitiesList] = useState([]);
    const [facilitiesOpt, setFacilitiesOpt] = useState([]);
    const [selectedFacility, setSelectedFacility] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [parentGroups,setParentGroups] = useState([]);
    const [currentBookingDatesOpt, setBookingDatesOpt] = useState([]);
    const [currentBookingSlotsOpt, setBookingSlotsOpt] = useState([]);
    const [selectedMembersList, setSelectedMembersList] = useState([]);
    const [members, setMembers] = useState([]);

    const [currentCardID,setCurrentCardID]  = useState('');
    const [paymentCards,setPaymentCards] = useState([]);
    const [cardNum, setCardNum] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardCVV, setCardCVV] = useState('');
    const [country, setCountry] = useState('');
    const [cardicon, setCardIcon] = useState(null);
    const [cardHolderName, setCardName] = useState('');

    const [walletData, setWalletData] = useState([]);
    const [isWallet, setWallet] = useState(false);
    const [walletTransactionFee, setWalletTransactionFee] = useState(0);
    const [isCardPayment, setIsCardPayment] = useState(false);

    const { register, handleSubmit, setValue, watch, control, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const fetchFacilities=async()=>{
        setLoading(true);
        const [facilitiesData, groupsData, mycardsData, myWallet] = await Promise.all([
            getFacilities(token, team._id),
            getGroupsV2(team._id, token),
            getMyPaymentCards(token),
            getWallet(token, team._id)
        ]);
        setFacilitiesList(facilitiesData.data);

        if(mycardsData.data && mycardsData.data.length > 0){
            setPaymentCards(mycardsData.data);
            mycardsData.data.forEach(element => {
                if(element.default_source){
                    setCurrentCardID(element.id);
                }
            });
        }

        let parentGroupOptions = [];
        groupsData.forEach(element => {
            parentGroupOptions.push({'value':element._id, 'label':element.name});            
        });
        setParentGroups(parentGroupOptions);

        if(facilitiesData.data.length > 0){
            let facilitiesArr = [];
            facilitiesData.data.forEach(element=>{
                facilitiesArr.push({'value':element._id, 'label':element.name});
            });
            setFacilitiesOpt(facilitiesArr);
        }
        if(myWallet.data.length >0 && myWallet.data[0].amount!=="" && myWallet.data[0].amount!=="0.00" && myWallet.data[0].amount!==0){
            setWalletData(myWallet.data);
            setWallet(true);
            setWalletTransactionFee(myWallet.wallet_transaction_fee/100);
        }
        setLoading(false);
    }

    const noPaymentForm=async(data)=>{
        setSuccessMessage(null);
        setErrorMessage(null);
        var formdata = new FormData();
        formdata.append('type','add_booking');
        formdata.append('team_id',team._id);
        formdata.append('booking_facilities_id',selectedFacility._id);
        formdata.append('date',dayjs(data.date).format('YYYY-MM-DD'));
        formdata.append('start_time',data.start_time);
        if(data.members!==undefined && data.members.length>0){
            formdata.append('joining_members',data.members.join('@#'));
        }        
        formdata.append('separate_by','@#');
        setLoading(true);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_booking_facilities' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status!==1){
                setErrorMessage(rdata.message);
            }
            else{
                window.location.href = '/team/'+team._id+'/your-bookings';
            }
        })
        .catch(error => console.log('error', error));
    }

    const withPaymentForm=async(data)=>{
        setSuccessMessage(null);
        setErrorMessage(null);
        if((!isWallet || (isWallet && isCardPayment)) && currentCardID !== ''){
            setLoading(true);
            var formdata = new FormData();
            formdata.append('type','add_booking');
            formdata.append('team_id',team._id);
            formdata.append('booking_facilities_id',selectedFacility._id);
            formdata.append('date',dayjs(data.date).format('YYYY-MM-DD'));
            formdata.append('start_time',data.start_time);
            if(data.members!==undefined && data.members.length>0){
                formdata.append('joining_members',data.members.join('@#'));
            }        
            formdata.append('separate_by','@#');
            if(!isWallet || (isWallet && isCardPayment)){
                formdata.append('card_id',currentCardID);
                if(isWallet){
                    formdata.append('use_wallet',isWallet);
                }
            }
            else{
                formdata.append('card_id',"");
                if(isWallet){
                    formdata.append('use_wallet',isWallet);
                }
            }
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_booking_facilities' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status!==1 && rdata.status!==4){
                    setErrorMessage(rdata.message);
                }
                else if(rdata.status===4){
                    window.location.href='/thankyou';
                }
                else{
                    if(rdata.data.redirct_url===undefined || rdata.data.redirct_url===''){
                        ///Go to thank you page
                        window.location.href='/thankyou';
                    }
                    else{
                        window.location.href=rdata.data.redirct_url;
                    }
                    //window.location.href = '/team/'+team._id+'/your-bookings';
                }
            })
            .catch(error => console.log('error', error));
        }
        else{
            if(cardNum!=="" && cardExpiry!=="" && cardCVV!=="" && country!==""){
                setLoading(true);
                let formdata = new FormData();
                let expiry = cardExpiry.split("/");
                let today, cardday;
                today = new Date();
                cardday = new Date();
                cardday.setFullYear(parseInt(expiry[1])+2000,expiry[0]-1,0);
                if(cardicon==='amex'){
                    if(cardNum.replace(/\D+/g, '').length<15){
                        setErrorMessage("Invalid card number");
                        if(cardCVV.length<4){
                            setErrorMessage("Invalid card number, cvc number");
                        }
                        return;
                    }
                    if(cardCVV.length<4){
                        setErrorMessage("Invalid CVC number");
                        return;
                    }
                }
                else if(cardicon==='dinersclub'){
                    if(cardNum.replace(/\D+/g, '').length<14){
                        setErrorMessage("Invalid card number");
                        return;
                    }
                }
                else{
                    if(cardNum.replace(/\D+/g, '').length<16){
                        setErrorMessage("Invalid card number");
                        return;
                    }
                }
                if (cardday < today) {
                    setErrorMessage("Invalid expiry date");
                }
                else{
                    formdata.append('type','add_card');                    
                    formdata.append('card_number',cardNum);
                    formdata.append('name',cardHolderName);
                    formdata.append('exp_year',expiry[1]);
                    formdata.append('exp_month',expiry[0]);
                    formdata.append('country',country);
                    formdata.append('cvc',cardCVV);
                    formdata.append('is_default_card','Yes');

                    var secureHeader2 = new Headers();
                    secureHeader2.append('Authorization','Bearer ' + token);
                    secureHeader2.append('device_type','W');
                    const requestOptions = {
                        method: 'POST',
                        redirect: 'follow',
                        body: formdata,
                        headers: secureHeader2
                    }
                    
                    await fetch(process.env.REACT_APP_APIURL + '/app_stripe_card' , requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        let rdata = JSON.parse(result);
                        if(rdata.status !== 1){
                            setLoading(false);
                            setErrorMessage(rdata.message);
                        }
                        else{
                            var formdata2 = new FormData();
                            formdata2.append('type','add_booking');
                            formdata2.append('team_id',team._id);
                            formdata2.append('booking_facilities_id',selectedFacility._id);
                            formdata2.append('date',dayjs(data.date).format('YYYY-MM-DD'));
                            formdata2.append('start_time',data.start_time);
                            if(data.members!==undefined && data.members.length>0){
                                formdata2.append('joining_members',data.members.join('@#'));
                            }        
                            formdata2.append('separate_by','@#');
                            formdata2.append('card_id',rdata.data[0].id);
                            formdata.append('use_wallet',isWallet);
                            setLoading(true);
                            var secureHeader = new Headers();
                            secureHeader.append('Authorization','Bearer ' + token);
                            secureHeader.append('device_type','W');
                            const requestOptions = {
                                method: 'POST',
                                redirect: 'follow',
                                body: formdata2,
                                headers: secureHeader
                            };

                            fetch(process.env.REACT_APP_APIURL + '/app_booking_facilities' , requestOptions)
                            .then(response => response.text())
                            .then(result => {
                                setLoading(false);
                                let rdata = JSON.parse(result);
                                if(rdata.status!==1 && rdata.status!==4){
                                    setErrorMessage(rdata.message);
                                }
                                else if(rdata.status===4){
                                    window.location.href='/thankyou';
                                }
                                else{
                                    //window.location.href = '/team/'+team._id+'/your-bookings';
                                    if(rdata.data.redirct_url===undefined || rdata.data.redirct_url===''){
                                        ///Go to thank you page
                                        window.location.href='/thankyou';
                                    }
                                    else{
                                        window.location.href=rdata.data.redirct_url;
                                    }
                                }
                            })
                            .catch(error => console.log('error', error));
                        }
                    })
                    .catch(error => console.log('error', error));
                }            
            }
            else{
                setErrorMessage("Please fill all fields above.");
            }
        }
    }

    const onSubmit=async(data)=>{
        if(selectedFacility.price!==""){
            withPaymentForm(data);
        }
        else{
            noPaymentForm(data);
        }
    }

    const handleFacilitySelect=async(e)=>{
        setValue('booking_facilities_id', e.target.value);
        setValue('date', "");
        setValue('start_time', "");        
        if(e.target.value!==""){
            let tmpSelectedFacility = facilitiesList.filter(item => item._id.includes(e.target.value));
            setSelectedFacility(tmpSelectedFacility[0]);            
            const [bookingsDateData] = await Promise.all([
                getBookingDates(token, team._id, e.target.value)
            ]);
            if(bookingsDateData.status===1){
                setBookingDatesOpt(bookingsDateData.data.booking_dates);
                setBookingSlotsOpt([]);
            }
            else{
                setBookingDatesOpt([]);
                setBookingSlotsOpt([]);
            }
        }
        else{
            setSelectedFacility([]);
            setBookingDatesOpt([]);
        }
    }

    const handleFacilityDate=async(e)=>{
        setValue('date', e.target.value);
        setValue('start_time', "");
        if(e.target.value!==""){
            const [bookingsSlotsData] = await Promise.all([
                getBookingSlots(token, team._id, selectedFacility._id, dayjs(e.target.value).format('YYYY-MM-DD'))
            ]);
            if(bookingsSlotsData.status===1){
                setBookingSlotsOpt(bookingsSlotsData.data.time_slots);                
            }
            else{
                setBookingSlotsOpt([]);
            }
        }
    }

    const bookingFacility = watch("booking_facilities_id");

    useEffect(()=>{
        setLoading(true);
        if(!teamUpdating){
            fetchFacilities();
        }
    },[teamUpdating]);

    useEffect(()=>{
        if(!teamUpdating){
            if(isWallet){  
                if(parseFloat(walletData[0].amount) >= (parseFloat(selectedFacility.price) + walletTransactionFee).toFixed(2)){
                    setIsCardPayment(false);
                }
                else{
                    setIsCardPayment(true);
                    /*if(parseFloat(walletData[0].amount) < (parseFloat(selectedFacility.price) + parseFloat(selectedFacility.transactionfee)).toFixed(2)){
                        setIsCardPayment(true);
                    }
                    else{
                        setIsCardPayment(false);
                    }*/
                }                
            }
        }
    },[isWallet, teamUpdating, selectedFacility]);

    return(
        <>
        {!loading ? 
            <>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}/your-bookings`}>
                        Bookings
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Make A Booking
                </div>
                <div className='body px-5 lg:px-8 py-10'>
                    <div className="rounded-xl bg-[var(--color-darkblue)] py-7.5 px-5">
                        <div className="form-heading text-center mb-[30px] !text-white">Make A Booking</div>
                        <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                            <div className="flex flex-wrap lg:mb-12">
                                <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">Select Facility*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap facility-field-wrap relative'>
                                            <select {...register("booking_facilities_id")} onChange={(e)=>{handleFacilitySelect(e)}}>
                                                <option value="">Select Facility</option>
                                                {facilitiesOpt.map((facilityOpt)=>{
                                                    return(
                                                        <option key={facilityOpt.value} value={facilityOpt.value}>{facilityOpt.label}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        {errors.booking_facilities_id && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.booking_facilities_id?.message}</p>}
                                    </div>
                                </div>
                            </div>
                            {bookingFacility!=="" &&
                            <>
                                <div className="flex flex-wrap lg:mb-12">
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">Select Date*</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                                            
                                                <select {...register("date")} onChange={(e)=>{handleFacilityDate(e)}}>
                                                    <option value="">Select Date</option>                                                    
                                                    {currentBookingDatesOpt.length>0 ?
                                                    <>
                                                        {currentBookingDatesOpt.map((dateOpt)=>{
                                                            return(
                                                                <option key={dateOpt} value={dateOpt}>{dateOpt}</option>
                                                            )
                                                        })}
                                                    </>
                                                    :
                                                    <>
                                                    <option value="">No date available</option>
                                                    </>
                                                    }
                                                </select>                                                
                                            </div>
                                            {errors.date && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.date?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">Start Time*</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap time-field-wrap relative'>
                                            
                                                <select {...register("start_time")} onChange={(e)=>{}}>
                                                    <option value="">Select Time</option>
                                                    {currentBookingSlotsOpt.length>0 ?                                      
                                                    <>
                                                        {currentBookingSlotsOpt.map((slot)=>{
                                                            return(
                                                                <option key={slot.key} value={slot.key}>{slot.value}</option>
                                                            )
                                                        })}
                                                    </>
                                                    :
                                                        <option value="">No slots available</option>
                                                    }
                                                </select>                                                
                                            </div>
                                            {errors.start_time && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.start_time?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">Duration*</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap time-field-wrap relative'>
                                                <span className='block w-full bg-[#EFF1F9] rounded-[8px] py-3.5 pr-4 pl-[45px] text-[var(--color-gray-dark)] text-base'>{selectedFacility.duration!==undefined ? selectedFacility.duration_display : ''}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap lg:mb-12">
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className='relative aspect-[390/264]'>
                                            <img src={selectedFacility.image} alt="" className='absolute w-full h-full rounded-xl object-cover' />
                                        </div>
                                    </div>
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">Who is joining you at this session?</div>
                                            <div className='form-field-wrap mb-5 relative'>
                                                <Controller
                                                    control={control}
                                                    name='members'
                                                    render={({ field: { onChange, value, ref }}) => (
                                                        <MemberWithGroupInput teamID={team._id} 
                                                            token={token} 
                                                            parentsGroups={parentGroups} 
                                                            selectedMembers={selectedMembersList} 
                                                            onChange={val=>{
                                                                onChange(val.map(c => c.sub));
                                                                setSelectedMembersList(val);
                                                            }} 
                                                            onChangeMain={setMembers} 
                                                        />
                                                    )}
                                                />
                                            </div>                                        
                                        </div>
                                        <div className="form-field-container">
                                            <div className="label">Club Booking Price*</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                                <span className='block w-full bg-[#EFF1F9] rounded-[8px] py-3.5 px-4 text-[var(--color-gray-dark)] text-base'>{(selectedFacility.price!==undefined && selectedFacility.price!=="") ?  selectedFacility.currency_symbol+selectedFacility.price : 'Free'}</span>
                                            </div>
                                        </div>
                                        {(selectedFacility.price!==undefined && selectedFacility.price!=="") &&
                                            <>
                                            {walletData.length > 0 && walletData[0].amount > 0 &&
                                                <div className='text-left p-3.5 rounded-[14px] text-white bg-[#363F6C] mb-5'>
                                                    <div className='flex justify-between gap-2.5'>
                                                        <div className='flex flex-1 gap-2.5'>
                                                            <div>
                                                                <label className="relative inline-flex items-center cursor-pointer">
                                                                    <input type="checkbox" value="" className="sr-only peer" onChange={()=>{setWallet(!isWallet)}} checked={isWallet ? true : false} />
                                                                    <div className="w-11 h-6 bg-[rgba(255,255,255,0.1)] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#FBDB09]"></div>                        
                                                                </label>
                                                            </div>
                                                            <div className="uppercase">Use PLAI Wallet</div>
                                                        </div>
                                                        <div>
                                                            <span className='text-xl font-bold'>{walletData[0].currency_symbol}{parseFloat(walletData[0].amount).toFixed(2)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {isWallet &&
                                                <>
                                                <div className='cart-detail-row cart-detail-total flex justify-between pb-5 mb-5 text-white'>
                                                    <div className='cart-detail-total-label'>PLAI Wallet</div>
                                                    <div className='cart-detail-total-value'>
                                                        <span className='text-green-500'>
                                                        {isCardPayment ?
                                                            <>
                                                                {parseFloat(walletData[0].amount) <= (parseFloat(selectedFacility.price) + parseFloat(selectedFacility.transactionfee)).toFixed(2) ?
                                                                    <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                    :
                                                                    <>-{walletData[0].currency_symbol}{(parseFloat(selectedFacility.price) + parseFloat(selectedFacility.transactionfee)).toFixed(2)}</>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {parseFloat(walletData[0].amount) <= (parseFloat(selectedFacility.price) + walletTransactionFee).toFixed(2) ?
                                                                    <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                    :
                                                                    <>-{walletData[0].currency_symbol}{(parseFloat(selectedFacility.price) + walletTransactionFee).toFixed(2)}</>
                                                                }
                                                            </>
                                                        }                                                        
                                                        </span>
                                                        {isCardPayment ?
                                                            <>
                                                            {parseFloat(walletData[0].amount) - (parseFloat(selectedFacility.price) + parseFloat(selectedFacility.transactionfee)).toFixed(2) >= 0 &&
                                                                <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - (parseFloat(selectedFacility.price) + parseFloat(selectedFacility.transactionfee))).toFixed(2)}</strong></p>
                                                            }
                                                            </>
                                                        :
                                                            <>
                                                            {parseFloat(walletData[0].amount) - (parseFloat(selectedFacility.price) + walletTransactionFee).toFixed(2) >= 0 &&
                                                                <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - (parseFloat(selectedFacility.price) + walletTransactionFee)).toFixed(2)}</strong></p>
                                                            }
                                                            </>
                                                        }                                                        
                                                    </div>
                                                </div>
                                                {isCardPayment &&
                                                    <>
                                                    <div className='cart-detail-row cart-detail-total flex justify-between pb-5 mb-5'>
                                                        <div className='cart-detail-total-label'>Remaining</div>
                                                        <div className='cart-detail-total-value'>
                                                        {parseFloat(walletData[0].amount) <= (parseFloat(selectedFacility.price) + parseFloat(selectedFacility.transactionfee)).toFixed(2) ?
                                                            <>{walletData[0].currency_symbol}{(parseFloat(selectedFacility.price) + parseFloat(selectedFacility.transactionfee)).toFixed(2)}</>
                                                            :
                                                            <>{walletData[0].currency_symbol}0.00</>
                                                        }
                                                        </div>
                                                    </div>
                                                    </>
                                                }
                                                </>
                                            }
                                            {(!isWallet || (isWallet && isCardPayment)) &&
                                                <>
                                                {paymentCards.length > 0 ? 
                                                    <>  
                                                        <label className='text-white'>Select card</label>
                                                        <div className='form-field-wrap w-full  mb-5 no-field-wrap relative'>
                                                            <select onChange={(e)=>{setCurrentCardID(e.target.value)}}>
                                                                {paymentCards.map((card) => {
                                                                    return(
                                                                        <option key={card.id} value={card.id}>****{card.last4} ({card.name})</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </>
                                                :
                                                    <div className='flex flex-wrap'>
                                                        <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                                                            <input 
                                                                placeholder="0000 0000 0000 0000" className='text-field' 
                                                                type='tel'
                                                                name='number'
                                                                maxLength='19'
                                                                required
                                                                autoComplete="off"
                                                                onChange={(e)=>{
                                                                    e.target.value = formatCreditCardNumber(e.target.value);
                                                                    setCardNum(e.target.value);
                                                                    let icon = getIsUser(e.target.value);
                                                                    setCardIcon(icon);
                                                                    setErrorMessage(null);
                                                                }}
                                                            />
                                                            <div className='absolute top-[18px] right-[20px]'>
                                                                <CardIcon value={cardicon}/>
                                                            </div>
                                                        </div>
                                                        <div className='form-field-wrap w-full sm:w-1/2 mb-5 no-field-wrap sm:pr-1 sm:pl-1 relative'>
                                                            <input 
                                                                placeholder="MM/YY" className='text-field' 
                                                                type='tel'
                                                                name='expiry'
                                                                pattern='\d\d/\d\d'
                                                                required
                                                                autoComplete="off"
                                                                onChange={(e)=>{
                                                                    e.target.value = formatExpirationDate(e.target.value);
                                                                    setCardExpiry(e.target.value);
                                                                    setErrorMessage(null);
                                                                }}
                                                            />                                
                                                        </div>
                                                        <div className='form-field-wrap w-full sm:w-1/2 mb-5 no-field-wrap sm:pl-1 relative'>
                                                            <input 
                                                                type='tel'
                                                                name='cvc'
                                                                className='text-field'
                                                                placeholder='CVC'
                                                                required
                                                                autoComplete="off"
                                                                onChange={(e)=>{
                                                                    e.target.value = formatCVC(e.target.value, {number:cardNum});
                                                                    setCardCVV(e.target.value);
                                                                    setErrorMessage(null);
                                                                }}
                                                            />                                
                                                        </div>
                                                        <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                                                            <input 
                                                                type='text'
                                                                name='name'
                                                                className='text-field'
                                                                placeholder='Card Holder Name'
                                                                required
                                                                autoComplete="off"
                                                                onChange={(e)=>{
                                                                    setCardName(e.target.value);
                                                                    setErrorMessage(null);
                                                                }}
                                                            />                                
                                                        </div>
                                                        <div className='form-field-wrap w-full mb-5 select-field-wrap country-field-wrap relative'>
                                                            <select name="country" onChange={(e)=>{setCountry(e.target.value)}}>
                                                                <option value="">Select Country *</option>
                                                                {CountriesList.map((c,cindex)=>{
                                                                    return(
                                                                        <option key={cindex} value={c.code}>{c.name}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                }
                                                </>
                                            }
                                            </>                                        
                                        }
                                    </div>
                                </div>
                                <div className='w-full flex items-end justify-center'>
                                    <input className='button button--large' type="submit" name="book" value="Book" disabled={loading}/>
                                </div>
                                <div className='flex justify-center'>
                                    {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                                    {successMessage!=='' && <p className="text-green-500 mb-2.5 text-center" role="alert">{successMessage}</p>}
                                </div>
                            </>
                            }                            
                        </form>
                    </div>                    
                </div>
            </>
            :
            <>
                <div className="py-5 text-center"><Spinner /></div>
            </>
        }
        </>
    )
}
const MakeABooking=()=>{
    const { id } = useParams();
    return(
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="">
                    <AdminNavbar heading="Your Bookings" />  
                    <MakeBookingForm />              
                </div>
            </TeamContextProvider>
        </>
    )
}

export default MakeABooking;