import { useState } from "react";
import { Link } from "react-router-dom";
import * as DOMPurify from 'dompurify';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import placeholderImg from '../../../assets/images/placeholder-transparent.png';
import truncate from "../../../libs/utils";
dayjs.extend(utc);
dayjs.extend(tz);

const DraftCard=({draftItem, refreshDrafts, team, token})=>{
    const [loading, setLoading] = useState(false);
    const newsinfo = truncate(draftItem.details, 240, '...');
    
    const publishDraft=async()=>{
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "published_draft_news");
        formdata.append("team_id", team._id);
        formdata.append("news_id", draftItem._id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_news' , requestOptions)
        .then(response => response.text())
        .then(result => {
            refreshDrafts();
        })
        .catch(error => console.log('error', error));
    }

    return(
        <>
        <div className="flex newscard relative">
            <Link className="image-wrapper block relative rounded-[14px] overflow-hidden cursor-pointer" to={'/team/'+team._id+'/news/'+draftItem._id}>
            {draftItem.images ? 
                <img src={draftItem.images} alt="" className="md:absolute object-cover w-full h-full" />
                : 
                <img src={placeholderImg} alt="" className="md:absolute object-contain w-full h-full" />
                
            }
            </Link>
            <div className="news-overview">
                <div className="news-heading">
                    <h2 className='cursor-pointer'><Link to={'/team/'+team._id+'/news/'+draftItem._id}>{draftItem.title}</Link></h2>
                    <div className="newsmeta">
                        {(draftItem?.user?.firstName!==undefined && draftItem?.user?.lastName!==undefined) &&
                            <>
                                <div className="publish-by">
                                    <span>Created </span>by {draftItem?.user?.firstName} {draftItem?.user?.lastName}
                                </div>
                            </>
                        }
                        <div className="publish-on">
                            <span>{dayjs(draftItem.date).format("DD MMM YYYY")}</span>
                        </div>                        
                    </div>
                </div>
                <div className="news-content !mb-2.5 !h-auto" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(newsinfo)}} />
                <div className="newscard-footer">
                    {!loading ?
                        <button type="button" className='button button-blue' onClick={(e)=>{publishDraft()}}>Publish</button>                  
                    :
                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
                    }
                </div>
            </div>
        </div>
        </>
    )
}

export default DraftCard;
