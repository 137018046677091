import { useState, useEffect, useContext } from "react";
import { useParams, NavLink } from "react-router-dom";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { TeamContextProvider, TeamContext } from "../../../contexts/TeamContext";
import Spinner from "../../../components/Spinner/Spinner";
import { getGeneralScoreInfo, getEventDetail } from "../../../libs/dataFunctions";
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import PlaiImageCropper from "../../../components/ImageCropper/ImageCropper";
import { generateRandomName } from "../../../components/ImageCropper/cropUtils";

const schema = yup.object({
    game_status: yup.string().required('Game Status is required.'),
    time_remaining: yup.string().required('Remaining Timing is required.'),
    home_team_name: yup.string().required('Home Team Name is required.'),
    home_team_score: yup.number().typeError('Only Numbers Allowed.').required('Home Team Score is required'),
    away_team_name: yup.string().required('Away Team Name is required.'),
    away_team_score: yup.number().typeError('Only Numbers Allowed.').required('Away Team Score is required'),
    is_there_yellow_card:yup.string().required('Select Option For Yellow Card.'),
    how_many_yellow_card:yup.string().when('is_there_yellow_card',{
        is:'Yes',
        then:yup.string().matches(/^\d+$/, 'Input must be numbers only').required('Number of yellow card is required')
    }),
    yellow_card_player_name:yup.string().when('is_there_yellow_card',{
        is:'Yes',
        then:yup.string().required('Yellow Card Player Name is required.')
    }),
    is_there_red_card:yup.string().required('Select Option For Red Card.'),
    how_many_red_card:yup.string().when('is_there_red_card',{
        is:'Yes',
        then:yup.string().matches(/^\d+$/, 'Input must be numbers only').required('Number of red card is required')
    }),
    red_card_player_name:yup.string().when('is_there_red_card',{
        is:'Yes',
        then:yup.string().required('Red Card Player Name is required.')
    }),
    is_there_substitutes:yup.string().required('Select Option For Yellow Card.'),
    key_update:yup.string().required('Key Update is required.'),
}).required();

const UpdateScoreForm=({id})=>{
    const { team, teamUpdating } = useContext(TeamContext);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [gameStatus, setGameStatus] = useState([]);
    const [gameTime, setGameTime] = useState([]);
    const [homeLogo, setHomeLogoImage] = useState(null);
    const [awayLogo, setAwayLogoImage] = useState(null);
    const [scoreDetails,setScoreDetails] = useState({});

    const [homeLogoErr, setHomeLogoError] = useState(null);
    const [awayLogoErr, setAwayLogoError] = useState(null);


    const [charCount, setCharCount] = useState(0);
    const [charCount2, setCharCount2] = useState(0);
    const maxLength = 30;

    const defaultSubstitutes = {
        substitutes: [
        {
            substitute_off: "",
            substitute_on: "",
        },
        ],
    };

    const { register, handleSubmit, setValue, getValues, watch, control, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultSubstitutes
    });

    const { fields, append, remove, update } = useFieldArray({
        control,
        name: "substitutes",
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const fetchGameScoreInfo=async()=>{
        setLoading(true);
        const [gameScoreData, eventData] = await Promise.all([
            getGeneralScoreInfo(token),
            getEventDetail(id, team._id, token)
        ]);
        setGameStatus(gameScoreData.data.game_status);
        setGameTime(gameScoreData.data.game_time);
        setScoreDetails(eventData.score_details);
        setLoading(false);
        if(Object.keys(eventData.score_details).length!==0){
            setValue('game_status',eventData.score_details.game_status);
            setValue('time_remaining',JSON.stringify({time:eventData?.score_details?.time_remaining?.time, display_time:eventData?.score_details?.time_remaining?.display_time}));
            setValue('home_team_name',eventData?.score_details?.home_team_name);
            setValue('home_team_score',eventData?.score_details?.home_team_score);
            setValue('away_team_name',eventData?.score_details?.away_team_name);
            setValue('away_team_score',eventData?.score_details?.away_team_score);
            setValue('is_there_yellow_card',eventData?.score_details?.is_there_yellow_card ? 'Yes':'No');
            setValue('is_there_red_card',eventData?.score_details?.is_there_red_card ? 'Yes':'No');
            setValue('how_many_yellow_card', eventData?.score_details?.how_many_yellow_card);
            setValue('yellow_card_player_name', eventData?.score_details?.yellow_card_player_name);
            setValue('how_many_red_card', eventData?.score_details?.how_many_red_card);
            setValue('red_card_player_name', eventData?.score_details?.red_card_player_name);
            setValue('is_there_substitutes',eventData?.score_details?.is_there_substitutes ? 'Yes':'No');
            setValue('key_update',eventData?.score_details?.key_update);
            eventData?.score_details?.substitutes.forEach((sitem, sindex)=>{
                update(sindex,{substitute_off:sitem.substitute_off, substitute_on:sitem.substitute_on});
            });
        }
    }

    const handleInputChange = (event) => {
        setCharCount(event.target.value.length);
    };

    const handleInput2Change = (event) => {
        setCharCount2(event.target.value.length);
    };

    const onSubmit=async(data)=>{
        setHomeLogoError(null);
        setAwayLogoError(null);
        setErrorMessage(null);

        let hasError = false;
        var formdata = new FormData();
        formdata.append('type','set_event_score');
        formdata.append('team_id',team._id);
        formdata.append('event_id',id);
        formdata.append('game_status',data.game_status);
        formdata.append('time_remaining',data.time_remaining);
        setLoading(true);
        
        var formdatas3 = new FormData();
        let hasImageErr = false;
        let tmpFilename = generateRandomName();
        let tmpFilename2 = generateRandomName();
        if(homeLogo!==null){
            const imgFileObj = new File([homeLogo], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: homeLogo.type })
            formdatas3.append("files[]", imgFileObj);
        }
        else{
            if(Object.keys(scoreDetails).length===0 || (Object.keys(scoreDetails).length!==0 && (scoreDetails.home_team_logo==="" || scoreDetails.home_team_logo===null))){
                setHomeLogoError("Please upload Home Team Logo");
                setErrorMessage("Home Team Logo and Away Team Logo Both Are Required.");
                hasImageErr=true;
            }
            else{
                formdata.append('home_team_logo',scoreDetails.home_team_logo);
            }
        }
        if(awayLogo!==null){
            const imgFileObj2 = new File([awayLogo], tmpFilename2+'.webp', { lastModified: new Date().getTime(), type: awayLogo.type })
            formdatas3.append("files[]", imgFileObj2);
        }
        else{
            if(Object.keys(scoreDetails).length===0 || (Object.keys(scoreDetails).length!==0 && (scoreDetails.away_team_logo==="" || scoreDetails.away_team_logo===null))){
                if(awayLogo===null){
                    setAwayLogoError("Please upload Away Team Logo");
                    setErrorMessage("Home Team Logo and Away Team Logo Both Are Required.");
                    hasImageErr=true;
                }
            }
            else{
                formdata.append('away_team_logo',scoreDetails.away_team_logo);
            }
        }
        if(!hasImageErr && (homeLogo!==null || awayLogo!==null)){
            var requestOptionss3 = {
                method: 'PUT',
                body: formdatas3,
                redirect: 'follow'
            };
            const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
            const resultsApp = await responsesApp.text();
            const rdatasApp = JSON.parse(resultsApp);
            if(homeLogo!==null && awayLogo!==null){
                formdata.append('home_team_logo',rdatasApp.data[0]);
                formdata.append('away_team_logo',rdatasApp.data[1]);
            }
            else{
                if(homeLogo!==null){
                    formdata.append('home_team_logo',rdatasApp.data[0]);
                }
                else if(awayLogo!==null){
                    formdata.append('away_team_logo',rdatasApp.data[0]);
                }                
            }
        }

        if(!hasError){
            formdata.append('home_team_name',data.home_team_name);
            formdata.append('home_team_score',data.home_team_score);
            formdata.append('away_team_name',data.away_team_name);
            formdata.append('away_team_score',data.away_team_score);
            formdata.append('is_there_yellow_card',data.is_there_yellow_card==='Yes' ? true : false);
            if(data.is_there_yellow_card==='Yes'){
                formdata.append('how_many_yellow_card',data.how_many_yellow_card);
                formdata.append('yellow_card_player_name',data.yellow_card_player_name);
            }
            formdata.append('is_there_red_card',data.is_there_red_card==='Yes' ? true : false);
            if(data.is_there_red_card==='Yes'){
                formdata.append('how_many_red_card',data.how_many_red_card);
                formdata.append('red_card_player_name',data.red_card_player_name);
            }
            formdata.append('is_there_substitutes',data.is_there_substitutes==='Yes' ? true : false);
            if(data.is_there_substitutes==="Yes"){
                formdata.append('substitutes',JSON.stringify(data.substitutes));
            }

            formdata.append('key_update',data.key_update);

            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            var requestOptions2 = {
                method: 'POST',
                body: formdata,
                redirect: 'follow',
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/app_event', requestOptions2)
                .then(response => response.text())
                .then(result => {
                    setLoading(false);
                    let rdata = JSON.parse(result);
                    if(rdata.status===1){
                        setSuccessMessage(rdata.message);
                        window.location=`/team/${team._id}/event/${id}`;
                    }
                    else{
                        setErrorMessage(rdata.message);
                    }
                })
        }
        else{
            setLoading(false);
            return null;
        }
    }

    const game_statuswatch=watch('game_status');
    const is_yellow_cardwatch=watch('is_there_yellow_card');
    const is_red_cardwatch=watch('is_there_red_card');
    const is_there_substitutes=watch('is_there_substitutes');

    useEffect(()=>{
        setLoading(true);
        if(!teamUpdating){
            if(team.role!=='admin' && (team.role!=='member' || team.admin_groups_new === undefined || team.admin_groups_new.length<=0)){
                window.location=`/team/${team._id}`;
            }
            else{
                fetchGameScoreInfo();               
            }            
        }
    },[teamUpdating]);

    return(
        <>
        {!loading ?
        <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}/events`}>
                    Event
                </NavLink>
                <span className='separator'>&lt;</span>
                Live Score
            </div>
            <div className='body px-5 lg:px-8 py-10'>
                <div className="rounded-xl bg-[var(--color-darkblue)] py-7.5 px-5">
                    <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                        <div className="flex flex-wrap mb-5">
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container">
                                    <div className="label">Game Staus*</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <select {...register("game_status")}>                                                
                                            <option value="">Game Status</option>
                                            {gameStatus.map((gstatus,index)=>{
                                                return(
                                                    <option key={index} value={gstatus}>{gstatus}</option>
                                                )
                                            })}
                                        </select>                                
                                    </div>
                                    {errors.game_status && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.game_status?.message}</p>}
                                </div>
                            </div>
                            {game_statuswatch!=='Completed' &&
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container">
                                    <div className="label">Time Remaining*</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <select {...register("time_remaining")}>                                                
                                            <option value="">Time Remaining</option>
                                            {gameTime.map((gmtime,index)=>{
                                                return(
                                                    <option key={index} value={JSON.stringify(gmtime)}>{gmtime.display_time}</option>
                                                )
                                            })}
                                        </select>                                
                                    </div>
                                    {errors.time_remaining && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.time_remaining?.message}</p>}
                                </div>
                            </div>
                            }
                        </div>
                        <div className="flex flex-wrap mb-5">
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container">
                                    <div className="label">Home Team Logo*</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <PlaiImageCropper editImage={(Object.keys(scoreDetails).length!==0 && scoreDetails.home_team_logo) ? scoreDetails.home_team_logo :''} onFinalChange={(newImage)=>{setHomeLogoImage(newImage)}} aspect={1 / 1} default_label={'Select Image'} default_message={'Maximum upload file size: 2MB<br/>(1024 X 1024)'}/>                           
                                    </div>
                                    {homeLogoErr!==null && <p className="error mb-2.5" role="alert">{homeLogoErr}</p>}
                                </div>
                            </div>
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container">
                                    <div className="label">Home Team Name*</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <input type="text" {...register("home_team_name")} placeholder="Home Team Name" onChange={handleInputChange} maxLength={maxLength} />
                                    </div>
                                    <p className="text-right text-xs text-white -mt-2.5">{charCount} / {maxLength}</p>
                                    {errors.home_team_name && <p className="error mb-2.5" role="alert">{errors.home_team_name?.message}</p>}
                                </div>
                                <div className="form-field-container">
                                    <div className="label">Home Team Score*</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <input type="text" {...register("home_team_score")} placeholder="Home Team Score" />
                                    </div>
                                    {errors.home_team_score && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.home_team_score?.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-5">
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container">
                                    <div className="label">Away Team Logo*</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <PlaiImageCropper editImage={(Object.keys(scoreDetails).length!==0 && scoreDetails.away_team_logo) ? scoreDetails.away_team_logo :''} onFinalChange={(newImage)=>{setAwayLogoImage(newImage)}} aspect={1 / 1} default_label={'Select Image'} default_message={'Maximum upload file size: 2MB<br/>(1024 X 1024)'}/>                           
                                    </div>
                                    {awayLogoErr!==null && <p className="error mb-2.5" role="alert">{awayLogoErr}</p>}                                   
                                </div>
                            </div>
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container">
                                    <div className="label">Away Team Name*</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <input type="text" {...register("away_team_name")} placeholder="Away Team Name" onChange={handleInput2Change} maxLength={maxLength} />
                                    </div>
                                    <p className="text-right text-xs text-white -mt-2.5">{charCount2} / {maxLength}</p>
                                    {errors.away_team_name && <p className="error mb-2.5" role="alert">{errors.away_team_name?.message}</p>}
                                </div>
                                <div className="form-field-container">
                                    <div className="label">Away Team Score*</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <input type="text" {...register("away_team_score")} placeholder="Away Team Score" />
                                    </div>
                                    {errors.away_team_score && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.away_team_score?.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-5">
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container">
                                    <div className="label">Have there been any Yellow Cards?*</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <select {...register("is_there_yellow_card")}>                                                
                                            <option value="">Have there been any Yellow Cards?</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>                                
                                    </div>
                                    {errors.is_there_yellow_card && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.is_there_yellow_card?.message}</p>}
                                </div>
                            </div>
                            {is_yellow_cardwatch==='Yes' &&
                                <>
                                <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">How many Yellow Cards?</div>
                                        <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                            <input type="text" {...register("how_many_yellow_card")} placeholder="How many Yellow Cards?" />
                                        </div>
                                        {errors.how_many_yellow_card && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.how_many_yellow_card?.message}</p>}
                                    </div>
                                </div>
                                <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">Type the Players who received them...</div>
                                        <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                            <input type="text" {...register("yellow_card_player_name")} placeholder="Type the Players who received them..." />
                                        </div>
                                        {errors.yellow_card_player_name && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.yellow_card_player_name?.message}</p>}
                                    </div>
                                </div>
                                </>
                            }                            
                        </div>
                        <div className="flex flex-wrap mb-5">
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container">
                                    <div className="label">Have there been any Red Cards?*</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <select {...register("is_there_red_card")}>                                                
                                            <option value="">Have there been any Red Cards?</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>                                
                                    </div>
                                    {errors.is_there_red_card && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.is_there_red_card?.message}</p>}
                                </div>
                            </div>
                            {is_red_cardwatch==='Yes' &&
                                <>
                                <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">How many Red Cards?</div>
                                        <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                            <input type="text" {...register("how_many_red_card")} placeholder="How many Red Cards?" />
                                        </div>
                                        {errors.how_many_red_card && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.how_many_red_card?.message}</p>}
                                    </div>
                                </div>
                                <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">Type the Players who received them...</div>
                                        <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                            <input type="text" {...register("red_card_player_name")} placeholder="Type the Players who received them..." />
                                        </div>
                                        {errors.red_card_player_name && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.red_card_player_name?.message}</p>}
                                    </div>
                                </div>
                                </>
                            }                            
                        </div>
                        <div className="flex flex-wrap mb-5">
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container">
                                    <div className="label">Has there been any substitutions?*</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <select {...register("is_there_substitutes")}>                                                
                                            <option value="">Has there been any substitutions?</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>                                
                                    </div>
                                    {errors.is_there_substitutes && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.is_there_substitutes?.message}</p>}
                                </div>
                            </div>
                        </div>
                        {is_there_substitutes==='Yes' &&
                            <>
                            <div className="bg-[#313961] rounded-xl p-5 max-w-[814px] mb-5">
                                {fields.map((item, index) => {
                                    return (
                                        <div key={item.id} className="flex flex-wrap items-center">
                                            <div className="w-full xl:w-1/2 md:px-2.5 xl:px-3.5">
                                                <div className="form-field-container">
                                                    <div className="label">Player Substituted OFF</div>
                                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                                        <input type="text" {...register(`substitutes.${index}.substitute_off`)} placeholder="Player Substituted OFF" />
                                                    </div>                                                
                                                </div>
                                            </div>
                                            <div className="w-full xl:w-1/2 md:px-2.5 xl:px-3.5">
                                                <div className="form-field-container">
                                                    <div className="label">Player Substituted ON</div>
                                                    <div className='form-field-wrap mb-5 no-field-wrap relative flex items-center gap-2'>
                                                        <input type="text" {...register(`substitutes.${index}.substitute_on`)} placeholder="Player Substituted ON" />
                                                        {fields.length > 1 &&
                                                            <div className="w-1/12">
                                                                <button type="button" onClick={() => remove(index)}>
                                                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12.1328 1.61778H16.1773V3.23556H14.5595V15.3689C14.5595 15.5834 14.4743 15.7892 14.3226 15.9409C14.1709 16.0926 13.9652 16.1778 13.7506 16.1778H2.42618C2.21165 16.1778 2.0059 16.0926 1.85421 15.9409C1.70251 15.7892 1.61729 15.5834 1.61729 15.3689V3.23556H-0.000488281V1.61778H4.04396V0H12.1328V1.61778ZM5.66173 5.66222V12.1333H7.27951V5.66222H5.66173ZM8.89729 5.66222V12.1333H10.5151V5.66222H8.89729Z" fill="#FBDB09"/>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>                                            
                                        </div>                                    
                                    );})
                                }
                                <div className="w-full md:px-2.5 xl:px-3.5">
                                    <button type="button" className='rounded-full flex items-center justify-center border border-[var(--color-yellow)] p-2.5 h-[48px] w-[303px] uppercase gap-2.5 text-[var(--color-yellow)] text-base font-semibold'
                                        onClick={() => {
                                            append({ substitute_off: "", substitute_on: "", });
                                        }}>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 -0.000244141V13.9998" stroke="currentColor" strokeWidth="2"/>
                                            <path d="M14.0002 6.99982L0.000242669 6.99982" stroke="currentColor" strokeWidth="2"/>
                                        </svg>
                                        Add Another Substitution
                                    </button>                                
                                </div>
                            </div>
                            </>
                        }
                        <div className="md:px-2.5 lg:px-3.5 lg:mb-12">
                            <div className="form-field-container">
                                <div className="label">Key Update</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                    <textarea {...register("key_update")} placeholder="Enter Key Update"></textarea>
                                </div>
                                {errors.key_update && <p className="error mb-2.5" role="alert">{errors.key_update?.message}</p>}
                            </div>
                        </div>
                        <div className="w-full md:px-2.5 xl:px-3.5 flex flex-col sm:flex-row gap-7.5 items-end justify-center lg:justify-end">
                            <input className='button !text-base !font-semibold p-2.5 h-[45px] w-[220px]' type="submit" value="Confirm" disabled={loading}/>
                        </div>
                        <div className='flex justify-center'>
                        {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}                            
                        </div>
                    </form>
                </div>
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
        }
        </>
    )
}
const UpdateScore=()=>{
    const { id, eventid } = useParams();
    return(
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="">
                    <AdminNavbar heading="Update Score" />
                    <UpdateScoreForm id={eventid} />
                </div>
            </TeamContextProvider>
        </>
    )
}

export default UpdateScore;