import { useState, useEffect } from "react";
import { getNotRespondedList } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";
import placeholderImg from "../../../assets/images/placeholder.png";


const NotRespondedList = ({setShowForm,team,eventItem}) =>{
    const [loading, setLoading] = useState(false);
    const [notRespondList,setnotRespondList] = useState([]);
    
    const [q, setSearchQuery] = useState(""); 
    const [searchParam] = useState(["firstName","lastName","name"]);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const fetchNotResponsList = async()=>{
        setLoading(true);
        var responseData = await getNotRespondedList(token, team._id, eventItem._id);
        setnotRespondList(responseData.data);
        setLoading(false);
    }
    
    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]?
                        item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                    :
                    null
                );
            });
        });
    }

    const NotRespondedListRow=({member})=>{
        const [rloading, setRloading] = useState(false);

        const handleNudgeRequest=async()=>{
            setRloading(true);
            var formdata = new FormData();
            formdata.append("type", "ask_individual_user_for_response");
            formdata.append("team_id", team._id);
            formdata.append("event_id", eventItem._id);
            formdata.append("user_sub", member.sub);

            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setRloading(false);
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    alert("Error !! - " + rdata.message);
                }
                else{
                    alert(rdata.message);
                }
            })
            .catch(error => console.log('error', error));
        }
        return(
            <div className="w-full bg-[#363F6C] rounded-[16px] px-2.5 py-2.5 flex items-center" key={member._id}>
                <div className="rounded-full relative w-[50px] h-[50px] mr-2.5 overflow-hidden">
                    {member.profilePicture!=="" ?
                        <img src={member.profilePicture} alt="" width="50px" height="50px" className="absolute object-cover w-full h-full top-0 left-0 rounded-full"/>
                    :
                        <img src={placeholderImg} width="50px" className="absolute object-cover w-full h-full top-0 left-0 rounded-full" height="50px" alt="" />
                    }
                </div>
                <div className="flex-1 text-white text-[14px]">{member.name}</div>
                {!rloading ?
                    <button className="button button-medium" disabled={rloading} onClick={()=>handleNudgeRequest()}>Nudge</button>
                :
                    <></>
                }
            </div>
        )
    }

    useEffect(()=>{
        fetchNotResponsList();
    },[]);

    return(
        !loading ? 
        <>
            <div className="bg-white px-2.5 rounded-xl">
                <div>
                    <div className="form-heading text-center mb-[30px]">Not Responded Members</div>
                </div>
                <div className="flex justify-center mb-5">
                    <label htmlFor="search-form" className="search-field relative">
                        <input
                            type="search"
                            name="search-field"
                            id="search-form"
                            className="search-input focus:!bg-gray-200"
                            placeholder="Search Members"
                            value={q}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);                                                            
                            }}
                        />
                    </label>
                </div>
                {search(notRespondList).length > 0 ?
                    <>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                            {search(notRespondList).map((member)=>{
                                return(
                                    <NotRespondedListRow member={member} />                                    
                                )
                            })}
                        </div>
                    </>
                :
                    <>
                        <div className="text-center">Not Found.</div>
                    </>
                }
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}

export default NotRespondedList;