import { useState, useEffect, useContext } from "react";
import { TeamContext } from "../../../contexts/TeamContext";
import { getGalleryFolder, getGalleryMedia } from "../../../libs/dataFunctions";
import rduties from '../../../assets/images/duties.svg';
import Spinner from "../../../components/Spinner/Spinner";
import GalleryForm from "./GalleryForm";
import FolderCard from "./FolderCard";
import "./gallery.scss";
import MediaCard from "./MediaCard";
import GalleryMediaForm from "./GalleryMediaForm";
import MediaApproval from "./MediaApproval";

const GalleryFolderOverview=({team, token, setGalleryMedia})=>{
    const [loading, setLoading] = useState(false);
    const [q, setSearchQuery] = useState(""); 
    const [searchParam] = useState(["name"]);
    const [galleryFolders, setGalleryFolders] = useState([]);
    const [showCreateFolder, setShowCreateFolder] = useState(false);
    const [showUploadImage, setShowUploadImage] = useState(false);
    const [unApprovedMedia, setUnApprovedMedia] = useState(0);
    let tmpindex = 0;
    var cssClass='short';

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    async function fetchGalleryFolders(){
        setLoading(true);
        let galleryFoldersData = await getGalleryFolder(token,team._id);
        setGalleryFolders(galleryFoldersData.data);
        setUnApprovedMedia(galleryFoldersData.unapproved_media);
        setLoading(false);
    }

    useEffect(()=>{
        fetchGalleryFolders();
    },[]);

    return(
        !loading ?
        <>
        <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center mb-[30px] relative">
            <div className="title-col mb-2.5 md:mb-0">
                Gallery
            </div>
            <div className="cta-col flex flex-col md:flex-row items-center relative">
                <label htmlFor="search-form" className="search-field relative mr-3.5 !mb-0">
                    <input
                        type="search"
                        name="search-field"
                        id="search-form"
                        className="search-input"
                        placeholder="Search"
                        value={q}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />                        
                </label>
                <button type="button" className="button button-action button-new mr-3.5" onClick={()=>{setShowCreateFolder(true)}}>Create Folder</button>
                <button type="button" className="button button-action button-new mr-3.5" onClick={()=>{setShowUploadImage(true)}}>Upload Image</button>
                {(team.role==='admin' && unApprovedMedia>0) &&
                    <button type="button" className="relative" onClick={()=>{setGalleryMedia('imageApproval')}}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="30" height="30" rx="8" fill="#363F6C"/>
                                <path d="M21.0055 9C21.3708 9 21.6668 9.29667 21.6668 9.662V15.8947C21.2386 15.7434 20.7877 15.6663 20.3335 15.6667V10.3333H9.66683L9.6675 19.6667L15.8622 13.4713C15.9768 13.3563 16.1295 13.2872 16.2916 13.2768C16.4536 13.2665 16.6139 13.3158 16.7422 13.4153L16.8048 13.472L19.1682 15.8387C18.6513 15.9962 18.1716 16.2567 17.7581 16.6046C17.3446 16.9525 17.0059 17.3806 16.7623 17.8629C16.5188 18.3453 16.3754 18.872 16.3409 19.4112C16.3063 19.9505 16.3814 20.4912 16.5615 21.0007L8.99483 21C8.81937 20.9998 8.65116 20.93 8.52716 20.8059C8.40315 20.6817 8.3335 20.5135 8.3335 20.338V9.662C8.33472 9.48692 8.40476 9.31934 8.5285 9.19548C8.65225 9.07161 8.81975 9.0014 8.99483 9H21.0055ZM12.3335 11.6667C12.6871 11.6667 13.0263 11.8071 13.2763 12.0572C13.5264 12.3072 13.6668 12.6464 13.6668 13C13.6668 13.3536 13.5264 13.6928 13.2763 13.9428C13.0263 14.1929 12.6871 14.3333 12.3335 14.3333C11.9799 14.3333 11.6407 14.1929 11.3907 13.9428C11.1406 13.6928 11.0002 13.3536 11.0002 13C11.0002 12.6464 11.1406 12.3072 11.3907 12.0572C11.6407 11.8071 11.9799 11.6667 12.3335 11.6667Z" fill="#FBDB03"/>
                                <g clipPath="url(#clip0_4426_4443)">
                                    <circle cx="19.7998" cy="18.7329" r="2.13333" fill="#FBDB03"/>
                                    <path d="M19.3567 18.1758C19.2749 18.1376 19.2401 18.0264 19.2892 17.9456C19.3885 17.7839 19.5542 17.666 19.7732 17.666C20.0137 17.666 20.1785 17.7862 20.2624 17.9366C20.334 18.0657 20.376 18.3071 20.2655 18.4868C20.1427 18.6855 20.025 18.7462 19.9615 18.8742C19.9462 18.9045 19.937 18.9292 19.9308 18.9797C19.9216 19.0617 19.8602 19.1257 19.7845 19.1257C19.6955 19.1257 19.6228 19.0415 19.633 18.9438C19.6392 18.8865 19.6515 18.827 19.6801 18.7709C19.7589 18.6148 19.9104 18.5227 19.9984 18.3846C20.0915 18.2398 20.0393 17.9692 19.7753 17.9692C19.6556 17.9692 19.5778 18.0377 19.5297 18.1196C19.4939 18.1836 19.4192 18.2038 19.3567 18.1758ZM19.9789 19.5748C19.9789 19.6983 19.8868 19.7993 19.7743 19.7993C19.6617 19.7993 19.5696 19.6983 19.5696 19.5748C19.5696 19.4513 19.6617 19.3502 19.7743 19.3502C19.8868 19.3502 19.9789 19.4513 19.9789 19.5748Z" fill="#363F6C"/>
                                </g>
                            <defs>
                                <clipPath id="clip0_4426_4443">
                                    <rect width="4.66667" height="4.66667" fill="white" transform="translate(17.6665 16.333)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        {unApprovedMedia>0 ? <i className="flex absolute bg-[#ff0000] text-[9px] text-white w-[20px] h-[20px] rounded-full top-[-10px] right-[-10px] items-center justify-center">{unApprovedMedia}</i> : null}
                    </button>
                }
            </div>
        </div>
        {showCreateFolder &&
            <GalleryForm setShowForm={setShowCreateFolder} teamId={team._id} isNew={true} fetchGalleryFolders={fetchGalleryFolders} isInsideFolder={false} />
        }
        {showUploadImage &&
            <GalleryMediaForm setShowForm={setShowUploadImage} teamId={team._id} galleryId={null} fetchGalleryFolderMedia={null} isInsideFolder={false} />
        }
        <div className="px-5">
            {galleryFolders && galleryFolders.length>0 ?                
                <div className="gallery-grid"> 
                    {search(galleryFolders).map((galfolder,index) => {
                            if(index % 4 === 0){
                                tmpindex=0;                                
                            }
                            if(tmpindex % 2 === 0){
                                cssClass='long';
                            }
                            else{
                                cssClass='short';
                            }
                            tmpindex++;
                            return (
                                <>
                                <FolderCard galfolder={galfolder} key={galfolder._id} cssClass={cssClass} setGalleryMedia={setGalleryMedia} teamId={team._id} fetchGalleryFolders={fetchGalleryFolders} token={token} />
                                </>                                
                            )
                        })
                    }                    
                </div>
            :
            <div className="empty text-center">
                <div className="image"><img src={rduties} alt="Plaisport" width={60} height={60} /></div>
                <div className="title mb-5">No Gallery Yet?</div>
                <div className="description mb-8">Click On Create Gallery To Start.</div>
            </div>
            }
        </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

const GalleryFolderDetail=({team, token, folderid, setGalleryMedia})=>{
    const [loading, setLoading] = useState(false);
    const [q, setSearchQuery] = useState(""); 
    const [searchParam] = useState(["created_by"]);
    const [galleryData, setGalleryData] = useState([]);
    const [showCreateFolder, setShowCreateFolder] = useState(false);
    const [showUploadImage, setShowUploadImage] = useState(false);
    let tmpindex = 0;
    var cssClass='short';
    
    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    async function fetchGalleryFolderMedia(){
        setLoading(true);
        const [galleryMediaData] = await Promise.all([
            getGalleryMedia(token,team._id,folderid),
        ]);
        setGalleryData(galleryMediaData.data);
        setLoading(false);
    }

    useEffect(()=>{
        fetchGalleryFolderMedia();
    },[]);

    return(
        !loading ?
        <>
        <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center mb-[30px] relative">
            <div className="title-col mb-2.5 md:mb-0">
                {galleryData.name}
            </div>
            <div className="cta-col flex flex-col md:flex-row items-center relative">
                <label htmlFor="search-form" className="search-field relative mr-3.5 !mb-0">
                    <input
                        type="search"
                        name="search-field"
                        id="search-form"
                        className="search-input"
                        placeholder="Search"
                        value={q}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />                        
                </label>
                <button type="button" className="button button-action button-new mr-3.5" onClick={()=>{setShowCreateFolder(true)}}>Create Folder</button>
                <button type="button" className="button button-action button-new mr-3.5" onClick={()=>{setShowUploadImage(true)}}>Upload Image</button>
                {team.role==='admin' &&
                    <button type="button" className="relative" onClick={()=>{setGalleryMedia('imageApproval')}}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="30" height="30" rx="8" fill="#363F6C"/>
                                <path d="M21.0055 9C21.3708 9 21.6668 9.29667 21.6668 9.662V15.8947C21.2386 15.7434 20.7877 15.6663 20.3335 15.6667V10.3333H9.66683L9.6675 19.6667L15.8622 13.4713C15.9768 13.3563 16.1295 13.2872 16.2916 13.2768C16.4536 13.2665 16.6139 13.3158 16.7422 13.4153L16.8048 13.472L19.1682 15.8387C18.6513 15.9962 18.1716 16.2567 17.7581 16.6046C17.3446 16.9525 17.0059 17.3806 16.7623 17.8629C16.5188 18.3453 16.3754 18.872 16.3409 19.4112C16.3063 19.9505 16.3814 20.4912 16.5615 21.0007L8.99483 21C8.81937 20.9998 8.65116 20.93 8.52716 20.8059C8.40315 20.6817 8.3335 20.5135 8.3335 20.338V9.662C8.33472 9.48692 8.40476 9.31934 8.5285 9.19548C8.65225 9.07161 8.81975 9.0014 8.99483 9H21.0055ZM12.3335 11.6667C12.6871 11.6667 13.0263 11.8071 13.2763 12.0572C13.5264 12.3072 13.6668 12.6464 13.6668 13C13.6668 13.3536 13.5264 13.6928 13.2763 13.9428C13.0263 14.1929 12.6871 14.3333 12.3335 14.3333C11.9799 14.3333 11.6407 14.1929 11.3907 13.9428C11.1406 13.6928 11.0002 13.3536 11.0002 13C11.0002 12.6464 11.1406 12.3072 11.3907 12.0572C11.6407 11.8071 11.9799 11.6667 12.3335 11.6667Z" fill="#FBDB03"/>
                                <g clipPath="url(#clip0_4426_4443)">
                                    <circle cx="19.7998" cy="18.7329" r="2.13333" fill="#FBDB03"/>
                                    <path d="M19.3567 18.1758C19.2749 18.1376 19.2401 18.0264 19.2892 17.9456C19.3885 17.7839 19.5542 17.666 19.7732 17.666C20.0137 17.666 20.1785 17.7862 20.2624 17.9366C20.334 18.0657 20.376 18.3071 20.2655 18.4868C20.1427 18.6855 20.025 18.7462 19.9615 18.8742C19.9462 18.9045 19.937 18.9292 19.9308 18.9797C19.9216 19.0617 19.8602 19.1257 19.7845 19.1257C19.6955 19.1257 19.6228 19.0415 19.633 18.9438C19.6392 18.8865 19.6515 18.827 19.6801 18.7709C19.7589 18.6148 19.9104 18.5227 19.9984 18.3846C20.0915 18.2398 20.0393 17.9692 19.7753 17.9692C19.6556 17.9692 19.5778 18.0377 19.5297 18.1196C19.4939 18.1836 19.4192 18.2038 19.3567 18.1758ZM19.9789 19.5748C19.9789 19.6983 19.8868 19.7993 19.7743 19.7993C19.6617 19.7993 19.5696 19.6983 19.5696 19.5748C19.5696 19.4513 19.6617 19.3502 19.7743 19.3502C19.8868 19.3502 19.9789 19.4513 19.9789 19.5748Z" fill="#363F6C"/>
                                </g>
                            <defs>
                                <clipPath id="clip0_4426_4443">
                                    <rect width="4.66667" height="4.66667" fill="white" transform="translate(17.6665 16.333)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                }
            </div>
        </div>
        {showCreateFolder &&
            <GalleryForm setShowForm={setShowCreateFolder} teamId={team._id} isNew={true} fetchGalleryFolders={false} isInsideFolder={true} />
        }
        {showUploadImage &&
            <GalleryMediaForm setShowForm={setShowUploadImage} teamId={team._id} galleryId={galleryData._id} fetchGalleryFolderMedia={fetchGalleryFolderMedia} isInsideFolder={true} />
        }        
        <div className="px-[26px]">
            {galleryData.description!=="" &&
            <p className="mb-2.5 text-[16px] leading-[24px] text-[#6E7079] px-1">{galleryData.description}</p>
            }
            {galleryData.files && galleryData.files.length>0 ?
                <div className="gallery-grid"> 
                    {search(galleryData.files).map((galmedia,index) => {
                        if(index % 4 === 0){
                            tmpindex=0;                                
                        }
                        if(tmpindex % 2 === 0){
                            cssClass='long';
                        }
                        else{
                            cssClass='short';
                        }
                        tmpindex++;
                        return (
                            <MediaCard mediaItem={galmedia} cssClass={cssClass} key={galmedia._id} teamId={team._id} fetchGalleryFolderMedia={fetchGalleryFolderMedia} token={token}/>
                        )
                    })}
                </div>
            :
            <div className="empty text-center">
                <div className="image"><img src={rduties} alt="Plaisport" width={60} height={60} /></div>
                <div className="title mb-5">No Media Yet?</div>
                <div className="description mb-8">Click On Upload Images To Start.</div>
            </div>
            }
        </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

const TabGallery = ({galleryMedia, setGalleryMedia})=>{
    const {team, token } = useContext(TeamContext);
    if(galleryMedia===null){
        return(
            <>
            <GalleryFolderOverview team={team} token={token} setGalleryMedia={setGalleryMedia} />
            </>
        )
    }
    else if(galleryMedia==='imageApproval'){
        return(
            <>
            <MediaApproval team={team} token={token} />
            </>
        )
    }
    else{
        return(
            <>
            <GalleryFolderDetail team={team} token={token} folderid={galleryMedia} />
            </>
        )
    }
}

export default TabGallery;