import { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import ImageUploading from "react-images-uploading";
import * as DOMPurify from 'dompurify';
import Spinner from "../Spinner/Spinner";
import { getCroppedImg } from "./cropUtils";
import './ImageCropper.scss';

const CropperWindow = ({image, onComplete, containerStyle, setImage, setStatus, setCroppedImage, setCroppedImageFile, onFinalChange, ...props}) =>{
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [cropLoading, setCropLoading] = useState(false);
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            setCropLoading(true);
            const {blob, previewUrl} = await getCroppedImg(
                image,
                croppedAreaPixels,
            );
            setCroppedImage(previewUrl);
            setCroppedImageFile(blob);
            onFinalChange(blob);
            setCropLoading(false);
            setStatus('done');
        } catch (e) {
          console.error(e)
        }
    }, [image, croppedAreaPixels, setCroppedImage, setCroppedImageFile,  setStatus, onFinalChange]);

    return(
        <>
        <div className="file-uploader relative">
            <div className="file-uploader-wrapper !cursor-default !border-0">
                <div style={containerStyle}>
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={props.aspect}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        {...props}
                    />
                </div>
            </div>
            <div className="flex mt-5 justify-center">
                {!cropLoading ?
                <>
                    <button type="button" id="cancel" className="button mr-2.5" onClick={() => {
                        setImage([]);
                        setStatus('default');
                        onFinalChange(null);
                    }}>Cancel</button>
                    <button type="button" id="finish" className="button ml-2.5" onClick={showCroppedImage}>Finish</button>
                </>
                :
                <>
                    <div className="text-center text-xs">Wait preparing image to upload...</div>
                </>
                }
                
            </div>
        </div>
        </>
    )
}

const PlaiImageCropper = ({editImage, onFinalChange, aspect, default_label, default_message}) =>{
    const [image,setImage] = useState([]);
    const [croppedImage, setCroppedImage] = useState(null);
    const [croppedImageFile, setCroppedImageFile] = useState(null);
    const [status, setStatus] = useState('default');
    const onChange = (image) => {
        setStatus('crop');
        setImage(image);
    };
    switch(status){
        case 'crop':
            return(
                <CropperWindow image={image.length > 0 && image[0].dataURL}
                    onComplete={(imagePromisse) => {
                        imagePromisse.then((image) => {
                            setCroppedImage(image);
                            setStatus('done');
                        });
                    }}
                    containerStyle={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        background: "#333"
                    }}
                    aspect={aspect}
                    setStatus={setStatus}
                    setImage={setImage}
                    setCroppedImage = {setCroppedImage}
                    setCroppedImageFile={setCroppedImageFile}
                    onFinalChange={onFinalChange}
                />
            );
        case 'done':
            return(
                <div className="file-uploader relative">
                    <div className="file-uploader-wrapper !h-auto !cursor-default !border-0">
                        {croppedImage ?
                        <div className="relative w-full">
                            <img src={croppedImage} alt="" />
                        </div>
                        :
                            <p>Something went wrong, preass change button to reupload.</p>
                        }
                    </div>
                    <div className="flex mt-5 justify-center">
                        <button type="button" id="cancel" className="button mr-2.5" onClick={() => {
                            setImage([]);
                            setStatus('default');
                            onFinalChange(null);
                        }}>Cancel</button>
                    </div>
                </div>
            )
        case 'loading':
                return(
                    <Spinner />
                );
        default:
            return(
                <ImageUploading
                    value={image}
                    onChange={onChange}
                    acceptType={["jpg","png","jpeg","webp"]}
                    maxFileSize={2000000} //maximum 2MB
                    >
                    {({onImageUpload, onImageUpdate, errors})=>(
                        <div className="file-uploader" onClick={image ? onImageUpload : () => onImageUpdate(0)}>
                            {editImage && editImage!=="" ?
                            <>
                            <div className="file-uploader-wrapper">
                                <img src={editImage} alt="" className="w-full h-full object-contain" />
                            </div>
                            {errors && 
                            <div className="text-red-600 text-xs font-medium pt-5">
                                {errors.maxFileSize && <span>Selected file is largre than required size of 2MB</span>}
                            </div>
                            }
                            </>
                            :
                            <div className="file-uploader-wrapper">
                                <div className="upload-icon mx-auto"></div>
                                <div className="upload-text">
                                    <h4>{default_label && default_label!==null ? default_label :'Upload an Image'}</h4>                                        
                                    <p className="text-center" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(default_message && default_message!==null ? default_message : 'Maximum upload file size: 2MB<br/>(1000 X 500)')}} />
                                </div>
                                {errors && 
                                <div className="text-red-600 text-xs font-medium pt-5">
                                    {errors.maxFileSize && <span>Selected file is largre than required size of 2MB</span>}
                                </div>
                                }
                            </div>
                            }
                        </div>
                    )}
                </ImageUploading>
            )
    }    
}

export default PlaiImageCropper;