import { useState, useEffect, useContext, useRef } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckTreePicker from 'rsuite/CheckTreePicker';
import ReactDatePicker from 'react-datepicker';
import * as yup from 'yup';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import Sidebar from '../../../components/Sidebar/Sidebar';
import Spinner from '../../../components/Spinner/Spinner';
import AdminNavbar from '../../../components/AdminNavbar/AdminNavbar';
import { TeamContextProvider, TeamContext } from '../../../contexts/TeamContext';
import { useAuthState } from '../../../contexts/AuthContext';
import FieldOption from './FieldOptions';
import { toCurrentOffset } from "../../../libs/utils";
import { getUserAsignedGroup, getAllProducts, getYourFormDetail, checkStripeConnect } from "../../../libs/dataFunctions";
import './admincreateform.scss';
dayjs.extend(utc);
dayjs.extend(tz);

const schema = yup.object({
    form_title: yup.string().required('Form name is required.'),
    description: yup.string().required('Form description is required.'),
    groups: yup
    .array()
    .min(1, "At least one group must be selected")
    .nullable()
    .required("At least one group must be selected"),
    product_id:yup.string().when('is_payment_required',{
        is:'Yes',
        then:yup.string().required('Please Select Product.')
    }),
    expire_date: yup.string().when('is_expire',{
        is:'Yes',
        then:yup.string().required('Please select Expiry Date.')
    }),
}).required();

const EditDynamicForm=({formID})=>{
    const { team, teamUpdating } = useContext(TeamContext);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [products, setProducts] = useState([]);
    const [isStripeConnect, setIsStripeConnect] = useState(false);
    const [disabledGroupsOpt, setDisableGroupsOpt]= useState([]);
    const contentRef=useRef();

    const userDetailAuth = useAuthState();
    const userDetail = userDetailAuth?.user;
    
    const defaultQuestions = {
        questions: [
        {
            question: "Question 1",
            question_type: 'short_answer',
            options:['Option 1'],
            is_required: false
        },
        ],
    };

    const { register, handleSubmit, setValue, getValues, watch, control, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultQuestions
    });

    const { fields, append, remove, update } = useFieldArray({
        control,
        name: "questions",
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    
    const onSubmit=async(data)=>{        
        setErrorMessage(null);
        setSuccessMessage(null);
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "update_form");
        formdata.append("team_id", team._id);
        formdata.append("title", data.form_title);
        formdata.append("description", data.description);
        formdata.append("group_ids", selectedGroups.join('@#'));
        formdata.append("separate_by", '@#');
        formdata.append("form_id",formID);
        
        if(isStripeConnect){
            formdata.append("is_payment_required", data.is_payment_required);
            if(data.is_payment_required.toLowerCase()==='yes'){
                formdata.append("product_id", data.product_id);
            }
        }
        else{
            formdata.append("is_payment_required", "No");
            formdata.append("product_id", "");
        }
        formdata.append("is_expire", data.is_expire);
        if(data.is_expire.toLowerCase()==='yes'){
            formdata.append("expire_date", dayjs(data.expire_date).format("YYYY-MM-DD"));
        }

        var questions = [];
        if(data.questions.length>0){
            data.questions.forEach((qitem)=>{
                let isRequired = qitem.is_required ? 'Yes' : 'No';
                if(qitem.question_type==='single_selection' || qitem.question_type==='checkbox'){
                    questions.push({'question':qitem.question, 'question_type':qitem.question_type, 'is_required': isRequired, 'options':qitem.options});
                }
                else{
                    questions.push({'question':qitem.question, 'question_type':qitem.question_type, 'is_required': isRequired});
                }                
            });
        }
        formdata.append('questions', JSON.stringify(questions));
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        var requestOptions2 = {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_forms', requestOptions2)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status===1){
                    setSuccessMessage(rdata.message);
                    window.location=`/team/${team._id}/your-forms`;
                }
                else{
                    setErrorMessage(rdata.message);
                }
            })
    }

    const fetchGroup=async()=>{
        setLoading(true);        
        const [groupsData, productsData, formData, stripeData] = await Promise.all([
            getUserAsignedGroup(token, team._id, formID, 'form' ),
            getAllProducts(team._id, token),
            getYourFormDetail(team._id, token, formID, userDetail.sub),
            checkStripeConnect(token, team._id)
        ]);

        if(groupsData.length>0){
            let disableGOpt=[];
            let groupOptions = [];
            groupsData.forEach(element => {
                let childOptions = [];
                if(!element.canSelect){
                    disableGOpt.push(element._id);
                }
                if(element.nest_groups.length > 0){
                    element.nest_groups.forEach(celm =>{
                        childOptions.push({'value':celm._id, 'label':celm.name});
                        if(!celm.canSelect){
                            disableGOpt.push(celm._id);
                        }
                    });
                    groupOptions.push({'value':element._id, 'label':element.name, 'children':childOptions});
                }
                else{
                    groupOptions.push({'value':element._id, 'label':element.name});
                }
            });
            setDisableGroupsOpt(disableGOpt);
            setGroups(groupOptions);
        }
        setProducts(productsData); 
        setIsStripeConnect(stripeData.stripeEnabled);
        if(formData.status===1){
            setValue('form_title',formData.data.title);
            setValue('description',formData.data.description);
            setValue('groups',formData.data.group_ids);
            setSelectedGroups(formData.data.group_ids);
            if(formData.data.product_id!==""){
                setValue('is_payment_required', 'Yes');
                setValue('product_id',formData.data.product_id);
            }
            else{
                setValue('is_payment_required', 'No');
            }
            if(formData.data.is_expire==="Yes" && formData.data.expire_date!==""){
                setValue('is_expire', 'Yes');
                setValue('expire_date',toCurrentOffset(formData.data.expire_date));
            }
            else{
                setValue('is_expire', 'No');
            }
            
            formData.data.questions.forEach((qitem, qindex)=>{
                update(qindex,{question_type:qitem.question_type, question:qitem.question, is_required:qitem.is_required==='Yes' ? true : false, options:qitem.options})                                
            });
        }
        setLoading(false);
    }

    const watchPaymentRequired = watch("is_payment_required");
    const watchis_expire = watch("is_expire");
    useEffect(()=>{
        setLoading(true);
        if(!teamUpdating){
            if(team.role!=='admin' && (team.role!=='member' || team.admin_groups_new === undefined || team.admin_groups_new.length<=0)){
                window.location=`/team/${team._id}`;
            }
            else{
                fetchGroup();
            }
        }
    },[teamUpdating]);

    return(
        <>
        {!loading ? 
            <>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}/your-forms`}>
                        Forms
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Edit Form
                </div>
                <div className='body px-5 lg:px-8 py-10'>
                    <div className="rounded-xl bg-[var(--color-darkblue)] py-7.5 px-5">
                        <div className="form-heading text-center mb-[30px] !text-white">Edit Form</div>
                        {successMessage!=='' && <p className="text-green-500 mb-2.5 text-center" role="alert">{successMessage}</p>}
                        <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">                            
                            <div className="w-full">
                                <div className="form-field-container">
                                    <div className="label">Form Title*</div>
                                    <div className='form-field-wrap mb-5 facility-field-wrap relative'>
                                        <input 
                                            type="text" id="form-name" disabled={loading} placeholder="Form Title*" className='text-field'
                                            {...register("form_title")}
                                            aria-invalid={errors.form_title ? "true" : "false"} 
                                        />                                
                                    </div>
                                    {errors.form_title && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.form_title?.message}</p>}
                                </div>
                            </div>
                            <div className="form-field-container">
                                <div className="label">Form Description*</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                    <textarea 
                                        id="form-description" disabled={loading} placeholder="Form Title*" className='textarea-field'
                                        {...register("description")}
                                        aria-invalid={errors.description ? "true" : "false"} 
                                    />                                
                                </div>
                                {errors.description && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.description?.message}</p>}                                
                            </div>
                            <div className="flex flex-wrap mb-12">
                                <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">Visible To*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap groups-field-wrap relative' ref={contentRef}>
                                        <Controller
                                            control={control}
                                            name='groups'
                                            render={({ field: { onChange, value, ref }}) => (
                                                <>
                                                <CheckTreePicker
                                                    defaultExpandAll
                                                    placeholder="Select Groups*"
                                                    data={groups}
                                                    onChange={val => {
                                                        onChange(val.map(c => c.value));
                                                        setValue('groups',val);
                                                        setSelectedGroups(val);
                                                        //handleSelectChange(val);
                                                    }}
                                                    disabledItemValues={disabledGroupsOpt}
                                                    cleanable={false}
                                                    container={()=>contentRef.current}
                                                    menuStyle={{zIndex: 100000000}}
                                                    defaultValue={selectedGroups}
                                                    value={selectedGroups}
                                                    style={{
                                                        width:'100%',
                                                        maxWidth:'500px',
                                                        borderWidth:0,
                                                        backgroundColor: '#EFF1F9',
                                                        padding: '8px 7px 8px 46px',
                                                        height: '52px',
                                                        borderRadius: 8
                                                    }}
                                                    onSelect={(item,val,event)=>{
                                                            if(item.label.toLowerCase()==="all members"){
                                                                if(item.check){
                                                                    let groupIds=[];
                                                                    groups.forEach((item)=>{
                                                                        groupIds.push(item.value);
                                                                    });
                                                                    setSelectedGroups(groupIds);
                                                                }
                                                                else{
                                                                    setSelectedGroups([]);
                                                                }
                                                            }
                                                        }
                                                    }
                                                />                                            
                                                </>
                                            )}
                                        />
                                        </div>
                                        {errors.groups?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.groups?.message}</p> }
                                    </div>
                                </div>
                                {isStripeConnect &&
                                <>
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">Do you want to connect a Shop Item to this form?</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                                <select {...register("is_payment_required")} onChange={(e)=>{setValue('is_payment_required', e.target.value);}}>                                                
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {watchPaymentRequired==='Yes' &&
                                        <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                            <div className="form-field-container">
                                                <div className="label">Connect a Shop Item...*</div>
                                                <div className='form-field-wrap mb-5 select-field-wrap product-field-wrap relative'>
                                                    <select {...register("product_id")} onChange={(e)=>setValue('product_id', e.target.value, { shouldValidate: true })}>
                                                        <option value="">Select Shop Item</option>
                                                        {products.length>0 &&
                                                            <>
                                                                {products.map((product)=>{
                                                                    return(
                                                                        <option key={product._id} value={product._id}>{product.name}</option>
                                                                    )
                                                                })
                                                            }
                                                            </>
                                                        }
                                                    </select>
                                                </div>
                                                {errors.product_id && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.product_id?.message}</p>}
                                            </div>
                                        </div>
                                    }
                                </>
                                }
                            </div>
                            <div className="flex flex-wrap mb-12">
                                <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">Does this form have an expiry date?</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                            <select {...register("is_expire")} onChange={(e)=>{setValue('is_expire', e.target.value);}}>                                                
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {watchis_expire==="Yes" &&
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">Expiry Date*</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                                                <Controller
                                                    control={control}
                                                    name='expire_date'
                                                    errors={errors}
                                                    render={({ field, ref }) => (
                                                        <ReactDatePicker
                                                            placeholderText='End Date *'
                                                            minDate={new Date()}
                                                            onChange={(date) => {field.onChange(date)}}
                                                            selected={field.value}
                                                            mask="____-__-__"
                                                            dateFormat="dd-MM-yyyy"
                                                            inputRef={ref}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            {errors.expire_date && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.expire_date?.message}</p>}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='mb-15'>
                                {fields.map((item, index) => {
                                return (
                                    <div key={item.id} className="bg-[#EFF1F9] rounded-xl py-4 px-4.5 mb-7.5">
                                    <div className="mb-7.5">
                                        <div className="text-[#53545C] text-sm font-normal mb-2.5">Select Question Type</div>
                                        <div className='form-field-wrap mb-5 questiontype-field-wrap relative flex gap-5 items-center justify-beetween'>
                                            <select {...register(`questions.${index}.question_type`)} className="bg-white flex-1">
                                            <option value="short_answer">Short Answer</option>
                                            <option value="paragraph">Paragraph</option>
                                            <option value="single_selection">Single Choice</option>
                                            <option value="checkbox">Multiple Choice</option>
                                            </select>
                                            {fields.length > 1 &&
                                                <div className="w-10">
                                                    <button type="button" className="delete-question" onClick={() => remove(index)}></button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                    {watch(`questions.${index}.question_type`) === 'paragraph' ?
                                        <textarea className="input-question" {...register(`questions.${index}.question`)}></textarea>
                                        :
                                        <input type="text" className="input-question" {...register(`questions.${index}.question`)} />
                                    }              
                                    </div>
                                    {watch(`questions.${index}.question_type`) === 'checkbox' && 
                                        <>
                                        <FieldOption nestIndex={index} questionType="checkbox"  {...{ control, register, }} />
                                        </>
                                    }
                                    {watch(`questions.${index}.question_type`) === 'single_selection' && 
                                        <>
                                        <FieldOption nestIndex={index} questionType="radio"  {...{ control, register }} />
                                        </>
                                    }
                                    <div className="flex justify-end pt-4">
                                        <div className="flex items-center">
                                        <label className="inline-flex items-center cursor-pointer">
                                            <span className='text-sm mr-1.5'>Required</span>
                                            <input type="checkbox" value="" className="sr-only peer" {...register(`questions.${index}.is_required`)} />
                                            <div className="relative w-11 h-6 bg-[rgba(54,63,108,0.1)] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-[var(--color-yellow)] peer-checked:after:bg-[var(--color-yellow)] after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[var(--color-darkblue)] after:border-[var(--color-darkblue)] after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[var(--color-darkblue)]"></div>                    
                                        </label>
                                        </div>
                                    </div>
                                    </div>
                                );
                                })}
                            </div>
                            <div className="w-full md:px-2.5 xl:px-3.5 flex flex-col sm:flex-row gap-7.5 items-end justify-center lg:justify-end">
                                <button type="button" className='rounded-[20px] flex items-center justify-center border-2 border-white p-2.5 h-[45px] w-[220px] uppercase gap-2.5 text-white text-base font-semibold'
                                    onClick={() => {
                                        append({ question: "Question", question_type:'short_answer',options:['Option 1'], is_required: false });
                                    }}>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 2.69971V15.2997" stroke="currentColor" strokeWidth="2"/>
                                        <path d="M15.3003 8.99951L2.70029 8.99951" stroke="currentColor" strokeWidth="2"/>
                                    </svg>
                                    Add Field
                                </button>
                                <input className='button !text-base !font-semibold p-2.5 h-[45px] w-[220px]' type="submit" name="confirm" value="Update Form" disabled={loading}/>                                    
                            </div>
                            <div className='flex justify-center'>
                            {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}                            
                            </div>
                        </form>
                    </div>
                </div>
            </>
        :
            <div className="py-5 text-center"><Spinner /></div>
        }
        </>
    )
}

const AdminEditForms=()=>{    
    const { id, formid } = useParams();    
    return(
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="">
                    <AdminNavbar heading="Edit Form" />  
                    <EditDynamicForm formID={formid} />              
                </div>
            </TeamContextProvider>
        </>
    )
}

export default AdminEditForms;