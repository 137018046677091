import { useEffect, useState, useContext } from "react";
import { useParams, NavLink } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import CSVReader from 'react-csv-reader';
//import { CSVLink } from "react-csv";
import { getMembers } from "../../../libs/dataFunctions";
import { TeamContext, TeamContextProvider } from "../../../contexts/TeamContext";
import JsFileDownloader from 'js-file-downloader';

const GameImportForm = () =>{
    const [loading,setLoading] = useState(false);
    const [dwloading,setDwLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(false);
    const [data, setData] = useState(null);
    const [originalFile, setOriginalFile] = useState(null);
    const {team, teamUpdating } = useContext(TeamContext);
    const [csvReport, setCsvReport] = useState(null);

    const headersCsv = [
        { label: "sub", key: "sub" },
        { label: "name", key: "name" },
        { label: "is_played(yes/no)", key: "is_played" },
        { label: "is_batting_occur(yes/no)", key: "is_batting_occur" },
        { label: "run", key: "run" },
        { label: "is_not_out(yes/no)", key: "is_not_out" },
        { label: "wickets", key: "wickets" },
        { label: "maiden_over", key: "maiden_over" },
        { label: "bowling_runs", key: "bowling_runs" },
        { label: "total_catch", key: "total_catch" },
        { label: "total_run_out", key: "total_run_out" },
        { label: "total_stumping", key: "total_stumping" },
        { label: "man_of_the_match(yes/no)", key: "man_of_the_match" },
    ];

    const handleForce = (data, fileInfo, originalFile) => {
        setData(null);
        setOriginalFile(null);
        setErrorMessage(null);
        setOriginalFile(originalFile);
        /*data = data.map((row) => {
            const { valid, keys } = verifyRow(row)            
            row.valid = valid
            row.keys = keys
            return row;
        })
        var errors = data.filter((row) => !row.valid)
        if (errors.length > 0) {
            setErrorMessage("Found "+ (errors.length > 1 ? errors.length + " errors" : errors.length + " error") +" in your file. Download template and compare your data.");
        } else {
            setErrorMessage(null)
        }*/
        setData(data);
    }
    /*const requiredKeys = ['name','sub']
    function verifyRow(data) {
        var missing = []
        for (var i = 0; i < requiredKeys.length; i++) {
            if (!data[requiredKeys[i]]) {
                missing.push(requiredKeys[i])
            }
        }

        // for every item in object verifycell
        for (var key in data) {
            if (!verifyCell(key, data)) {
                setErrorMessage('Errors found. Invalid date format')
                missing.push(key)
            }
        }
        return { valid: missing.length < 1, keys: missing }
    }
    function verifyCell(key, data) {
        //check data["start_date (yyyy-mm-dd)"] format is yyyy-mm-dd regex
        if (key === 'start_date (yyyy-mm-dd)' && !data[key].match(/^\d{4}-\d{2}-\d{2}$/)) {
            return false
        }
    
        //check data["end_date (yyyy-mm-dd)"] format is yyyy-mm-dd regex
        if (key === 'end_date (yyyy-mm-dd)' && !data[key].match(/^\d{4}-\d{2}-\d{2}$/)) {
            return false
        }
    
        return true
    }*/
    function cleanVariableName(variableName) {
        return variableName.replace(/_/g, ' ').replace('yyyy mm dd', 'yyyy-mm-dd')
    }
    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
    }

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const convert2base64 = file =>new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleSubmit = async(data) => {
        setLoading(true);
        const file = originalFile;
        setErrorMessage(null);
        if(file.type === 'text/csv'){
            let bs64code = await convert2base64(file);
            let bs64codeData = bs64code.replace('data:', '').replace(/^.+,/, '');
            var formdata = new FormData();
            formdata.append("type", "import_fantasy_game_data_new");
            formdata.append("team_id", team._id);
            formdata.append("document_content", bs64codeData);
            formdata.append("document_extension", 'csv');

            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_fantasy' , requestOptions)
            .then(response => response.text())
            .then(result => {                
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    setLoading(false);
                    setErrorMessage(rdata.message);
                }
                else{
                    setLoading(false);
                    setData(null);
                    setOriginalFile(null);
                    setErrorMessage(null);
                    window.location.href='/team/'+team._id+'/fantasygame';
                }
            })
            .catch(error => console.log('error', error));
        }
        else{
            setErrorMessage('Invalid file type, please upload CSV file.');
        }
    }

    const downloadCsvTemplate=async()=>{
        setDwLoading(true);
        var formdata = new FormData();
        formdata.append("type", "get_fantasy_points_csv");
        formdata.append("team_id", team._id);

        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_fantasy' , requestOptions)
        .then(response => response.text())
        .then(result => {                
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setDwLoading(false);
                //setErrorMessage(rdata.message);
            }
            else{
                setDwLoading(false);
                //setErrorMessage(null);                
                new JsFileDownloader({ 
                    url:rdata.data.pdf,
                })
            }
        })
        .catch(error => console.log('error', error));
        
    }

    async function refreshTeamMembers(){
        setLoading(true);
        const [teamMemberData] = await Promise.all([
            getMembers(team._id, token)
        ]);
        var tmpMembers = [];
        teamMemberData.forEach((member)=>{
            var tmp = {};
            if(member.price!==0){
                tmp.sub=member.sub;
                tmp.name=member.name;
                tmp.is_played=null;
                tmp.is_bating_occur=null;
                tmp.run=null;
                tmp.is_not_out=null;
                tmp.wickets=null;
                tmp.maiden_over=null;
                tmp.bowling_runs=null;
                tmp.total_catch=null;
                tmp.total_run_out=null;
                tmp.total_stumping=null;
                tmp.man_of_the_match=null;
                tmpMembers.push(tmp);
            }
        });

        setCsvReport({
            data: tmpMembers,
            headers: headersCsv,
            filename: 'Fantasy_Team_Data.csv'
        });

        setLoading(false);
    }

    useEffect(()=>{
        if(!teamUpdating){
            if(team.is_fantasy_game_available && team.stripeEnabled){
                refreshTeamMembers();
            }
            else{
                window.location.href = '/team/'+team._id;
            }
        }
        else{
            setLoading(true);
        }
    },[teamUpdating]);
    
    return (
        !loading && !teamUpdating?
        <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}`}>
                    {team.name}
                </NavLink>
                <span className='separator'>&lt;</span>
                Upload Fantasy Game
            </div>
            <div className="body px-5 lg:px-8 py-10">
                <div className="team-contents w-full rounded-xl bg-white py-6 px-6">
                    <div className="form-heading mb-[30px]">Upload Fantasy Game</div>
                    <div className="mb-5">
                        {csvReport!==null &&
                            <p className="mb-5">
                                Here you can import your game via a spreadsheet file quickly and easily. Start by downloading{' '}
                                {!dwloading?
                                    <button className="button button--medium" type="button" onClick={()=>{downloadCsvTemplate()}}>Download template</button>
                                    :
                                    <span className="button button--medium">Generating...</span>
                                }
                                
                            </p>
                        }
                        <button className="button button--medium button-gray" onClick={() => {
                            window.open('https://calendly.com/plaisport_ceo/plai-import-support', '_blank')
                        }}>Book a demo</button>
                    </div>
                    <div className="w-full flex items-center justify-start mb-2.5">
                        <label className="button button--medium button-blue cursor-pointer" style={{ height: 'fit-content' }} >
                            <span>Choose a file</span>
                            <CSVReader cssClass="react-csv-input" inputStyle={{ display: 'none' }} label="" onFileLoaded={handleForce} parserOptions={papaparseOptions} />
                        </label>
                        {data!==null && errorMessage === null && 
                            <>
                                <input className='button button--medium  ml-5' type="button" onClick={()=>{handleSubmit()}} value="Confirm & Import" disabled={loading}/>
                            </>
                        }
                    </div>
                    {data && (
                        <div className="game-import">
                            {errorMessage !== null && 
                                <><p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p></>
                            }
                            <table className="table-auto w-full">
                                <thead>
                                <tr className="row">
                                    {data &&
                                    Object.keys(data[0]).map((key) => {
                                        if (key !== 'valid') {
                                            return (
                                                <th className="text-[12px] uppercase text-left px-2.5 py-2.5 border-b" key={key}>
                                                    {cleanVariableName(key)}
                                                </th>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {data &&
                                    data.map((row, index) => (
                                    <tr className={'row '} key={index}>
                                        {Object.keys(row).map((key) => {
                                        if (key !== 'valid') {
                                            return (
                                            <td className={'text-[12px] text-left px-2.5 py-2.5 border-b ' + (row.keys && row.keys.includes(key) ? 'data-error' : '')}
                                                key={key}>
                                                {row[key]}
                                            </td>
                                            )
                                        } else {
                                            return null
                                        }
                                        })}
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}


function GameImport(){
    const { id } = useParams();

    return(
        <>
            <Sidebar type="thin"/>
            <TeamContextProvider id={id}>
                <div id="page" className="page-team">
                    <AdminNavbar heading="Upload Fantasy Game"/>
                    <GameImportForm/>                    
                </div>
            </TeamContextProvider>
        </>
    )
}

export default GameImport;