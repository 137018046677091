import { useEffect } from 'react';
import { useState } from 'react';
import placeholderImg from '../../../assets/images/placeholder.png';

const JoinGamePlayer = ({iscreatedBefore, player, players, setPlayers, setSelectedPlayers, selectedPlayers, totalBudget, setTotalBudget, maxPlayer, spendAmount, setSpendAmount, threeStarTotal, setThreeStarTotal, twoStarTotal, setTwoStarTotal, gameRule, playersMainList, setPlayersMainList, captain, setCaptain, vcaptain, setVCaptain}) =>{
    const [internalSelected, setInternalSelected] = useState(false);
    const selectOnClick = () =>{
        if(selectedPlayers.length < maxPlayer){
            let goAhead = false;
            if(player.rating===3){
                if(threeStarTotal>0){
                    setThreeStarTotal(threeStarTotal-1);
                    goAhead = true;
                }
                else{
                    alert("You can add maximum "+gameRule.three_star_player+" Icon players");
                }
            }
            else if(player.rating===2){
                if(twoStarTotal>0){
                    setTwoStarTotal(twoStarTotal-1);
                    goAhead = true;
                }
                else{
                    alert("You can add maximum "+gameRule.two_star_player+" Pro players");
                }
            }
            else{
                goAhead = true;
            }
            if(((totalBudget  - player.price) < 0) && goAhead){
                goAhead=false;
                alert("You don't have sufficient balance to buy this player.");
            }
            if(goAhead){
                setSpendAmount( spendAmount + player.price );
                setTotalBudget( totalBudget  - player.price );
                setSelectedPlayers(oldArray => [...oldArray,player] );
                const playerIndex = players.findIndex(element => element._id === player._id );
                let newArray = [...players];
                newArray[playerIndex] = {...newArray[playerIndex], is_tmpselected: true }
                setPlayers(newArray);

                const playerIndex2 = playersMainList.findIndex(element => element._id === player._id );
                let newArray2 = [...playersMainList];
                newArray2[playerIndex2] = {...newArray2[playerIndex2], is_tmpselected: true }
                setPlayersMainList(newArray2);

                setInternalSelected(true);
            }
        }
        else{
            alert("You can add maximum "+maxPlayer+" players");
        }
    }
    const deselectOnClick = () =>{
        setSpendAmount( spendAmount - player.price );
        setTotalBudget( totalBudget + player.price );
        if(player.rating===3){
            setThreeStarTotal(threeStarTotal + 1);
        }
        else if(player.rating===2){
            setTwoStarTotal(twoStarTotal + 1);
        }
        if(player.sub===captain){
            setCaptain(null);
        }
        if(player.sub===vcaptain){
            setVCaptain(null);
        }
        setSelectedPlayers((current)=>current.filter((mitem) => mitem._id !== player._id));        
        const playerIndex = players.findIndex(element => element._id === player._id );
        let newArray = [...players];
        newArray[playerIndex] = {...newArray[playerIndex], is_tmpselected: false }
        setPlayers(newArray);

        const playerIndex2 = playersMainList.findIndex(element => element._id === player._id );
        let newArray2 = [...playersMainList];
        newArray2[playerIndex2] = {...newArray2[playerIndex2], is_tmpselected: false }
        setPlayersMainList(newArray2);

        setInternalSelected(false);
    }
    const getRatingContent = () => {
        let ratingContent = [];
        for (let i = 1; i <= player.rating; i++) {
            ratingContent.push(<span className="star" key={i}>&#9733;</span>);
        }
        return ratingContent;
    };
    useEffect(()=>{
        setInternalSelected(player.is_tmpselected !== undefined && player.is_tmpselected ? true : false);
    },[]);
    return(
        <div className="w-full max-w-[364px] flex px-3 mb-5">
            <div className={`player-item ${player.is_selected || internalSelected ? 'player-item-selected' : ''} w-full px-2.5 py-2.5 flex justify-between rounded-[16px] items-center`}>
                <div className="flex items-center flex-1">
                    {player.profilePicture !=="" ?
                        <img src={player.profilePicture} width="50" height="50" className="rounded-full w-[50px] mr-[18px]" alt="" />
                    :
                        <img src={placeholderImg} width="50" height="50" className="rounded-full w-[50px] mr-[18px]" alt="" />
                    }
                    <div className="flex-1 pr-2.5">
                        <div className="player-name text-[16px] leading-[24px] font-semibold">{player.name}</div>
                        <div className="flex text-[14px] leading-[21px] font-semibold items-center">
                            <div className='player-price mr-1'>{`Price: $${player.price}`}m</div>
                            {player.trending_status!=='equal' &&
                                <>
                                    <div className={`${player.trending_status==='up' ? 'trending-up' : 'trending-down'}`}></div>   
                                </>
                            }                            
                            <div className='ratings'>
                                {getRatingContent()}
                            </div>
                        </div>
                    </div>
                </div>
                {!iscreatedBefore &&
                <button className={`player-btn ${internalSelected ? 'player-btn-remove' : 'player-btn-add'} mr-3 cursor-pointer rounded-full`} 
                    onClick={()=>{internalSelected ? deselectOnClick() : selectOnClick()}}></button>
                }
            </div>
        </div>
    )
}

export default JoinGamePlayer;