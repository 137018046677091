// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/char_counter.min.js'
import 'froala-editor/js/plugins/image.min.js';
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'

import FroalaEditor from 'react-froala-wysiwyg'

function TextEditor(props) {
  
  const config = {
    placeholderText: props.placeholderText,
    charCounterCount: props.charCount!==undefined ? true : false,
    height: props.height,
    attribution: false,
    imageDefaultAlign: 'left',
    imagePaste: true,
    imagePasteProcess: true,
    imageResize: false,
    //imageUploadURL: 'https://dashboard.plaisport.com/upload_content',
    //imageUploadMethod: 'PUT',
    //imageUploadParam: 'files[]',
    imageMaxSize: 3 * 1024 * 1024, // Set max image size to 3MB.
    imageEditButtons: ['imageDisplay', 'imageAlign', 'imageInfo', 'imageRemove'],
    key: 'eHE5C-11G2C1F2G1A5C3D-17xekbC-7H-7aB-16dD1qG-10yiB3B9B6A5C2B4C3H3G3C2==',
    charCounterMax: props.charLimit!==undefined ? props.charLimit : -1,
    zIndex: 999999,
    /*events:{
      "image.beforeUpload": function (files) {
        var editor = this;
        if (files.length) {
            // Create a File Reader.
            var reader = new FileReader();
            // Set the reader to insert images when they are loaded.
            reader.onload = function (e) {
                var result = e.target.result;
                editor.image.insert(result, null, null, editor.image.get());
            };
            // Read image as base64.
            reader.readAsDataURL(files[0]);
        }
        editor.popups.hideAll();
        // Stop default upload chain.
        return false;
      }      
    }*/
      events:{
        "image.beforeUpload": function (files) {
          var editor = this;
          if (files.length) {
            var formdatas3 = new FormData();
            formdatas3.append("files[]", files[0]);
            editor.popups.show('image.insert');
            editor.image.showProgressBar();
            var requestOptionss3 = {
              method: 'PUT',
              body: formdatas3,
              redirect: 'follow'
            };
            fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3)
            .then(response => response.text())
            .then(result => {
              let rdata = JSON.parse(result);

              // Insert the uploaded image into the editor
              editor.image.insert(rdata.data[0], null, null, editor.image.get());

              // Hide the progress bar and the loading popup
              editor.image.hideProgressBar();
              editor.popups.hide('image.insert');
            });
          }
          // editor.popups.hideAll();
          // Stop default upload chain.
          return false;
        },
        "image.pasteBefore": function (img) {
          var editor = this;

          // Show Froala's loading popup
          editor.popups.show('image.insert');
          editor.image.showProgressBar();

          var formdatas3 = new FormData();
          formdatas3.append("files[]", img[0]);

          var requestOptionss3 = {
            method: 'PUT',
            body: formdatas3,
            redirect: 'follow'
          };

          fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3)
            .then(response => response.text())
            .then(result => {
              let rdata = JSON.parse(result);

              // Replace the pasted image src with the final URL
              img[0].src = rdata.data[0];

              // Hide the progress bar and the loading popup
              editor.image.hideProgressBar();
              editor.popups.hide('image.insert');
            })
            .catch(error => {
              console.error('Error uploading image:', error);

              // Hide the progress bar and the loading popup in case of error
              editor.image.hideProgressBar();
              editor.popups.hide('image.insert');
            });

          // Prevent default behavior
          return false;
        }
      }
}

  return (
    <div className="editor">
      <FroalaEditor tag="textarea" config={config} model={props.value} onModelChange={props.onChange} />
    </div>
  )
}

export default TextEditor;