import { useState, useEffect, createContext, useContext } from "react";
import { getSports } from "../libs/dataFunctions";
export const FormContext = createContext();

export default function FormProvider({ children }) {
    const [data, setData] = useState({});
    const [sports, setSports] = useState([]);
    const setFormValues = (values) => {
        setData((prevValues) => ({
            ...prevValues,
            ...values,
        }));
    };
    async function refresh() {
        const [sportsData] = await Promise.all([
            getSports(),
        ]);
        setSports(sportsData);
    }
    useEffect(() => {
        refresh()
    }, []);
    

    return (
        <FormContext.Provider value={{ data, setFormValues, sports }}>
            {children}
        </FormContext.Provider>
    );
}

export const useFormData = () => useContext(FormContext);