import { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import 'rsuite/dist/rsuite-no-reset.min.css';
import CheckTreePicker from 'rsuite/CheckTreePicker';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import Spinner from "../../../components/Spinner/Spinner";
import TextEditor from "../../../components/TextEditor";
import PlaiImageCropper from "../../../components/ImageCropper/ImageCropper";
import { getGroupsV2, getUserAsignedGroup, getProductCategories } from "../../../libs/dataFunctions";
import InfoIcon from '../../../assets/images/info-white.svg';
import CurrencyInput from "react-currency-input-field";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { generateRandomName } from "../../../components/ImageCropper/cropUtils";
import 'filepond/dist/filepond.min.css';
import * as _ from "lodash";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

registerPlugin(FilePondPluginFileValidateType);
dayjs.extend(utc);
dayjs.extend(tz);

const schema = yup.object({
    name: yup.string().required('Product name is required.'),
    price: yup.number().min(2).required('Enter product price'),
    onSale: yup.string().required('Select option for discount.'),
    salePrice:yup.string().when('onSale',{
        is:'true',
        then:yup.string().required('Enter sale price')
    }),
    quantityUnlimited: yup.string().required('Select option for quantity.'),
    quantity:yup.string().when('quantityUnlimited',{
        is:'false',
        then:yup.string().required('Enter quantity')
    }),
    category: yup.string().required('Category is required.'),
    stripe_account: yup.string().required('Stripe account is required.'),
    allow_installment: yup.string().required('Select option for installment.'),
    total_installment:yup.string().when('allow_installment',{
        is:'Yes',
        then:yup.string().required('How many instalments would you like to offer your member?')
    }),
    installment_type:yup.string().when('allow_installment',{
        is:'Yes',
        then:yup.string().required('How regularly would you like these installments to be settled?')
    }),    
    groups: yup
        .array()
        .min(1, "At least one group must be selected")
        .nullable()
        .required("At least one group must be selected"),
    duration_of_membership:yup.string()
        .when('category',{
            is:'Memberships',
            then:yup.string().required('Select Expiry Duration')
        }).nullable(),
    newcategory:yup.string()
        .when('category',{
            is:'new_catg',
            then:yup.string().required('Enter New Category')
        }).nullable(),
    size:yup.array()
        .when('is_multiple_size',{
            is:'Yes',
            then:yup.array()
                .min(1, "At least one size must be selected")
                .nullable()
                .required("At least one size must be selected"),
        }).nullable(),
    sleeve:yup.array()
        .when('is_multiple_sleeve',{
            is:'Yes',
            then:yup.array()
                .min(1, "At least one sleeve must be selected")
                .nullable()
                .required("At least one sleeve must be selected"),
        }).nullable(),
    color:yup.array()
        .when('is_multiple_color',{
            is:'Yes',
            then:yup.array()
                .min(1, "At least one color must be selected")
                .nullable()
                .required("At least one color must be selected"),
        }).nullable(),
    gender:yup.array()
        .when('is_multiple_gender',{
            is:'Yes',
            then:yup.array()
                .min(1, "At least one gender must be selected")
                .nullable()
                .required("At least one gender must be selected"),
        }).nullable(),
}).required();

const ProductForm = ({ product, team, setShowForm, refreshShop, refreshProduct }) => {
    const [loading, setLoading] = useState(false);
    const [editorContent, setEditorContent] = useState('');
    const [productImage, setProductImage] = useState('');
    const [imageErrorMsg, setImageErrorMsg] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [isDiscount, setIsDiscount] = useState('false');
    const [isInventory, setInventory] = useState('true');
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [disabledGroupsOpt, setDisableGroupsOpt]= useState([]);
    
    const [pdfFiles, setPdfFile] = useState([]);
    const [categories, setCategories] = useState([]);
    
    const [colors, setColors]=useState([]);
    const [gender, setGender]=useState([]);
    const [sizes, setSizes]=useState([]);
    const [sleeves, setSleeves]=useState([]);

    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedGender, setSelectedGender] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [selectedSleeves, setSelectedSleeves] = useState([]);

    const [stripeAccounts, setStripeAccounts] = useState([]);
    const [allowInstallment, setAllowInstallment] = useState(false);
    const [totalInstallment,setTotalInstallMent] = useState(0);
    const [productWatchPrice, setProductWatchPrice] = useState(0);
    const [installMentAmountError, setInstallMentAmountError] = useState(null);
    const [membershipDurations, setMembershipdurations] = useState([]);
    const [transactionFee, setTransactionFee] = useState(0);
    const contentRef=useRef();
    const contentRef2=useRef();
    const contentRef3=useRef();
    const contentRef4=useRef();
    const contentRef5=useRef();

    const { register, handleSubmit, setValue, watch, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const isNewProduct = product ? false : true;

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');
            resolve(base64String);
        }
        reader.onerror = error => reject(error);
    });

    const SubmitNewProduct = async (data) => {
        setImageErrorMsg(false);
        setInstallMentAmountError(null);
        
        if(data.allow_installment === 'Yes'){
            if((data.price - _.sum(installmentAmounts)) !== 0){
                setInstallMentAmountError('Instalment price sum should be same as original price.');
                return;
            }
            for (let installment = 0; installment < Number(data.total_installment); installment++) {
                if(data[`installment_value${installment}`]==='' || data[`installment_value${installment}`]===null || data[`installment_value${installment}`]===0){
                    setInstallMentAmountError('Oops! Ensure there is an amount within every Instalment bracket.');
                    return;
                }
            }
        }
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "create_product");
        formdata.append("team_id", team._id);
        formdata.append("name", data.name);
        formdata.append("price", data.price * 100);
        formdata.append("onSale", data.onSale);
        formdata.append("active", data.publish);
        if (data.onSale === "true") {
            formdata.append("salePrice", data.salePrice * 100);
        }
        formdata.append("quantityUnlimited", data.quantityUnlimited);
        if (data.quantityUnlimited === "false") {
            formdata.append("quantity", data.quantity);
        }
        formdata.append('description', editorContent);
        formdata.append('currency', team.currency_code);
        formdata.append("group_ids", selectedGroups.join('@#'));
        formdata.append('separate_by', '@#');
        if (data.category==='new_catg' && data.newcategory!=='') {
            formdata.append('category', data.newcategory);
        }
        else {
            formdata.append('category', data.category);
        }
        if(data.is_multiple_size==='Yes'){
            formdata.append('size', selectedSizes.join('@#'));
        }
        if(data.is_multiple_sleeve==='Yes'){
            formdata.append('sleeve', selectedSleeves.join('@#'));
        }
        if(data.is_multiple_color==='Yes'){
            formdata.append('color', selectedColors.join('@#'));
        }
        if(data.is_multiple_gender==='Yes'){
            formdata.append('gender', selectedGender.join('@#'));
        }

        formdata.append('is_personalize', data.is_personalize);

        if(data.category==='Memberships'){
            formdata.append('duration_type', data.duration_type);
            if(data.duration_type==='variable'){
                formdata.append('duration_of_membership', data.duration_of_membership);
            }
            else{
                formdata.append('duration_of_membership', dayjs(data.duration_of_membership).format("YYYY-MM-DD"));
            }
        }
        else{
            formdata.append('duration_type', '');
            formdata.append('duration_of_membership', '');
        }
        formdata.append('stripe_account_id', data.stripe_account);

        if (productImage !== '') {
            var formdatas3 = new FormData();
            let tmpFilename = generateRandomName();
            const imgFileObj = new File([productImage], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: productImage.type })
            formdatas3.append("files[]", imgFileObj);
            var requestOptionss3 = {
                method: 'PUT',
                body: formdatas3,
                redirect: 'follow'
            };
            const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
            const resultsApp = await responsesApp.text();
            const rdatasApp = JSON.parse(resultsApp);
            formdata.append('content',rdatasApp.data[0]);
        }
        if (pdfFiles.length > 0) {
            let pdfcodes = [];
            let exts = [];
            for (let i = 0; i < pdfFiles.length; i++) {
                pdfcodes[i] = await toBase64(pdfFiles[i].file);
                exts[i] = pdfFiles[i].fileExtension;
            };
            formdata.append("document_content", pdfcodes.join('@#'));
            formdata.append("document_extension", exts.join('@#'));
        }
        
        formdata.append('allow_installment', data?.allow_installment);
        if (data?.allow_installment === 'Yes') {
            formdata.append('total_installment', data?.total_installment);

            const installmentAmounts = [];

            for (let installment = 0; installment < Number(data.total_installment); installment++) {
                installmentAmounts.push(Number(data[`installment_value${installment}`]* 100));
            }

            formdata.append('installment_values', installmentAmounts.join("@#"));
            formdata.append('installment_type', data?.installment_type);
        }
        formdata.append('notify_email', data?.notify_email ? 'Yes' : 'No');
        formdata.append('notify_notification', data?.notify_notification ? 'Yes' : 'No');
        formdata.append('is_required_delivery', data?.is_required_delivery ? 'Yes' : 'No');

        var secureHeader = new Headers();
        secureHeader.append('Authorization', 'Bearer ' + token);
        secureHeader.append('device_type', 'W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_stripe_create_product', requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if (rdata.status !== 1) {
                    setErrorMessage(rdata.message);
                }
                else {
                    setShowForm(false);
                    refreshShop();
                }
            })
            .catch(error => console.log('error', error));
    }

    const SubmitUpdateProduct = async (data) => {
        setInstallMentAmountError(null);
        if(data.allow_installment === 'Yes'){
            if((data.price - _.sum(installmentAmounts)) !== 0){
                setInstallMentAmountError('Instalment price sum should be same as original price.');
                return;
            }
            for (let installment = 0; installment < Number(data.total_installment); installment++) {
                if(data[`installment_value${installment}`]==='' || data[`installment_value${installment}`]===null || data[`installment_value${installment}`]===0){
                    setInstallMentAmountError('Oops! Ensure there is an amount within every Instalment bracket.');
                    return;
                }
            }
        }
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "update_product");
        formdata.append("team_id", team._id);
        formdata.append("product_id", product._id);
        formdata.append("name", data.name);
        formdata.append("price", data.price * 100);
        formdata.append("onSale", data.onSale);
        formdata.append("active", data.publish);
        if (data.onSale === "true") {
            formdata.append("salePrice", data.salePrice * 100);
        }
        formdata.append("quantityUnlimited", data.quantityUnlimited);
        if (data.quantityUnlimited === "false") {
            formdata.append("quantity", data.quantity);
        }
        formdata.append('description', editorContent);
        formdata.append('currency', team.currency_code);
        if(selectedGroups.length > 0){
            formdata.append("group_ids", selectedGroups.join('@#'));
        }
        else{
            var preGroups = [];
            product.groups_new.forEach((item)=>{
                preGroups.push(item._id);
            });
            formdata.append("group_ids", preGroups.join('@#'));
        }
        formdata.append('separate_by', '@#');
        
        if (data.category==='new_catg' && data.newcategory!=='') {
            formdata.append('category', data.newcategory);
        }
        else {
            formdata.append('category', data.category);
        }

        if(data.category==='Memberships'){
            formdata.append('duration_type', data.duration_type);
            if(data.duration_type==='variable'){
                formdata.append('duration_of_membership', data.duration_of_membership);
            }
            else{
                formdata.append('duration_of_membership', dayjs(data.duration_of_membership).format("YYYY-MM-DD"));
            }
        }
        else{
            formdata.append('duration_type', '');
            formdata.append('duration_of_membership', '');
        }

        if(data.is_multiple_size==='Yes'){
            formdata.append('size', selectedSizes.join('@#'));
        }
        if(data.is_multiple_sleeve==='Yes'){
            formdata.append('sleeve', selectedSleeves.join('@#'));
        }
        if(data.is_multiple_color==='Yes'){
            formdata.append('color', selectedColors.join('@#'));
        }
        if(data.is_multiple_gender==='Yes'){
            formdata.append('gender', selectedGender.join('@#'));
        }

        formdata.append('is_personalize', data.is_personalize);

        formdata.append('stripe_account_id', data.stripe_account);

        if (productImage !== '') {
            var formdatas3 = new FormData();
            let tmpFilename = generateRandomName();
            const imgFileObj = new File([productImage], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: productImage.type })
            formdatas3.append("files[]", imgFileObj);
            var requestOptionss3 = {
                method: 'PUT',
                body: formdatas3,
                redirect: 'follow'
            };
            const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
            const resultsApp = await responsesApp.text();
            const rdatasApp = JSON.parse(resultsApp);
            formdata.append('content',rdatasApp.data[0]);
        }

        if (pdfFiles.length > 0) {
            let pdfcodes = [];
            let exts = [];
            for (let i = 0; i < pdfFiles.length; i++) {
                pdfcodes[i] = await toBase64(pdfFiles[i].file);
                exts[i] = pdfFiles[i].fileExtension;
            };
            formdata.append("document_content", pdfcodes.join('@#'));
            formdata.append("document_extension", exts.join('@#'));
        }
        

        formdata.append('allow_installment', data?.allow_installment);
        if (data?.allow_installment === 'Yes') {
            formdata.append('total_installment', data?.total_installment);

            const installmentAmounts = [];

            for (let installment = 0; installment < Number(data.total_installment); installment++) {
                installmentAmounts.push(Number(data[`installment_value${installment}`]* 100));
            }

            formdata.append('installment_values', installmentAmounts.join("@#"));
            formdata.append('installment_type', data?.installment_type);
        }
        formdata.append('notify_email', data?.notify_email ? 'Yes' : 'No');
        formdata.append('notify_notification', data?.notify_notification ? 'Yes' : 'No');
        formdata.append('is_required_delivery', data?.is_required_delivery ? 'Yes' : 'No');
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization', 'Bearer ' + token);
        secureHeader.append('device_type', 'W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_stripe_create_product', requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if (rdata.status !== 1) {
                    setErrorMessage(rdata.message);
                }
                else {
                    setShowForm(false);
                    refreshProduct(product._id, team._id, token);
                }
            })
            .catch(error => console.log('error', error));
    }

    const onSubmit = (data) => {
        if (isNewProduct) {
            SubmitNewProduct(data);
        }
        else {
            SubmitUpdateProduct(data);
        }
    };

    const handleChange = (e, key) => {
        const { value } = e.target
        setEditorContent(value);
    }
    const fetchFieldsData = async()=>{
        setLoading(true);
        const [groupsData, catgData] = await Promise.all([
            getGroupsV2(team._id, token),
            getProductCategories(team._id, token),
        ]);

        let groupOptions = [];
        groupsData.forEach(element => {
            let childOptions = [];
            if(element.nest_groups.length > 0){
                element.nest_groups.forEach(celm =>{
                    childOptions.push({'value':celm._id, 'label':celm.name});
                });
                groupOptions.push({'value':element._id, 'label':element.name, 'children':childOptions});
            }
            else{
                groupOptions.push({'value':element._id, 'label':element.name});
            }
        });
        setGroups(groupOptions);

        setCategories(catgData.category);

        let genderOptions = [];
        catgData.gender.forEach((element)=>{
            genderOptions.push({'value':element, 'label':element});
        });
        setGender(genderOptions);

        let sizeOptions = [];
        catgData.size.forEach((element)=>{
            sizeOptions.push({'value':element, 'label':element});
        });
        setSizes(sizeOptions);

        let colorOptions = [];
        catgData.color.forEach((element)=>{
            colorOptions.push({'value':element, 'label':element});
        });
        setColors(colorOptions);

        let sleeveOptions = [];
        catgData.sleeve.forEach((element)=>{
            sleeveOptions.push({'value':element, 'label':element});
        });
        setSleeves(sleeveOptions);

        if (catgData.stripe_accounts !== undefined) {
            setStripeAccounts(catgData.stripe_accounts);
        }

        if (catgData.total_transaction !== undefined) {
            setTransactionFee(catgData.total_transaction);
        }
        
        setMembershipdurations(catgData.duration_of_membership);
        setLoading(false);
    }

    const fetchFieldsDataForEdit = async()=>{
        setLoading(true);
        const [groupsData, catgData] = await Promise.all([
            getUserAsignedGroup(token, team._id, product._id, 'shop'),
            getProductCategories(team._id, token),
        ]);

        let disableGOpt=[];
        let groupOptions = [];
        groupsData.forEach(element => {
            let childOptions = [];
            if(!element.canSelect){
                disableGOpt.push(element._id);
            }
            if(element.nest_groups.length > 0){
                element.nest_groups.forEach(celm =>{
                    childOptions.push({'value':celm._id, 'label':celm.name});
                    if(!celm.canSelect){
                        disableGOpt.push(celm._id);
                    }
                });
                groupOptions.push({'value':element._id, 'label':element.name, 'children':childOptions});                
            }
            else{
                groupOptions.push({'value':element._id, 'label':element.name});
            }            
        });
        setDisableGroupsOpt(disableGOpt);
        setGroups(groupOptions);

        setCategories(catgData.category);

        let genderOptions = [];
        catgData.gender.forEach((element)=>{
            genderOptions.push({'value':element, 'label':element});
        });
        setGender(genderOptions);

        let sizeOptions = [];
        catgData.size.forEach((element)=>{
            sizeOptions.push({'value':element, 'label':element});
        });
        setSizes(sizeOptions);

        let colorOptions = [];
        catgData.color.forEach((element)=>{
            colorOptions.push({'value':element, 'label':element});
        });
        setColors(colorOptions);

        let sleeveOptions = [];
        catgData.sleeve.forEach((element)=>{
            sleeveOptions.push({'value':element, 'label':element});
        });
        setSleeves(sleeveOptions);

        if (catgData.stripe_accounts !== undefined) {
            setStripeAccounts(catgData.stripe_accounts);
        }

        if (catgData.total_transaction !== undefined) {
            setTransactionFee(catgData.total_transaction);
        }
        
        setMembershipdurations(catgData.duration_of_membership);
        setLoading(false);
    }
    
    useEffect(() => {
        if (!isNewProduct) {
            fetchFieldsDataForEdit();

            setSelectedGroups(product.group_ids);
            setValue('groups', product.group_ids);
            setEditorContent(product.description);
            setValue('name', product.name);
            setValue('price', product.price / 100);
            setProductWatchPrice(product.price / 100);
            setValue('onSale', product.onSale);
            setIsDiscount(product.onSale ? 'true' : 'false');
            setValue('publish', product.active ? 'true' : 'false');
            setValue('salePrice', product.salePrice / 100);
            if(product.salePrice!==0){
                setProductWatchPrice(product.salePrice / 100);
            }
            setValue('quantityUnlimited', product.quantityUnlimited ? 'true' : 'false');
            setInventory(product.quantityUnlimited ? 'true' : 'false');
            setValue('quantity', product.quantity);
            setValue('category', product.category);
            if (product.stripe_account !== undefined) {
                setValue('stripe_account', product.stripe_account._id);
            }
            if(product.category==='Memberships'){
                setValue("duration_type", product.duration_type);
                if(product.duration_type==='variable'){
                    setValue('duration_of_membership', product.duration_of_membership);
                }
                else{
                    setValue('duration_of_membership', new Date(product.duration_of_membership));
                }
                
            }
            else{
                setValue("duration_type", "fixed");
            }
            setValue('allow_installment', product?.allow_installment ? 'Yes' : 'No');

            if (product?.allow_installment) {
                setTotalInstallMent(product.installment_values.length);
                setValue('total_installment', product?.total_installment);
                setAllowInstallment('Yes');
                const installments = product.installment_values;                
                for (let installment = 0; installment < Number(product.total_installment); installment++) {
                    setValue(`installment_value${installment}`, installments[installment] / 100);
                }
                setValue('installment_type', product?.installment_type);
            }
            setValue('notify_email', product?.notify_email === 'Yes' ? true : false);
            setValue('notify_notification', product?.notify_notification === 'Yes' ? true : false);   
            setValue('is_required_delivery', product?.is_required_delivery === 'Yes' ? true : false); 
            setValue('is_personalize', product?.is_personalize ? 'Yes' : 'No');
            setValue('is_multiple_gender', product?.gender.length > 0 ? 'Yes' : 'No');
            if(product?.gender.length > 0){
                setValue('gender',product?.gender);
                setSelectedGender(product?.gender);
            }
            setValue('is_multiple_color', product?.color.length > 0 ? 'Yes' : 'No');
            if(product?.color.length > 0){
                setValue('color',product?.color);
                setSelectedColors(product?.color);
            }
            setValue('is_multiple_sleeve', product?.sleeve.length > 0 ? 'Yes' : 'No');
            if(product?.sleeve.length > 0){
                setValue('sleeve',product?.sleeve);
                setSelectedSleeves(product?.sleeve);
            }
            setValue('is_multiple_size', product?.size.length > 0 ? 'Yes' : 'No');
            if(product?.size.length > 0){
                setValue('size',product?.size);
                setSelectedSizes(product?.size);
            }
        }
        else {
            fetchFieldsData();

            if (stripeAccounts.length === 1) {
                setValue('stripe_account', stripeAccounts[0]._id);
            }
            setValue("duration_type", "fixed");
            setValue("is_multiple_size", "No");
            setValue("is_multiple_color", "No");
            setValue("is_multiple_sleeve", "No");
            setValue("is_multiple_gender", "No");
            setValue("is_personalize", "No");
        }
    }, []);

    useEffect(()=>{
        if (isNewProduct) {
            if (stripeAccounts.length === 1) {
                setValue('stripe_account', stripeAccounts[0]._id);
            }
        }
    },[stripeAccounts]);
    
    const installmentAmounts = [];

    for (let installment = 0; installment < Number(totalInstallment); installment++) {
        if(watch('installment_value' + installment)!==undefined){
            installmentAmounts.push(Number(watch('installment_value' + installment)));
        }
    }

    const watchProductCatg = watch("category");
    const watchMemDurationtype = watch("duration_type");
    const watchis_multiple_size = watch("is_multiple_size");
    const watchis_multiple_sleeve = watch("is_multiple_sleeve");
    const watchis_multiple_color = watch("is_multiple_color");
    const watchis_multiple_gender = watch("is_multiple_gender");
    
    return (
        <div className={`popup-form`}>
            <div className="popup-form-wrap relative !px-7.5 !w-full">
            {!loading ?
            <>            
                <button type="button" className="close-popup" onClick={(e) => { setShowForm(false) }}><span>close</span></button>
                <div className="bg-[#363F6C] py-7.5 px-5 rounded-xl">
                    <div className="form-heading text-center mb-[30px] !text-white">{isNewProduct ? 'Add Product' : 'Edit Product'}</div>
                    <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                        <div className="flex flex-wrap mb-12">
                            <div className="w-full xl:w-1/3 md:px-2.5">
                                <div className="form-field-container">
                                    <div className="label">Product name*</div>
                                    <div className='form-field-wrap mb-5 productname-field-wrap relative'>
                                        <input
                                            type="text" id="product-name" disabled={loading} placeholder="Product name*" className='text-field'
                                            {...register("name")}
                                            aria-invalid={errors.name ? "true" : "false"}
                                        />
                                    </div>
                                    {errors.name && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.name?.message}</p>}
                                </div>
                            </div>
                            <div className="w-full xl:w-1/3 md:px-2.5">
                                <div className="form-field-container">
                                    <div className="label">Price (Eg {team.currency_symbol}2.00)*</div>
                                    <div className='form-field-wrap mb-5 price-field-wrap relative'>
                                        <Controller
                                            name="price"
                                            control={control}
                                            render={({ field }) => (
                                                <CurrencyInput
                                                    placeholder="Product Price*"
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => { field.onChange(value); setProductWatchPrice(value); }}
                                                    defaultValue={!isNewProduct ? product.price / 100 : ''}
                                                />
                                            )}
                                        />
                                    </div>
                                    {errors.price && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.price?.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className='form-field-wrap mb-12 z-0 relative'>
                            <TextEditor
                                value={editorContent}
                                placeholderText="Product details will be here"
                                height={200}
                                onChange={(e) => handleChange({ target: { value: e } }, 'editor')}
                            />
                        </div>
                        <div className="flex flex-wrap mb-12">                            
                            <div className="w-full md:w-1/2 xl:w-1/6 md:px-2.5">
                                <div className="form-field-container">
                                    <div className="label">Apply Discounts?*</div>
                                    <div className='form-field-wrap mb-5 select-field-wrap discount-field-wrap relative'>
                                        <select {...register("onSale")} onChange={(e) => { setValue('onSale', e.target.value); setIsDiscount(e.target.value) }}>
                                            <option value="">Apply Discounts?*</option>
                                            <option value="false">No</option>
                                            <option value="true">Yes</option>
                                        </select>
                                    </div>
                                    {errors.onSale?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.onSale?.message}</p>}
                                </div>
                            </div>
                            {isDiscount === 'true' &&
                            <div className="w-full md:w-1/2 xl:w-1/6 md:px-2.5">
                                <div className="form-field-container">
                                    <div className="label">Discounted Price*</div>
                                    <div className='form-field-wrap mb-5 price-field-wrap relative'>
                                        <Controller
                                            name="salePrice"
                                            control={control}
                                            render={({ field }) => (
                                                <CurrencyInput
                                                    placeholder="Discounted Price*"
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => { field.onChange(value); setProductWatchPrice(value); }}
                                                    defaultValue={!isNewProduct ? product.salePrice / 100 : ''}
                                                />
                                            )}
                                        />
                                    </div>
                                    {errors.salePrice?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.salePrice?.message}</p>}
                                </div>
                            </div>
                            }                            
                            <div className="w-full md:w-1/2 xl:w-1/6 md:px-2.5">
                                <div className="form-field-container">
                                    <div className="label">Unlimited Inventory?*</div>
                                    <div className='form-field-wrap mb-5 select-field-wrap inventory-field-wrap relative'>
                                        <select {...register("quantityUnlimited")} onChange={(e) => { setValue('quantityUnlimited', e.target.value); setInventory(e.target.value) }}>
                                            <option value="">Unlimited Inventory?*</option>
                                            <option value="false">No</option>
                                            <option value="true">Yes</option>
                                        </select>
                                    </div>
                                    {errors.quantityUnlimited?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.quantityUnlimited?.message}</p>}
                                </div>
                            </div>
                            {isInventory === 'false' &&
                            <div className="w-full md:w-1/2 xl:w-1/6 md:px-2.5">
                                <div className="form-field-container">
                                    <div className="label">Inventory*</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <input type="number" min="0" placeholder="Inventory Quantity*" {...register('quantity')} />
                                    </div>
                                    {errors.quantity?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.quantity?.message}</p>}
                                </div>
                            </div>
                            }
                            <div className="w-full xl:w-1/3 md:px-2.5">
                                <div className="form-field-container">
                                    <div className="label">Published</div>
                                    <div className='form-field-wrap mb-5 select-field-wrap publish-field-wrap relative'>
                                        <select {...register("publish")} onChange={(e) => setValue('publish', e.target.value)}>
                                            <option value="true">Publish Now</option>
                                            <option value="false">Not Publish</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-12">
                            <div className="w-full xl:w-1/3 md:px-2.5">
                                <div className="form-field-container relative">
                                    <div className="flex justify-between">
                                        <div className="label">Select multiple groups*</div>
                                        <p className="mb-1 font-medium text-[12px] leading-[18px] text-white">
                                            {selectedGroups.length===0 ? 
                                                '0 Groups Selected':
                                                (selectedGroups.length > 1) ? selectedGroups.length + ' Groups Selected' : selectedGroups.length + ' Group Selected'}
                                        </p>
                                    </div>
                                    <div className='form-field-wrap mb-5 select-field-wrap groups-field-wrap relative' ref={contentRef}>
                                        <Controller
                                            control={control}
                                            name='groups'
                                            render={({ field: { onChange, value, ref } }) => (
                                                <CheckTreePicker
                                                    defaultExpandAll
                                                    placeholder="Select Groups*"
                                                    data={groups}
                                                    onChange={val => {
                                                        onChange(val.map(c => c.value));
                                                        setValue('groups',val);
                                                        setSelectedGroups(val);
                                                    }}
                                                    disabledItemValues={disabledGroupsOpt}
                                                    cleanable={false}
                                                    container={()=>contentRef.current}
                                                    menuStyle={{zIndex: 100000000}}
                                                    defaultValue={selectedGroups}
                                                    value={selectedGroups}
                                                    style={{
                                                        width:'100%',
                                                        maxWidth:'500px',
                                                        borderWidth:0,
                                                        backgroundColor: '#EFF1F9',
                                                        padding: '8px 7px 8px 46px',
                                                        height: '52px',
                                                        borderRadius: 8
                                                    }}
                                                    onSelect={(item,val,event)=>{
                                                            if(item.label.toLowerCase()==="all members"){
                                                                if(item.check){
                                                                    let groupIds=[];
                                                                    groups.forEach((item)=>{
                                                                        groupIds.push(item.value);
                                                                    });
                                                                    setSelectedGroups(groupIds);
                                                                }
                                                                else{
                                                                    setSelectedGroups([]);
                                                                }
                                                            }
                                                        }
                                                    }
                                                />
                                            )}
                                        />                                    
                                    </div>
                                    {errors.groups?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.groups?.message}</p>}
                                </div>
                            </div>
                            <div className="w-full xl:w-1/3 md:px-2.5">                                
                                <div className="form-field-container">
                                    <div className="label">Select Category*</div>
                                    <div className='form-field-wrap mb-5 select-field-wrap type-field-wrap relative'>
                                        <select {...register("category")} onChange={(e) => { setValue('category', e.target.value); }}>
                                            <option value="">Select Category*</option>
                                            {categories.length > 0 &&
                                                categories.map((catg, index) => {
                                                    return <option value={catg} key={index}>{catg}</option>
                                                })
                                            }
                                            <option value="new_catg">Add New Category</option>
                                        </select>
                                    </div>
                                    {errors.category?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.category?.message}</p>}
                                </div>
                            </div>
                            {watchProductCatg==='new_catg' &&
                            <>
                            <div className="w-full xl:w-1/3 md:px-2.5">
                                <div className="form-field-container">
                                    <div className="label">New category name</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <input type="text" placeholder="Enter New Category Name" {...register('newcategory')} />
                                    </div>
                                    {errors.newcategory && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.newcategory?.message}</p>}
                                </div>
                            </div>
                            </>
                            }
                            {watchProductCatg==='Memberships' &&
                            <div className="w-full xl:w-1/3 flex flex-wrap">
                                <div className="w-full mb-2.5 2xl:mb-0 2xl:w-1/2 2xl:px-2.5">
                                    <div className="form-field-container">
                                        <div className="label mb-1.5">Membership Duration*</div>
                                        <div className="r-button-group w-[148px] flex">
                                            <div className="mr-4">
                                            <input type="radio" {...register("duration_type")} value="fixed" id="duration_type-fixed" className="peer hidden" 
                                                onChange={(e)=>{
                                                    setValue("duration_type",e.target.value);
                                                    setValue("duration_of_membership","");
                                                }}/>
                                            <label
                                                htmlFor="duration_type-fixed"
                                                className="block py-[5px] w-[90px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                                >Fixed</label>
                                            </div>
                                            <div>
                                            <input type="radio" {...register("duration_type")} value="variable" id="duration_type-variable" className="peer hidden" 
                                                onChange={(e)=>{
                                                    setValue("duration_type",e.target.value);
                                                    setValue("duration_of_membership","");
                                                }}
                                            />
                                            <label
                                                htmlFor="duration_type-variable"
                                                className="block py-[5px] w-[112px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                                >Variable</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full 2xl:w-1/2 2xl:px-2.5">
                                    {watchMemDurationtype==='variable' ?
                                        <div className="form-field-container">
                                            <div className="label">Expiry Duration*</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                                <select {...register("duration_of_membership")} onChange={(e) => { setValue('duration_of_membership', e.target.value);}}>
                                                    <option value="">Expiry Duration*</option>
                                                    {membershipDurations.length > 0 &&
                                                        membershipDurations.map((memduration, index) => {
                                                            return <option value={memduration} key={index}>{memduration}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            {errors.duration_of_membership?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.duration_of_membership?.message}</p>}
                                        </div>
                                        :
                                        <div className="form-field-container">
                                            <div className="label">Expiry Date*</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                                                <Controller
                                                    control={control}
                                                    name='duration_of_membership'
                                                    errors={errors}
                                                    render={({ field, ref }) => (
                                                        <ReactDatePicker
                                                            placeholderText='Expiry Date*'
                                                            minDate={new Date()}
                                                            onChange={(date) => {
                                                                field.onChange(date); 
                                                                setValue('duration_of_membership',date);
                                                            }}
                                                            //utcOffset={timeZone}
                                                            selected={field.value}
                                                            mask="____-__-__"
                                                            dateFormat="dd-MM-yyyy"
                                                            inputRef={ref}                                     
                                                        />
                                                    )}
                                                />
                                            </div>
                                            {errors.duration_of_membership?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.duration_of_membership?.message}</p>}
                                        </div>
                                    }
                                </div>
                            </div>
                            }
                        </div>
                        <div className="flex flex-wrap mb-12">
                            <div className="w-full xl:w-1/3 md:px-2.5">
                                <div className="form-field-container button-group-wrap mb-5 flex gap-2.5 flex-col sm:flex-row justify-between sm:items-center">
                                    <div className="flex-1 label">Does this Shop Item come in multiple sizes?</div>
                                    <div className="r-button-group w-[148px] flex">
                                        <div className="mr-4">
                                        <input type="radio" {...register("is_multiple_size")} value="Yes" id="is_multiple_size-yes" className="peer hidden" />
                                        <label
                                            htmlFor="is_multiple_size-yes"
                                            className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >Yes</label>
                                        </div>
                                        <div>
                                        <input type="radio" {...register("is_multiple_size")} value="No" id="is_multiple_size-no" className="peer hidden" />
                                        <label
                                            htmlFor="is_multiple_size-no"
                                            className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >No</label>
                                        </div>
                                    </div>
                                </div>
                                {watchis_multiple_size==='Yes' &&
                                    <div className="form-field-container relative">
                                        <div className="label">Select Size*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative' ref={contentRef2}>
                                            <Controller
                                                control={control}
                                                name='size'
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <CheckTreePicker
                                                        defaultExpandAll
                                                        placeholder="Select Size*"
                                                        data={sizes}
                                                        onChange={val => {
                                                            onChange(val.map(c => c.value));
                                                            setValue('size',val);
                                                            setSelectedSizes(val);
                                                        }}
                                                        container={()=>contentRef2.current}
                                                        countable={false}
                                                        menuStyle={{zIndex: 100000000}}
                                                        defaultValue={selectedSizes}
                                                        value={selectedSizes}
                                                        style={{
                                                            width:'100%',
                                                            maxWidth:'500px',
                                                            borderWidth:0,
                                                            backgroundColor: '#EFF1F9',
                                                            padding: '8px 7px 8px 7px',
                                                            height: '52px',
                                                            borderRadius: 8
                                                        }}                                                
                                                    />
                                                )}
                                            />
                                        </div>
                                        {errors.size?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.size?.message}</p>}
                                    </div>
                                }
                            </div>
                            <div className="w-full xl:w-1/3 md:px-2.5">
                                <div className="form-field-container button-group-wrap mb-5 flex gap-2.5 flex-col sm:flex-row justify-between sm:items-center">
                                    <div className="flex-1 label">Does this Shop Item come in multiple sleeve length variations?</div>
                                    <div className="r-button-group w-[148px] flex">
                                        <div className="mr-4">
                                        <input type="radio" {...register("is_multiple_sleeve")} value="Yes" id="is_multiple_sleeve-yes" className="peer hidden" />
                                        <label
                                            htmlFor="is_multiple_sleeve-yes"
                                            className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >Yes</label>
                                        </div>
                                        <div>
                                        <input type="radio" {...register("is_multiple_sleeve")} value="No" id="is_multiple_sleeve-no" className="peer hidden" />
                                        <label
                                            htmlFor="is_multiple_sleeve-no"
                                            className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >No</label>
                                        </div>
                                    </div>
                                </div>
                                {watchis_multiple_sleeve==='Yes' &&
                                    <div className="form-field-container relative">
                                        <div className="label">Select Sleeve*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative' ref={contentRef3}>
                                            <Controller
                                                control={control}
                                                name='sleeve'
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <CheckTreePicker
                                                        defaultExpandAll
                                                        placeholder="Select Sleeve*"
                                                        data={sleeves}
                                                        onChange={val => {
                                                            onChange(val.map(c => c.value));
                                                            setValue('size',val);
                                                            setSelectedSleeves(val);
                                                        }}
                                                        container={()=>contentRef3.current}
                                                        countable={false}
                                                        menuStyle={{zIndex: 100000000}}
                                                        defaultValue={selectedSleeves}
                                                        value={selectedSleeves}
                                                        style={{
                                                            width:'100%',
                                                            maxWidth:'500px',
                                                            borderWidth:0,
                                                            backgroundColor: '#EFF1F9',
                                                            padding: '8px 7px 8px 7px',
                                                            height: '52px',
                                                            borderRadius: 8
                                                        }}                                                
                                                    />
                                                )}
                                            />
                                        </div>
                                        {errors.sleeve?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.sleeve?.message}</p>}
                                    </div>
                                }
                            </div>
                            <div className="w-full xl:w-1/3 md:px-2.5">
                                <div className="form-field-container button-group-wrap mb-5 flex gap-2.5 flex-col sm:flex-row justify-between sm:items-center">
                                    <div className="flex-1 label">Does this Shop Item come in multiple colors?</div>
                                    <div className="r-button-group w-[148px] flex">
                                        <div className="mr-4">
                                        <input type="radio" {...register("is_multiple_color")} value="Yes" id="is_multiple_color-yes" className="peer hidden" />
                                        <label
                                            htmlFor="is_multiple_color-yes"
                                            className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >Yes</label>
                                        </div>
                                        <div>
                                        <input type="radio" {...register("is_multiple_color")} value="No" id="is_multiple_color-no" className="peer hidden" />
                                        <label
                                            htmlFor="is_multiple_color-no"
                                            className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >No</label>
                                        </div>
                                    </div>
                                </div>
                                {watchis_multiple_color==='Yes' &&
                                    <div className="form-field-container relative">
                                        <div className="label">Select Color*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative' ref={contentRef4}>
                                            <Controller
                                                control={control}
                                                name='color'
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <CheckTreePicker
                                                        defaultExpandAll
                                                        placeholder="Select Color*"
                                                        data={colors}
                                                        onChange={val => {
                                                            onChange(val.map(c => c.value));
                                                            setValue('size',val);
                                                            setSelectedColors(val);
                                                        }}
                                                        container={()=>contentRef4.current}
                                                        countable={false}
                                                        menuStyle={{zIndex: 100000000}}
                                                        defaultValue={selectedColors}
                                                        value={selectedColors}
                                                        style={{
                                                            width:'100%',
                                                            maxWidth:'500px',
                                                            borderWidth:0,
                                                            backgroundColor: '#EFF1F9',
                                                            padding: '8px 7px 8px 7px',
                                                            height: '52px',
                                                            borderRadius: 8
                                                        }}                                                
                                                    />
                                                )}
                                            />
                                        </div>
                                        {errors.color?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.color?.message}</p>}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-12">
                            <div className="w-full xl:w-1/3 md:px-2.5">
                                <div className="form-field-container button-group-wrap mb-5 flex gap-2.5 flex-col sm:flex-row justify-between sm:items-center">
                                    <div className="flex-1 label">Do you need to sell differnet types of this item to different Genders?</div>
                                    <div className="r-button-group w-[148px] flex">
                                        <div className="mr-4">
                                        <input type="radio" {...register("is_multiple_gender")} value="Yes" id="is_multiple_gender-yes" className="peer hidden" />
                                        <label
                                            htmlFor="is_multiple_gender-yes"
                                            className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >Yes</label>
                                        </div>
                                        <div>
                                        <input type="radio" {...register("is_multiple_gender")} value="No" id="is_multiple_gender-no" className="peer hidden" />
                                        <label
                                            htmlFor="is_multiple_gender-no"
                                            className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >No</label>
                                        </div>
                                    </div>
                                </div>
                                {watchis_multiple_gender==='Yes' &&
                                    <div className="form-field-container relative">
                                        <div className="label">Select Gender*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative' ref={contentRef5}>
                                            <Controller
                                                control={control}
                                                name='gender'
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <CheckTreePicker
                                                        defaultExpandAll
                                                        placeholder="Select Gender*"
                                                        data={gender}
                                                        onChange={val => {
                                                            onChange(val.map(c => c.value));
                                                            setValue('size',val);
                                                            setSelectedGender(val);
                                                        }}
                                                        container={()=>contentRef5.current}
                                                        countable={false}
                                                        menuStyle={{zIndex: 100000000}}
                                                        defaultValue={selectedGender}
                                                        value={selectedGender}
                                                        style={{
                                                            width:'100%',
                                                            maxWidth:'500px',
                                                            borderWidth:0,
                                                            backgroundColor: '#EFF1F9',
                                                            padding: '8px 7px 8px 7px',
                                                            height: '52px',
                                                            borderRadius: 8
                                                        }}                                                
                                                    />
                                                )}
                                            />
                                        </div>
                                        {errors.gender?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.gender?.message}</p>}
                                    </div>
                                }
                            </div>
                            <div className="w-full xl:w-1/3 md:px-2.5">
                                <div className="form-field-container button-group-wrap mb-5 flex gap-2.5 flex-col sm:flex-row justify-between sm:items-center">
                                    <div className="flex-1 label">Is personalization a REQUIRED submission for this item?</div>
                                    <div className="r-button-group w-[148px] flex">
                                        <div className="mr-4">
                                        <input type="radio" {...register("is_personalize")} value="Yes" id="is_personalize-yes" className="peer hidden" />
                                        <label
                                            htmlFor="is_personalize-yes"
                                            className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >Yes</label>
                                        </div>
                                        <div>
                                        <input type="radio" {...register("is_personalize")} value="No" id="is_personalize-no" className="peer hidden" />
                                        <label
                                            htmlFor="is_personalize-no"
                                            className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >No</label>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-12">
                            {stripeAccounts.length > 1 ?
                                <>
                                <div className="w-full xl:w-1/3 md:px-2.5">
                                    <div className="form-field-container">
                                        <div className="label">Select Account*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap stripe-field-wrap relative'>
                                            <select {...register("stripe_account")} onChange={(e) => { setValue('stripe_account', e.target.value); }}>
                                                <option value="">Select Account*</option>
                                                {stripeAccounts.length > 0 &&
                                                    stripeAccounts.map((acitem, index) => {
                                                        return <option value={acitem._id} key={index}>{(acitem.name !== "" && acitem.name !== null) ? acitem.name : acitem.email}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {errors.stripe_account?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.stripe_account?.message}</p>}
                                    </div>
                                </div>
                                </>
                                :
                                <input type="hidden" {...register("stripe_account")} />
                            }
                            <div className="w-full xl:w-1/3 md:px-2.5">
                                <div className="form-field-container">
                                    <div className="label">Allow Payment in Instalments*</div>
                                    <div className='form-field-wrap mb-5 select-field-wrap type-field-wrap relative'>
                                        <select {...register("allow_installment")} onChange={(e) => { setValue('allow_installment', e.target.value); setAllowInstallment(e.target.value) }}>
                                            <option value="">Allow Payment in Instalments</option>
                                            {
                                                ["Yes", "No"].map((catg, index) => {
                                                    return <option value={catg} key={index}>{catg}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    {errors.allow_installment?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.allow_installment?.message}</p>}
                                </div>
                            </div>
                            {allowInstallment === 'Yes' &&
                                <>
                                <div className="w-full xl:w-1/3 md:px-2.5">
                                    <div className="form-field-container">
                                        <div className="label">How many instalments would you like to receive?</div>
                                        <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                            <select {...register('total_installment')} onChange={(e)=>{setTotalInstallMent(e.target.value)}} aria-invalid={errors.total_installment ? "true" : "false"}>
                                                <option value="">Select number of installments</option>
                                                {Array.from(Array(20), (e,i) => {
                                                    return(
                                                        <option value={i+1}>{i+1}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        {errors.total_installment?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.total_installment?.message}</p>}
                                    </div>
                                </div>
                                </>
                            }
                        </div>
                        <div className="flex flex-wrap mb-12">
                            {allowInstallment === 'Yes' &&
                                <div className="w-full xl:w-1/3 md:px-2.5">
                                    <div className="mb-5">
                                        {totalInstallment > 0 && Array.from(Array(Number(totalInstallment)).keys()).map((item) => {
                                            return (
                                                <div className="instamentls1 flex flex-wrap w-100 justify-between items-center" key={item}>
                                                    <label className="installment-label text-white">INSTALMENT {item + 1}</label> <span className="line"></span>
                                                    <input type="text" className="instalment_input" {...register('installment_value' + item)} onBlur={(e)=>{ e.target.nextElementSibling.innerHTML = e.target.value < 2 ? "Minimum required insalment price is "+team.currency_symbol+"2.00" : "";}} />
                                                    <p className="error w-100 mb-2.5 mt-2.5" role="alert"></p>
                                                </div>
                                                )
                                            })
                                        }
                                        {(totalInstallment > 0 && (productWatchPrice!==0)) &&                                        
                                            <>
                                                <div className="flex w-100 items-center">
                                                    <span className="line-remaining flex-1"></span>
                                                    <div className="w-100 remaining"><span className="text">REMAINING:</span> <span className="amount"> {team.currency_symbol}{(Number(productWatchPrice - _.sum(installmentAmounts))).toFixed(2)}</span></div>
                                                </div>
                                            </>
                                        }
                                        {installMentAmountError!==null && <p className="error mb-2.5 mt-2.5" role="alert">{installMentAmountError}</p>}
                                    </div>
                                    <div className="form-field-container">
                                        <div className="label">Instalment Frequency</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap type-field-wrap relative'>
                                            <select {...register("installment_type")} onChange={(e) => { setValue('installment_type', e.target.value); }}>
                                                <option value="">Instalment Frequency</option>
                                                    {
                                                        ["Weekly", "Monthly", "Bimonthly", "Quarterly", "Half Yearly"].map((catg, index) => {
                                                            return <option value={catg} key={index}>{catg}</option>
                                                        })
                                                    }
                                            </select>
                                        </div>
                                        {errors.installment_type?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.installment_type?.message}</p>}
                                    </div>                                    
                                </div>
                            }
                            <div className="w-full xl:w-1/3 md:px-2.5">
                                <div className='form-field-wrap mb-5 relative'>
                                    <PlaiImageCropper editImage={(!isNewProduct && product.image && product.image !== "") ? product.image : ''} onFinalChange={(newImage) => { setProductImage(newImage) }} aspect={1 / 1} />
                                </div>
                                {(isNewProduct && imageErrorMsg) && <p className="error mb-2.5 -mt-2.5" role="alert">Please upload image</p>}
                            </div>
                            <div className="w-full xl:w-1/3 md:px-2.5">
                                <div className='form-field-wrap mb-5 relative'>
                                    <FilePond
                                        files={pdfFiles}
                                        onupdatefiles={setPdfFile}
                                        allowMultiple={true}
                                        credits={false}
                                        allowFileTypeValidation={true}
                                        acceptedFileTypes={['application/pdf']}
                                        labelFileTypeNotAllowed='File of invalid type'
                                        //maxFiles={1}                                    
                                        labelIdle='
                                        <div class="upload-text">
                                            <h4>Upload a PDF</h4>
                                            <p class="text-center">Maximum upload file size: 2MB</p>
                                        </div>'
                                        {...register("pdfdocuments")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-1/3 flex flex-wrap">
                                <div className="w-full md:w-1/2 md:px-2.5">
                                    <div className='form-field-wrap new-check-field-wrap relative'>                                
                                        <label htmlFor="notify_email" className=''>
                                            <input type="checkbox" id="notify_email" {...register("notify_email")} className='checkbox-field appearance-none'/>
                                            Send Email
                                        </label>
                                    </div>
                                </div>
                                <div className="w-full md:w-1/2 md:px-2.5">
                                    <div className='form-field-wrap new-check-field-wrap relative'>                                
                                        <label htmlFor="notify_notification" className=''>
                                            <input type="checkbox" id="notify_notification" {...register("notify_notification")} className='checkbox-field appearance-none'/>
                                            Send Notification
                                        </label>
                                    </div>
                                </div>
                                <div className="w-full md:px-2.5">
                                    <div className='form-field-wrap new-check-field-wrap relative'>                                
                                        <label htmlFor="required_delivery" className=''>
                                            <input type="checkbox" id="required_delivery" {...register("is_required_delivery")} className='checkbox-field appearance-none'/>
                                            Require Home Delivery / Physical Hand-Off To Your Member
                                        </label>
                                    </div>
                                </div>
                                {productWatchPrice!==0 && 
                                    <div className="w-full md:px-2.5">
                                        <p className="flex"><span className="text-[12px] leading-[18px] text-white">Purchasers are charged a small transaction fee at checkout, this item will be presented on the team shop at a cost of {team.currency_symbol}{(Number(productWatchPrice)+transactionFee).toFixed(2)}</span><img src={InfoIcon} className="cursor-pointer" alt="" id="purchase_info" /></p>
                                        <Tooltip anchorSelect="#purchase_info">
                                            <div className="w-[280px]">
                                                <h3 className="uppercase text-[22px] leading-[33px] font-semibold border-b border-b-[#FBDB03] border-b-solid text-center">PLAI</h3>
                                                <p className="mb-4 text-center text-[14px] leading-[19px]">Buyers are charged only one transaction fee regardless of purchase size, when buying multiple items, 50p will be discounted from the cost of every item beyond the first.</p>                    
                                            </div>
                                        </Tooltip>
                                    </div>
                                }
                            </div>
                            <div className="w-full lg:w-2/3 flex flex-col justify-center lg:justify-end">
                                {!loading && errorMessage ? <div className="flex justify-center lg:justify-end"><p className='text-red-500 mb-2.5 text-center'>{errorMessage}</p></div> : null}
                                <div className="flex justify-center lg:justify-end">
                                    <input className='button button--large' type="submit" value={isNewProduct ? 'Add' : 'Update'} disabled={loading} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </>
            :
            <>
                <div className="py-5 text-center"><Spinner /></div>
            </>
            }
            </div>
        </div>
    );
}
export default ProductForm;