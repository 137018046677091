import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './pendingrequests.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import Spinner from '../../components/Spinner/Spinner';
import { getPendingTeams, getProfile } from '../../libs/dataFunctions';
import rgroups from '../../assets/images/noemptyother.png';
import AdminNavbar from '../../components/AdminNavbar/AdminNavbar';
import TeamCard from './TeamCard';

const PendingRequests = ()=>{
    const [loading,setLoading] = useState(false);
    const [teams,setAllTeams] = useState([]);
    const [error,setError] = useState('');
    const [userDetail, setUserDetail] = useState([]);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    async function refreshPendingTeams(){
        setError('');
        setLoading(true);
        const [pendingTeams, profileData] = await Promise.all([
            getPendingTeams(token),
            getProfile(token)
        ]);
        if(pendingTeams.status===1){
            setAllTeams(pendingTeams.data);
            setUserDetail(profileData);            
        }
        else{
            setError(pendingTeams.message);
        }
        setLoading(false);
    }
    useEffect(()=>{
        refreshPendingTeams();
    },[]);
    
    return(
        <>
            <Sidebar type="thin"/>            
            <div id="page" className="page-pending-reques">
                <AdminNavbar heading="Pending Requests"/>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Pending requests
                </div>
                <div className="body px-5 lg:px-8 py-10">
                    {!loading ?
                        <>
                        {teams.length > 0 ?
                            <div className='flex flex-wrap'>
                                {teams.map((item) => {
                                    return( 
                                        <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 2xl:w-1/5 px-2.5 pb-5' key={item._id}>
                                            <TeamCard team={item} refreshRequests={refreshPendingTeams} userDetail={userDetail}/>
                                        </div>
                                    )
                                })}
                            </div>
                            :
                            <>
                                <div className="empty text-center">
                                    <div className="image"><img src={rgroups} alt="Plaisport" width={228} /></div>
                                    <div className="title mb-5">No pending requests found</div>
                                    <a href="/" className='button button-yellow button-yellow-add'>Home</a>
                                </div>
                            </>
                        }
                        </>
                    :
                    <>
                        <div className="py-5 text-center"><Spinner /></div>
                    </>
                    }
                </div>
            </div>
        </>
    );
}

export default PendingRequests;