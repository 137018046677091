import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import {useState, useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { useFormData } from '../../contexts/FormContext';
import { checkEmailExists } from '../../libs/dataFunctions';

const schema = yup.object({
    first_name: yup.string().required('First name is required.'),
    last_name: yup.string().required('Last name is required.'),
    email: yup.string().email().required('Email address is required.'),
    password: yup.string().required('Password is required.').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Password must contain at least 8 characters, including one upper case and one number."
    ),
    conf_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
}).required();

const schemaSocial = yup.object({
    first_name: yup.string().required('First name is required.'),
    last_name: yup.string().required('Last name is required.'),
    email: yup.string().email().required('Email address is required.'),        
}).required();

const SignUpStepOne =({ formStep, nextFormStep }) => {
    const { setFormValues } = useFormData();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfPassword, setShowConfPassword] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailCheckLoad, setEmailCheckLoad] = useState(false);
    const location = useLocation();

    let socialSignIn = location.state ? JSON.parse(location.state.social_data) : '';

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: socialSignIn!=="" ? yupResolver(schemaSocial) : yupResolver(schema),
        mode: "all"
    });

    const onSubmit = async (values) => {
        setEmailError(null);
        setEmailCheckLoad(true);
        let emailExistData = await checkEmailExists(values.email);
        
        if(emailExistData.status===1){
            setFormValues(values);
            setEmailCheckLoad(false);
            nextFormStep();
        }
        else{
            setEmailCheckLoad(false);
            setEmailError(emailExistData.message);
        }
    };
    
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleClickShowConfPassword = () => {
        setShowConfPassword(!showConfPassword);
    };
    useEffect(()=>{
        if(socialSignIn!==''){
            if(socialSignIn.platform==='google'){
                setValue('first_name', socialSignIn.name.firstName);
                setValue('last_name', socialSignIn.name.lastName);
                setValue('email', socialSignIn.email);
            }
            else if(socialSignIn.platform==='facebook'){
                setValue('first_name', socialSignIn.name);
                setValue('email', socialSignIn.email);
            }
            else if(socialSignIn.platform==='apple'){
                setValue('first_name', socialSignIn.name.firstName);
                setValue('last_name', socialSignIn.name.lastName);
                setValue('email', socialSignIn.email);
            }
        }
    },[]);
    return (
        <div className={formStep === 0 ? '' : 'hidden'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='auth-form-field-wrap user-field-wrap mb-5 relative'>
                    <input 
                        type="text" placeholder="Your First Name" className='text-field'
                        {...register("first_name")}
                        aria-invalid={errors.first_name ? "true" : "false"} 
                    />                                
                </div>
                {errors.first_name && <p className="error mb-2.5" role="alert">{errors.first_name?.message}</p>}
                <div className='auth-form-field-wrap user-field-wrap mb-5 relative mb-5'>
                    <input 
                        type="text" placeholder="Your Last Name" className='text-field'
                        {...register("last_name")}
                        aria-invalid={errors.last_name ? "true" : "false"} 
                    />                                
                </div>
                {errors.last_name && <p className="error mb-2.5" role="alert">{errors.last_name?.message}</p>}
                <div className='auth-form-field-wrap mb-5 email-field-wrap relative'>
                    <input 
                        type="email" placeholder="Your Email Address" className='email-field'
                        {...register("email")}
                        aria-invalid={errors.email ? "true" : "false"} 
                    />                                
                </div>
                {errors.email && <p className="error mb-2.5" role="alert">{errors.email?.message}</p>}
                {emailError && <p className="error mb-2.5" role="alert">{emailError}</p>}
                {socialSignIn==='' &&
                    <>
                        <div className='auth-form-field-wrap mb-5 password-field-wrap relative'>
                            <input 
                                type={showPassword ? "text" : "password"} placeholder="Create Password" className='password-field'
                                {...register("password")} 
                                aria-invalid={errors.password ? "true" : "false"}
                            />
                            <span className={`passwordTrigger bg-no-repeat bg-center bg-contain ${showPassword ? 'toggled' : ''}`} onClick={handleClickShowPassword}></span>
                        </div>                
                        {errors.password && <p className="error mb-2.5" role="alert">{errors.password?.message}</p>}
                        <div className='auth-form-field-wrap mb-3 password-field-wrap relative'>
                            <input 
                                type={showConfPassword ? "text" : "password"} placeholder="Confirm Password" className='password-field'
                                {...register("conf_password")} 
                                aria-invalid={errors.conf_password ? "true" : "false"}
                            />
                            <span className={`passwordTrigger bg-no-repeat bg-center bg-contain ${showConfPassword ? 'toggled' : ''}`} onClick={handleClickShowConfPassword}></span>
                        </div>
                        {errors.conf_password && <p className="error mb-2.5" role="alert">{errors.conf_password?.message}</p>}
                    </>
                }
                <div className='auth-form-field-wrap check-field-wrap flex justify-center mb-5 md:mb-10 relative'>                
                    <label htmlFor="opt_given">
                        <input type="checkbox" id="opt_given" {...register("opt_given")} className='checkbox-field appearance-none'/>
                        <span>Opt to receive PLAI discounts, rewards, educational content and platform news.</span>
                    </label>
                </div>
                <div className='auth-form-field-wrap flex justify-center mb-5 md:mb-10'>
                    <input className='button button--large' type="submit" value={emailCheckLoad ? 'Checking' : 'Continue'} disabled={emailCheckLoad}/>
                </div>
                <div className='auth-form-field-wrap flex justify-center'>
                    <span>Already have an account? <a href="/signin" className='auth-form-link'>Login</a></span>
                </div>
                {socialSignIn!=='' &&
                    <>
                        <input type="hidden" {...register(`social_id`)} value={socialSignIn.social_id} />
                        <input type="hidden" {...register(`registered_type`)} value={socialSignIn.platform} />
                    </>    
                }
            </form>
        </div>
    );
}

export default SignUpStepOne;