import { useState } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Spinner from "../../../components/Spinner/Spinner";

import '../../SignIn/auth.scss';

const schema = yup.object({
    oldpassword: yup.string().required('Current password is required.'),
    password: yup.string().min(8).required('Password is required.').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Password must contain at least 8 characters, including one upper case and one number."
    ),
    conf_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
}).required();

const Changepassword=()=>{   
    const [showPasswordold, setShowPasswordold] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfPassword, setShowConfPassword] = useState(false);
    const [loading,setLoading] = useState(false);
    const [error,setErrorMsg]= useState('');
    const [successmsg,setSuccessMsg]= useState('');

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        mode: "all"
    });
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const handleClickShowPasswordold = () => {
        setShowPasswordold(!showPasswordold);
    };
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleClickShowConfPassword = () => {
        setShowConfPassword(!showConfPassword);
    };
    
    const onSubmit = async (data) => {
        setSuccessMsg('');
        setErrorMsg('');
        var formdata = new FormData();
        formdata.append("oldPassword", data.oldpassword);
        formdata.append("newPassword", data.password);
        setLoading(true);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/changepassword' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status===1){
                setSuccessMsg(rdata.message);
            }
            else{
                setErrorMsg(rdata.message);
            }
        })
        .catch(error => console.log('error', error));
        setLoading(false);
        reset();
    }

    return(
        !loading ?
        <>
            <div className="change_password">
                <div className="ln-header flex justify-between items-center w-full pb-5 relative">
                    <h2 className="title mr-5">Change Password</h2>
                    <div className="sep mr-5 sm:mr-11"></div> 
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {error!=='' && <p className="text-red-500 mb-2.5 -mt-2.5" role="alert">{error}</p>}
                    {successmsg!=='' && <p className="text-green-600 mb-2.5 -mt-2.5" role="alert">{successmsg}</p>}
                    <div className="w-full">
                        <label>Current Password</label>
                        <div className='auth-form-field-wrap mb-6 password-field-wrap relative w-full lg:w-1/2 lg:pr-[10px] '>                        
                            <input 
                                type={showPasswordold ? "text" : "password"} placeholder="Current Password" className='password-field'
                                {...register("oldpassword")} 
                                onChange={()=>{setSuccessMsg(''); setErrorMsg('');}}
                                aria-invalid={errors.oldpassword ? "true" : "false"}
                            />
                            <span className={`passwordTrigger bg-no-repeat bg-center bg-contain ${showPasswordold ? 'toggled' : ''}`} onClick={handleClickShowPasswordold}></span>
                        </div>
                        {errors.oldpassword && <p className="error mb-2.5" role="alert">{errors.oldpassword?.message}</p>}
                    </div>
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-1/2 lg:pr-[10px]">
                            <label className="w-full">New Password</label>
                            <div className='auth-form-field-wrap mb-6 password-field-wrap relative '>
                                <input 
                                    type={showPassword ? "text" : "password"} placeholder="New Password" className='password-field w-full'
                                    {...register("password")}
                                    onChange={()=>{setSuccessMsg(''); setErrorMsg('');}} 
                                    aria-invalid={errors.password ? "true" : "false"}
                                />
                                <span className={`passwordTrigger bg-no-repeat bg-center bg-contain ${showPassword ? 'toggled' : ''}`} onClick={handleClickShowPassword}></span>
                                {errors.password && <p className="error w-full mb-2.5" role="alert">{errors.password?.message}</p>}
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 lg:pl-[10px]">
                            <label className="w-full">Confirm New Password</label>
                            <div className='auth-form-field-wrap mb-6 password-field-wrap relative'>
                                <input 
                                    type={showConfPassword ? "text" : "password"} placeholder="Confirm New Password" className='password-field w-full'
                                    {...register("conf_password")} 
                                    onChange={()=>{setSuccessMsg(''); setErrorMsg('');}}
                                    aria-invalid={errors.conf_password ? "true" : "false"}
                                />
                                <span className={`passwordTrigger bg-no-repeat bg-center bg-contain ${showConfPassword ? 'toggled' : ''}`} onClick={handleClickShowConfPassword}></span>
                                {errors.conf_password && <p className="error w-full mb-2.5" role="alert">{errors.conf_password?.message}</p>}
                            </div>
                        </div>                    
                    </div>
                    <div className='auth-form-field-wrap flex justify-center mb-2.5 md:mb-10'>
                        <input className='button button--large' type="submit" value={'Update Password'}/>
                    </div>
                </form>
            </div>
        </>
       :
       <>
           <div className="py-5 text-center"><Spinner /></div>
       </>
    );
}

export default Changepassword;