import { NavLink } from 'react-router-dom';
import PaymentFailedImg from '../assets/images/paymentfailed.png';
import IsMobile from '../components/isMobile';

const AppPaymentFailed = () =>{
    const isMobile = IsMobile(window);
    return(
        <>
        <div id="page" className="page-thankyou !ml-0">
            <div className="empty text-center">
                <div className="image"><img src={PaymentFailedImg} alt="Plaisport" width={500} /></div>
                <div className="title mb-5">Payment failed</div>
                <div className="description mb-8">
                    {isMobile ?
                        <>
                            Your transaction has failed due to some technical error, please press <strong className='text-[#363F6C] font-bold'>Home</strong> at the top right to return on the PLAI app.
                        </>
                    :
                        <>
                            Your transaction has failed due to some technical error, please return to the PLAI and Press <NavLink className='text-[#363F6C] underline hover:text-[#FBDB09] font-bold' to="/">HOME</NavLink>
                        </>
                    }
                </div>
                {!isMobile && 
                    <div className='flex justify-center'>                    
                        <NavLink className='button' to="/">HOME</NavLink>
                    </div>
                }
            </div>
        </div>        
        </>
    )
}

export default AppPaymentFailed;