import { useState, useEffect, useContext, useRef } from "react";
import { NavLink } from "react-router-dom";
import { TeamContext } from "../../../contexts/TeamContext";
import { getArchiveConversations, getConversationsDetails } from "../../../libs/dataFunctions";
import ChatConversations from "./ChatConversations";
import Spinner from "../../../components/Spinner/Spinner";
import ChatBoard from "./ChatBoard";
import ChatSendForm from "./ChatSendForm";
import { useDispatch } from "react-redux";
import { fetchConversationListAction } from "../../../Redux/conversationListSlice";
import { fetchActiveChatAction } from "../../../Redux/activeChatSlice";

const ChatArchiveComponent = ({socket, setCurrentChatPage, setGroupID})=>{
    const { team, token, teamUpdating } = useContext(TeamContext);
    const [loading, setLoading] = useState(false);
    const [activeChatLoading, setActiveChatLoading] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const ref = useRef();
    const lastMessageRef = useRef(null);
    const [mobShowChatboard,setmobShowChatboard] = useState(false);
    const dispatch = useDispatch();

    const fetchArchiveMembers = async() =>{
        setLoading(true);
        let [conversationListdata] = await Promise.all([
            getArchiveConversations(token, team._id)
        ]);
        
        dispatch(fetchConversationListAction(conversationListdata.data));
        
        if(conversationListdata.data.length > 0){
            let [tmpData] = await Promise.all([
                getConversationsDetails(token, team._id, conversationListdata.data[0].conversation_id)
            ]); 
            if(tmpData.status===1){
                dispatch(fetchActiveChatAction({chatSource:conversationListdata.data[0],messages:tmpData.data}));
            }
            else{
                dispatch(fetchActiveChatAction({chatSource:conversationListdata.data[0],messages:[]}));
            }

            if(socket !==null){
                if(tmpData.all_read_by_conversation!==""){
                    socket.emit('read_message',tmpData.all_read_by_conversation);
                }
            }
        }
        else{
            dispatch(fetchActiveChatAction({chatSource:{},messages:[]}));
        }
        setLoading(false);
    }

    useEffect(()=>{ 
        setLoading(true);
        if(!teamUpdating){
            fetchArchiveMembers();
        }
    },[teamUpdating]);
    
    return(
        <>
        {!loading && !teamUpdating ?
            <>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}`}>
                        <span>{team.name}</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink onClick={()=>setCurrentChatPage('')}>
                        <span>Chat</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Archive Chat
                </div>
                <div className="body px-5 lg:px-8 py-10">
                    <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                        <h2 className="title mr-5 uppercase">Chat</h2>
                        <div className="sep mr-4"></div>
                        <div className="cta flex">
                            <button className={`${mobShowChatboard ? 'lg:hidden': 'hidden'} button button--yellow button-medium`} onClick={()=>setmobShowChatboard(false)}>Back</button>
                            {/*
                            <NavLink to='/' className='flex items-center justify-center w-[30px] h-[30px] mr-4 rounded-lg bg-[#363F6C] text-[#FBDB09] hover:bg-[#FBDB09] hover:text-[#363F6C]'>
                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.5015 14.8889H4.50146C4.33096 14.8889 4.16745 14.9566 4.04689 15.0771C3.92633 15.1977 3.8586 15.3612 3.8586 15.5317C3.8586 15.7022 3.92633 15.8657 4.04689 15.9863C4.16745 16.1068 4.33096 16.1746 4.50146 16.1746H13.5015C13.672 16.1746 13.8355 16.1068 13.956 15.9863C14.0766 15.8657 14.1443 15.7022 14.1443 15.5317C14.1443 15.3612 14.0766 15.1977 13.956 15.0771C13.8355 14.9566 13.672 14.8889 13.5015 14.8889ZM4.50146 13.6031H4.55931L7.24003 13.3589C7.53368 13.3296 7.80834 13.2002 8.01788 12.9924L13.8036 7.20671C14.0282 6.96947 14.1495 6.65289 14.1411 6.32634C14.1326 5.99978 13.9951 5.68989 13.7586 5.46457L11.9972 3.70314C11.7673 3.4872 11.466 3.3633 11.1507 3.355C10.8354 3.3467 10.5281 3.45459 10.2872 3.65814L4.50146 9.44385C4.29366 9.6534 4.16428 9.92805 4.13503 10.2217L3.8586 12.9024C3.84994 12.9966 3.86216 13.0915 3.89438 13.1804C3.9266 13.2693 3.97804 13.35 4.04503 13.4167C4.1051 13.4763 4.17633 13.5234 4.25466 13.5554C4.33298 13.5874 4.41685 13.6036 4.50146 13.6031ZM11.1036 4.60314L12.8586 6.35814L11.5729 7.61171L9.85003 5.88885L11.1036 4.60314Z" fill="currentColor"/>
                                </svg>
                            </NavLink>
                            */}
                        </div>
                    </div>
                    <div className="lg:w-full lg:rounded-xl lg:bg-white lg:pl-3 lg:pr-8 lg:py-10">
                        <div className="lg:flex relative">
                            <div className={`chat-conversation-list ${mobShowChatboard ? 'hidden lg:block': 'block'} rounded-xl w-full z-10 bg-white lg:w-[40%] lg:max-w-[467px] px-1.5 lg:px-5 lg:mr-9 py-5 lg:py-0`}>
                                <ChatConversations setActiveChatLoading={setActiveChatLoading} team={team} socket={socket} enableArchiveMode={false} selectedMembers={selectedMembers} setSelectedMembers={setSelectedMembers} setmobShowChatboard={setmobShowChatboard} />
                            </div>
                            <div className={`lg:flex-1 ${!mobShowChatboard ? 'hidden lg:block': 'block'} `}>
                                <div className="chatboard">
                                    {!activeChatLoading ?
                                        <>
                                            <ChatBoard team={team} lastMessageRef={lastMessageRef} socket={socket} setCurrentChatPage={setCurrentChatPage} setGroupID={setGroupID} setmobShowChatboard={setmobShowChatboard} isArchive={true} />
                                            <ChatSendForm team={team}  socket={socket} />                                                
                                        </>
                                    :
                                        <>
                                            <div className="text-center py-5"><Spinner /></div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        :
            <div className="text-center py-5"><Spinner /></div>
        }
        </>
    )
}

export default ChatArchiveComponent;