import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import CheckTreePicker from 'rsuite/CheckTreePicker';
import DatePicker from 'rsuite/DatePicker';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import ReactDatePicker from "react-datepicker";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import TextEditor from "../../../components/TextEditor";
import PlaiImageCropper from "../../../components/ImageCropper/ImageCropper";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import { toCurrentOffset } from "../../../libs/utils";
import CoachesWithRoleInputV2 from "../../../components/CoachesWithRoleInputv2/CoachesWithRoleInputv2";
import { generateRandomName } from '../../../components/ImageCropper/cropUtils';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import Sidebar from "../../../components/Sidebar/Sidebar";
//import Advertise from '../../../assets/images/ad.png';
import Spinner from '../../../components/Spinner/Spinner';
import { getEventDetail, getUserAsignedGroup, getAllProducts } from '../../../libs/dataFunctions';
import { TeamContext, TeamContextProvider } from '../../../contexts/TeamContext';

registerPlugin(FilePondPluginFileValidateType);
dayjs.extend(utc);
dayjs.extend(tz);

const schemaEdit = yup.object({
    event_name: yup.string().required('Event name is required.'),
    event_type: yup.string().required('Event type is required.'),
    sub_type: yup.string().when('event_type',{
        is:'Game',
        then:yup.string().required('Please Select Game Type.')
    }),
    autoAccept: yup.string().required('Please select Request to join.'),
    /*dateStart: yup.string().required('Please select Event Start Date.'),
    dateEnd: yup.string().required('Please select Event End Date.'),*/
    timeStart: yup.string().required('Please select Event Start Time.'),    
    timeEnd: yup.string().required('Please select Event End Time.'),
    minimum_attendance_count:yup.number()
        .when('minimum_attendance',{
            is:'Yes',
            then:yup.number().min(1,'Minium 1 is required').required('Enter valid number of attendance').typeError('Enter valid number of attendance')
    }).nullable(),
}).required();

const EventDetailEditForm = ({ id }) => {
    const [locationValue,setLocationValue] = useState(null);
    const [latlong,setLatLong] = useState([]);
    const { team, teamUpdating } = useContext(TeamContext);
    const [loading, setLoading] = useState(false);
    const [eventItem, setEventItem] = useState([]);
    const [eventDescription, setEventDescEditor]= useState("");
    const [errorMessage,setErrorMessage] = useState(false);
    const [image, setEventImage] = useState(null);
    const [coaches, setCoaches] = useState([]);

    //New coaches with role input form needs to code
    const [selectedCoachesList, setSelectedCoachesList] = useState([]);

    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [updateAll, setUpdateAll] = useState('No');
    const [reminderState, setReminderState] = useState('No');
    const [reminderTimeErr, setReminderTimeErr] = useState(false);
    const [pdfFiles, setPdfFile] = useState([]);
    const [minEndDate, setMinEndDate] = useState(new Date());
    const [isMinimumAttandance,setMinimumAttendance] = useState('No');

    const [isAddedProduct, setIsAddedProduct] = useState(eventItem.is_added_product?'Yes':'No');
    const [products, setProducts] = useState([]);
    const [isStripeEnabled, setIsStripeEnabled] = useState(false);
    const [showStripeForm,setShowStripeForm] = useState(false);

    const [timepickerValstart, setTimepickerValStart] = useState(new Date());
    const [timepickerValEnd, setTimepickerValEnd] = useState(new Date());
    const [parentGroups,setParentGroups] = useState([]);
    const [disabledGroupsOpt, setDisableGroupsOpt]= useState([]);
    const contentRef=useRef();

    const { register, handleSubmit, setValue, watch, control,formState: { errors } } = useForm({
        resolver: yupResolver(schemaEdit)
    });

    const reminderTimeList = [
        {key:15,value:"15 minutes before"}, 
        {key:30,value:"30 minutes before"}, 
        {key:60,value:"1 hour before"}, 
        {key:120,value:"2 hours before"},
        {key:360,value:"6 hours before"},
        {key:1440,value:"1 day before"}, 
        {key:2880, value:"2 days before"},
        {key:4320, value:"3 days before"},
        {key:5760, value:"4 days before"},
        {key:7200, value:"5 days before"}];
    
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
            resolve(base64String);
        }
        reader.onerror = error => reject(error);
    });

    const EnableStripeAccount = ({setShowForm}) =>{
        const [connectStripErr, setConnectStripeErr] = useState(null);
        const [loadingStripe, setLoadingStripe] = useState(false);

        const handleConnectStripe = async()=>{
            setLoadingStripe(true);
            var formdata = new FormData();
            formdata.append("team_id", team._id);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/app_stripe_connect' , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                setLoadingStripe(false);
                if(rdata.status !== 1){                    
                    setConnectStripeErr(rdata.message);
                }
                else{
                    //window.location.href=rdata.data.redirct_url;
                    window.open(
                        rdata.data.redirct_url,
                        '_blank' // <- This is what makes it open in a new window.
                    );
                }
            })
            .catch(error => console.log('error', error));
        }
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-full !max-w-[500px]">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <p className="text-center mb-5">Connect your stipe account to maximise your team revenue on PLAI.</p>
                    <div className="flex justify-center">
                        {!loadingStripe ? 
                        <>
                            <button type="button" className="button mx-2.5" onClick={(e)=>{handleConnectStripe()}}>Connect Stripe</button>
                            <button type="button" className="button mx-2.5" onClick={(e)=>{setShowForm(false)}}>Cancel</button>
                            </>
                            :
                            <>
                                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
                            </>
                        }
                    </div>
                    {connectStripErr!==null &&
                        <div className="text-red-500 text-center">{connectStripErr}</div>
                    }
                </div>
            </div>
        )
    }

    const submitUpdateEvent = async(data) =>{
        let submitflag = true;
        setReminderTimeErr(false);
        var etimearr = data.timeEnd.split(":");
        var stimearr = data.timeStart.split(":");
        if(data.reminder_notification==='Yes'){            
            if(data.reminder_time === null || data.reminder_time === ""){
                submitflag=false;
                setReminderTimeErr(true);
            }
        }
        if(eventItem.recurring!=='ONCE'){
            if(updateAll==='No'){
                if(dayjs(data.dateEnd).format("YYYYMMDD") < dayjs(data.dateStart).format("YYYYMMDD")){
                    submitflag = false;
                    setErrorMessage('End Date should be same or future date from start date');
                }
                else if(dayjs(data.dateEnd).format("YYYYMMDD") === dayjs(data.dateStart).format("YYYYMMDD")){
                    if((dayjs(new Date(data.dateEnd).setHours(Number(etimearr[0]),Number(etimearr[1]))) - dayjs(new Date(data.dateStart).setHours(Number(stimearr[0]),Number(stimearr[1])))) <= 0){
                        submitflag = false;
                        setErrorMessage('End time should be greater than start time.');
                    }
                }
            }
        }
        else{            
            if(dayjs(data.dateEnd).format("YYYYMMDD") < dayjs(data.dateStart).format("YYYYMMDD")){
                submitflag = false;
                setErrorMessage('End Date should be same or future date from start date');                
            }
            else if(dayjs(data.dateEnd).format("YYYYMMDD") === dayjs(data.dateStart).format("YYYYMMDD")){
                if((dayjs(new Date(data.dateEnd).setHours(Number(etimearr[0]),Number(etimearr[1]))) - dayjs(new Date(data.dateStart).setHours(Number(stimearr[0]),Number(stimearr[1])))) <= 0){
                    submitflag = false;
                    setErrorMessage('End time should be greater than start time.');
                }
            }
        }
        if(submitflag){
            setLoading(true);
            var formdata = new FormData();
            formdata.append("type", "update_event");
            formdata.append("team_id", team._id);
            formdata.append("event_id", eventItem._id);
            formdata.append("name", data.event_name);
            formdata.append("event_type", data.event_type);
            if(data.event_type==='Game'){
                formdata.append("sub_type", data.sub_type);
            }
            formdata.append("autoAccept", data.autoAccept);
            formdata.append("description", eventDescription);
            formdata.append("capacity", data.capacity);
            formdata.append("timezone", timeZone);
            formdata.append("commentsEnabled", data.commentsEnabled ? 'Yes' : 'No');
            formdata.append("notify_email",data.notify_email ? 'Yes' : 'No');
            formdata.append("notify_notification", data.notify_notification ? 'Yes' : 'No');

            if(eventItem.recurring!=='ONCE'){
                formdata.append("updateAll", data.updateAll);
                if(updateAll==='No'){
                    formdata.append("dateStart", dayjs(data.dateStart).format("YYYY-MM-DD"));
                    formdata.append("dateEnd", dayjs(data.dateEnd).format("YYYY-MM-DD"));
                }
                else{
                    formdata.append("dateStart", data.dateStart);
                    formdata.append("dateEnd", data.dateEnd);
                }
            }
            else{
                formdata.append("dateStart", dayjs(data.dateStart).format("YYYY-MM-DD"));
                formdata.append("dateEnd", dayjs(data.dateEnd).format("YYYY-MM-DD"));
            }
            formdata.append("timeStart", data.timeStart);
            formdata.append("timeEnd", data.timeEnd);

            formdata.append("reminder_notification", data.reminder_notification);
            if(data.reminder_notification==='Yes'){            
                formdata.append("reminder_time",data.reminder_time);
                var rtindex = reminderTimeList.findIndex(p => p.key === parseInt(data.reminder_time));
                formdata.append("reminder_time_display",reminderTimeList[rtindex].value);
            }

            if(data.minimum_attendance === 'Yes'){
                formdata.append('minimum_attendance','Yes');
                formdata.append('minimum_attendance_count',data.minimum_attendance_count);
            }
            else{
                formdata.append('minimum_attendance','No');
                formdata.append('minimum_attendance_count',0);
            }
            
            if(selectedCoachesList.length > 0){
                let selectedCoaches = [];
                selectedCoachesList.forEach((scoach)=>{
                    if(scoach.role==='OTHER'){
                        selectedCoaches.push({'sub':scoach.sub, 'coach_role':scoach.custom_role});
                    }
                    else{
                        selectedCoaches.push({'sub':scoach.sub, 'coach_role':scoach.role});
                    }                    
                });
                
                formdata.append("coaches_new", JSON.stringify(selectedCoaches));
            }
            else{
                formdata.append("coaches_new", "");
            }
            if(selectedGroups.length>0){
                formdata.append("group_ids", selectedGroups.join('@#'));
            }
            else{
                formdata.append("group_ids", "");
            }
            if(data.is_added_product){
                formdata.append("is_added_product", 'Yes');
                formdata.append("product_id", data.product_id);
            }
            else{
                formdata.append("is_added_product", 'No');
                formdata.append("product_id", "");
            }
            if(image!==null){
                var formdatas3 = new FormData();
                let tmpFilename = generateRandomName();
                const imgFileObj = new File([image], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: image.type })
                formdatas3.append("files[]", imgFileObj);
                var requestOptionss3 = {
                    method: 'PUT',
                    body: formdatas3,
                    redirect: 'follow'
                };
                const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
                const resultsApp = await responsesApp.text();
                const rdatasApp = JSON.parse(resultsApp);
                formdata.append('content',rdatasApp.data[0]);
            }

            if(pdfFiles.length > 0){
                let pdfcodes = [];
                let exts = [];
                for(let i=0; i<pdfFiles.length; i++){
                    pdfcodes[i] = await toBase64(pdfFiles[i].file);
                    exts[i] = pdfFiles[i].fileExtension;
                };
                formdata.append("document_content", pdfcodes.join('@#'));
                formdata.append("document_extension", exts.join('@#'));
            }
            
            if(locationValue!==null && locationValue.label!==undefined){
                formdata.append('location',locationValue.label);
            }
            if(latlong.lng !== undefined){
                formdata.append('longitude',latlong.lng);
                formdata.append('latitude',latlong.lat);
            }
            formdata.append('separate_by','@#');
            
            for (var pair of formdata.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }

            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    setErrorMessage(rdata.message);
                }
                else{                    
                    window.location.href = '/team/'+team._id+'/event/'+eventItem._id;
                }
            })
            .catch(error => console.log('error', error));
        }        
    }

    const onSubmit = (data) => {
        submitUpdateEvent(data);        
    }
    
    async function refreshEvent() {
        setLoading(true);
        
        const [eventData, groupsData, productsData] = await Promise.all([
            getEventDetail(id, team._id, token),            
            getUserAsignedGroup(token, team._id, id, 'event'),
            getAllProducts(team._id,token)
        ]);
        
        setEventItem(eventData);

        const coach_roleList = ["LEAD COACH", "ASSISTANT COACH", "CAPTAIN", "TEAM MANAGER", "COORDINATOR", "OFFICIATOR", "REFEREE", "UMPIRE", "SCORER", "TIME KEEPER", "OTHER"];
        
        let selectedCoachesOptions = [];
        if(eventData.coaches.length>0){
            eventData.coaches.forEach((tcoach)=>{
                if(coach_roleList.includes(tcoach.coach_role)){
                    selectedCoachesOptions.push({'sub':tcoach.sub, 'name':tcoach.name, 'role': tcoach.coach_role, 'custom_role':''})
                }
                else{
                    selectedCoachesOptions.push({'sub':tcoach.sub, 'name':tcoach.name, 'role': 'OTHER', 'custom_role':tcoach.coach_role})
                }                
            });
        }
        
        setSelectedCoachesList(selectedCoachesOptions);
        //setCoaches(coachesOptions);

        let disableGOpt=[];
        let groupOptions = [];
        let parentGroupOptions = [];

        groupsData.forEach(element => {
            let childOptions = [];
            parentGroupOptions.push({'value':element._id, 'label':element.name});
            if(!element.canSelect){
                disableGOpt.push(element._id);
            }
            if(element.nest_groups.length > 0){
                element.nest_groups.forEach(celm =>{
                    childOptions.push({'value':celm._id, 'label':celm.name});
                    if(!celm.canSelect){
                        disableGOpt.push(celm._id);
                    }
                });
                groupOptions.push({'value':element._id, 'label':element.name, 'children':childOptions});
            }
            else{
                groupOptions.push({'value':element._id, 'label':element.name});
            }            
        });
        setDisableGroupsOpt(disableGOpt);
        setGroups(groupOptions);
        setParentGroups(parentGroupOptions);
        setProducts(productsData);
        setIsStripeEnabled(team.stripeEnabled);

        setValue('updateAll',updateAll);
        setValue('event_name',eventData.name);
        setValue('event_type',eventData.type);
        if(eventData.type==='Game'){
            setValue('sub_type',eventData.sub_type);
        }
        setValue('capacity',eventData.capacity);
        setValue('autoAccept',eventData.autoAccept ? 'Yes' : 'No');
        setValue('commentsEnabled',eventData.commentsEnabled);
        setValue('dateStart',toCurrentOffset(eventData.dateStart));
        //setValue('dateStart',new Date(eventData.dateStart));
        setValue('dateEnd',toCurrentOffset(eventData.dateEnd));
        //setValue('dateEnd',new Date(eventData.dateEnd));
        setMinEndDate(toCurrentOffset(eventData.dateStart));
        setValue('timeStart',eventData.timeStart);
        setValue('timeEnd',eventData.timeEnd);
        setValue('reminder_notification',eventData.reminder_notification ? 'Yes' : 'No');
        setReminderState(eventData.reminder_notification ? 'Yes' : 'No');

        let startDateArr = eventData.dateStart.split('-');
        let startTimeArr = eventData.timeStart.split(':');
        
        setTimepickerValStart(new Date(startDateArr[0],startDateArr[1],startDateArr[2],startTimeArr[0],startTimeArr[1],'0'));

        let endDateArr = eventData.dateEnd.split('-');
        let endTimeArr = eventData.timeEnd.split(':');
        
        setTimepickerValEnd(new Date(endDateArr[0],endDateArr[1],endDateArr[2],endTimeArr[0],endTimeArr[1],'0'));


        setValue('notify_notification',eventData.notify_notification);
        setValue('notify_email',eventData.notify_email);

        if(eventData.minimum_attendance){
            setMinimumAttendance(eventData.minimum_attendance ? 'Yes' : 'No');
            setValue('minimum_attendance',eventData.minimum_attendance ? 'Yes' : 'No');
            setValue('minimum_attendance_count',eventData.minimum_attendance_count);
        }
        if(eventData.reminder_notification){
            setValue('reminder_time',eventData.reminder_time);
        }
        else{
            setValue('reminder_time','');
        }
        
        setSelectedGroups(eventData.group_ids);
        setValue('groups', eventData.group_ids);
        setEventDescEditor(eventData.description);
        setValue('is_added_product',eventData.is_added_product);
        if(eventData.product_id!==""){
            setValue('product_id',eventData.product_id);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (!teamUpdating) {
            refreshEvent();
        }
    }, [teamUpdating]);

    useEffect(()=>{
        if(locationValue!==null){
            geocodeByAddress(locationValue.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) =>{
                    setLatLong({ lat, lng });
                }
            );
        }        
    },[locationValue]);

    const handleChange = (e, key) => {
        const { value } = e.target        
        setEventDescEditor(value);
    }

    const watchEventType = watch("event_type");

    return (
        (!loading && Object.keys(eventItem).length > 0) ?
            <>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}/events`}>
                        Event
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}/event/${eventItem._id}`}>
                        {eventItem.name}
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Edit
                </div>                
                <div className="body px-5 lg:px-8 py-10">
                    <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                        <h2 className="title mr-5 uppercase">Edit {eventItem.name}</h2>
                        <div className="sep mr-4"></div>
                        <div className="cta flex">
                            <NavLink to={`/team/${team._id}/event/${eventItem._id}`} className={`button button--yellow button-medium`}>Back</NavLink>
                        </div>
                    </div>
                    <div className="bg-[#363F6C] py-7.5 px-5 rounded-xl">
                        <div>                            
                            <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                                <div className="flex flex-wrap lg:mb-12">                            
                                    {eventItem.recurring!=='ONCE' &&
                                        <>
                                        <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                            <div className="form-field-container">
                                                <div className="label">Update recurring events?</div>
                                                <div className='form-field-wrap mb-5 select-field-wrap repeat-field-wrap relative'>
                                                    <select {...register("updateAll")} onChange={(e)=>{setValue('updateAll', e.target.value); setUpdateAll(e.target.value);}}>
                                                        <option value="No">No, Update only this</option>
                                                        <option value="Yes">Yes, Update all recurring events</option>
                                                    </select>
                                                </div>
                                                <p className="-mt-2.5 mb-2.5 text-white text-[10px] leading-[16px]">Do you need to change the Dates of a recurring event, or mass delete a series? Reach out to <a href="mailto:support@plaisport.com">support@plaisport.com</a> and we'll ne happy to support!</p>
                                            </div>
                                        </div>
                                        </>
                                    }
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">Event Name*</div>
                                            <div className='form-field-wrap mb-5 user-field-wrap relative'>
                                                <input 
                                                    type="text" id="event-name" disabled={loading} placeholder="Event Name *" className='text-field'
                                                    {...register("event_name")}
                                                    aria-invalid={errors.event_name ? "true" : "false"} 
                                                />                                
                                            </div>
                                            {errors.event_name && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.event_name?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="w-full xl:w-1/3 flex flex-wrap">
                                        <div className={`w-full ${watchEventType==='Game' ? 'xl:w-1/2':''} md:px-2.5 xl:px-3.5`}>
                                            <div className="form-field-container">
                                                <div className="label">Event Type*</div>
                                                <div className='form-field-wrap mb-5 select-field-wrap type-field-wrap relative'>
                                                    <select {...register("event_type")} onChange={(e)=>setValue('event_type', e.target.value, { shouldValidate: true })}>
                                                        <option value="">Event Type *</option>
                                                        <option value="Competition">Competition</option>
                                                        <option value="Game">Game</option>
                                                        <option value="Race">Race</option>
                                                        <option value="Social Event">Social Event</option>
                                                        <option value="Practice">Practice</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                {errors.event_type && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.event_type?.message}</p>}
                                            </div>
                                        </div>
                                        {watchEventType==='Game' &&
                                            <div className={`w-full xl:w-1/2 md:px-2.5 xl:px-3.5`}>
                                                <div className="form-field-container mb-5">
                                                    <div className="label">Game Type*</div>
                                                    <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                                        <select {...register("sub_type")} onChange={(e)=>{setValue('sub_type', e.target.value, { shouldValidate: true });}}>
                                                            <option value="">Game Type*</option>
                                                            <option value="League">League</option>
                                                            <option value="Cup">Cup</option>
                                                            <option value="Tournament">Tournament</option>
                                                            <option value="Friendly">Friendly</option>
                                                            <option value="Tour">Tour</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </div>
                                                    {errors.sub_type?.message && <p className="error mb-2.5" role="alert">{errors.sub_type?.message}</p> }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="flex flex-wrap lg:mb-12">
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">Event Location</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap location-field-wrap relative'>
                                                <GooglePlacesAutocomplete
                                                    apiKey={process.env.REACT_APP_GAPIKEY}
                                                    selectProps={{
                                                        //locationValue,
                                                        onChange: setLocationValue,
                                                        //components: { DropdownIndicator },
                                                        styles: {
                                                            control: (baseStyles) => ({
                                                                ...baseStyles,
                                                                borderWidth: 0,
                                                                backgroundColor: '#EFF1F9',
                                                                padding: '6px 7px 6px 46px'
                                                            }),
                                                            option: (baseStyles) => ({
                                                                ...baseStyles,
                                                                backgroundColor: '#ffffff',
                                                                zIndex: 10000
                                                            }),
                                                            menu: (baseStyles) => ({
                                                                ...baseStyles,
                                                                zIndex:1000,
                                                                backgroundColor: '#ffffff'
                                                            }),
                                                        },
                                                        theme:(theme) => ({
                                                            ...theme,
                                                            borderRadius: 8,                                                
                                                        }),
                                                    }}                                    
                                                />
                                                <p className="text-[10px] text-white">Previously: {eventItem.location}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-2/3 flex flex-wrap">
                                        {updateAll==='No' &&
                                            <>
                                                <div className="w-full md:w-1/2 lg:w-1/4 md:px-2.5 lg:px-3.5">
                                                    <div className="form-field-container">
                                                        <div className="label">Start Date*</div>
                                                        <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                                                            <Controller
                                                                control={control}
                                                                name='dateStart'
                                                                errors={errors}
                                                                render={({ field, ref }) => (
                                                                    <ReactDatePicker
                                                                        placeholderText='Start Date*'
                                                                        minDate={new Date()}
                                                                        onChange={(date) => {
                                                                            field.onChange(date); 
                                                                            setMinEndDate(date);
                                                                            setValue('dateEnd',date);
                                                                        }}
                                                                        //utcOffset={timeZone}
                                                                        selected={field.value}
                                                                        mask="____-__-__"
                                                                        dateFormat="dd-MM-yyyy"
                                                                        inputRef={ref}                                     
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        {errors.dateStart && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.dateStart?.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="w-full md:w-1/2 lg:w-1/4 md:px-2.5 lg:px-3.5">
                                                    <div className="form-field-container">
                                                        <div className="label">End Date*</div>
                                                        <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                                                            <Controller
                                                                control={control}
                                                                name='dateEnd'
                                                                errors={errors}
                                                                render={({ field, ref }) => (
                                                                    <ReactDatePicker
                                                                        placeholderText='End Date *'
                                                                        minDate={minEndDate}
                                                                        onChange={(date) => {field.onChange(date)}}
                                                                        selected={field.value}
                                                                        mask="____-__-__"
                                                                        dateFormat="dd-MM-yyyy"
                                                                        inputRef={ref}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        {errors.dateEnd && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.dateEnd?.message}</p>}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="w-full md:w-1/2 lg:w-1/4 md:px-2.5 lg:px-3.5">
                                            <div className="form-field-container">
                                                <div className="label">Start Time*</div>
                                                <div className='form-field-wrap mb-5 select-field-wrap time-field-wrap relative'>
                                                    <Controller
                                                        control={control}
                                                        name='timeStart'
                                                        errors={errors}
                                                        render={({ field }) => (
                                                            <DatePicker 
                                                                placeholder='Start Time *'
                                                                format="hh:mm aa" 
                                                                onChange={(date) => {
                                                                    field.onChange(dayjs(date).format('HH:mm'));
                                                                }}
                                                                caretAs={null} 
                                                                cleanable={false}
                                                                defaultValue={timepickerValstart}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                {errors.timeStart && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.timeStart?.message}</p>}
                                            </div>
                                        </div>
                                        <div className="w-full md:w-1/2 lg:w-1/4 md:px-2.5 lg:px-3.5">
                                            <div className="form-field-container">
                                                <div className="label">End Time*</div>
                                                <div className='form-field-wrap mb-5 select-field-wrap time-field-wrap relative'>
                                                    <Controller
                                                        control={control}
                                                        name='timeEnd'
                                                        errors={errors}
                                                        render={({ field }) => (
                                                            <DatePicker 
                                                                placeholder='End Time *'
                                                                format="hh:mm aa" 
                                                                onChange={(date) => {
                                                                    field.onChange(dayjs(date).format('HH:mm'));
                                                                }}
                                                                caretAs={null} 
                                                                cleanable={false}
                                                                defaultValue={timepickerValEnd}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                {errors.timeEnd && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.timeEnd?.message}</p>}
                                            </div>
                                        </div>
                                    </div>                               
                                </div>
                                <div className="flex flex-wrap lg:mb-12">
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">Requests to join*</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap requestjoin-field-wrap relative'>
                                                <select {...register("autoAccept")} onChange={(e)=>setValue('autoAccept', e.target.value, { shouldValidate: true })}>
                                                    <option value="">Requests to join*</option>
                                                    <option value="Yes">Auto Accept</option>
                                                    <option value="No">Manual Accept</option>
                                                </select>
                                            </div>
                                            {errors.autoAccept && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.autoAccept?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">Send Reminder*</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                                <select {...register("reminder_notification")} onChange={(e)=>{setValue("reminder_notification",e.target.value, { shouldValidate: true }); setReminderState(e.target.value);}}>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>                                        
                                                </select>
                                            </div>                                
                                        </div>
                                    </div>
                                    {reminderState==='Yes' &&
                                        <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                            <div className="form-field-container">
                                                <div className="label">Reminder Time*</div>
                                                <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                                    <select {...register("reminder_time")} onChange={(e)=>{setValue('reminder_time', e.target.value, { shouldValidate: true});}}>
                                                        <option value="">Reminder Before *</option>
                                                        {reminderTimeList.map((reminderTime)=>{
                                                            return(
                                                                <option value={reminderTime.key} key={reminderTime.key}>{reminderTime.value}</option>
                                                            )
                                                        })}
                                                        </select>                                    
                                                </div>
                                                {reminderTimeErr && <p className="error mb-2.5 -mt-2.5" role="alert">Please select reminder time</p>}
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="flex flex-wrap lg:mb-12">
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">Event Capacity*</div>
                                            <div className='form-field-wrap mb-5 user-field-wrap relative'>
                                                <input 
                                                    type="number" min="0" className='text-field'
                                                    {...register("capacity")} 
                                                />                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container z-[1000000] relative">
                                            <div className="flex justify-between">
                                                <div className="label">Select Coaches</div>
                                                <p className="mb-1 font-medium text-[12px] leading-[18px] text-white">{(selectedCoachesList && selectedCoachesList.length) > 0 ? selectedCoachesList.length : 0} Members Selected</p>
                                            </div>
                                            <div className='form-field-wrap mb-5 select-field-wrap user-field-wrap relative'>
                                                {/*
                                                <Controller
                                                    control={control}
                                                    name='coaches'
                                                    render={({ field: { onChange, value, ref }}) => (
                                                        <Select
                                                            inputRef={ref}
                                                            value={selectedCoachesOut}
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            components={{ DropdownIndicator }}
                                                            placeholder={<div>Select Coaches</div>}
                                                            onChange={val => {
                                                                onChange(val.map(c => c.value));
                                                                handleSelectChange(val);
                                                            }}
                                                            options={coaches}
                                                            isMulti
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderWidth: 0,
                                                                    backgroundColor: '#EFF1F9',
                                                                    padding: '6px 7px 6px 46px'
                                                                }),
                                                                menu: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    zIndex:1000,
                                                                    backgroundColor: '#ffffff'
                                                                })
                                                            }}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                borderRadius: 8,                                                
                                                            })}
                                                        />
                                                    )}
                                                />
                                                */}
                                                <CoachesWithRoleInputV2 teamID={team._id} token={token} parentsGroups={parentGroups} selectedCoaches={selectedCoachesList} options={coaches} onChange={setSelectedCoachesList} onChangeMain={setCoaches} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container z-[1000000]">
                                            <div className="flex justify-between">
                                                <div className="label">Sending to*</div>
                                                <p className="mb-1 font-medium text-[12px] leading-[18px] text-white">
                                                    {selectedGroups.length===0 ? 
                                                        '0 Groups Selected':
                                                        (selectedGroups.length > 1) ? selectedGroups.length + ' Groups Selected' : selectedGroups.length + ' Group Selected'}
                                                </p>
                                            </div>
                                            <div className='form-field-wrap mb-5 select-field-wrap capacity-field-wrap relative' ref={contentRef}>                            
                                                <Controller
                                                    control={control}
                                                    name='groups'
                                                    render={({ field: { onChange, value, ref }}) => (
                                                        <CheckTreePicker
                                                            defaultExpandAll
                                                            placeholder="Select Groups*"
                                                            data={groups}
                                                            disabledItemValues={disabledGroupsOpt}
                                                            cleanable={false}
                                                            onChange={val => {
                                                                onChange(val.map(c => c.value));
                                                                setValue('groups',val);
                                                                setSelectedGroups(val);
                                                            }}
                                                            container={()=>contentRef.current}
                                                            menuStyle={{zIndex: 100000000}}
                                                            defaultValue={selectedGroups}
                                                            value={selectedGroups}
                                                            style={{
                                                                width:'100%',
                                                                maxWidth:'500px',
                                                                borderWidth:0,
                                                                backgroundColor: '#EFF1F9',
                                                                padding: '8px 7px 8px 46px',
                                                                height: '52px',
                                                                borderRadius: 8
                                                            }}
                                                            onSelect={(item,val,event)=>{
                                                                    if(item.label.toLowerCase()==="all members"){
                                                                        if(item.check){
                                                                            let groupIds=[];
                                                                            groups.forEach((item)=>{
                                                                                groupIds.push(item.value);
                                                                            });
                                                                            setSelectedGroups(groupIds);
                                                                        }
                                                                        else{
                                                                            setSelectedGroups([]);
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        />
                                                    )}
                                                />
                                            </div>
                                            {errors.groups?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.groups?.message}</p> }
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-wrap lg:mb-12">
                                    <div className="w-full lg:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">Required Minimum Attendance*</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                                <select {...register("minimum_attendance")} onChange={(e)=>{setValue('minimum_attendance', e.target.value, { shouldValidate: true }); setMinimumAttendance(e.target.value)}}>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </div>
                                            {errors.minimum_attendance && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.minimum_attendance?.message}</p>}
                                        </div>                                
                                    </div>                            
                                    {isMinimumAttandance==='Yes' &&
                                    <div className="w-full lg:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">How Many?*</div>
                                            <div className='form-field-wrap mb-5 user-field-wrap relative'>
                                                <input 
                                                    type="number" min="1" className='text-field'
                                                    {...register("minimum_attendance_count")} 
                                                />                                
                                            </div>
                                            {errors.minimum_attendance_count && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.minimum_attendance_count?.message}</p>}
                                        </div>
                                    </div>
                                    }
                                </div>

                                <div className="md:px-2.5 lg:px-3.5 lg:mb-12">
                                    <div className="form-field-wrap mb-5 relative">
                                        <TextEditor
                                            value={eventDescription}
                                            placeholderText="Enter Event Information..."
                                            height={200}
                                            onChange={(e) => handleChange({ target: { value: e } }, 'editor')}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap lg:mb-12">
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className='form-field-wrap no-field-wrap mb-5 lg:mb-0 relative'>
                                            <PlaiImageCropper editImage={eventItem.image ? eventItem.image : ''} onFinalChange={(newImage)=>{setEventImage(newImage)}} aspect={2 / 1}/>
                                        </div>
                                    </div>
                                    <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                        <div className='form-field-wrap no-field-wrap mb-5 lg:mb-0 relative'>
                                            <FilePond
                                                files={pdfFiles}
                                                onupdatefiles={setPdfFile}                                    
                                                allowMultiple={true}
                                                credits={false}
                                                allowFileTypeValidation={true}
                                                acceptedFileTypes={['application/pdf']}
                                                labelFileTypeNotAllowed='File of invalid type'
                                                //maxFiles={1}                                    
                                                labelIdle='
                                                    <div class="upload-text">
                                                        <h4>Upload a PDF</h4>
                                                        <p class="text-center">Maximum upload file size: 2MB</p>
                                                    </div>'
                                                {...register("pdfdocuments")}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full xl:w-1/3 flex flex-col justify-between">
                                        <div>
                                            <div className="flex flex-wrap">
                                                <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                                    <div className='form-field-wrap new-check-field-wrap relative'>                                
                                                        <label htmlFor="notify_email" className=''>
                                                            <input type="checkbox" id="notify_email" {...register("notify_email")} className='checkbox-field appearance-none'/>
                                                            Send Email
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                                    <div className='form-field-wrap new-check-field-wrap relative'>                                
                                                        <label htmlFor="notify_notification" className=''>
                                                            <input type="checkbox" id="notify_notification" {...register("notify_notification")} className='checkbox-field appearance-none'/>
                                                            Send Notification
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap">
                                                <div className="w-full md:px-2.5 lg:px-3.5">
                                                    <div className='form-field-wrap new-check-field-wrap relative'>                                
                                                        <label htmlFor="commentsEnabled" className=''>
                                                            <input type="checkbox" id="commentsEnabled" {...register("commentsEnabled")} className='checkbox-field appearance-none'/>
                                                            Allow Comments
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap">
                                                {isStripeEnabled ?                                        
                                                <>
                                                {!eventItem.is_send_payment_link ?
                                                    <>
                                                        <div className="w-full md:px-2.5 xl:px-3.5">
                                                            <div className='form-field-wrap new-check-field-wrap relative'>                                
                                                                <label htmlFor="is_added_product" className=''>
                                                                    <input type="checkbox" id="is_added_product" {...register("is_added_product")}
                                                                        onChange={(e)=>{
                                                                            if(e.target.checked){
                                                                                setIsAddedProduct('Yes');
                                                                            }
                                                                            else{
                                                                                setIsAddedProduct('No');
                                                                            }
                                                                        }}
                                                                        className='checkbox-field appearance-none'/>
                                                                        Link this event to shop item
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {isAddedProduct==='Yes' &&
                                                            <div className="w-full md:px-2.5 xl:px-3.5">
                                                                <div className="form-field-container">
                                                                    <div className="label">Shop Item*</div>
                                                                    <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                                                        <select {...register("product_id")} onChange={(e)=>setValue('product_id', e.target.value, { shouldValidate: true })}>
                                                                            {products.length>0 &&
                                                                                <>
                                                                                    {products.map((product)=>{
                                                                                        return(
                                                                                            <option key={product._id} value={product._id}>{product.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                </>
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    {errors.product_id && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.product_id?.message}</p>}
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <div className="w-full md:px-2.5 xl:px-3.5">
                                                            <div className='form-field-wrap new-check-field-wrap relative'>                                
                                                                <label htmlFor="is_added_product" className="cursor-pointer relative before:content-[''] before:absolute before:w-[20px] before:h-full before:top-0 before:left-0 before:z-10" onClick={(e)=>{                                                                    
                                                                            alert("Now you can't change the linked shop item as Admin has sent the payment request to all the approved members.");
                                                                    }}>
                                                                    <input type="checkbox" id="is_added_product" {...register("is_added_product")} disabled                                                                
                                                                        className='checkbox-field appearance-none'/>
                                                                        Link this event to shop item
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {isAddedProduct==='Yes' &&
                                                            <div className="w-full md:px-2.5 xl:px-3.5">
                                                                <div className="form-field-container">
                                                                    <div className="label">Shop Item*</div>
                                                                    <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'
                                                                        onClick={()=>{
                                                                            alert("Now you can't change the linked shop item as Admin has sent the payment request to all the approved members.");
                                                                        }}>
                                                                        <select {...register("product_id")} disabled>
                                                                            {products.length>0 &&
                                                                                <>
                                                                                    {products.map((product)=>{
                                                                                        return(
                                                                                            <option key={product._id} value={product._id}>{product.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                </>
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    {errors.product_id && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.product_id?.message}</p>}
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                </>
                                                :
                                                <>
                                                <div className="w-full md:px-2.5 xl:px-3.5">
                                                    <div className='form-field-wrap new-check-field-wrap relative'>                                
                                                        <label htmlFor="is_added_product" className="cursor-pointer relative before:content-[''] before:absolute before:w-[20px] before:h-full before:top-0 before:left-0 before:z-10" onClick={()=>{setShowStripeForm(true)}}>
                                                            <input type="checkbox" id="notify_sms" {...register("is_added_product")}                                                        
                                                                className='checkbox-field appearance-none' disabled/>
                                                                Link this event to shop item
                                                        </label>
                                                    </div>
                                                </div>
                                                </>
                                            }
                                            </div>                                    
                                        </div>
                                        <div className="w-full md:px-2.5 lg:px-3.5 pt-5">
                                            <div className='flex justify-center lg:justify-end'>
                                                <input className='button button--large' type="submit" value='Update' disabled={loading}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!loading && errorMessage ?
                                    <div className="w-full">
                                        <div className='flex justify-center mb-10'>
                                            <p className='error text-center mb-2.5 text-[#ff0000]'>{errorMessage}</p>
                                        </div>
                                    </div>
                                    : null
                                }                        
                            </form>
                        </div>
                    </div>
                </div>
                {showStripeForm &&
                    <EnableStripeAccount setShowForm={setShowStripeForm} />
                }
            </>
            :
            <>
                <div className="py-5 text-center"><Spinner /></div>
            </>
    )
}

const EventDetailEdit = () => {
    const { id, eventid } = useParams();
    return (
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="page-eventdetail">
                    <AdminNavbar heading="Event" />
                    <EventDetailEditForm id={eventid} />
                </div>
            </TeamContextProvider>
        </>
    );
}

export default EventDetailEdit;