import { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NoNews from "../../../assets/images/noemptyother.png";
import { TeamContext } from "../../../contexts/TeamContext";
import NewsCard from "./NewsCard";
import { getNews } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";

const TabNews=({setTabActive})=>{
    const [newsList, setNewsList] = useState([]);
    const [newsListMain, setNewsListMain] = useState([]);
    const [gridView,setGridview] = useState(false);
    const { team, refreshTeam, teamUpdating } = useContext(TeamContext);
    const [showSortingOpt, setShowSortingOpt] = useState(false);
    const [newsLoading, setNewsLoading] = useState(false);
    const [filterBy, setFilterBy] = useState('all');
    const ref = useRef();
    const [q, setSearchQuery] = useState("");    
    const [searchParam] = useState(["title"]);
    const navigate = useNavigate();

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
	? localStorage.getItem('auth_token')
	: '';

    const sortBy = (orderby) =>{
        let sorted_news;
        if(orderby==='bookmarked'){
            sorted_news = newsListMain.filter((latestitem)=>(latestitem.bookmark==='yes'));
        }        
        if(orderby==='all'){
            sorted_news = newsListMain;
        }
        setNewsList(sorted_news);
        setShowSortingOpt(false);
        setFilterBy(orderby);
    }
    
    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    async function refreshNews() {
        setNewsLoading(true);
        const [newsdata] = await Promise.all([
            getNews(team._id,token),
        ]);
        setNewsListMain(newsdata);
        if(filterBy==='bookmarked'){
            setNewsList(newsdata.filter((latestitem)=>(latestitem.bookmark==='yes')));
        }
        else{
            setNewsList(newsdata);
        }
        setNewsLoading(false);
    }

    useEffect(()=>{
        refreshNews();
    },[team._id]);

    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (showSortingOpt && ref.current && !ref.current.contains(e.target)) {
                setShowSortingOpt(false)
            }
        }      
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[showSortingOpt]);

    return(
        !teamUpdating &&
        <>
            {!newsLoading ?
            <>
                <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center">
                    <div className="title-col max-w-[320px] md:max-w-full mx-auto md:mx-0 mb-2.5 md:mb-0">News List</div>
                    <div className="cta-col max-w-[320px] md:max-w-full mx-auto md:mx-0 flex justify-center md:justify-end flex-wrap items-center relative" ref={ref}>
                        <label htmlFor="search-form" className="search-field relative mr-auto ml-auto md:mr-3.5 mb-2.5 md:mb-0">
                            <input
                                type="search"
                                name="search-field"
                                id="search-form"
                                className="search-input"
                                placeholder="Search News"
                                value={q}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />                        
                        </label>
                        {(team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0)) &&
                            <>
                                <button type="button" className="button button-action button-new mr-3.5" onClick={(e)=>{navigate('/team/'+team._id+'/news/create-news')}}>Add new article</button>
                                <button type="button" className="button button-action mr-3.5" onClick={(e)=>{setTabActive('drafts')}}>Drafts</button>
                            </>
                        }
                        <button type="button" className={`button-icon button-icon-sort mr-3.5`} onClick={(e)=>{setShowSortingOpt(!showSortingOpt)}}><span>Sort</span></button>
                        <button type="button" className={`button-icon hidden md:block ${gridView ? 'button-icon-list' : 'button-icon-grid'}`} onClick={(e)=>setGridview(!gridView)}><span>View</span></button>
                        <ul className={`sort-actions absolute rounded-lg bg-white overflow-hidden top-full right-0 ${!showSortingOpt ? 'hidden' : ''}`}>
                            <li className={filterBy==='all' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('all');}}>All</div></li>
                            <li className={filterBy==='bookmarked' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('bookmarked');}}>Favourites</div></li>                        
                        </ul>
                    </div>                    
                </div>
                <div className={`${gridView ? 'newsgridview' : 'newslistview'} py-3 px-2.5 sm:px-7.5`}>
                    {newsList && newsList.length > 0 ? (
                        search(newsList).map((newsitem,index) => {                        
                            return (
                                <NewsCard newsitem={newsitem} gridView={gridView} refreshTeam={refreshTeam} refreshNews={refreshNews} team={team} key={newsitem._id+index} />
                            )
                        })
                    )
                    :
                    <div className="empty text-center">
                        <div className="image"><img src={NoNews} alt="Plaisport" width={228} /></div>                    
                        {filterBy==='bookmarked' ?
                            <>
                            <div className="title mb-5">No Favourites have been selected?</div>
                            <div className="description mb-8">Click on ⭐ to select news as Favourites</div>
                            </>
                        :
                            <>
                                <div className="title mb-5">No News Yet?</div>
                                <div className="description mb-8">{team.role==='admin' ? 'News is the first draft of history!' : 'They say no news is good news…'}</div>
                            </>
                        }
                        {(team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0)) &&
                            <button type="button" className='button button-yellow button-yellow-add' onClick={(e)=>{navigate('/team/'+team._id+'/news/create-news')}}>New Article</button>
                        }
                    </div>
                    }
                </div>
            </>
            :
            <>
                <div className="py-5 text-center"><Spinner /></div>
            </>
        }
        </>
    )
}

export default TabNews;