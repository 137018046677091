import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useState, useEffect} from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select, { components } from "react-select";
import dayjs from "dayjs";
import ReactDatePicker from "react-datepicker";

//import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import DownArrowSvg from "../../assets/images/icon-down.svg";
import { useFormData } from '../../contexts/FormContext';
import CountriesList from '../../libs/countries';
import PhoneCodeList from '../../libs/phonecodes';
import iconMale from "../../assets/images/icon-male.svg";
import iconFemale from "../../assets/images/icon-female.svg";

const schema = yup.object({
    phonecode: yup.string().required('Phone Code is required.'),
    phone:yup.number().typeError('Mobile number is required.'),
    birthdate: yup.string().when('bdatenottosay',{
        is:false,
        then:yup.string().required('Birthdate is required').nullable()
    }).nullable(),
    country: yup.string().required('Please select Country.'),
}).required();
const SignUpStepTwo =({ formStep, nextFormStep }) => {
    const { setFormValues } = useFormData();
    const [genderErr, setGenderErr] = useState(false);
    const [phoneCodeOptions,setPhoneCodeOptions]=useState([]);
    const [isBdateNotPreffer, setBdateNotPreffer] = useState(false);
    const [isgenderNotPreffer, setGenderNotPreffer] = useState(false);
    const [selectedPhoneCode, setSelectedPhoneCode] = useState([]);
    const [selectedPhoneCodeOut, setSelectedPhoneCodeOut] = useState(selectedPhoneCode);

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "all"
    });

    const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <img src={DownArrowSvg} width={24} height={24} alt="" />
          </components.DropdownIndicator>
        );
    }

    function handleSelectChange(selectedOpt) {        
        setSelectedPhoneCode(selectedOpt.value);
        setSelectedPhoneCodeOut(selectedOpt);
    }

    const onSubmit = (values) => {
        
        if((values.gender!==null || values.nottosay)){
            if(!values.bdatenottosay){
                setFormValues({
                    country:values.country,
                    phonecode:values.phonecode,
                    phone:values.phone,
                    birthdate:dayjs(values.birthdate).format('YYYY-MM-DD'), 
                    is_above_18: "",
                    gender: !values.nottosay ? values.gender : 'prefer_not_to_say',
                    nottosay: values.nottosay
                });
            }
            else{
                setFormValues({
                    country:values.country,
                    phonecode:values.phonecode,
                    phone:values.phone,
                    birthdate:"", 
                    is_above_18: values.is_above_18==='Yes' ? "Yes" : "No",
                    gender: !values.nottosay ? values.gender : 'prefer_not_to_say',
                    nottosay: values.nottosay
                });
            }
            nextFormStep();
        }
        else{
            if(values.gender===null && values.nottosay===false){
                setGenderErr(true);
            }
            else{
                setGenderErr(false);
            }
        }
    };

    useEffect(()=>{
        let phoneCodeOpt = [];
        PhoneCodeList.forEach(element => {
            phoneCodeOpt.push({'value':element.code, 'label':'('+element.code+') ' + element.name, 'country':element.name});
        });
        setPhoneCodeOptions(phoneCodeOpt);
    },[])
    
    return (
        <div className={formStep === 1 ? '' : 'hidden'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='auth-form-field-wrap mb-5 select-field-wrap country-field-wrap relative'>
                    <select {...register("country")} onChange={(e)=>{
                        setValue('country', e.target.value, { shouldValidate: true }); 
                        phoneCodeOptions.forEach(element => {
                            if(element.country === e.target.options[e.target.selectedIndex].text ){
                                setSelectedPhoneCode(element.value);
                                setSelectedPhoneCodeOut(element);
                                setValue('phonecode',element.value);
                            }
                            /*else{
                                setSelectedPhoneCode([]);
                                setSelectedPhoneCodeOut([]);
                            }*/
                        });
                    }}>
                        <option value="">Select Country *</option>
                        {CountriesList.map((c,index)=>{
                            return(
                                <option key={index} value={c.code}>{c.name}</option>
                            );
                        })}
                    </select>
                </div>
                {errors.country && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.country?.message}</p>}                                
                <div className='auth-form-field-wrap w-full mb-5 no-field-wrap relative'>
                    {/*<select {...register("phonecode")} onChange={(e)=>setValue('phonecode', e.target.value, { shouldValidate: true })}>
                        <option value="">Code *</option>
                        {PhoneCodeList.map((c,index)=>{
                            return(
                                <option key={index} value={c.code}>{c.name}</option>
                            );
                        })}
                    </select>*/}
                    <Controller
                        control={control}
                        name='phonecode'
                        render={({ field: { onChange, value, ref }}) => (
                            <Select
                                inputRef={ref}
                                value={selectedPhoneCodeOut}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                components={{ DropdownIndicator }}
                                placeholder={<div>Select Code*</div>}
                                isSearchable
                                onChange={val => {
                                    onChange(val.value);
                                    handleSelectChange(val);
                                }}
                                options={phoneCodeOptions}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderWidth: 0,
                                        backgroundColor: 'rgba(239, 241, 249, 0.6)',
                                        padding: '6px 7px 6px 7px'
                                    }),
                                    menu: (baseStyles) => ({
                                        ...baseStyles,
                                        zIndex:1000,
                                        backgroundColor: '#ffffff'
                                    })
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 8,                                                
                                })}
                            />
                        )}
                    />
                </div>
                {errors.phonecode && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.phonecode?.message}</p>}
                <div className='auth-form-field-wrap w-full mb-5 no-field-wrap relative'>
                    <input 
                        type="tel" id="phone" placeholder="Mobile Number *" className='text-field'
                        {...register("phone")}
                    />                                
                </div>
                
                {errors.phone && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.phone?.message}</p>}
                {!isBdateNotPreffer &&
                    <>
                        <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                            <Controller
                                control={control}
                                name='birthdate'
                                errors={errors}
                                render={({ field }) => (
                                <ReactDatePicker
                                    placeholderText='DD/MMM/YYYY'
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    maxDate={new Date()}
                                    mask="__-___-____"
                                    dateFormat="dd/MMM/yyyy"
                                    showYearDropdown
                                />
                            )}
                            />
                        </div>
                        {errors.birthdate && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.birthdate?.message}</p>}
                    </>
                }
                <div className='form-field-wrap mb-5 check-field-wrap relative !p-0'>
                    <label htmlFor="bdatenottosay" className='!text-[12px] font-normal leading-[12px] uppercase justify-end items-center'>
                        <input 
                            type="checkbox" id="bdatenottosay" className='checkbox-field appearance-none h-3 w-3 rounded-sm bg-white align-top float-left mr-1 cursor-pointer'
                            {...register("bdatenottosay")}
                            onChange={(e)=>{
                                if(e.target.checked){ setBdateNotPreffer(true); setValue('is_above_18','Yes'); }else{ setBdateNotPreffer(false); }
                            }}
                        />I'D PREFER NOT TO SHARE MY DOB
                    </label>
                </div>
                {isBdateNotPreffer &&
                    <div className="form-field-wrap button-group-wrap mb-5 flex justify-between items-center">
                        <div className="flex-1 button-grop-label">Are you over the age of 18?</div>
                        <div className="r-button-group w-[148px] flex">
                            <div className="mr-4">
                            <input type="radio" {...register("is_above_18")} value="Yes" id="is_above_18-yes" className="peer hidden" />
                            <label
                                htmlFor="is_above_18-yes"
                                className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                >Yes</label>
                            </div>
                            <div>
                            <input type="radio" {...register("is_above_18")} value="No" id="is_above_18-no" className="peer hidden" />
                            <label
                                htmlFor="is_above_18-no"
                                className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                >No</label>
                            </div>
                        </div>
                    </div>
                }
                {!isgenderNotPreffer &&
                    <div className="auth-form-field-wrap button-group-wrap mb-5 flex flex-wrap justify-between items-center">
                        <div className="flex-1 button-grop-label gender-field-label">Select Gender</div>
                        <div className="r-button-group flex">
                            <div className="mr-4">
                            <input type="radio" {...register("gender")} value="male" id="gender-male" className="peer hidden" onChange={(e)=>{setValue('nottosay',false); setValue('gender','male')}} />
                            <label
                                htmlFor="gender-male"
                                className="gender-label block py-[5px] flex items-center cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                ><img src={iconMale} width={18} height={18} alt="" className='mr-2'/>Male</label>
                            </div>
                            <div>
                            <input type="radio" {...register("gender")} value="female" id="gender-female" className="peer hidden" onChange={(e)=>{setValue('nottosay',false); setValue('gender','female')}} />
                            <label
                                htmlFor="gender-female"
                                className="gender-label block py-[5px] flex items-center cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                ><img src={iconFemale} width={18} height={18} alt="" className='mr-2'/>Female</label>
                            </div>
                        </div>
                    </div>
                }
                <div className='auth-form-field-wrap mb-5 check-field-wrap relative !p-0'>
                    <label htmlFor="nottosay" className='!text-[12px] font-normal leading-[12px] uppercase justify-end items-center'>
                        <input 
                            type="checkbox" id="nottosay" className='checkbox-field appearance-none h-3 w-3 rounded-sm bg-white align-top float-left mr-1 cursor-pointer'
                            {...register("nottosay")}
                            onChange={(e)=>{
                                if(e.target.checked){setGenderNotPreffer(true); setValue('gender','')}else{setGenderNotPreffer(false)}
                            }}
                        />I'D PREFER NOT TO SHARE MY GENDER
                    </label>
                </div>
                {genderErr && <p className="error mb-2.5 -mt-2.5" role="alert">Please either gender or prefer not to say.</p>}
                <div className='auth-form-field-wrap flex justify-center mb-2.5 md:mb-10'>
                    <input className='button button--large' type="submit" value={'Continue'}/>
                </div>
                <div className='auth-form-field-wrap flex justify-center'>
                    <span>Already have an account? <a href="/signin" className='auth-form-link'>Login</a></span>
                </div>
            </form>
        </div>
    );
}

export default SignUpStepTwo;