import {useState} from 'react';
import HeaderBlank from "../../components/HeaderBlank/HeaderBlank";
import signinBG from "../../assets/images/signin-bg.png";
import logoShort from "../../assets/images/logo-short.png";
import logoMedium from '../../assets/images/logo-medium.png';
import '../SignIn/auth.scss';
import FormProvider from '../../contexts/FormContext';
import ForgotPasswordStepOne from './ForgotPasswordStepOne';
import ForgotPasswordStepTwo from './ForgotPasswordStepTwo';

const FormCard = ({ children, currentStep, prevFormStep }) => {
    return (
      <div className='auth-login-form'>
        {children}        
      </div>
    );
}

const ForgotPasswordWrap = () =>{
    const [formStep, setFormStep] = useState(0);
    const nextFormStep = () => setFormStep((currentStep) => currentStep + 1);
    const prevFormStep = () => setFormStep((currentStep) => currentStep - 1);
    
    return(
        <div className='auth-page bg-no-repeat bg-center bg-cover'>
            <div className='auth-wrapper flex justify-between'>
                <div className='image-col hidden md:relative md:flex md:justify-center md:flex-1 md:items-center'>
                    <div className='image-bgwrapper absolute w-full h-full'>
                        <img src={signinBG} alt="Sign In" className='w-full h-full absolute' width={791} height={948}/>
                    </div>
                    <div className='logo-short relative z-10'>
                        <img src={logoShort} alt="Plaisport" width={129} height={141} />
                    </div>
                </div>
                <div className='auth-form-col flex justify-center items-center'>
                    <div className='auth-form-wrapper bg-white rounded-xl pt-11 pb-7'>
                        <div className='auth-form-head'>
                            <img src={logoMedium} alt="Plaisport" className='mx-auto' width={126} height={47} />
                        </div>
                        <FormCard currentStep={formStep} prevFormStep={prevFormStep}>
                            {formStep >= 0 && (
                                <ForgotPasswordStepOne formStep={formStep} nextFormStep={nextFormStep} />
                            )}
                            {formStep >= 1 && (
                                <ForgotPasswordStepTwo formStep={formStep} nextFormStep={nextFormStep} />
                            )}                            
                        </FormCard>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default function ForgotPassword() {
    return(
        <>
            <FormProvider>
                <HeaderBlank/>
                <ForgotPasswordWrap />
            </FormProvider>
        </>
    );
}