import { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import CheckTreePicker from 'rsuite/CheckTreePicker';
import * as yup from 'yup';
import Spinner from "../../../components/Spinner/Spinner";
import { getGroupsV2 } from "../../../libs/dataFunctions";

const schema = yup.object({
    firstName: yup.string().required('First name is required.'),
    lastName: yup.string().required('Last name is required.'),
    email: yup.string().email().required('Email address is required.'),
    groups: yup
    .array()
    .min(1, "Select at least one group for this member")
    .nullable()
    .required("Select at least one group for this member"),
}).required();

const MemberNewForm = ({team, setShowForm, refreshMembers}) =>{
    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(false);
    const [successMessage,setSuccessMessage] = useState(false);

    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    
    const { register, handleSubmit, setValue, control, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const contentRef=useRef();

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const newMemberSubmit = async(data)=>{
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "add_member");
        formdata.append("team_id", team._id);
        formdata.append("firstName", data.firstName);
        formdata.append("lastName", data.lastName);
        formdata.append("email", data.email);
        formdata.append("group_ids", selectedGroups.join('@#'));
        formdata.append("phone", data.phone);
        formdata.append("is_admin", data.is_admin ? 'Yes' : 'No');
        formdata.append('separate_by','@#');
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                setSuccessMessage(rdata.message);
                reset();
                setShowForm(false);
                refreshMembers();                
            }
        })
        .catch(error => console.log('error', error));
    }

    

    const onSubmit = async(data) => {
        newMemberSubmit(data);
    }

    async function refreshGroups(){
        setLoading(true);
        const [groupsData] = await Promise.all([
            getGroupsV2(team._id, token),
        ]); 
        
        let groupOptions = [];
        groupsData.forEach(element => {
            let childOptions = [];
            if(element.nest_groups.length > 0){
                element.nest_groups.forEach(celm =>{
                    childOptions.push({'value':celm._id, 'label':celm.name});
                });
                groupOptions.push({'value':element._id, 'label':element.name, 'children':childOptions});
            }
            else{
                groupOptions.push({'value':element._id, 'label':element.name});
            }
        });

        setGroups(groupOptions);          
        setLoading(false);      
    }

    useEffect(() => {
        refreshGroups();
        setValue('showLink', 'Y');
    },[]);

    return (
        !loading ?
        <>
            <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
            <div className="form-heading text-center mb-[30px]">New Member</div>
            <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">                
                <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2 md:px-2.5">
                        <div className='form-field-wrap mb-5 user-field-wrap relative'>
                            <input 
                                type="text" id="first-name" disabled={loading} placeholder="First name *" className='text-field'
                                {...register("firstName")}
                                aria-invalid={errors.firstName ? "true" : "false"} 
                            />                                
                        </div>
                        {errors.firstName && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.firstName?.message}</p>}
                        <div className='form-field-wrap mb-5 user-field-wrap relative'>
                            <input 
                                type="text" id="last-name" disabled={loading} placeholder="Last name *" className='text-field'
                                {...register("lastName")}
                                aria-invalid={errors.lastName ? "true" : "false"} 
                            />                                
                        </div>
                        {errors.lastName && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.lastName?.message}</p>}
                        <div className='form-field-wrap mb-5 email-field-wrap relative'>
                            <input 
                                type="email" id="email" disabled={loading} placeholder="Email *" className='text-field'
                                {...register("email")}
                                aria-invalid={errors.email ? "true" : "false"} 
                            />                                
                        </div>
                        {errors.email && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.email?.message}</p>}                        
                    </div>                    
                    <div className="w-full md:w-1/2 md:px-2.5">
                        <div className='form-field-wrap mb-5 select-field-wrap groups-field-wrap relative' ref={contentRef}>
                            <Controller
                                control={control}
                                name='groups'
                                render={({ field: { onChange, value, ref }}) => (
                                    <>
                                    <CheckTreePicker
                                        defaultExpandAll
                                        placeholder="Select Groups*"
                                        data={groups}
                                        onChange={val => {
                                            onChange(val.map(c => c.value));
                                            setValue('groups',val);
                                            setSelectedGroups(val);
                                            //handleSelectChange(val);
                                        }}
                                        container={()=>contentRef.current}
                                        defaultValue={selectedGroups}
                                        value={selectedGroups}
                                        style={{
                                            width:'100%',
                                            maxWidth:'500px',
                                            borderWidth:0,
                                            backgroundColor: '#EFF1F9',
                                            padding: '8px 7px 8px 46px',
                                            height: '52px',
                                            borderRadius: 8
                                        }}
                                        onSelect={(item,val,event)=>{
                                                if(item.label.toLowerCase()==="all members"){
                                                    if(item.check){
                                                        let groupIds=[];
                                                        groups.forEach((item)=>{
                                                            groupIds.push(item.value);
                                                        });
                                                        setSelectedGroups(groupIds);
                                                    }
                                                    else{
                                                        setSelectedGroups([]);
                                                    }
                                                }
                                            }
                                        }
                                    />                                    
                                    </>                                    
                                )}
                            />
                            <p className="mt-1 uppercase font-medium" style={{ fontSize: '12px', lineHeight: '18px', color: "#6E7079"}}>
                                {selectedGroups.length===0 ? 
                                        'NO GROUP SELECTED.':
                                        (selectedGroups.length > 1) ? selectedGroups.length + ' GROUPS SELECTED' : selectedGroups.length + ' GROUP SELECTED'}
                            </p>
                        </div>
                        {errors.groups?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.groups?.message}</p> }
                        <div className='form-field-wrap mb-5 phone-field-wrap relative'>
                            <input 
                                type="tel" id="phone" disabled={loading} placeholder="Mobile Number" className='text-field'
                                {...register("phone")}
                            />                                
                        </div>                        
                        <div className='form-field-wrap mb-5 check-field-wrap relative'>
                            <input 
                                type="checkbox" id="isadmin" className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                                {...register("is_admin")}
                            />
                            <label htmlFor="isadmin" className='!text-[12px]'>Make This Member a Team Admin</label>
                        </div>
                    </div>
                </div>
                {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                {successMessage!=='' && <p className="text-green-600 mb-2.5 text-center" role="alert">{successMessage}</p>}
                <div className="w-full px-2.5">
                    <div className='flex justify-center'>
                        <input className='button button--large' type="submit" value="Send Invitation" disabled={loading}/>
                    </div>
                </div>                
            </form>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default MemberNewForm;