import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import { getDraftNews } from "../../../libs/dataFunctions";
import NoNews from "../../../assets/images/noemptyother.png";
import DraftCard from "./DraftCard";

const DraftNews=({team, token, setTabActive})=>{
    const [loading, setLoading]=useState(false);
    const [newsDrafts, setNewsDrafts] = useState([]);
    const [q, setSearchQuery] = useState("");    
    const [searchParam] = useState(["title"]);

    const navigate = useNavigate();

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    const fetchDraftNews = async() =>{
        setLoading(true);
        const [newsdata] = await Promise.all([
            getDraftNews(team._id,token),
        ]);
        setNewsDrafts(newsdata.data);        
        setLoading(false);
    }
    useEffect(()=>{
        fetchDraftNews();
    },[]);
    return(
        <>
        {!loading 
        ?
        <>
            <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center">
                <div className="title-col max-w-[320px] md:max-w-full mx-auto md:mx-0 mb-2.5 md:mb-0">Drafts</div>
                <div className="cta-col max-w-[320px] md:max-w-full mx-auto md:mx-0 flex justify-center md:justify-end flex-wrap items-center relative">
                    <label htmlFor="search-form" className="search-field relative mr-auto ml-auto md:mr-3.5 mb-2.5 md:mb-0">
                        <input
                            type="search"
                            name="search-field"
                            id="search-form"
                            className="search-input"
                            placeholder="Search News"
                            value={q}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />                        
                    </label>                                        
                </div>                    
            </div>
            <div className={`newslistview py-3 px-2.5 sm:px-7.5`}>
                {search(newsDrafts).length > 0 ? (
                    search(newsDrafts).map((draftItem,index) => {                        
                        return (
                            <DraftCard draftItem={draftItem} refreshDrafts={fetchDraftNews} team={team} token={token} key={draftItem._id+index} />
                        )
                    })
                )
                :
                <div className="empty text-center">
                    <div className="image"><img src={NoNews} alt="Plaisport" width={228} /></div>                                        
                    <div className="title mb-5">No News Yet?</div>
                    <div className="description mb-8">{team.role==='admin' ? 'News is the first draft of history!' : 'They say no news is good news…'}</div>
                    
                    {(team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0)) &&
                        <button type="button" className='button button-yellow button-yellow-add' onClick={(e)=>{navigate('/team/'+team._id+'/news/create-news')}}>New Article</button>
                    }
                </div>
                }
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
        }
        </>
    )
}

export default DraftNews;