import './spinner.scss';
import animation from './loading.gif'
function Spinner(){
    return (
        <div className="loading">
            <img src={animation} alt="loading" />
        </div>
    )
}

export default Spinner;