import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import tinyCalendarIcon from "../../../assets/images/icon-tiny-calendar.svg";
import tinyUserIcon from "../../../assets/images/icon-tiny-user.svg";
dayjs.extend(utc);
dayjs.extend(tz);

const YourFormCard=({formItem, teamID})=>{
    var formLink = `/team/${teamID}/your-form/${formItem._id}`;
    return(
        <>
        <a href={formLink} className="form-card bg-white pt-6 px-5 relative">
            <div className="form-card-title font-bold text-lg/6 mb-4.5">{formItem.title}</div>
            <div className="max-h-8 overflow-hidden tracking-[0.01em] font-light text-xs mb-7">{formItem.description}</div>
            <div className="flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-5 xl:gap-12.5">
                <div className="flex items-center">
                    <img src={tinyCalendarIcon} className="w-4 h-4 mr-1.5" alt=""/>
                    <div className="flex flex-1">
                        <div>
                            <div className="tracking-[0.01em] text-xs">Created on</div>
                            <div className="tracking-[0.01em] text-sm font-semibold">{dayjs(formItem.created_at).format("DD MMM YYYY")}</div>
                        </div>
                        {formItem.expire_date!=="" &&
                            <>
                            <svg className="mx-4" width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.5 1L0.499999 35.641" stroke="#363F6C" strokeWidth="0.5"/>
                            </svg>
                            <div>
                                <div className="tracking-[0.01em] text-xs">Expires</div>
                                <div className="tracking-[0.01em] text-sm font-semibold">{dayjs(formItem.expire_date).format("DD MMM YYYY")}</div>
                            </div>
                            </>
                        }
                    </div>
                </div>
                <div className="flex items-center">
                    <img src={tinyUserIcon} className="w-4 h-4 mr-1.5" alt=""/>
                    <div className="flex-1">
                        <div className="tracking-[0.01em] text-xs">Created by</div>
                        <div className="tracking-[0.01em] text-sm font-semibold">{formItem.create_user}</div>
                    </div>
                </div>
            </div>
            {formItem.submited_at!=="" ?
            <>
            <div className="completed-status absolute bottom-0 right-0">
                <div className="flex-1">
                    <div className="tracking-[0.01em] text-xs">Completed on</div>
                    <div className="tracking-[0.01em] text-sm font-semibold">{dayjs(formItem.submited_at).format("DD MMM YYYY | hh:mm A")}</div>
                </div>
            </div>
            </>
            :
            <>
            <div className="notstarted-status absolute bottom-0 right-0 text-xs font-semibold">Not Started</div>
            </>
            }
        </a>
        </>
    )
}

export default YourFormCard;