import { useContext, useEffect, useState, useRef } from "react";
import { TeamContext } from "../../../contexts/TeamContext";
import redirect from '../../../assets/images/connect_stripe.png';
import Spinner from "../../../components/Spinner/Spinner";
import { getStripeAccounts } from "../../../libs/dataFunctions";

const TabManageStripe=()=>{
    const { team, token } = useContext(TeamContext);
    const [accounts, setAccounts] = useState([]);
    const [loading,setLoading] = useState([]);
    const [connectStripeErr, setConnectStripeErr] = useState(null);

    async function refreshAccounts(){
        setLoading(true);
        let stripeData= await getStripeAccounts(team._id, token);
        if(stripeData.status===1){
            setAccounts(stripeData.data);
        }
        else{
            setAccounts([]);
        }
        setLoading(false);
    }

    const handleConnectStripe = async()=>{
        setLoading(true);
        setConnectStripeErr(null);
        var formdata = new FormData();
        formdata.append("team_id", team._id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_stripe_connect' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);            
            if(rdata.status !== 1){
                setConnectStripeErr(rdata.message);
            }
            else{
                window.location.href=rdata.data.redirct_url;
            }
        })
        .catch(error => console.log('error', error));
    }

    useEffect(()=>{
        refreshAccounts();
    },[]);
    
    return(
        !loading ?
        <>
            <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center">
                <div className="title-col mb-2.5 md:mb-0">Accounts List</div>
                <div className="cta-col flex flex-col md:flex-row items-center">
                    {(team.role==='admin') &&
                        <>
                            <button type="button" className="button button-action button-new" onClick={()=>{handleConnectStripe()}}>ADD ANOTHER STRIPE ACCOUNT</button>
                            {connectStripeErr!==null &&
                                <div className="text-red-500 text-center">{connectStripeErr}</div>
                            }
                        </>
                    }
                </div>
            </div>
            {accounts && accounts.length > 0 ?
                <div className="links-list px-2.5 sm:px-7.5 pb-[77px]">                    
                    <table className="table-auto w-full">
                        <tbody>
                            {accounts.map((account,index) => {                        
                                    return (
                                        <tr key={index}>
                                            <td className='pt-5 pb-3.5'>
                                                <div className="font-medium">{account.name}</div>
                                                <div className="font-medium">{account.email}</div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }                            
                        </tbody>
                    </table>                    
                </div>
                :
                <div className="empty text-center">
                    <div className="image"><img src={redirect} alt="Plaisport" width={228} /></div>
                    <div className="title mb-5">No Stripe Connect Yet?</div>
                    <div className="description mb-8"><span>Rule 1: Never lose money<br/>Rule 2: Never forget Rule 1</span></div>
                    {(team.role==='admin') &&
                        <>
                        <button type="button" className='button button-yellow button-yellow-add' onClick={()=>{handleConnectStripe()}}>ADD ANOTHER STRIPE ACCOUNT</button>
                        {connectStripeErr!==null &&
                            <div className="text-red-500 text-center">{connectStripeErr}</div>
                        }
                        </>
                    }
                </div>
            }
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default TabManageStripe;