import { useState } from 'react';
import './comments.scss';
import placeholderImg from '../../assets/images/placeholder.png';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
dayjs.extend(utc);
dayjs.extend(tz);

const CommentRow = ({comment, team, element, type, refreshData})=>{
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const [commentsUpdating, setCommentsUpdating] = useState(false);
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    
    const deleteComment = async ()=>{
        setCommentsUpdating(true);
        if(window.confirm("Are you sure you want to delete this comment?")){
            var formdata = new FormData();
            formdata.append("type", "delete_comment");
            formdata.append("comment_id",comment.comment_id);
            formdata.append("team_id", team._id);
            if(type==='news'){
                formdata.append("news_id", element._id);
            }
            else{
                formdata.append("event_id", element._id);
            }
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            var apipoint='';
            if(type==='news'){
                apipoint = process.env.REACT_APP_APIURL + '/app_news';
            }
            else if(type==='event'){
                apipoint = process.env.REACT_APP_APIURL + '/app_event';
            }

            await fetch(apipoint , requestOptions)
            .then(response => response.text())
            .then(result => {
                setCommentsUpdating(false);
                refreshData();
            })
            .catch(error => console.log('error', error));
        }
    }

    return(
        <div className="comment-item flex mb-5">
            <div className="rounded-full relative overflow-hidden w-[28px] h-[28px] mr-3.5">
                {(comment.user.profilePicture && comment.user.profilePicture!=='') ?
                    <img src={comment.user.profilePicture} alt='' width={28} height={28} />
                :
                    <img src={placeholderImg} alt='' width={28} height={28} />
                }
            </div>
            <div className="comment-detail flex-1">
                <div className="comment-by flex justify-between">
                    <span>{comment.user.firstName} {comment.user.lastName}</span>
                    {!commentsUpdating ?
                        <button type="button" className="button-tiny delete rounded-full" onClick={()=>deleteComment()}></button>
                    :
                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={12} height={12} alt="" />
                    }
                </div>
                <div className="comment-on">{dayjs(new Date(comment.timestamp)).tz(timeZone).format('DD MMM YYYY hh:mm A')}</div>
                <div className="comment-text">{comment.comment}</div>
            </div>
        </div>
    )
}
export default CommentRow;