import {useState} from 'react';
//import { useForm, Controller } from 'react-hook-form';

import HeaderBlank from "../../components/HeaderBlank/HeaderBlank";
import signinBG from "../../assets/images/signin-bg.png";
import logoShort from "../../assets/images/logo-short.png";
import logoMedium from '../../assets/images/logo-main.svg';
import '../SignIn/auth.scss';
import FormProvider from '../../contexts/FormContext';

import SignUpStepOne from './SignUpStepOne';
import SignUpStepTwo from './SignUpStepTwo';
import SignUpStepThree from './SignUpStepThree';
import SignUpStepFour from './SignUpStepFour';
import SignUpCompleted from './SignUpCompleted';

const FormCard = ({ children, currentStep, prevFormStep }) => {
    return (
      <div className='auth-login-form'>
        {children}
        {currentStep < 3 && (
          <>
            {(currentStep === 1 || currentStep === 2) && (
                <div className='flex justify-center'>
                    <button className='back-btn' onClick={prevFormStep} type="button">Back</button>
                </div>
            )}
          </>
        )}
      </div>
    );
}

const SignUpFormWrap = () =>{
    const [formStep, setFormStep] = useState(0);
    const nextFormStep = () => setFormStep((currentStep) => currentStep + 1);
    const prevFormStep = () => setFormStep((currentStep) => currentStep - 1);

    return(
        <div className='auth-page bg-no-repeat bg-center bg-cover'>
            <div className='auth-wrapper flex justify-between'>
                <div className='image-col hidden md:relative md:flex md:justify-center md:flex-1 md:items-center'>
                    <div className='image-bgwrapper absolute w-full h-full'>
                        <img src={signinBG} alt="Sign In" className='w-full h-full absolute' width={791} height={948}/>
                    </div>
                    <div className='logo-short relative z-10'>
                        <img src={logoShort} alt="Plaisport" width={129} height={141} />
                    </div>
                </div>
                <div className='auth-form-col flex justify-center items-center'>
                    <div className='auth-form-wrapper bg-white rounded-xl pt-11 pb-7'>
                        <div className='auth-form-head'>
                            <img src={logoMedium} alt="Plaisport" className='mx-auto' width={126} height={47} />
                        </div>
                        {(formStep===0 || formStep === 1 || formStep === 2) &&
                            <>
                                <div className='auth-form-content text-center mb-[34px]'>
                                    {(formStep===0 || formStep === 1) &&
                                        <>
                                            <h2>Get Started with PLAI</h2>
                                            <p>Create your free account</p>
                                        </>
                                    }
                                    {(formStep===2) &&
                                        <>
                                            <h2>SPORTS THAT YOU PLAY</h2>
                                            <p>Tell us which sports you participate in regulary!</p>
                                        </>
                                    }
                                </div>
                            </>
                        }
                        <FormCard currentStep={formStep} prevFormStep={prevFormStep}>
                            {formStep >= 0 && (
                                <SignUpStepOne formStep={formStep} nextFormStep={nextFormStep} />
                            )}
                            {formStep >= 1 && (
                                <SignUpStepTwo formStep={formStep} nextFormStep={nextFormStep} />
                            )}
                            {formStep >= 2 && (
                                <SignUpStepThree formStep={formStep} nextFormStep={nextFormStep} />
                            )}
                            {formStep >= 3 && (
                                <SignUpStepFour formStep={formStep} nextFormStep={nextFormStep} />
                            )}
                            {formStep > 3 && <SignUpCompleted />}
                        </FormCard>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default function SignUp() {
    return(
        <>
            <FormProvider>
                <HeaderBlank/>
                <SignUpFormWrap />
            </FormProvider>
        </>
    );
}