import { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckTreePicker from 'rsuite/CheckTreePicker';

import dayjs from 'dayjs';
import * as yup from 'yup';
import { getUserAsignedGroup, getMemberDetail } from "../../../libs/dataFunctions";
import placeholderImg from '../../../assets/images/placeholder.png';
import Spinner from '../../../components/Spinner/Spinner';
import DocumentView from '../../../components/DocumentView/DocumentView';
import MemberParentItem from './MemberParentItem';
import MemberChildrenItem from "./MemberChildrenItem";
import MembershipCardForm from "./MembershipCardForm";
import BasicInfoForm from "./BasicInfoForm";

const schema = yup.object({
    groups: yup
    .array()
    .min(1, "Select at least one group for this member")
    .nullable()
    .required("Select at least one group for this member"),
  }).required();

const MemberDetail = ({setShowForm, team, teamMemberID, memberSub, isEdit, setIsEdit, refreshMembers, deleteMember}) =>{
    const [editMode, setEditMode] = useState(isEdit);
    const [errorMessage,setErrorMessage] = useState(false);
    const [groups, setGroups] = useState([]);
    const [teamMember, setTeamMember] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [loading,setLoading] = useState(true);
    const [customFields, setCustomFields] = useState([]);
    const [customFieldName, setCustomFieldName] = useState('');
    const [customFieldValue, setCustomFieldValue] = useState('');
    const [showAddMembershipCard, setShowAddMembershipCard] = useState(false);
    const [showBasicInfoForm, setShowBasicInfoForm] = useState(false);
    const [disabledGroupsOpt, setDisableGroupsOpt]= useState([]);
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
      resolver: yupResolver(schema)
    });

    const contentRef=useRef();

    const navigate = useNavigate();
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
        ? localStorage.getItem('auth_token')
        : '';

    const onSubmit = async(data) => {
      setErrorMessage('');
      setLoading(true);
      var formdata = new FormData();
      formdata.append("type", "update_member");
      formdata.append("team_id", team._id);
      formdata.append("group_ids", selectedGroups.join('@#'));
      formdata.append("is_admin", data.is_admin ? 'Yes' : 'No');
      formdata.append("member_id",teamMember._id);
      formdata.append("membership_id",data.membership_id);
      if(teamMember.same_child_subs.length > 0){
        formdata.append("same_child_subs",teamMember.same_child_subs.join('@#'));
      }
      if(customFields.length>0){
        var fname=[];
        var fval=[]
        customFields.forEach(element => {
          fname.push(element.name);
          fval.push(element.value);
        });
        formdata.append('custom_fields_name',fname.join('@#'));
        formdata.append('custom_fields_value',fval.join('@#'));            
      }
      formdata.append('separate_by','@#');
      var secureHeader = new Headers();
      secureHeader.append('Authorization','Bearer ' + token);
      secureHeader.append('device_type','W');
      const requestOptions = {
          method: 'POST',
          redirect: 'follow',
          body: formdata,
          headers: secureHeader
      };

      await fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
      .then(response => response.text())
      .then(result => {
          setLoading(false);
          let rdata = JSON.parse(result);
          if(rdata.status!==1){
            setErrorMessage(rdata.message);
          }
          else{
            setShowForm(false);
            refreshMembers();  
          }
      })
      .catch(error => console.log('error', error));
    }

    async function refreshMemberDetail(){
      setLoading(true);
      const [groupsData, MemberDetailData] = await Promise.all([
          getUserAsignedGroup(token,team._id, memberSub, 'member'),
          getMemberDetail(team._id, teamMemberID, token)
      ]);
      
      setTeamMember(MemberDetailData.data);
      setCustomFields(MemberDetailData.data.customFields);
      setValue('groups', MemberDetailData.data.group_ids);
      setSelectedGroups(MemberDetailData.data.group_ids);
      setValue('membership_id',MemberDetailData.data.membership_id);

      let disableGOpt=[];
      let groupOptions = [];
      groupsData.forEach(element => {
          let childOptions = [];
          if(!element.canSelect){
            disableGOpt.push(element._id);
          }
          if(element.nest_groups.length > 0){
              element.nest_groups.forEach(celm =>{
                  childOptions.push({'value':celm._id, 'label':celm.name});
                  if(!celm.canSelect){
                    disableGOpt.push(celm._id);
                  }
              });
              groupOptions.push({'value':element._id, 'label':element.name, 'children':childOptions});
          }
          else{
              groupOptions.push({'value':element._id, 'label':element.name});
          }
      });
      setDisableGroupsOpt(disableGOpt);
      setGroups(groupOptions);          
      setLoading(false);      
    }

    function searchInCF(nameKey, cfArray){
      for (let i=0; i < cfArray.length; i++) {
          if (cfArray[i].name === nameKey) {
              return cfArray[i];
          }
      }
      return false;
    }

    const addCustomFields = ()=>{
      var cf = [...customFields];
      if(customFieldName && customFieldValue){
        if(!searchInCF(customFieldName, customFields)){
          cf.push({name:customFieldName, value:customFieldValue});            
          setCustomFields(cf);
        }
        setCustomFieldName('');
        setCustomFieldValue('');
      }
    }
    const editCustomFields = (fieldname,fieldvalue)=>{
      var cf = [...customFields];
      for (var i = 0; i < cf.length; i++) {
        if (cf[i].name === fieldname) {
          cf[i].value = fieldvalue
        }
      }
      setCustomFields(cf);
    }
    const deleteCustomField = (index)=>{
      if (window.confirm('Are you sure you want to delete this custom field?')) {
        var cf = [...customFields];
        cf.splice(index, 1);
        setCustomFields(cf);            
      }
    }
    useEffect(() => {
      refreshMemberDetail();        
    },[]);
    
    return(
        <>
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails relative !px-2.5 md:!px-8">                    
              <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false); setIsEdit(false);}}><span>close</span></button>
              {!loading ?
              <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">
              <div className='member-card'>
                 <div className='flex flex-col md:flex-row items-center md:items-start w-full member-card-row pb-5 border-b border-[#53545C]/30'>
                  <div className='pr-5'>
                    {teamMember.profilePicture !== '' ?  
                      <img src={teamMember.profilePicture} alt="" width={100} height={100} className="rounded-full cursor-pointer"/>
                      :
                      <img src={placeholderImg} alt="" width={100} height={100} className="rounded-full cursor-pointer"/>
                    }
                  </div>
                  <div className='member-info grow flex flex-col md:flex-row px-2.5 md:px-0 py-2.5 md:py-0 text-center md:text-left md:items-start'>
                    <div className='flex flex-col self-center grow'>
                      <h3 className='member-name'>{teamMember.name}</h3>
                      <div className='member-email'>{teamMember.email}</div>
                      {!editMode ?
                        <div className={`member-role uppercase is-${teamMember.user_role}`}>{teamMember.user_role==='group_admin' ? 'Group admin' : teamMember.user_role}</div>
                        :
                        <>
                        <div className='form-field-wrap mb-5 check-field-wrap relative'>                                
                          <input 
                              type="checkbox" id="isadmin" className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                              defaultChecked={teamMember.role? true : false}
                              {...register("is_admin")}
                          />
                          <label htmlFor="isadmin" className='!text-[12px]'>Make This Member a Team Admin</label>
                        </div>
                        </>
                      }
                    </div>
                    {(teamMember.can_see_member_profile)  &&
                      <div className="flex items-center justify-center md:justify-end pt-2.5 md:pt-0 md:flex-wrap md:max-w-[120px]">
                          {!editMode &&
                            <button type='button' className="button-tiny blue edit rounded-full mr-2.5 md:mr-1.5 md:ml-1.5" onClick={()=>{setEditMode(true)}}></button>
                          }
                          <button type='button' className="button-tiny blue delete rounded-full mr-1.5 md:ml-1.5" onClick={()=>{setShowForm(false); setIsEdit(false); deleteMember()}}></button>
                          <button type="button" className="flex items-center md:mt-2.5 px-4 py-1.5 rounded-full bg-[#FBDB09] text-[#363F6C] hover:text-white hover:bg-[#363F6C]" onClick={()=>{setShowBasicInfoForm(true);}}>
                            <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                              <path d="M2.49902 14.375V17.5H5.62402L14.8407 8.28333L11.7157 5.15833L2.49902 14.375ZM4.93236 15.8333H4.16569V15.0667L11.7157 7.51667L12.4824 8.28333L4.93236 15.8333ZM17.2574 4.69167L15.3074 2.74167C15.1407 2.575 14.9324 2.5 14.7157 2.5C14.499 2.5 14.2907 2.58333 14.1324 2.74167L12.6074 4.26667L15.7324 7.39167L17.2574 5.86667C17.5824 5.54167 17.5824 5.01667 17.2574 4.69167Z" fill="currentColor"/>
                            </svg>
                            <span className="text-[11px] font-medium">Basic Info</span>
                          </button>
                      </div>
                    }
                  </div>                      
                 </div>
                 <div className='flex flex-col md:flex-row w-full justify-between member-card-row py-5'>
                    {teamMember.phoneNumber!=="" &&
                      <div className='member-phone flex items-center mb-2.5 md:mb-0'><div className='button-tiny phone rounded-full mr-1.5'></div>{teamMember.phoneCode!=="" ? teamMember.phoneCode+' '+teamMember.phoneNumber : teamMember.phoneNumber}</div>
                    }
                    <div className='member-birthdate flex items-center'><div className='button-tiny date rounded-full mr-1.5'></div>{(teamMember.dateOfBirth!=='' && teamMember.dateOfBirth!==null) ? dayjs(teamMember.dateOfBirth).format('DD / MMM / YYYY'): (teamMember.is_above_18 ? 'Over 18 years' : 'Under 18 years')}</div>
                 </div>
                 {teamMember.medical_emergency!=="" &&
                    <div className='member-card-row'>
                      <div className='uppercase flex items-center mb-4'><span className='mr-[23px] text-xs font-semibold text-black'>Medical Information</span><span className='h-[1px] grow bg-[#53545C] opacity-30'></span></div>
                      <div className="mb-4">{teamMember.medical_emergency}</div>
                    </div>
                  }
                 {teamMember.parents.length > 0 &&
                  <div className='member-card-row'>
                      <div className='uppercase flex items-center mb-4'><span className='mr-[23px] text-xs font-semibold text-black'>Parents</span><span className='h-[1px] grow bg-[#53545C] opacity-30'></span></div>
                      <ul className='flex flex-wrap'>
                        {teamMember.parents.map((prnt,index)=>{
                          return (
                            <>
                              <MemberParentItem parentData={prnt} teamId={team._id} key={index} />
                            </>
                            );
                        })}
                      </ul>
                    </div>
                  }
                  {teamMember.childrens.length > 0 &&
                  <div className='member-card-row'>
                    <div className='uppercase flex items-center mb-4'><span className='mr-[23px] text-xs font-semibold text-black'>Children</span><span className='h-[1px] grow bg-[#53545C] opacity-30'></span></div>
                    <ul className='flex flex-wrap'>
                      {teamMember.childrens.map((child,index)=>{
                        return (
                          <>
                            <MemberChildrenItem childData={child} teamId={team._id} key={index} />
                          </>
                          );
                      })}
                    </ul>
                  </div>
                  }
                 {!editMode ?
                  <>
                    {teamMember.membership_id!=="" &&
                      <>
                      <div className='uppercase flex items-center mb-4'>
                        <span className='mr-[23px] text-xs font-semibold text-black'>Membership ID</span><span className='h-[1px] grow bg-[#53545C] opacity-30'></span>
                      </div>
                      <p className='mb-4 font-semibold text-[#363F6C]'>{teamMember.membership_id}</p>
                      </>
                    }
                    {teamMember.customFields && teamMember.customFields.length > 0 ?
                    <div className='member-card-row'>
                        <div className='uppercase flex items-center mb-4'><span className='mr-[23px] text-xs font-semibold text-black'>Custom fields</span><span className='h-[1px] grow bg-[#53545C] opacity-30'></span></div>
                        <ul className='flex flex-wrap'>
                          {teamMember.customFields.map((tg,index)=>{
                            return (
                              <li key={index}>
                                <div className='member-cf mr-2 mb-3 py-1.5 px-5 bg-[#F5F5F8] rounded-[14px]'>
                                  <strong className='block text-[10px] uppercase'>{tg.name}</strong>
                                  {tg.value}
                                </div>
                              </li>);
                          })}
                        </ul>
                    </div>
                    :null
                      }
                  </>
                  :
                  <>
                  <div className='uppercase flex items-center mb-4'><span className='mr-[23px] text-xs font-semibold text-black'>Membership ID</span><span className='h-[1px] grow bg-[#53545C] opacity-30'></span></div>
                    <div className='form-field-wrap mb-5 input-field-wrap no-field-wrap relative'>                          
                      <input 
                            type="text" placeholder="" className='text-field'
                            {...register("membership_id")}
                            aria-invalid={errors.lastName ? "true" : "false"} 
                        />                    
                    </div>
                    <div className='uppercase flex items-center mb-4'><span className='mr-[23px] text-xs font-semibold text-black'>Custom Fields</span><span className='h-[1px] grow bg-[#53545C] opacity-30'></span></div>
                    <div className='mb-5 relative'>
                        <div className='custom-fields-wrap'>
                          <div className='flex items-center mb-2.5'>
                            <input type="text" name='fieldname' placeholder="Field name" className='mr-2.5 !px-5 !py-2'
                            value={customFieldName}
                            onChange={(e) => {
                              setCustomFieldName(e.target.value)
                            }} />
                            <input type="text" name='fieldvalue' placeholder="Field value" className='mr-2.5 !px-5 !py-2'
                            value={customFieldValue}
                            onChange={(e) => {
                              setCustomFieldValue(e.target.value)
                            }}
                            />
                            <button type='button' className='button button-action !rounded-[14px]' onClick={()=>addCustomFields()}>Add</button>
                          </div>
                          {customFields.length > 0 &&
                            customFields.map((customField,index)=>{
                              return(
                                <div className='flex items-center mb-2.5' key={index}>
                                  <input type="text" className='mr-2.5 !px-5 !py-2'
                                  value={customField.name}
                                  readOnly />
                                  <input type="text" name='fieldvalue' placeholder="Field value" className='mr-2.5 !px-5 !py-2'
                                  defaultValue={customField.value}
                                  onChange={(e) => {
                                    editCustomFields(customField.name, e.target.value)
                                  }}
                                  />
                                  <button type='button' className='button button-tiny button-blue delete' onClick={()=>deleteCustomField(index)}></button>
                                </div>
                              )
                            })
                          }
                        </div>
                    </div>                        
                  </>
                  }
                 {!editMode ?
                  <>
                    {teamMember.groups && teamMember.groups.length > 0 ?
                    <div className='member-card-row'>
                        <div className='uppercase flex items-center mb-4'><span className='mr-[23px] text-xs font-semibold text-black'>Content Groups</span><span className='h-[1px] grow bg-[#53545C] opacity-30'></span></div>
                        <ul className='flex flex-wrap'>
                          {teamMember.groups.map((tg,index)=>{
                            return (<li key={index}><div className='member-group mr-2 mb-3 py-1.5 px-5 bg-[#F5F5F8] rounded-[100px]'>{tg}</div></li>);
                          })}
                        </ul>
                    </div>
                    :null
                    }
                  </>
                  :
                  <>
                    <div className='uppercase flex items-center mb-4'><span className='mr-[23px] text-xs font-semibold text-black'>Content Groups</span><span className='h-[1px] grow bg-[#53545C] opacity-30'></span></div>
                    <div className='form-field-wrap mb-5 select-field-wrap groups-field-wrap relative' ref={contentRef}>                          
                      <Controller
                          control={control}
                          name='groups'
                          render={({ field: { onChange, value, ref }}) => (
                            <>
                            <CheckTreePicker
                                defaultExpandAll
                                placeholder="Select Groups*"
                                data={groups}
                                onChange={val => {
                                    onChange(val.map(c => c.value));
                                    setValue('groups',val);
                                    setSelectedGroups(val);
                                    //handleSelectChange(val);
                                }}
                                disabledItemValues={disabledGroupsOpt}
                                cleanable={false}
                                container={() => contentRef.current}
                                defaultValue={selectedGroups}
                                value={selectedGroups}
                                style={{
                                    width:'100%',
                                    maxWidth:'500px',
                                    borderWidth:0,
                                    backgroundColor: '#EFF1F9',
                                    padding: '8px 7px 8px 46px',
                                    height: '52px',
                                    borderRadius: 8
                                }}
                                onSelect={(item,val,event)=>{
                                    if(item.label.toLowerCase()==="all members"){
                                      if(item.check){
                                        let groupIds=[];
                                        groups.forEach((item)=>{
                                            groupIds.push(item.value);
                                        });
                                        setSelectedGroups(groupIds);
                                      }
                                      else{
                                        setSelectedGroups([]);
                                      }
                                    }
                                  }
                                }
                            />                                    
                            </>                                    
                        )}
                      />
                      <p className="mt-1 uppercase font-medium" style={{ fontSize: '12px', lineHeight: '18px', color: "#6E7079"}}>
                        {selectedGroups.length===0 ? 
                                    'NO GROUP SELECTED.':
                                    (selectedGroups.length > 1) ? selectedGroups.length + ' GROUPS SELECTED' : selectedGroups.length + ' GROUP SELECTED'}
                      </p>
                    </div>
                    {errors.groups?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.groups?.message}</p> }
                    {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                    <div className='flex justify-center'>
                        <input className='button button-medium' type="submit" value="Save" disabled={loading}/>
                    </div>
                  </>
                  }
                  {!editMode && (teamMember.documents !== undefined && teamMember.documents.length > 0) &&
                    <div className='member-card-row'>
                      <div className='uppercase flex items-center mb-4'><span className='mr-[23px] text-xs font-semibold text-black'>Documents</span><span className='h-[1px] grow bg-[#53545C] opacity-30'></span></div>
                      <ul className='flex flex-wrap'>
                          {teamMember.documents.map((doc,index) => { 
                              return (
                                  <li key={index}>
                                      <div className='mr-5 mb-5 w-[150px] h-[180px] relative'>
                                          <DocumentView doc={doc} />
                                      </div>
                                  </li>
                              )
                          })}
                      </ul>
                  </div>                        
                  }
                  {!editMode &&
                  <div className='flex justify-center pt-2.5'>
                    <button type="button" className="button button-medium mr-2.5" onClick={()=>{setShowAddMembershipCard(true);}}>Add Membership Card</button>
                    <button type='button' className='button button-medium' onClick={()=>{
                      navigate('/team/'+team._id+'/members/'+teamMember._id)
                    }}>PURCHASES</button>
                  </div>
                  }
              </div>
              </form>
              :
              <>
                  <div className="py-5 text-center"><Spinner /></div>
              </>
              }
            </div>
        </div>
        {showAddMembershipCard &&
            <MembershipCardForm setShowForm={setShowAddMembershipCard} team={team} teamMember={teamMember} />
        }
        {showBasicInfoForm &&
          <BasicInfoForm setShowForm={setShowBasicInfoForm} team={team} member={teamMember} refreshMembers={refreshMembers} />
        }
        </>
    )
}

export default MemberDetail;