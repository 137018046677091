import { useContext, useState, useEffect, useRef } from "react";
import { TeamContext } from "../../../contexts/TeamContext";
import Spinner from "../../../components/Spinner/Spinner";
import { getSubscriptionHistroy } from "../../../libs/dataFunctions";
import dayjs from "dayjs";

const TabSubscriptions = ({setShowSubscription}) =>{
    const {team, token } = useContext(TeamContext);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const refreshSubscriptionHistory= async()=>{
        setErrorMessage(null);
        setLoading(true);
        const [subData] = await Promise.all([
            getSubscriptionHistroy('team', token, team._id)
        ]);
        
        if(subData.status===1){
            setSubscriptionData(subData.data.subscription);
            setHistoryData(subData.data.history);
        }
        else{
            setErrorMessage(subData.message);
        }
        setLoading(false);
    }
    const cancelSubscription = async ()=>{
        if(window.confirm("Are you sure you want to cancel this subscription?")){
            var formdata = new FormData();
            formdata.append("type", "cancel_subscription");
            formdata.append("subscription_for",subscriptionData.subscription_for);
            formdata.append("team_id", team._id);
            formdata.append("auto_subscription","off");
            
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_subscription' , requestOptions)
            .then(response => response.text())
            .then(result => {
                refreshSubscriptionHistory();
            })
            .catch(error => console.log('error', error));
        }
    }
    useEffect(()=>{
        refreshSubscriptionHistory();
    },[]);
    
    return(
        !loading ?
        <>
            <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center">
                <div className="title-col mb-2.5 md:mb-0">Subscription</div>                
            </div>
            <div className="subscriptions-list px-2.5 sm:px-7.5 py-[18px]">
                {subscriptionData ?
                    <>
                        {subscriptionData.title &&                    
                            <>
                            <h2 className="flex justify-between items-center font-semibold uppercase mb-5">
                                Subscription
                                <span className="h-[1px] grow bg-[#53545C] opacity-30 mx-5"></span>                    
                            </h2>
                            <div className="px-7 py-6 bg-[#363F6C] text-white mb-5 max-w-[470px] rounded-[10px] flex flex-col flex-wrap md:flex-row md:justify-between">
                                <div className="text-center md:text-left md:max-w-[155px]">
                                    <h3 className="font-semibold uppercase text-[22px] leading-[33px] mb-0">{subscriptionData.title}</h3>                            
                                    {subscriptionData.status!=='cancel' ?
                                        <div className="text-[14px] leading-[21px] italic">
                                            Next Billing on<br/>{dayjs(subscriptionData.next_billing_date).format('DD MMM YYYY')}
                                        </div>
                                        :
                                        <div className="text-[14px] leading-[21px] italic">
                                            You have cancelled your subscription but still<br/>your subscription is valid till<br/>{dayjs(subscriptionData.next_billing_date).format('DD MMM YYYY')}
                                        </div>
                                    }
                                </div>
                                <div className="text-center md:text-right">
                                    <div className="text-[14px] leading-[21px]">Pay</div>
                                    <div className="text-[38px] leading-[38px] font-semibold text-[#FBDB09]">{subscriptionData.currency_symbol}{subscriptionData.amount}</div>
                                    <div className="text-[14px] leading-[21px]">{subscriptionData.duration}</div>
                                </div>
                                {subscriptionData.status!=='cancel' &&
                                    <div className="w-full text-center pt-2">
                                        <button type="button" className="button button-medium" onClick={cancelSubscription}>Cancel Subscription</button>
                                    </div>
                                }
                            </div>
                            </>
                        }
                        {historyData.length > 0 &&
                        <>
                            <h2 className="flex justify-between items-center font-semibold uppercase mb-5">
                                Transaction History
                                <span className="h-[1px] grow bg-[#53545C] opacity-30 mx-5"></span>                    
                            </h2>
                            <div className="subscription-histories">
                                {historyData.map((hdata,index)=>{
                                    return(
                                        <div className="rounded-[14px] mb-5 md:mb-9 py-3.5 px-7 max-w-[768px] flex flex-col sm:flex-row justify-between shadow-[0_4px_34px_0px_rgba(0,0,0,0.06)]" key={index}>
                                            <div>
                                                <div className="text-[14px] leading-[21px] text-[#363F6C] font-semibold mb-1">Subscription Name</div>
                                                <div className="text-[24px] leading-[28px] font-bold mb-5">{hdata.title}</div>
                                                <div className="text-[12x] leading-[16px] font-light mb-1">Transaction Date</div>
                                                <div className="text-[14px] leading-[16px] font-medium mb-5">{dayjs(hdata.payment_date).format('DD MMM YYYY')}</div>
                                                <div className="flex flex-col md:flex-row">
                                                    <div className="mr-5">
                                                        <div className="text-[12x] leading-[16px] font-light mb-1">Transaction ID</div>
                                                        <div className="text-[14px] leading-[16px] font-medium">{hdata.invoice_number}</div>
                                                    </div>
                                                    <div>
                                                        <div className="text-[12x] leading-[16px] font-light mb-1">From</div>
                                                        <div className="text-[14px] leading-[16px] font-medium">{dayjs(hdata.purchased_plan_start_date).format('DD MMM YYYY')} - {dayjs(hdata.purchased_plan_end_date).format('DD MMM YYYY')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col justify-between">
                                                <div className="mb-2.5 sm:mb-2.5">
                                                    <div className="font-bold text-[24px] leading-[36px]">{hdata.currency_symbol}{hdata.amount}</div>
                                                    {hdata.status==='paid' ?
                                                        <span className="bg-[#1AB900] px-2.5 py-1 text-white text-[10px] leading-[15px] rounded-[50px] inline-block">Payment Successfull</span>
                                                    :
                                                        <span className="bg-[#FF0000] px-2.5 py-1 text-white text-[10px] leading-[15px] rounded-[50px] inline-block">Payment Failed</span>
                                                    }
                                                </div>
                                                <div>
                                                {hdata.status==='paid' ?
                                                    <a href={hdata.hosted_invoice_url} target="_blank" rel="noreferrer" className="button button-blue inline-block">Download Receipt</a>
                                                :
                                                    <a href={hdata.hosted_invoice_url} target="_blank" rel="noreferrer" className="button button-medium inline-block">Pay now</a>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                        }
                    </>
                    :
                    <>
                        <div className="text-center font-semibold">No Subscription added yet for this team.</div>
                    </>
                }
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}
export default TabSubscriptions;