import Slider from "react-slick";
import SlideItem from "./SlideItem";
import './TeamsSlider.scss';
import "slick-carousel/slick/slick.css";

const TeamsSlider = ({teams, isPendngRequest}) =>{
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        draggable:false,
        easing: 'linear',
        responsive: [
            {
              breakpoint: 1280,
              settings: {
                slidesToShow: 2,
              }
            },
            {
                breakpoint: 999,
                settings: {
                  slidesToShow: 1,
                }
            },
        ]
    };
    return(
        <>
            <div className="teams-slider">
                <div className="container max-w-full mx-auto">
                    <div className="ln-header flex justify-between items-center w-full pt-5 relative">
                        <h2 className="title mr-5">My Teams</h2>
                        <div className='sep mr-5 sm:mr-11'></div>
                        {isPendngRequest &&
                            <div>
                                <a href="/pending-requests" className='block button button-medium'>Pending Requests</a>
                            </div>
                        }
                    </div>
                    <div className='teams-wrapper'>
                        <Slider {...settings}>
                            {teams.map((team,index)=>{
                                return (
                                    <SlideItem team={team} key={index} />                                  
                                )
                            })}                            
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TeamsSlider;