import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import placeholderImg from '../../../assets/images/placeholder.png';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
} from "react-share"

const DutiesRow = ({duties, team}) =>{
    const navigate = useNavigate();
    const [showShare, setShowShare] = useState(false);
    function viewEvent() {
        navigate('/team/'+team._id+'/event/'+duties._id,{
          state:{
            team:team,
            teamID:team._id
          }          
        })
    }
    const ShareEvent = ({setShowShare}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative w-full max-w-[400px]">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowShare(false)}}><span>close</span></button>
                    <div className="form-heading text-center mb-[30px]">Share</div>
                    <div className='flex flex-wrap items-center'>
                        <div className='px-2.5 mb-5'>
                            <FacebookShareButton url={process.env.REACT_APP_WEBURL+'team/'+team._id+'/event/'+duties._id} quote={duties.name}>
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <LinkedinShareButton url={process.env.REACT_APP_WEBURL+'team/'+team._id+'/event/'+duties._id} title={duties.name} summary={duties.name} source='PLAI Sports'>
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>                            
                            <TwitterShareButton url={process.env.REACT_APP_WEBURL+'team/'+team._id+'/event/'+duties._id} title={duties.name}>
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <WhatsappShareButton url={process.env.REACT_APP_WEBURL+'team/'+team._id+'/event/'+duties._id} title={duties.name}>
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <EmailShareButton url={process.env.REACT_APP_WEBURL+'team/'+team._id+'/event/'+duties._id} subject={duties.name} body={duties.name}>
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return( 
        <>
            <div className="w-full lg:w-1/2 xl:w-1/3 px-2.5 mb-5">
                <div className="flex p-2.5 rounded-[16px] bg-[#EFF1F9]">
                    <div className="w-[145px] pr-2.5" onClick={() => { viewEvent() }}>
                        <div className="image-wrapper rounded-lg overflow-hidden w-[135px] h-[135px] relative">
                        {duties.image && duties.image !== "" ?
                            <img src={duties.image} alt="" className='w-full h-full absolute object-cover top-0 left-0' />                        
                            :
                            <img src={placeholderImg} alt="" className='w-full h-full absolute object-cover top-0 left-0' />
                        }
                        </div>
                    </div>
                    <div className="flex-1 relative">
                        <div className="flex justify-between mb-[15px]">
                            <h2 className="flex-1 text-[16px] leading-[24px] text-[#363F6C] font-bold pr-2.5 cursor-pointer" onClick={() => { viewEvent() }}>{duties.name}</h2>
                            <button type="button" className="button-tiny blue share rounded-full w-[22px] h-[22px]" onClick={()=>{setShowShare(true)}}>Share</button>
                        </div>                            
                        {/*
                            <div className="mb-1.5"><span className="cursor-pointer text-[12px] leading-[14px] text-[#363F6C]">{event.type}</span></div>
                        */}
                        <div className="mb-[15px] text-[10px] leading-[15px] text-[#363F6C]">
                            <div className="flex items-center mb-[15px]">
                                <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.74362 1.53398H8.21273V1.00309C8.21273 0.711101 7.97383 0.4722 7.68184 0.4722C7.38985 0.4722 7.15095 0.711101 7.15095 1.00309V1.53398H2.90384V1.00309C2.90384 0.711101 2.66494 0.4722 2.37295 0.4722C2.08096 0.4722 1.84206 0.711101 1.84206 1.00309V1.53398H1.31117C0.721881 1.53398 0.254699 2.01178 0.254699 2.59576L0.24939 10.0282C0.24939 10.3098 0.361255 10.5799 0.560377 10.779C0.7595 10.9781 1.02957 11.09 1.31117 11.09H8.74362C9.3276 11.09 9.8054 10.6122 9.8054 10.0282V2.59576C9.8054 2.01178 9.3276 1.53398 8.74362 1.53398ZM8.74362 9.49732C8.74362 9.78931 8.50472 10.0282 8.21273 10.0282H1.84206C1.55007 10.0282 1.31117 9.78931 1.31117 9.49732V4.18843H8.74362V9.49732ZM2.37295 5.25021H3.43473V6.31198H2.37295V5.25021ZM4.4965 5.25021H5.55828V6.31198H4.4965V5.25021ZM6.62006 5.25021H7.68184V6.31198H6.62006V5.25021Z" fill="#363F6C"/>
                                </svg>
                                <span className="pl-[8px]">{dayjs(duties.dateStart_display).format("DD MMM YYYY")} To {dayjs(duties.dateEnd_display).format("DD MMM YYYY")}</span>
                            </div>
                            <div className="flex items-center">
                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.31158 0.0053978C2.51945 0.0053978 0.255493 2.26935 0.255493 5.06149C0.255493 7.85362 2.51945 10.1176 5.31158 10.1176C8.10372 10.1176 10.3677 7.85362 10.3677 5.06149C10.3677 2.26935 8.10372 0.0053978 5.31158 0.0053978ZM7.30355 6.61556L6.98077 7.05571C6.97375 7.06529 6.9649 7.07339 6.95474 7.07955C6.94458 7.0857 6.9333 7.08979 6.92156 7.09157C6.90981 7.09336 6.89783 7.0928 6.8863 7.08994C6.87477 7.08708 6.86392 7.08196 6.85437 7.07489L4.98768 5.71381C4.97605 5.70546 4.96659 5.69444 4.96011 5.68167C4.95363 5.66891 4.95031 5.65477 4.95043 5.64045V2.53344C4.95043 2.48378 4.99106 2.44316 5.04072 2.44316H5.58357C5.63323 2.44316 5.67386 2.48378 5.67386 2.53344V5.32671L7.28323 6.49028C7.32386 6.5185 7.33289 6.57493 7.30355 6.61556Z" fill="#363F6C"/>
                                </svg>
                                <span className="pl-[8px]">{dayjs('1/1/1 '+duties.timeStart_display).format('hh:mm A')} - {dayjs('1/1/1 '+duties.timeEnd_display).format('hh:mm A')}</span>
                            </div>
                        </div>
                        {duties.location && duties.location!==null &&
                            <div className="text-[10px] leading-[17px] text-[#363F6C] flex w-full">
                                <svg width="16" height="16" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.25 22.1448C10.8967 20.9904 9.64234 19.725 8.49999 18.3616C6.78571 16.3141 4.75 13.2648 4.75 10.3591C4.74926 8.87511 5.18876 7.42427 6.01288 6.19018C6.83701 4.95609 8.00871 3.99422 9.3797 3.4263C10.7507 2.85838 12.2594 2.70994 13.7147 2.99976C15.1701 3.28958 16.5068 4.00464 17.5557 5.05444C18.254 5.74965 18.8075 6.57644 19.1841 7.48698C19.5608 8.39752 19.7531 9.37371 19.75 10.3591C19.75 13.2648 17.7143 16.3141 16 18.3616C14.8576 19.725 13.6033 20.9904 12.25 22.1448ZM12.25 7.1448C11.3975 7.1448 10.5799 7.48344 9.97714 8.08624C9.37435 8.68903 9.0357 9.50659 9.0357 10.3591C9.0357 11.2116 9.37435 12.0291 9.97714 12.6319C10.5799 13.2347 11.3975 13.5734 12.25 13.5734C13.1025 13.5734 13.92 13.2347 14.5228 12.6319C15.1256 12.0291 15.4643 11.2116 15.4643 10.3591C15.4643 9.50659 15.1256 8.68903 14.5228 8.08624C13.92 7.48344 13.1025 7.1448 12.25 7.1448Z" fill="#363F6C"/>
                                </svg>
                                <span className="flex-1">{duties.location}</span>
                            </div>
                        }
                        {duties.responses_for_action_count!==undefined && duties.responses_for_action_count>0 &&
                            <div className="rounded-full w-[22px] h-[22px] text-white text-[12px] leading-[12px] bg-[#ff0000] flex items-center justify-center absolute right-0 bottom-0">{duties.responses_for_action_count}</div>
                        }
                    </div>
                </div>
            </div>
            {showShare &&
                <ShareEvent setShowShare={setShowShare}/>
            }
        </>
    );
}
export default DutiesRow;