
import { confirmAlert } from 'react-confirm-alert';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import placeholderImg from '../../../assets/images/placeholder.png';
import iconTimeTiny from '../../../assets/images/icon-time-tiny.svg';
import iconDateTiny from '../../../assets/images/icon-calendar-tiny.svg';
import 'react-confirm-alert/src/react-confirm-alert.css';
dayjs.extend(utc);
dayjs.extend(tz);

const YourBookingCard = ({facility, token, fetchMemberFacilities}) =>{
    const cancelFacilityAction=async()=>{
        var formdata = new FormData();
        formdata.append("type", "cancel_booking");
        formdata.append("booking_id",facility._id);
        formdata.append("team_id", facility.team_id);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_booking_facilities' , requestOptions)
        .then(response => response.text())
        .then(result => {
            fetchMemberFacilities();
        })
        .catch(error => console.log('error', error));
    }

    const cancelFacility=()=>{
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                <div className='confirm-ui'>
                    <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                    <p className="text-center text-[14px] leading-[18px] mb-5">Are you sure you want to cancel this booking?</p>
                    <div className="flex justify-center">
                        <button className="button button-blue mx-1" onClick={onClose}>No</button>
                        <button className="button mx-1"
                        onClick={() => { 
                            cancelFacilityAction();                       
                            onClose();
                        }}
                        >
                        Yes
                        </button>
                    </div>
                </div>
                );
            }
        });
    }
    return(
        <>
        <div className="booking-card bg-white p-3 relative">
            <div className="flex flex-col sm:flex-row gap-6.5 mb-4.5 sm:mb-0">
                <div className='sm:w-35'>
                    <div className='aspect-[140/90] relative rounded-xl overflow-hidden'>
                    {facility.image!=="" ?
                        <img src={facility.image} alt="" width={140} height={90} className="w-full h-full absolute left-0 top-0 object-cover" />
                    :
                        <img src={placeholderImg} alt="" width={140} height={90} className="w-full h-full absolute left-0 top-0 object-cover"/>
                    }
                    </div>
                </div>
                <div className='flex-1'>
                    <div className='text-xl/6 font-bold mb-3 relative pr-5'>
                        {facility.name}
                    </div>
                    <div className='flex flex-col sm:flex-row gap-6 mb-2.5'>
                        <div className='flex gap-2 items-center'>
                            <img src={iconTimeTiny} width={12} height={12} alt="time" />
                            <div>
                                <div className='text-[11px] leading-[11px] font-light'>Time &amp; Duration</div>
                                <div className='text-[13px] leading-[13px] font-semibold'>{dayjs('1/1/1 '+facility.start_time).format('hh:mm A')} | {facility.duration_display}</div>
                            </div>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <img src={iconDateTiny} width={10} height={12} alt="date" />
                            <div className='text-[13px] leading-[13px] font-semibold'>{dayjs(facility.date).format('DD MMM YYYY')}</div>
                        </div>                        
                    </div>                
                </div>
            </div>
            <div className='flex gap-2.5 absolute bottom-0 right-0 justify-between'>
                <div><button type="button" className="button button-red" onClick={()=>{cancelFacility()}}>Cancel</button></div>
                <div className="text-sm px-6 py-2 font-semibold bg-[var(--color-darkblue)] text-[var(--color-yellow)] rounded-tl-xl rounded-br-xl">
                    {facility.payment_status}
                </div>
            </div>            
        </div>
        </>
    )
}

export default YourBookingCard;