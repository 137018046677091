import { useEffect, useState, useContext } from "react";
import { useParams, NavLink } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Spinner from "../../../components/Spinner/Spinner";
import { TeamContextProvider, TeamContext } from "../../../contexts/TeamContext";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import { getRequestDetail, getMyPaymentCards, getWallet } from "../../../libs/dataFunctions";
import { formatCreditCardNumber, formatExpirationDate, formatCVC, getIsUser } from '../../../libs/utils';
import placeholderImg from '../../../assets/images/placeholder.png';

import CountriesList from '../../../libs/countries';
import Amex from '../../../assets/images/amex.svg';
import Discover from '../../../assets/images/discover.svg';
import DinnersClub from '../../../assets/images/dinersclub.svg';
import Jcb from '../../../assets/images/jcb.svg';
import Mastercard from '../../../assets/images/mastercard.svg';
import UnionPay from '../../../assets/images/unionpay.svg';
import Visa from '../../../assets/images/visa.svg';

const CardIcon=({value})=>{
    const icons = {
        'amex':Amex,
        'mastercard':Mastercard,
        'visa':Visa,
        'discover':Discover,
        'jcb':Jcb,
        'dinersclub': DinnersClub,
        'uninpay': UnionPay,
        'elo':null,
        'hipercard':null,
        'troy':null,
        'maestro':null,
        'laser':null
    }
    if (!value) {
        return null;
    }
    
    return(
        icons[value]!==null ? <img src={icons[value]} alt="" width={24} /> : null
    )
}

const RequestPayment=({pid})=>{
    const { team, teamUpdating } = useContext(TeamContext);
    const [loading, setLoading] = useState(false);
    const [requestError, setRequestError] = useState(null);
    const [requestDetail, setRequestDetail] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage,setSuccessMessage] = useState(null);
    const [walletData, setWalletData] = useState([]);
    const [isWallet, setWallet] = useState(false);
    const [transactionFee, setTransactionFee] = useState(0);
    const [walletTransactionFee, setWalletTransactionFee] = useState(0);
    const [isCardPayment, setIsCardPayment] = useState(false);

    const [currentCardID,setCurrentCardID]  = useState('');
    const [paymentCards,setPaymentCards] = useState([]);
    const [cardNum, setCardNum] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardCVV, setCardCVV] = useState('');
    const [country, setCountry] = useState('');
    const [cardicon, setCardIcon] = useState(null);
    const [cardHolderName, setCardName] = useState('');
    
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const fetchRequestDetail=async()=>{
        setRequestError(null);
        const [requestData, myWallet, mycardsData] = await Promise.all([            
            getRequestDetail(token, pid),
            getWallet(token, team._id),
            getMyPaymentCards(token),
        ]);

        if(requestData.status===1){
            setRequestDetail(requestData.data);

            if(mycardsData.data && mycardsData.data.length > 0){
                setPaymentCards(mycardsData.data);
                mycardsData.data.forEach(element => {
                    if(element.default_source){
                        setCurrentCardID(element.id);
                    }
                });
            }
            if(myWallet.data.length >0 && myWallet.data[0].amount!=="" && myWallet.data[0].amount!=="0.00" && myWallet.data[0].amount!==0){
                setWalletData(myWallet.data);
                setWallet(true);                
            }
            setWalletTransactionFee(myWallet.wallet_transaction_fee/100);
            setTransactionFee(myWallet.transaction_fee/100);
        }
        else{
            setRequestError(requestData.message);
        }
        setLoading(false);
    }

    const getPaymentIntent=async()=>{
        setSuccessMessage(null);
        setErrorMessage(null);
        if((!isWallet || (isWallet && isCardPayment)) && currentCardID !== ''){
            setLoading(true);
            var formdata = new FormData();
            formdata.append('type','stripe_payment_intent_v3');
            formdata.append('request_id',pid);
            if(!isWallet || (isWallet && isCardPayment)){
                formdata.append('card_id',currentCardID);
                if(isWallet){
                    formdata.append('use_wallet',isWallet);
                }
            }
            else{
                formdata.append('card_id',"");
                if(isWallet){
                    formdata.append('use_wallet',isWallet);
                }
            }
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_stripe_payment_intent' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status!==1 && rdata.status!==4){
                    setErrorMessage(rdata.message);
                }
                else if(rdata.status===4){
                    window.location.href='/thankyou';
                }
                else{
                    //window.location.href = '/team/'+team._id+'/your-forms';
                    if(rdata.data.redirct_url===undefined || rdata.data.redirct_url===''){
                        ///Go to thank you page
                        window.location.href='/thankyou';
                    }
                    else{
                        window.location.href=rdata.data.redirct_url;
                    }
                }
            })
            .catch(error => console.log('error', error));
        }
        else{
            if(cardNum!=="" && cardExpiry!=="" && cardCVV!=="" && country!==""){
                setLoading(true);
                let formdata = new FormData();
                let expiry = cardExpiry.split("/");
                let today, cardday;
                today = new Date();
                cardday = new Date();
                cardday.setFullYear(parseInt(expiry[1])+2000,expiry[0]-1,0);
                if(cardicon==='amex'){
                    if(cardNum.replace(/\D+/g, '').length<15){
                        setErrorMessage("Invalid card number");
                        if(cardCVV.length<4){
                            setErrorMessage("Invalid card number, cvc number");
                        }
                        return;
                    }
                    if(cardCVV.length<4){
                        setErrorMessage("Invalid CVC number");
                        return;
                    }
                }
                else if(cardicon==='dinersclub'){
                    if(cardNum.replace(/\D+/g, '').length<14){
                        setErrorMessage("Invalid card number");
                        return;
                    }
                }
                else{
                    if(cardNum.replace(/\D+/g, '').length<16){
                        setErrorMessage("Invalid card number");
                        return;
                    }
                }
                if (cardday < today) {
                    setErrorMessage("Invalid expiry date");
                }
                else{
                    formdata.append('type','add_card');                    
                    formdata.append('card_number',cardNum);
                    formdata.append('name',cardHolderName);
                    formdata.append('exp_year',expiry[1]);
                    formdata.append('exp_month',expiry[0]);
                    formdata.append('country',country);
                    formdata.append('cvc',cardCVV);
                    formdata.append('is_default_card','Yes');

                    var secureHeader2 = new Headers();
                    secureHeader2.append('Authorization','Bearer ' + token);
                    secureHeader2.append('device_type','W');
                    const requestOptions = {
                        method: 'POST',
                        redirect: 'follow',
                        body: formdata,
                        headers: secureHeader2
                    }
                    
                    await fetch(process.env.REACT_APP_APIURL + '/app_stripe_card' , requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        let rdata = JSON.parse(result);
                        if(rdata.status !== 1){
                            setLoading(false);
                            setErrorMessage(rdata.message);
                        }
                        else{
                            var formdata2 = new FormData();
                            formdata2.append('type','stripe_payment_intent_v3');
                            formdata2.append('request_id',pid);
                            formdata2.append('card_id',rdata.data[0].id);
                            formdata.append('use_wallet',isWallet);
                            setLoading(true);
                            var secureHeader = new Headers();
                            secureHeader.append('Authorization','Bearer ' + token);
                            secureHeader.append('device_type','W');
                            const requestOptions = {
                                method: 'POST',
                                redirect: 'follow',
                                body: formdata2,
                                headers: secureHeader
                            };

                            fetch(process.env.REACT_APP_APIURL + '/app_stripe_payment_intent' , requestOptions)
                            .then(response => response.text())
                            .then(result => {
                                setLoading(false);
                                let rdata = JSON.parse(result);
                                if(rdata.status!==1 && rdata.status!==4){
                                    setErrorMessage(rdata.message);
                                }
                                else if(rdata.status===4){
                                    window.location.href='/thankyou';
                                }
                                else{
                                    //window.location.href = '/team/'+team._id+'/your-forms';
                                    if(rdata.data.redirct_url===undefined || rdata.data.redirct_url===''){
                                        ///Go to thank you page
                                        window.location.href='/thankyou';
                                    }
                                    else{
                                        window.location.href=rdata.data.redirct_url;
                                    }
                                }
                            })
                            .catch(error => console.log('error', error));
                        }
                    })
                    .catch(error => console.log('error', error));
                }            
            }
            else{
                setErrorMessage("Please fill all fields above.");
            }
        }
    }

    useEffect(()=>{
        if(!teamUpdating){
            if(isWallet){  
                if((parseFloat(walletData[0].amount) - ((requestDetail.display_price/100) + parseFloat(walletTransactionFee))).toFixed(2) >=0 ){
                    setIsCardPayment(false);                    
                }
                else{
                    setIsCardPayment(true);
                    /*if(parseFloat(walletData[0].amount) < (parseFloat(selectedFacility.price) + parseFloat(selectedFacility.transactionfee)).toFixed(2)){
                        setIsCardPayment(true);
                    }
                    else{
                        setIsCardPayment(false);
                    }*/
                }   
            }
        }
    },[isWallet, teamUpdating, requestDetail]);

    useEffect(()=>{
        setLoading(true);
        if(!teamUpdating){
            fetchRequestDetail();
        }
    },[teamUpdating]);
    
    return(
        <>
        {!loading ?
            <>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}/shop`}>
                        Shop
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Request Payment
                </div>
                <div className='body px-5 lg:px-8 py-10'>
                    <div className="ln-header flex justify-between items-center w-full flex-wrap pb-10 relative">
                        <h2 className="title mr-5 uppercase">Request Payment</h2>
                        <div className="sep mr-4"></div>
                    </div>
                    <div className="rounded-xl bg-white p-7.5">
                        {requestError!==null ?
                            <>
                                <p className="text-center py-5 text-red-600">{requestError}</p>
                            </>
                            :
                            <>
                                <div className="cart-items">                                    
                                    <div className="cart-item flex border-b border-black-100/10 mb-7 pb-7">
                                        <div className="flex items-center flex-1">
                                            <div className="relative w-[85px] h-[85px] mr-3.5">
                                                {requestDetail.image!=="" ?
                                                    <img src={requestDetail.image} className="absolute w-full h-full object-cover top-0 left-0 rounded-[14px]" alt="" />
                                                :
                                                    <img src={placeholderImg} className="absolute w-full h-full object-cover top-0 left-0 rounded-[14px]" alt="" />
                                                }
                                            </div>
                                            <div className="flex-1 max-w-[465px] text-[16px] leading-[24px] font-bold">
                                                {requestDetail.name}
                                                {requestDetail.installment_no!=="" &&
                                                    <p className="flex text-green-400 items-center">
                                                        {requestDetail.installment_no}
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                        <div className="flex w-full lg:w-1/3">
                                            <div className="flex w-full justify-end items-center">
                                                <div className="mr-[40px] w-[130px]">
                                                    <div className="text-[10px] leading-[15px] text-[#363F6C] uppercase">Price</div>
                                                    <div className="text-[#363F6C] text-lg font-bold">{requestDetail.currency_symbol}{(requestDetail.display_price / 100).toFixed(2)}</div>
                                                </div>                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="checkout-action flex justify-end pt-12">
                                    <div className='w-full md:w-1/2 md:max-w-[500px]'>
                                        <div className='cart-detail mb-5'>
                                            {walletData.length > 0 && walletData[0].amount > 0 &&
                                                <div className='text-left p-3.5 rounded-[14px] text-white bg-[#363F6C] mb-5'>
                                                    <div className='flex justify-between gap-2.5'>
                                                        <div className='flex flex-1 gap-2.5'>
                                                            <div>
                                                                <label className="relative inline-flex items-center cursor-pointer">
                                                                    <input type="checkbox" value="" className="sr-only peer" onChange={()=>{setWallet(!isWallet)}} checked={isWallet ? true : false} />
                                                                    <div className="w-11 h-6 bg-[rgba(255,255,255,0.1)] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#FBDB09]"></div>                        
                                                                </label>
                                                            </div>
                                                            <div className="uppercase">Use PLAI Wallet</div>
                                                        </div>
                                                        <div>
                                                            <span className='text-xl font-bold'>{walletData[0].currency_symbol}{parseFloat(walletData[0].amount).toFixed(2)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className={`cart-detail-row cart-detail-total flex justify-between pb-5 ${isWallet ? 'mb-5' : 'mb-11'}`}>
                                                <div className='cart-detail-total-label'>Basket Total</div>
                                                <div className='cart-detail-total-value'>
                                                    {!loading ?
                                                    <>
                                                        {isWallet ?
                                                            <>
                                                                {parseFloat(walletData[0].amount) >= (((requestDetail.display_price/100) + walletTransactionFee)).toFixed(2) ?
                                                                    <>
                                                                        {team.currency_symbol}{(((requestDetail.display_price/100) + walletTransactionFee)).toFixed(2)}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {team.currency_symbol}{(((requestDetail.display_price / 100) + transactionFee)).toFixed(2)}
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                            {team.currency_symbol}{(((requestDetail.display_price / 100) + transactionFee)).toFixed(2)}
                                                            </>
                                                        }                                                       
                                                        <p className='text-[10px] leading-[12px] font-light'>inclusive of all fees and taxes</p>
                                                    </>
                                                    :
                                                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={12} height={12} alt="" />
                                                    }
                                                </div>
                                            </div>
                                            {isWallet &&
                                                <>
                                                <div className='cart-detail-row cart-detail-total flex justify-between pb-5 mb-5'>
                                                    <div className='cart-detail-total-label'>PLAI Wallet</div>
                                                    <div className='cart-detail-total-value'>
                                                        <span className='text-green-500'>                                                        
                                                            <>
                                                                {parseFloat(walletData[0].amount) >= (((requestDetail.display_price/100) + walletTransactionFee)).toFixed(2) ?
                                                                    <>
                                                                    {parseFloat(walletData[0].amount) === (((requestDetail.display_price/100) + walletTransactionFee)).toFixed(2) ?
                                                                        <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                        :
                                                                        <>-{walletData[0].currency_symbol}{(((requestDetail.display_price/100) + walletTransactionFee)).toFixed(2)}</>
                                                                    }
                                                                    </>
                                                                    :
                                                                    <>
                                                                    {parseFloat(walletData[0].amount) <= (((requestDetail.display_price/100) + transactionFee)).toFixed(2) ?
                                                                        <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                        :
                                                                        <>-{walletData[0].currency_symbol}{(((requestDetail.display_price/100) + transactionFee)).toFixed(2)}</>
                                                                    }
                                                                    </>
                                                                }
                                                            </>
                                                        </span>
                                                        {parseFloat(walletData[0].amount) >= (((requestDetail.display_price/100) + walletTransactionFee)).toFixed(2) ?
                                                            <>
                                                                {(parseFloat(walletData[0].amount) - (((requestDetail.display_price/100) + walletTransactionFee)).toFixed(2)) >=0 &&
                                                                    <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - ((requestDetail.display_price/100) + parseFloat(walletTransactionFee))).toFixed(2)}</strong></p>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {parseFloat(walletData[0].amount) - (((requestDetail.display_price/100) + transactionFee)).toFixed(2) >=0 &&
                                                                    <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - ((requestDetail.display_price/100) + parseFloat(transactionFee))).toFixed(2)}</strong></p>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='cart-detail-row cart-detail-total flex justify-between pb-5 mb-11'>
                                                    <div className='cart-detail-total-label'>Remaining</div>
                                                    <div className='cart-detail-total-value'>
                                                        {parseFloat(walletData[0].amount) <= (((requestDetail.display_price/100) + transactionFee)).toFixed(2) ?
                                                            <>{walletData[0].currency_symbol}{(((requestDetail.display_price/100) + transactionFee) - parseFloat(walletData[0].amount)).toFixed(2)}</>
                                                            :
                                                            <>{walletData[0].currency_symbol}0.00</>                                                                    
                                                        }
                                                    </div>
                                                </div>
                                                </>
                                            }
                                            {(!isWallet || (isWallet && isCardPayment)) &&
                                                <>
                                                {paymentCards.length > 0 ? 
                                                    <>  
                                                        <label className='text-black'>Select Card</label>
                                                        <div className='form-field-wrap w-full  mb-5 no-field-wrap relative'>
                                                            <select onChange={(e)=>{setCurrentCardID(e.target.value)}}>
                                                                {paymentCards.map((card) => {
                                                                    return(
                                                                        <option key={card.id} value={card.id}>****{card.last4} ({card.name})</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </>
                                                :
                                                    <div className='flex flex-wrap'>
                                                        <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                                                            <input 
                                                                placeholder="0000 0000 0000 0000" className='text-field' 
                                                                type='tel'
                                                                name='number'
                                                                maxLength='19'
                                                                required
                                                                autoComplete="off"
                                                                onChange={(e)=>{
                                                                    e.target.value = formatCreditCardNumber(e.target.value);
                                                                    setCardNum(e.target.value);
                                                                    let icon = getIsUser(e.target.value);
                                                                    setCardIcon(icon);
                                                                    setErrorMessage(null);
                                                                }}
                                                            />
                                                            <div className='absolute top-[18px] right-[20px]'>
                                                                <CardIcon value={cardicon}/>
                                                            </div>
                                                        </div>
                                                        <div className='form-field-wrap w-full sm:w-1/2 mb-5 no-field-wrap sm:pr-1 sm:pl-1 relative'>
                                                            <input 
                                                                placeholder="MM/YY" className='text-field' 
                                                                type='tel'
                                                                name='expiry'
                                                                pattern='\d\d/\d\d'
                                                                required
                                                                autoComplete="off"
                                                                onChange={(e)=>{
                                                                    e.target.value = formatExpirationDate(e.target.value);
                                                                    setCardExpiry(e.target.value);
                                                                    setErrorMessage(null);
                                                                }}
                                                            />                                
                                                        </div>
                                                        <div className='form-field-wrap w-full sm:w-1/2 mb-5 no-field-wrap sm:pl-1 relative'>
                                                            <input 
                                                                type='tel'
                                                                name='cvc'
                                                                className='text-field'
                                                                placeholder='CVC'
                                                                required
                                                                autoComplete="off"
                                                                onChange={(e)=>{
                                                                    e.target.value = formatCVC(e.target.value, {number:cardNum});
                                                                    setCardCVV(e.target.value);
                                                                    setErrorMessage(null);
                                                                }}
                                                            />                                
                                                        </div>
                                                        <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                                                            <input 
                                                                type='text'
                                                                name='name'
                                                                className='text-field'
                                                                placeholder='Card Holder Name'
                                                                required
                                                                autoComplete="off"
                                                                onChange={(e)=>{
                                                                    setCardName(e.target.value);
                                                                    setErrorMessage(null);
                                                                }}
                                                            />                                
                                                        </div>
                                                        <div className='form-field-wrap w-full mb-5 select-field-wrap country-field-wrap relative'>
                                                            <select name="country" onChange={(e)=>{setCountry(e.target.value)}}>
                                                                <option value="">Select Country *</option>
                                                                {CountriesList.map((c,cindex)=>{
                                                                    return(
                                                                        <option key={cindex} value={c.code}>{c.name}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                }
                                                </>
                                            }
                                        </div>
                                        <div className='cart-form'>
                                            <div className='flex justify-end'>
                                                <button type='button' className='button button--large'  onClick={()=>getPaymentIntent()}>Pay Now</button>
                                            </div>
                                            <div className='flex justify-center'>
                                                {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                                                {successMessage!=='' && <p className="text-green-500 mb-2.5 text-center" role="alert">{successMessage}</p>}
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </>
        :
            <>
                <div className="py-5 text-center"><Spinner /></div>
            </>
        }
        </>
    )
}
const RequestPaymentPage=()=>{
    const { id, pid } = useParams();
    return(
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="">
                    <AdminNavbar heading="Request Payment" />  
                    <RequestPayment pid={pid} />
                </div>
            </TeamContextProvider>
        </>
    )
}
export default RequestPaymentPage;