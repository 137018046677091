import { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import { TeamContext } from "../../../contexts/TeamContext";
import ProductCard from "./ProductCard";
import NoNews from "../../../assets/images/noproduct.png";
import ProductForm from "./ProductForm";
import { getProducts, getProductsByCatg, getMyCart } from "../../../libs/dataFunctions";

const TabShop=()=>{
    const { team, token } = useContext(TeamContext);
    const [products, setProducts] = useState([]);
    const [shopLoading, setShopLoading] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [q, setSearchQuery] = useState("");    
    const [searchParam] = useState(["name"]);
    const [productcatg, setProductCatg] = useState('All');
    const [connectStripeErr, setConnectStripeErr] = useState(null);
    const [isStripeEnabled, setIsStripeEnabled] = useState(false);
    const [cartTotalItems, setCartTotalItems] = useState(false);
    const [isCurrentlyDue, setIsCurrentlyDue] = useState(false);
    const [settingsOptions, showSettingsOption] = useState(false);
    const navigate = useNavigate();
    const ref=useRef();

    async function refreshShop(){
        setShopLoading(true);
        
        const [shopData, cartData] = await Promise.all([
            getProducts(team._id, token),
            getMyCart(token, team._id)
        ]); 
        if(cartData.status===1){
            setCartTotalItems(cartData.data.length);
        }
        setProducts(shopData.data);
        setIsStripeEnabled(shopData.stripeEnabled);
        setIsCurrentlyDue(shopData.currently_due);
        setShopLoading(false);
    }

    const viewCategoryProducts = async(category) =>{
        setProductCatg(category);
        if(category!=='All'){
            setShopLoading(true);
            let shopData=await getProductsByCatg(team._id, category, token);
            setProducts(shopData.data);
            setShopLoading(false);
        }
        else{
            refreshShop();
        }
    }
    
    const NewProductForm = ({setShowForm}) =>{        
        return(<ProductForm team={team} setShowForm={setShowForm} refreshShop={refreshShop}/>)
    }
    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    const handleConnectStripe = async()=>{
        setShopLoading(true);
        setConnectStripeErr(null);
        var formdata = new FormData();
        formdata.append("team_id", team._id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_stripe_connect' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setShopLoading(false);
            let rdata = JSON.parse(result);            
            if(rdata.status !== 1){
                setConnectStripeErr(rdata.message);
            }
            else{
                window.location.href=rdata.data.redirct_url;
            }
        })
        .catch(error => console.log('error', error));
    }

    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if ((settingsOptions) && ref.current && !ref.current.contains(e.target)) {
                showSettingsOption(false);
            }
        }      
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[settingsOptions]);

    useEffect(()=>{
        refreshShop();
    },[]);
    
    return(
        !shopLoading ?
        <> 
            {(isCurrentlyDue && team.role==='admin') &&
                <div className="px-2.5 sm:px-7.5 my-2.5">
                    <div className="bg-red-300 rounded-lg text-red-600 p-5 text-[14px] font-semibold">
                        Stripe requires a quick ID verification, <a href="https://dashboard.stripe.com" target="_blank" rel="noreferrer" className="underline">click here</a> to log-in with your Stripe credentials and submit to recommence team transactions on PLAI.
                    </div>
                </div>
            }
            <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center relative" ref={ref}>
                <div className="title-col mb-2.5 md:mb-0">Shop List</div>
                <div className="cta-col flex flex-col md:flex-row items-center">
                    <label htmlFor="search-form" className="search-field relative md:mr-3.5 mb-2.5 md:mb-0">
                        <input
                            type="search"
                            name="search-field"
                            id="search-form"
                            className="search-input"
                            placeholder="Search Product"
                            value={q}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />                        
                    </label>
                    {team.role==='admin' && 
                        <button type="button" className="button button-action button-blue md:mr-3.5 mb-2.5 md:mb-0" onClick={()=>{navigate(`/team/`+team._id+'/shop/orders');}}>Orders</button>
                    }
                    {((team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0)) && isStripeEnabled) &&
                        <button type="button" className="button button-action button-new mr-3.5" onClick={(e)=>{setOpenForm(true)}}>Add Product</button>
                    }
                    <button type="button" className={`button-icon button-icon-cart relative mr-3.5`} onClick={(e)=>{navigate(`/team/`+team._id+'/shop/mycart');}}>
                        <span>My Cart</span>
                        {cartTotalItems>0 ? <i className="flex absolute bg-[#ff0000] text-[9px] text-white w-[20px] h-[20px] rounded-full top-[-10px] right-[-10px] items-center justify-center">{cartTotalItems}</i> : null}
                    </button>
                    {team.role==='admin' &&
                    <>
                        <button type="button" className={`button-icon button-icon-settings relative`} onClick={()=>{showSettingsOption(!settingsOptions)}}>
                            <span>Settings</span>
                        </button>
                        <ul className={`sort-actions absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!settingsOptions ? 'hidden' : ''}`}>
                            <li><a href={`/team/${team._id}/manage_stripe_accounts`}>Manage Stripe Account</a></li>
                            <li><a href={`/team/${team._id}/shop/manage-discount-codes`}>Manage Discount Codes</a></li>
                        </ul>
                    </>
                    }
                </div>
                {openForm &&
                    <NewProductForm setShowForm={setOpenForm} />
                }
            </div>
            <div className="productgridview py-3 px-7.5">
                {productcatg==='All' ?
                <>
                    {products && products.length > 0 ? (
                        products.map((productGroup,index) => {                        
                            return (
                                search(productGroup.value).length > 0 &&
                                <div className="product-group" key={index}>
                                    <h2 className="flex justify-between items-center">
                                        {productGroup.name}
                                        <span className="h-[1px] grow bg-[#53545C] opacity-30 mx-5"></span>
                                        <span className="link-btn italic underline hover:no-underline cursor-pointer text-[14px] font-normal" onClick={()=>{viewCategoryProducts(productGroup.name)}}>View all</span>
                                    </h2>
                                    <div className="product-lists">
                                    {search(productGroup.value).map((product,index2) => {
                                        return(
                                            <ProductCard product={product} team={team} key={index2} />
                                        )
                                    })
                                    }
                                    </div>
                                </div>
                            )
                        })
                    )
                    :
                    <div className="empty text-center">
                        <div className="image"><img src={NoNews} alt="Plaisport" width={228} /></div>
                        <div className="title mb-5">No Product Yet?</div>
                        <div className="description mb-8">
                            {isStripeEnabled ?
                                'You can settle your Team Fees safely & securely on PLAI'
                            :
                                <span>Rule 1: Never lose money<br/>Rule 2: Never forget Rule 1</span>
                            }                        
                        </div>
                        {((team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0)) && isStripeEnabled) &&
                            <button type="button" className='button button-yellow button-yellow-add' onClick={(e)=>{setOpenForm(true)}}>New Product</button>
                        }
                        {(team.role==='admin' && !isStripeEnabled) &&
                            <button type="button" className='button button-yellow button-yellow' onClick={()=>{handleConnectStripe()}}>Connect Stripe</button>                            
                        }
                        {connectStripeErr!==null &&
                            <div className="text-red-500 text-center">{connectStripeErr}</div>
                        }
                    </div>
                    }                    
                </>
                :
                <>
                    {products && products.length > 0 ? (
                        search(products).length > 0 &&
                        <div className="product-group">
                            <h2 className="flex justify-between items-center">
                                {productcatg}
                                <span className="h-[1px] grow bg-[#53545C] opacity-30 mx-5"></span>
                                <span className="link-btn italic underline hover:no-underline cursor-pointer text-[14px] font-normal" onClick={()=>{viewCategoryProducts('All')}}>Back</span>                                
                            </h2>
                            <div className="product-lists">
                            {search(products).map((product,index2) => {
                                return(
                                    <ProductCard product={product} team={team} key={index2} />
                                )
                            })
                            }
                            </div>
                        </div>
                    )
                    :
                    <div className="empty text-center">
                        <div className="image"><img src={NoNews} alt="Plaisport" width={228} /></div>
                        <div className="title mb-5">No Product Yet?</div>
                        <div className="description mb-8">
                            {isStripeEnabled ?
                                'You can settle your Team Fees safely & securely on PLAI'
                            :
                                <span>Rule 1: Never lose money<br/>Rule 2: Never forget Rule 1</span>
                            }                        
                        </div>
                        {((team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0)) && isStripeEnabled) &&
                            <button type="button" className='button button-yellow button-yellow-add' onClick={(e)=>{setOpenForm(true)}}>New Product</button>
                        }
                        {(team.role==='admin' && !isStripeEnabled) &&
                            <button type="button" className='button button-yellow button-yellow' onClick={()=>{handleConnectStripe()}}>Connect Stripe</button>                            
                        }
                        {connectStripeErr!==null &&
                            <div className="text-red-500 text-center">{connectStripeErr}</div>
                        }
                    </div>
                    }
                </>
                }
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default TabShop;