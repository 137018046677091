import { useState, useRef, useEffect } from "react";
import CFGSOneMemSelRow from "./CFGSOneSelMemRow";
import CFGSOneMemRow from "./CFGSOneMemRow";
const CFGGameStepOne = ({groups, members,setMembers,selectedMembers,setSelectedMembers, setCurrentStep}) =>{
    const [showInfoOne, setShowInfooneForm] = useState(false);
    const [q, setSearchQuery] = useState("");    
    const [searchParam] = useState(["name"]);
    const [sortby, setSortBy] = useState(null);
    const [filterby, setFilterBy] = useState(null);
    const [membersMain, setMembersMain] = useState(members);
    const [showSortingOpt, setShowSortingOpt] = useState(false);
    const [showFilterOpt,setFilterOpt] = useState(false);

    const StepOneInfo = ({setShowForm}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !max-w-[550px] !px-7.5">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <div className="w-full">
                        <h3 className="uppercase text-[22px] leading-[33px] font-semibold pb-[22px] border-b border-b-[#FBDB03] border-b-solid">FANTASY: Selectable Players</h3>
                        <p className="mb-4"><strong>IMPORTANT:</strong></p>
                        <p className="mb-4">On this page, you are choosing the PLAYERS at the Club that you would like to be SELECTABLE, by members who choose to join your Fantasy League and create a team!</p>
                        <p className="mb-4">Once you have activated your game, your members will pay a small fee to join the game for the ENTIRE season... and the Club receives 50% of everything!</p>
                        <p>Hello, new revenue!</p>
                    </div>
                </div>
            </div>
        )
    }
    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }
    const playersSortBy = (orderby) =>{
        let sorted_players;
        if(orderby==='atoz'){
            sorted_players = members.sort((a,b)=>(a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
            setSortBy('atoz');
        }
        else if(orderby==='ztoa'){
            sorted_players = members.sort((a,b)=>(a.name.toLowerCase() < b.name.toLowerCase()) ? 1 : -1);
            setSortBy('ztoa');
        }
        else{
            sorted_players = membersMain;
        }
        setMembers(sorted_players);
    }
    const playersFilterBy = (fby) =>{
        let filtered_players;
        if(fby==='all'){
            filtered_players = membersMain;
        }
        else{
            filtered_players = membersMain.filter((playerItem)=>(playerItem.groups.includes(fby)));
        }
        setFilterBy(fby);        
        setMembers(filtered_players);
    }
    const ref = useRef();

    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if ((showSortingOpt || showFilterOpt) && ref.current && !ref.current.contains(e.target)) {
                setShowSortingOpt(false);
                setFilterOpt(false);
            }
        }      
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[showSortingOpt, showFilterOpt]);

    return(
        <>
            <div className="px-2.5 md:px-7 pt-[35px] mb-6">
                <div className="flex justify-between items-start">
                    <div className="flex-1 pr-5"><strong>Step 1:</strong> Select from your MEMBERS LIST the players at the Club that you would like to make available for selection in the Fantasy Game</div>
                    <button className="info-button" onClick={()=>{setShowInfooneForm(true)}}>info</button>
                </div>
                {showInfoOne &&
                    <StepOneInfo setShowForm={setShowInfooneForm} />
                }
            </div>
            <div className="flex flex-wrap px-2.5 md:px-7 pb-2.5 md:pb-7">
                <div className="w-full md:w-auto md:flex-1 md:pr-[34px]">
                    <div className="pb-3 mb-3 border-b border-solid border-b-[#dfdfdf] flex justify-between">
                        <h3 className="uppercase text-[#363F6C] text-[22px] leading-[33px] mb-0">Players List</h3>
                        <div className="flex flex-col md:flex-row justify-between items-center">
                            <label htmlFor="search-form" className="search-field relative mr-3.5">
                                <input
                                    type="search"
                                    name="search-field"
                                    id="search-form"
                                    className="search-input"
                                    placeholder="Search Player"
                                    value={q}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />                        
                            </label>
                            <div className="flex items-center relative" ref={ref}>
                                <button className="btn-with-icon mr-3.5" onClick={(e)=>{setFilterOpt(false); setShowSortingOpt(!showSortingOpt)}}>
                                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.6946 7.17478H8.69465C8.22139 7.17478 7.83758 7.55859 7.83758 8.03185C7.83758 8.50511 8.22139 8.88892 8.69465 8.88892H11.6946C12.1679 8.88892 12.5517 8.50511 12.5517 8.03185C12.5517 7.55859 12.1687 7.17478 11.6946 7.17478ZM15.1229 0.318237H8.69465C8.22139 0.318237 7.83758 0.702043 7.83758 1.17531C7.83758 1.64857 8.22139 2.03237 8.69465 2.03237H15.1229C15.5962 2.03237 15.98 1.64857 15.98 1.17531C15.98 0.702043 15.597 0.318237 15.1229 0.318237ZM9.98051 10.6031H8.67054C8.19728 10.6031 7.81348 10.9869 7.81348 11.4601C7.81348 11.9334 8.19728 12.3172 8.67054 12.3172H9.98051C10.4538 12.3172 10.8135 11.9334 10.8135 11.4601C10.8135 10.9869 10.4546 10.6031 9.98051 10.6031ZM13.4088 3.74651H8.69465C8.22139 3.74651 7.83758 4.13032 7.83758 4.60358C7.83758 5.07684 8.22139 5.46065 8.69465 5.46065H13.4088C13.882 5.46065 14.2659 5.07684 14.2659 4.60358C14.2659 4.13032 13.8829 3.74651 13.4088 3.74651ZM5.2522 8.31843L4.38442 9.26657V1.17611C4.38442 0.702043 4.00142 0.318237 3.52735 0.318237C3.05329 0.318237 2.67028 0.702043 2.67028 1.17611V9.26469L1.8025 8.31843C1.63345 8.13408 1.40236 8.04042 1.17015 8.04042C0.955764 8.0402 0.749145 8.12065 0.591358 8.26578C0.24237 8.58584 0.219069 9.12874 0.538621 9.47826L2.87145 12.0519C3.19607 12.407 3.81048 12.407 4.13536 12.0519L6.46819 9.47826C6.78799 9.12874 6.76442 8.58611 6.41546 8.26578C6.11463 7.94615 5.5736 7.97025 5.2522 8.31843Z" fill="currentColor"/>
                                </svg>
                                Sort by</button>
                                <ul className={`sort-actions absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showSortingOpt ? 'hidden' : ''}`}>
                                    <li className={sortby==='atoz' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{playersSortBy('atoz'); setShowSortingOpt(false);}}>A-Z</div></li>
                                    <li className={sortby==='ztoa' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{playersSortBy('ztoa'); setShowSortingOpt(false);}}>Z-A</div></li>                        
                                </ul>
                                {groups.length>0 &&
                                <>
                                    <button className="btn-with-icon" onClick={(e)=>{setShowSortingOpt(false); setFilterOpt(!showFilterOpt)}}>
                                    <svg width="14.52" height="14" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.76826 6.44336V14.3184L5.84401 11.6934V6.44336L0.046875 0.318359H14.5654L8.76826 6.44336Z" fill="currentColor"/>
                                    </svg>
                                    Filter by</button>
                                    <ul className={`sort-actions absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showFilterOpt ? 'hidden' : ''}`}>
                                        <li className={`${filterby==='all' ? 'active': ''}`}><div className='cursor-pointer' onClick={()=>{playersFilterBy('all'); setFilterOpt(false);}}>All</div></li>
                                        {groups.map((group)=>{
                                            return(
                                                <li key={group._id} className={`${filterby===group.name ? 'active': ''}`}><div className='cursor-pointer' onClick={()=>{playersFilterBy(group.name); setFilterOpt(false);}}>{group.name}</div></li>
                                            );
                                        })}
                                    </ul>
                                </>
                                }
                            </div>
                        </div>                        
                    </div>
                    {search(members).length > 0 ?
                        <>
                            <div className="flex justify-end  mb-2.5">
                                <button 
                                    className="button button-action" 
                                    onClick={()=>{
                                        search(members).forEach((memitem,index)=>{
                                            setSelectedMembers(oldArray => [...oldArray,memitem] );
                                            setMembers((current)=>current.filter((mitem) => mitem._id !== memitem._id));
                                            setMembersMain((current)=>current.filter((mitem) => mitem._id !== memitem._id));
                                        });
                                    }}
                                >Select all</button>
                            </div>
                            <div className="flex flex-wrap">
                                {search(members).map((memberitem,index)=>{
                                    return (
                                        <CFGSOneMemRow 
                                            memberitem={memberitem} 
                                            setSelectedMembers={setSelectedMembers} 
                                            selectedMembers={selectedMembers} 
                                            setMembers={setMembers} 
                                            members={members}
                                            membersMain={membersMain} 
                                            setMembersMain={setMembersMain}
                                            key={memberitem._id} 
                                            index={index} />                                            
                                    )
                                })}                                    
                            </div>
                        </>
                        :
                        null
                    }
                </div>
                <div className="w-full max-w-[446px] rounded-[20px] bg-[#EFF1F9]">
                    <div className="pb-3 pt-[18px] px-7 mb-3 border-b border-solid border-[#aeb2c7]">
                        <h3 className="uppercase text-[#363F6C] text-[20px] leading-[30px] text-center">AVAILABLE FOR FANTASY SELECTION</h3>
                    </div>
                    {selectedMembers.length > 0 ?
                        <>
                            <div className="flex justify-end px-10 mb-2.5">
                                <button 
                                    className="button button-action" 
                                    onClick={()=>{
                                        selectedMembers.forEach((memitem,index)=>{
                                            setMembers(oldArray => [...oldArray,memitem] );
                                            setMembersMain(oldArray => [...oldArray,memitem]);
                                            setSelectedMembers((current)=>current.filter((mitem) => mitem._id !== memitem._id));
                                        });
                                    }}
                                >Remove all</button>
                            </div>
                            <div className="fantacy-selected-list">
                                <div className="fantacy-selected-list-wrap">
                                    {selectedMembers.map((memberitem,index)=>{                                        
                                        return (
                                            <CFGSOneMemSelRow 
                                                memberitem={memberitem} 
                                                setSelectedMembers={setSelectedMembers} 
                                                selectedMembers={selectedMembers}
                                                setMembers={setMembers}
                                                members={members}
                                                membersMain={membersMain} 
                                                setMembersMain={setMembersMain}
                                                key={memberitem._id} 
                                                index={index} />
                                        )
                                    })}
                                </div>
                                <div className="flex w-full justify-center items-center mb-5 absolute bottom-0 left-0">
                                    <button className="button button--medium" onClick={()=>{setCurrentStep(2)}}>Confirm</button>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="text-center italic text-[18px] leading-[22px]">No player selected yet.</div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}
export default CFGGameStepOne;