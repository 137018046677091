import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import socketIO from "socket.io-client";
import { store } from "../../../Redux/store";
import { Provider } from "react-redux";
import { useAuthState } from '../../../contexts/AuthContext';
import { withTimeout } from "../../../libs/utils";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { TeamContextProvider } from "../../../contexts/TeamContext";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import ChatComponent from "./ChatComponent";
import ChatCreateGroupComponent from "./ChatCreateGroupComponent";
import ChatGroupDetailComponent from "./ChatGroupDetail";
import ChatArchiveComponent from "./ChatArchiveComponent";
import './chatpage.scss';

const ChatPage = () =>{
    const { id, group_id } = useParams();
    const userDetailArr = useAuthState();
    const userDetail = userDetailArr.user;
    const [socket, setSocket] = useState(null);
    const [currentChatPage, setCurrentChatPage] = useState(group_id!==undefined ? 'group-detail' : '');
    const [groupID,setGroupID] = useState(group_id!==undefined ? group_id : null);
    
    useEffect(() => {
        var newSocket = socketIO.connect(process.env.REACT_APP_SOCKETURL);
        setSocket(newSocket);
        
        return () => {
            newSocket.close();            
        }
    }, [setSocket]);

    const chatRenderSwitch=(param)=>{
        switch(param) {
            case 'create-group':
                return(
                    <ChatCreateGroupComponent socket={socket} setCurrentChatPage={setCurrentChatPage} />
                )
            case 'group-detail':
                return(
                    <ChatGroupDetailComponent group_id={groupID} socket={socket} setCurrentChatPage={setCurrentChatPage} />
                )
            case 'archive':
                return(
                    <ChatArchiveComponent socket={socket} setCurrentChatPage={setCurrentChatPage} setGroupID={setGroupID} />
                )
            default:
                return(
                    <ChatComponent socket={socket} setCurrentChatPage={setCurrentChatPage} setGroupID={setGroupID} />
                )
        }
    }

    useEffect(() => {
        if(socket !==null){
            socket.on('connect', function(){
                try {
                    console.log('socket connect');
                    socket.emit('add_user', userDetail.sub, withTimeout((response) => {
                        console.log("success!" + response);
                    }, (response) => {
                        console.log(response);
                    }, 1500));                    
                } catch(e) {
                    console.log(e);
                }
            });
            return () => {
                socket.off('connect');
            };
        }
    },[socket]);

    return(
        <>
            <Sidebar type="thin"/>
            <TeamContextProvider id={id}>
                <Provider store={store}>
                <div id="page" className="page-chat">
                    <AdminNavbar heading="Chat"/>
                    {chatRenderSwitch(currentChatPage)}
                </div>
                </Provider>
            </TeamContextProvider>
        </>
    )
}

export default ChatPage;