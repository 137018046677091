import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-main.svg';
import './HeaderBlank.scss';

export default function HeaderBlank(){
    return(
        <>
        <div className='site-header hidden md:block md:h-[76px]'>
            <div className='container'>
                <div className='logobar'><Link to="/"><img src={logo} alt="Plaisport" width={100} height={39} /></Link></div>
            </div>
        </div>
        </>
    )
}