import { useState } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Spinner from "../../../components/Spinner/Spinner";
import * as yup from 'yup';

const schema = yup.object({
    paid_through: yup
    .string()
    .required("Select an option."),
    other_paid_type: yup.string().when('paid_through',{
        is:'Other',
        then:yup.string().required('Please Enter Other Payment Type.')
    }),
}).required();

const ResponsePaymentForm =({setShowForm, teamID, eventID, responseSub, refreshEvent})=>{
    const [errorMessage,setErrorMessage] = useState(false);
    const [successMessage,setSuccessMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [paymentType, setPaymentType] = useState(null);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';
    
    const onSubmit = async(data) => {
        setErrorMessage(false);
        setSuccessMessage(false);
        setLoading(true);
        var formdata = new FormData();
            formdata.append("type", "update_payment_status_for_event");
            formdata.append("team_id", teamID);
            formdata.append("event_id", eventID);
            if(data.paid_through!=='Other'){
                formdata.append("paid_through", data.paid_through);
            }
            else{
                formdata.append("paid_through", data.other_paid_type);
            }
            formdata.append("sub",responseSub);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
            .then(rresponse => rresponse.text())
            .then(result => {
                setLoading(false);
                setShowForm(false);
                refreshEvent();
            })
            .catch(error => console.log('error', error));
    }
    return(
        !loading ?
        <>
        <div className="bg-[#363F6C] py-7.5 px-5 rounded-xl">
            <div>
                <div className="form-heading text-center mb-[30px] !text-white">Paid type</div>
                <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">                
                    <div>
                        <div className="form-field-container">
                            <div className="label">Select Paid Type*</div>
                            <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                <select {...register("paid_through")} onChange={(e)=>{setValue('paid_through', e.target.value, { shouldValidate: true }); setPaymentType(e.target.value);}}>
                                    <option value="">Select Paid Type*</option>
                                    <option value="Cash">Cash</option>
                                    <option value="Bank Transfer">Bank Transfer</option>
                                    <option value="Cheque">Cheque</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            {errors.paid_through?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.paid_through?.message}</p> }
                        </div>
                        {paymentType==='Other' &&
                            <>
                            <div className="form-field-container">
                                <div className="label">Other *</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                    <textarea disabled={loading} placeholder="Type here" className='text-field'
                                        {...register("other_paid_type")}
                                    />                                
                                </div> 
                                {errors.other_paid_type?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.other_paid_type?.message}</p> }                               
                            </div>                            
                            </>
                        }
                    </div>
                    {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                    {successMessage!=='' && <p className="text-green-600 mb-2.5 text-center" role="alert">{successMessage}</p>}
                    <div className="w-full px-2.5">
                        <div className='flex justify-center'>
                            <input className='button' type="submit" value="Submit" disabled={loading}/>
                        </div>
                    </div>                
                </form>
            </div>
        </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default ResponsePaymentForm;