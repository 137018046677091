import { useState, useEffect, useRef } from "react";
import CFGSTwoMemSelRow from "./CFGTwoSelMemRow";
import StarRating from "./StarRatings";
import CFGIconPlayerSlider from "./CFGIconPlayerSlider";
import CFGProPlayerSlider from "./CFGProPlayerSlider";
import CFGRookiePlayerSlider from "./CFGRookiePlayerSlider";

const CFGGameStepTwo = ({groups, selectedMembers, setSelectedMembers, iconplayers, setIconPlayers, proplayers, setProPlayers,rookieplayers,setRookiePlayers, setCurrentStep}) =>{
    const [showInfoTwo, setShowInfotwoForm] = useState(false);
    const [playerList, setPlayerList] = useState(selectedMembers);
    const [isCheckAll, setIsCheckAll] = useState(false);        
    const [isCheckplayers, setIsCheckPlayers] = useState([]);
    const [playersMain, setPlayerMain] = useState(selectedMembers);
    const [showFilterOpt,setFilterOpt] = useState(false);
    const [filterby, setFilterBy] = useState(null);
    const [q, setSearchQuery] = useState("");    
    const [searchParam] = useState(["name"]);
    const [bulkRatingform,setShowBulkRatingForm] = useState(false);

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheckPlayers(playerList.map(memitem => memitem));
        if (isCheckAll) {
            setIsCheckPlayers([]);
        }
    };
    
    useEffect(()=>{
        if(iconplayers.length>0){
            iconplayers.forEach((memitem,index)=>{
                setPlayerList((current)=>current.filter((mitem) => mitem._id !== memitem._id));
                setPlayerMain((current)=>current.filter((mitem) => mitem._id !== memitem._id));
            });
        }
        if(proplayers.length>0){
            proplayers.forEach((memitem,index)=>{
                setPlayerList((current)=>current.filter((mitem) => mitem._id !== memitem._id));
                setPlayerMain((current)=>current.filter((mitem) => mitem._id !== memitem._id));
            });
        }
        if(rookieplayers.length>0){
            rookieplayers.forEach((memitem,index)=>{
                setPlayerList((current)=>current.filter((mitem) => mitem._id !== memitem._id));
                setPlayerMain((current)=>current.filter((mitem) => mitem._id !== memitem._id));
            });
        }
    },[]);

    const ref = useRef();
    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }
    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (ref.current && !ref.current.contains(e.target)) {
                setFilterOpt(false);
            }
        }      
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[showFilterOpt]);

    const playersFilterBy = (fby) =>{
        let filtered_players;
        if(fby==='all'){
            filtered_players = playersMain;
        }
        else{
            filtered_players = playersMain.filter((playerItem)=>(playerItem.groups.includes(fby)));            
        }
        setFilterBy(fby);        
        setPlayerList(filtered_players);
    }
    const StepTwoInfo = ({setShowForm}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !max-w-[550px] !px-7.5">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <div className="w-full">
                        <h3 className="uppercase text-[22px] leading-[33px] font-semibold pb-[22px] border-b border-b-[#FBDB03] border-b-solid">FANTASY: RATING YOUR PLAYERS</h3>
                        <div className="flex mb-4">
                            <div className="flex mr-3"><span className="star mr-1.5 text-[#FBDB03]">&#9733;</span><span className="star mr-1.5 text-[#FBDB03]">&#9733;</span><span className="star text-[#FBDB03]">&#9733;</span></div>
                            <p className="flex-1">Your strongest players at the Club should be marked with 3 Stars and will be your ICONS.</p>
                        </div>
                        <div className="flex mb-4">
                            <div className="flex mr-3"><span className="star mr-1.5 text-[#EFF1F9]">&#9733;</span><span className="star mr-1.5 text-[#FBDB03]">&#9733;</span><span className="star text-[#FBDB03]">&#9733;</span></div>
                            <p className="flex-1">Your middle tier players at the Club should be marked with 2 Stars and will be your PROS.</p>
                        </div>
                        <div className="flex mb-4">
                            <div className="flex mr-3"><span className="star mr-1.5 text-[#EFF1F9]">&#9733;</span><span className="star mr-1.5 text-[#EFF1F9]">&#9733;</span><span className="star text-[#FBDB03]">&#9733;</span></div>
                            <p className="flex-1">Your lower tier players (inc. Juniors) at the Club should be marked with 1 Star and will be your ROOKIES</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const SetBulkRatings = ({setShowForm}) =>{
        const [starRate, setStarRate] = useState(0);
        const updateRate = (rate) =>{
            setStarRate(rate);
        }
        const handleBulkRating = () =>{
            if(starRate===1){
                isCheckplayers.forEach((memberitem)=>{
                    memberitem.rating=starRate;
                    setRookiePlayers(oldArray => [...oldArray,memberitem] );
                    setIconPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                    setProPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                    setPlayerList((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                    setPlayerMain((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                    setIsCheckPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                });                
            }
            else if(starRate===2){
                isCheckplayers.forEach((memberitem)=>{
                    memberitem.rating=starRate;
                    setProPlayers(oldArray => [...oldArray,memberitem] );
                    setIconPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                    setRookiePlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                    setPlayerList((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                    setPlayerMain((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                    setIsCheckPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                });
            }
            else if(starRate===3){
                isCheckplayers.forEach((memberitem)=>{
                    memberitem.rating=starRate;
                    setIconPlayers(oldArray => [...oldArray,memberitem] );
                    setProPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                    setRookiePlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                    setPlayerList((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                    setPlayerMain((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                    setIsCheckPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                });
            }
            setShowForm(false);
        }
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !max-w-[550px] !px-7.5">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <div className="w-full">
                        <h3 className="uppercase text-[22px] leading-[33px] font-semibold pb-[22px] border-b border-b-[#FBDB03] border-b-solid">RATING YOUR PLAYERS IN BULK</h3>
                        <div className="flex justify-center text-[28px] leading-[28px] mb-5"><StarRating onChange={updateRate} ratingValue={starRate}/></div>
                        <div className="flex justify-center">
                            <button className="button" type="button" onClick={()=>{handleBulkRating();}}>Apply now</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    return(
        <>
            <div className="px-2.5 sm:px-7.5 pt-[35px] mb-6">
                <div className="flex justify-between items-start">
                    <div className="flex-1 pr-5"><strong>Step 2:</strong> Now, assign your Fantasy players a RATING</div>
                    <div className="flex items-center">
                        <label htmlFor="search-form" className="search-field relative mr-3.5 !mb-0">
                            <input
                                type="search"
                                name="search-field"
                                id="search-form"
                                className="search-input"
                                placeholder="Search Player"
                                value={q}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />                        
                        </label>
                        <button className="info-button" onClick={()=>{setShowInfotwoForm(true)}}>info</button>
                    </div>
                </div>
                {showInfoTwo &&
                    <StepTwoInfo setShowForm={setShowInfotwoForm} />
                }
            </div>
            <div className="flex flex-wrap px-2.5 md:px-7 pb-2.5 md:pb-7">
                <div className="w-full lg:w-1/3 xl:max-w-[364px] mb-5 lg:mb-0">
                    <div className="pb-3 mb-3 border-b border-solid border-b-[#dfdfdf] flex justify-between relative" ref={ref}>
                        <h3 className="uppercase text-[#363F6C] text-[22px] leading-[33px] mb-0">Players List</h3>
                        {groups.length>0 &&
                        <>
                            <button className="btn-with-icon" onClick={(e)=>{setFilterOpt(!showFilterOpt)}}>
                            <svg width="14.52" height="14" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.76826 6.44336V14.3184L5.84401 11.6934V6.44336L0.046875 0.318359H14.5654L8.76826 6.44336Z" fill="currentColor"/>
                            </svg>
                            Filter by</button>
                            <ul className={`sort-actions absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showFilterOpt ? 'hidden' : ''}`}>
                                <li className={`${filterby==='all' ? 'active': ''}`}><div className='cursor-pointer' onClick={()=>{playersFilterBy('all');}}>All</div></li>
                                {groups.map((group)=>{
                                    return(
                                        <li key={group._id} className={`${filterby===group.name ? 'active': ''}`}><div className='cursor-pointer' onClick={()=>{playersFilterBy(group.name);}}>{group.name}</div></li>
                                    );
                                })}
                            </ul>
                        </>
                        }
                    </div>
                    {search(playerList).length > 0 ?
                        <>
                            <div className="flex justify-end  mb-2.5">
                                <label className='italic text-[14px] leading-[21px] text-black cursor-pointer flex items-center'>
                                    <input 
                                        type="checkbox"
                                        name="selectAll"
                                        id="selectAll"
                                        className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white align-top float-left mr-2 cursor-pointer'
                                        onChange={handleSelectAll}
                                        checked={isCheckAll} />
                                        Select all
                                </label>
                            </div>
                            <div className="md:grid md:gap-5 md:grid-cols-2 lg:block">
                                {search(playerList).map((memberitem,index)=>{                                        
                                    return (
                                        <CFGSTwoMemSelRow 
                                            memberitem={memberitem}
                                            playerList={playerList} 
                                            setPlayerList={setPlayerList}
                                            setPlayerMain={setPlayerMain}
                                            key={memberitem._id}
                                            isCheckAll={isCheckAll}
                                            setIsCheckAll={setIsCheckAll}
                                            isCheckplayers={isCheckplayers}
                                            setIsCheckPlayers={setIsCheckPlayers}
                                            iconplayers={iconplayers}
                                            setIconPlayers={setIconPlayers}
                                            proplayers={proplayers}
                                            setProPlayers={setProPlayers}
                                            rookieplayers={rookieplayers}
                                            setRookiePlayers={setRookiePlayers}  
                                            index={index} />
                                    )
                                })}
                                {isCheckplayers.length > 0 &&
                                    <>
                                    <div className="flex justify-center items-center pt-5 mb-5">
                                        <button className="button button--medium" onClick={()=>{setShowBulkRatingForm(true)}}>Set ratings</button>
                                    </div>
                                    {bulkRatingform &&
                                        <SetBulkRatings setShowForm={setShowBulkRatingForm} />
                                    }
                                    </>
                                }
                            </div>
                        </>
                        :
                        <>
                            <div className="text-center italic text-[18px] leading-[22px]">No player selected yet.</div>
                        </>
                    }
                </div>
                <div className="w-full lg:w-2/3 lg:pl-[28px]">
                    {iconplayers.length>0 &&
                        <CFGIconPlayerSlider 
                            setPlayerList={setPlayerList}
                            iconplayers={iconplayers}
                            setIconPlayers={setIconPlayers}
                            proplayers={proplayers}
                            setProPlayers={setProPlayers}
                            rookieplayers={rookieplayers}
                            setRookiePlayers={setRookiePlayers}
                            setPlayerMain={setPlayerMain}
                        />
                    }
                    {proplayers.length>0 &&
                        <CFGProPlayerSlider 
                            setPlayerList={setPlayerList}
                            iconplayers={iconplayers}
                            setIconPlayers={setIconPlayers}
                            proplayers={proplayers}
                            setProPlayers={setProPlayers}
                            rookieplayers={rookieplayers}
                            setRookiePlayers={setRookiePlayers}
                            setPlayerMain={setPlayerMain}
                        />
                    }
                    {rookieplayers.length>0 &&
                        <CFGRookiePlayerSlider 
                            setPlayerList={setPlayerList}
                            iconplayers={iconplayers}
                            setIconPlayers={setIconPlayers}
                            proplayers={proplayers}
                            setProPlayers={setProPlayers}
                            rookieplayers={rookieplayers}
                            setRookiePlayers={setRookiePlayers}
                            setPlayerMain={setPlayerMain}
                        />
                    }
                    {(rookieplayers.length > 0 || iconplayers.length > 0 || proplayers.length > 0) && playerList.length <= 0 &&
                        <div className="flex max-w-[830px] justify-center">
                            <button className="button button--medium" onClick={()=>{setCurrentStep(3)}}>Next</button>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default CFGGameStepTwo;