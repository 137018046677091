import { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logouticon from '../../../assets/images/delete-account-icon.png';
import Spinner from "../../../components/Spinner/Spinner";


const Deleteaccount=()=>{   
    const [deleteaccountLoading, setDeleteaccountLoading] = useState(false);
    const [error,setErrorMsg] = useState('');
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    const handleDeleteYes = async ()=>{
        setDeleteaccountLoading(true);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/deleteaccount' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setDeleteaccountLoading(false);
            var rdata=(JSON.parse(result));
            if(rdata.status===1){
                localStorage.removeItem('isAuthenticated');
                localStorage.removeItem('currentUser');
                localStorage.removeItem('auth_token');
                window.location.href = '/signin';
            }
            else{
                setErrorMsg(rdata.message);
            }
        })
        .catch(error => console.log('error', error));
        setDeleteaccountLoading(false);
    }
    const handleDeleteNo = ()=>{
        window.location.href = '/';
    }
    
    return(
       !deleteaccountLoading ?
        <>
            <div className="my_order_section">
                <div className="my_order_top">
                    <div className="ln-header flex justify-between items-center w-full pb-5 relative">
                        <h2 className="title mr-5">Delete Account</h2>
                        <div className="sep mr-5 sm:mr-11"></div> 
                    </div>
                </div>
                {error!=='' && <p className="text-red-500 mb-2.5 -mt-2.5" role="alert">{error}</p>}
                <div className="my_order_bottom flex justify-center item-center w-full text-center">
                   <div className="logout-main ">
                       <div className="logout_image flex justify-center">
                          <img src={logouticon} alt='logouticon' width={251} height={252} />
                       </div>
                       <div className="logout_content">
                          <p> Are you sure you want to</p>
                          <h3>Delete your Account?</h3>               
                       </div>
                       <div className="logout_btn my-8">
                          <ul className="flex justify-center item-center w-full text-center">
                            <li><button type="button" className="btn-grey yellow-bg mr-7" onClick={()=>{handleDeleteNo()}}>No</button></li>
                              <li><button type="button" className="btn-grey" onClick={()=>{handleDeleteYes()}}>Yes</button></li>
                          </ul>
                       </div>
                   </div>
                </div>
            </div>
        </>
       :
       <>
           <div className="py-5 text-center"><Spinner /></div>
       </>
    );
}

export default Deleteaccount;