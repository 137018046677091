import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as DOMPurify from 'dompurify';
import dayjs from "dayjs";
import truncate from "../../../libs/utils";
import { useAuthState } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import placeholderImg from '../../../assets/images/placeholder-transparent.png';
import IsMobile from '../../../components/isMobile';
import SubscriptionForm from '../../../components/SubscriptionForm/SubscriptionForm';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
} from "react-share";

const NewsCard =({newsitem, gridView, team, refreshNews})=>{    
    var newsinfo = '';
    if(!newsitem.is_ads){
        newsinfo = truncate(newsitem.details, 240, '...');
    }
    const [bookmark, setBookmark] = useState(false);
    const [bookmarkLoading, setBookmarkLoading] = useState(false);
    const [pinning, setPinning] = useState(false);
    const [pinLoading, setPinLoading] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [showSubscriptionFrom, setShowSubscriptionForm] = useState(false);
    const userDetail = useAuthState();
    var token = userDetail.token;
    const navigate = useNavigate();
    const ismobile = IsMobile(window);
    function viewNewsArticle(news) {
        navigate('/team/'+team._id+'/news/'+news._id);
    }
    const FormSubscription = ({setShowForm}) =>{        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap !w-full !max-w-[500px] relative">                    
                    <SubscriptionForm setShowForm={setShowForm} subfor={team.role==='admin' && team.subscription_type!=='add_ads' ? 'team' : 'user'} teamID={team._id}/>
                </div>
            </div>
        )
    }
    async function handleBookmarkAction(e){
        e.preventDefault();
        if(bookmarkLoading){
            return;
        }
        setBookmarkLoading(true);
        var formdata = new FormData();
        formdata.append("type", "bookmark_news");
        formdata.append("team_id",team._id);        
        formdata.append("news_id", newsitem._id);
        formdata.append("bookmark", bookmark ? 'no' : 'yes');
        formdata.append("bookmark_type",'news');
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_news' , requestOptions)
        .then(response => {            
            setBookmarkLoading(false);
            refreshNews();
        })
        .catch(error => console.log('error', error));

        setBookmark(!bookmark);
    }

    async function handlePinAction(e){
        e.preventDefault();
        if(pinLoading){
            return;
        }
        setPinLoading(true);
        var formdata = new FormData();
        formdata.append("type", "add_pin");
        formdata.append("team_id", team._id);
        formdata.append("news_id", newsitem._id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_news' , requestOptions)
        .then(response => {
            setPinLoading(false);
            refreshNews();
        })
        .catch(error => console.log('error', error));
        setPinning(!pinning);
    }

    const ShareNews = ({setShowShare}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative w-full max-w-[400px]">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowShare(false)}}><span>close</span></button>
                    <div className="form-heading text-center mb-[30px]">Share</div>
                    <div className='flex flex-wrap items-center'>
                        <div className='px-2.5 mb-5'>
                            <FacebookShareButton url={process.env.REACT_APP_WEBURL+'team/'+team._id+'/news/'+newsitem._id} quote={newsitem.title}>
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <LinkedinShareButton url={process.env.REACT_APP_WEBURL+'team/'+team._id+'/news/'+newsitem._id} title={newsitem.title} summary={newsitem.details_new} source='PLAI Sports'>
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>                            
                            <TwitterShareButton url={process.env.REACT_APP_WEBURL+'team/'+team._id+'/news/'+newsitem._id} title={newsitem.title}>
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <WhatsappShareButton url={process.env.REACT_APP_WEBURL+'team/'+team._id+'/news/'+newsitem._id} title={newsitem.title}>
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <EmailShareButton url={process.env.REACT_APP_WEBURL+'team/'+team._id+'/news/'+newsitem._id} subject={truncate(newsitem.title,500,'...')} body={truncate(newsitem.details_new.replaceAll("&nbsp;", "\n"), 1024, '...')}>
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(()=>{
        setBookmark(newsitem.bookmark === 'yes' ? true : false);
        setPinning(newsitem.pin && newsitem.pin === 'yes' ? true : false);
    },[newsitem]);

    return(
        !ismobile ?
        <>  
            {!newsitem.is_ads ?
                <div className="newscard relative">
                    <Link className="image-wrapper block relative rounded-[14px] overflow-hidden cursor-pointer" to={'/team/'+team._id+'/news/'+newsitem._id}>
                    {newsitem.images ? 
                        <img src={newsitem.images} alt="" className="md:absolute object-cover w-full h-full" />
                        : 
                        <img src={placeholderImg} alt="" className="md:absolute object-contain w-full h-full" />
                        
                    }
                    </Link>
                    <div className="news-overview">
                        <div className="news-heading">
                            <h2 className='cursor-pointer'><Link to={'/team/'+team._id+'/news/'+newsitem._id}>{newsitem.title}</Link></h2>
                            <div className="newsmeta">
                                {(newsitem?.user?.firstName!==undefined && newsitem?.user?.lastName!==undefined) &&
                                    <>
                                        <div className="publish-by">
                                            <span>Created </span>by {newsitem?.user?.firstName} {newsitem?.user?.lastName}
                                        </div>
                                    </>
                                }
                                <div className="publish-on">
                                    <span>{dayjs(newsitem.date).format("DD MMM YYYY")}</span>
                                </div>                        
                            </div>
                        </div>
                        <div className="news-content mb-3.5" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(newsinfo)}} />
                        <div className="newscard-footer flex justify-between items-center">
                            <div className="status">
                                <span className={`uppercase block text-center ${newsitem.published ? 'publish': 'unpublish'}`}>
                                    {newsitem.published ? 'Published' : 
                                    <>
                                        {newsitem.released ?
                                            `Unpublished`
                                        :
                                            <>
                                                {`SCHEDULED FOR ${dayjs(newsitem.date).format("DD MMM YYYY")} at ${newsitem.hour_new}`}
                                            </>
                                        }
                                    </>
                                    }
                                </span>
                            </div>
                            <div className="newsitem-cta flex">
                                <button type="button" className={`button-tiny pin mr-2 rounded-full ${pinning ? 'pinned' : 'dopinned'} ${pinLoading ? 'loading' : ''}`} onClick={(e)=>handlePinAction(e)} title={pinning ? 'Unpin' : 'Pin'}>Pin</button>
                                <button type="button" className={`button-tiny bookmark mr-2 rounded-full ${bookmark ? 'bookmarked' : 'dobookmark'} ${bookmarkLoading ? 'loading' : ''}`} onClick={(e)=>handleBookmarkAction(e)} title={bookmark ? 'Remove Bookmark' : 'Bookmark'}>Bookmark</button>
                                <button type="button" className="button-tiny share rounded-full" onClick={()=>{setShowShare(true)}}>Share</button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <>
                {!gridView &&
                    <div className="newscard relative">
                    <div className='relative news-ads w-full h-full'>
                        <div className="relative w-full max-w-[1200px] mx-auto">
                            <a className="image-wrapper after:hidden !bg-transparent block relative rounded-[14px] overflow-hidden cursor-pointer" href={newsitem.redirect_url} target="_blank" rel="noreferrer">
                                {newsitem.web_images ? 
                                    <img src={newsitem.web_images} width={newsitem.web_width} height={newsitem.web_height}  alt="" className="md:absolute object-cover w-full h-full" />
                                    : 
                                    <img src={placeholderImg} alt="" className="md:absolute object-contain w-full h-full" />
                                    
                                }
                            </a>
                            {!gridView &&
                                <button type="button" className='button button-medium absolute top-[10px] right-[10px]' onClick={()=>{setShowSubscriptionForm(true);}}>Remove</button>
                            }
                        </div>
                    </div>
                    {showSubscriptionFrom && 
                        <FormSubscription setShowForm={setShowSubscriptionForm}/>
                    }
                    </div>
                }
                </>
            }
            {showShare &&
                <ShareNews setShowShare={setShowShare}/>
            }
        </>
        :
        <>  
            {!newsitem.is_ads ?
                <div className={`${!newsitem.is_ads ? 'mobile-row mobile-news':''} flex flex-wrap p-2.5 mb-5`}>
                    <div className='flex w-full justify-between'>
                        <div className="flex-1 text-[16px] leading-[24px] text-[#363F6C] font-bold mb-2.5" onClick={() => { viewNewsArticle(newsitem) }}>{newsitem.title}</div>
                        <div className="newsitem-cta flex w-[94px]">
                            <button type="button" className={`button-tiny pin mr-2 rounded-full ${pinning ? 'pinned' : 'dopinned'} ${pinLoading ? 'loading' : ''}`} onClick={(e)=>handlePinAction(e)} title={pinning ? 'Unpin' : 'Pin'}>Pin</button>
                            <button type="button" className={`button-tiny bookmark mr-2 rounded-full ${bookmark ? 'bookmarked' : 'dobookmark'} ${bookmarkLoading ? 'loading' : ''}`} onClick={(e)=>handleBookmarkAction(e)} title={bookmark ? 'Remove Bookmark' : 'Bookmark'}>Bookmark</button>
                            <button type="button" className="button-tiny share rounded-full" onClick={()=>{setShowShare(true)}}>Share</button>
                        </div>
                    </div>                
                    <div className="w-1/3 pr-2.5" onClick={() => { viewNewsArticle(newsitem) }}>
                        <div className="image-wrapper rounded-lg overflow-hidden">
                        {newsitem.images && newsitem.images !== "" ?
                            <img src={newsitem.images} alt="" className='w-full object-cover min-h-[85px]' />                        
                            :
                            <img src={placeholderImg} alt="" className='w-full object-cover min-h-[85px]' />
                        }
                        </div>
                    </div>
                    <div className="w-2/3 flex flex-col justify-between">
                        <div className='newsinfo'>
                            <div className="publish-on text-[12px] leading-[14px]">
                                <span>{dayjs(newsitem.date).format("DD MMM YYYY")} by {newsitem?.user?.firstName} {newsitem?.user?.lastName}</span>
                            </div>
                        </div>
                        <div className="newscard-footer flex justify-between items-center">
                            <div className="status">
                                <span className={`uppercase block text-center ${newsitem.published ? 'publish': 'unpublish'}`}>
                                    {newsitem.published ? 'Published' : 
                                    <>
                                        {newsitem.released ?
                                            `Unpublished`
                                        :
                                            <>
                                                {`SCHEDULED FOR ${dayjs(newsitem.date).format("DD MMM YYYY")} at ${newsitem.hour_new}`}
                                            </>
                                        }
                                    </>
                                    }
                                </span>
                            </div>                        
                        </div>
                    </div>
                </div>
                :
                <>
                <div className={`${!newsitem.is_ads ? 'mobile-row mobile-news':''} flex flex-wrap p-2.5 mb-5`}>
                    <div className='relative block'>
                        <a className="image-wrapper rounded-lg overflow-hidden mb-5 !bg-transparent" href={newsitem.redirect_url} target="_blank" rel="noreferrer">
                            {newsitem.web_images && newsitem.web_images !== "" ?
                                <img src={newsitem.web_images} alt="" className='w-full object-cover min-h-[85px]' />                        
                                :
                                <img src={placeholderImg} alt="" className='w-full object-cover min-h-[85px]' />
                            }
                        </a>
                        <button type="button" className='button button-medium absolute top-[10px] right-[10px]' onClick={()=>{setShowSubscriptionForm(true);}}>Remove</button>
                    </div>
                    {showSubscriptionFrom && 
                        <FormSubscription setShowForm={setShowSubscriptionForm} />
                    }
                </div>
                </>
            }
            {showShare &&
                <ShareNews setShowShare={setShowShare}/>
            }       
        </>        
    );
}

export default NewsCard;