import { NavLink } from 'react-router-dom';
import ThankYouImg from '../assets/images/thankyou.png';
import IsMobile from '../components/isMobile';

const FantacyGameThankyou = () =>{
    const isMobile = IsMobile(window);
    return(
        <>
        <div id="page" className="page-thankyou !ml-0">
            <div className="empty text-center">
                <div className="image"><img src={ThankYouImg} alt="Plaisport" width={500} /></div>
                <div className="title mb-5">Thank you for your purchase</div>
                <div className="description mb-8">                    
                    {isMobile ?
                        <>
                            <p className='mb-5'>Your purchase was successful, you can now<br/><strong className='font-bold'>CREATE YOUR FANTASY TEAM!</strong></p>
                            <p>Please press <strong className='font-bold'>Home</strong> at the top right to return on the PLAI app.</p>
                        </>
                    :
                        <>
                            Your purchase was successful, you can now <strong className='font-bold'>CREATE YOUR FANTASY TEAM!</strong>
                        </>
                    }
                </div>
                {!isMobile && 
                    <div className='flex justify-center'>                    
                        <NavLink className='button' to="/">HOME</NavLink>
                    </div>
                }
            </div>
        </div>        
        </>
    )
}

export default FantacyGameThankyou;