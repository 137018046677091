import placeholderImg from '../../../assets/images/placeholder.png';

const CFGSOneMemSelRow = ({memberitem,setSelectedMembers, selectedMembers, setMembers, members,  index, membersMain,setMembersMain}) =>{
    const removeOnClick = (index) =>{
        setSelectedMembers([
            ...selectedMembers.slice(0, index),
            ...selectedMembers.slice(index + 1)
        ]);
        setMembersMain(oldArray => [...oldArray,memberitem]);
        setMembers(oldArray => [...oldArray,memberitem]);
    }
    return (
        <div className="w-full px-10 mb-5">
            <div className="w-full p-2.5 flex justify-between rounded-[16px] bg-white items-center">
                <div className="flex items-center">
                    {memberitem.profilePicture !=="" ?
                        <img src={memberitem.profilePicture} width="50" height="50" className="rounded-full" alt="" />
                    :
                        <img src={placeholderImg} width="50" height="50" className="rounded-full" alt="" />
                    }
                    <span className="pl-[16px] pr-2.5 text-[#363F6C] text-[16px] leading-[24px] font-semibold">{memberitem.name}</span>
                </div>
                <button className="minus-btn mr-3 cursor-pointer" onClick={()=>{removeOnClick(index)}}></button>
            </div>
        </div>
    )
}

export default CFGSOneMemSelRow;